import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Form, Row, Col, Button, notification } from 'antd'
import { Redirect } from "react-router-dom";

/* CUSTOM COMPONENT */
import Header from '../../../component/Header/Header';
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveModule, saveModuleReset } from '../../../action/saveModuleAction'
import { getModuleDetail, getModuleDetailReset } from '../../../action/getModuleDetailAction'

const ModuleMasterSave = (props) => {
    /* VARIABLES */
    const id = props.match.params.id
    const mode = id ? "edit" : "add"
    const {
        saveModule, saveModuleReset, saveModuleState,
        getModuleDetail, getModuleDetailReset, getModuleDetailState,
    } = props
    const [formData, setFormData] = useState({
        name: "",
        key: "",
    })
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    if (mode === "edit") {
        formData.id = id
        formData.mode = mode
    }

    /* CALLBACKS */
    useEffect(() => {
        return (() => {
            saveModuleReset()
            getModuleDetailReset()
        })
    }, [])
    useEffect(() => {
        if (mode === "edit") {
            getModuleDetail({
                id: id
            })
        }
    }, [])
    useEffect(() => {
        if (saveModuleState.apiState === "success") {
            notification["success"]({
                message: saveModuleState.message,
                placement: "topRight"
            })
            setRedirect([true, "/module/list"])
        }
        if (saveModuleState.apiState === "error") {
            notification["error"]({
                message: saveModuleState.data,
                placement: "topRight",
            })
        }
    }, [saveModuleState])

    useEffect(() => {
        if (getModuleDetailState.apiState === "success") {
            let data = getModuleDetailState.data
            form.setFieldsValue({
                name: data.name,
                key: data.key,
            })
            setFormData({
                ...formData,
                ["name"]: data.name,
                ["key"]: data.key,
            })
        }
    }, [getModuleDetailState])

    /* FUNCTIONS */

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        saveModule(formData)
    }


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                backButton={"/module/list"}
                title={mode === "edit" ? "Edit Module" : "Add Module"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    name="name"
                                    label="Module Name"
                                    rules={[{
                                        required: true,
                                        message: "Required"
                                    }]}
                                    onChange={handleChange}
                                >
                                    <Input name="name" placeholder="Enter Module Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    name="key"
                                    label="Module Key"
                                    rules={[{
                                        required: true,
                                        message: "Required"
                                    }]}
                                    onChange={handleChange}
                                >
                                    <Input name="key" placeholder="Enter Module Key" />
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            mode === "edit" ?
                                <Button loading={getModuleDetailState.apiState === "loading"} type="primary" htmlType="submit">Update Module</Button> :
                                <Button loading={saveModuleState.apiState === "loading"} type="primary" htmlType="submit">Add Module</Button>
                        }
                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveModuleState: state.saveModule,
    getModuleDetailState: state.getModuleDetail,
})
const mapDispatchToProps = (dispatch) => ({
    saveModule: (params) => dispatch(saveModule(params)),
    saveModuleReset: () => dispatch(saveModuleReset()),
    getModuleDetail: (params) => dispatch(getModuleDetail(params)),
    getModuleDetailReset: () => dispatch(getModuleDetailReset()),
})


export default connect(mapStateToProps, mapDispatchToProps)(ModuleMasterSave)

