import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_ADMIN_TYPE_LIST,
    getAdminTypeListFail,
    getAdminTypeListSuccess
} from '../action/getAdminTypeListAction'

// api 
import getAdminTypeListApi from '../api/getAdminTypeListApi'

export function* getAdminTypeListSaga(action) {
    try {
        const response = yield call(() => getAdminTypeListApi(action.params))
        if (response.data.status === 1) {
            yield put(getAdminTypeListSuccess(response, action))
        } else {
            yield put(getAdminTypeListFail(response, action))
        }
    }
    catch (e) {
        yield put(getAdminTypeListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_ADMIN_TYPE_LIST, getAdminTypeListSaga)
}