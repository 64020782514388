import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE,
    getInstituteTestPricingMasterInstituteFail,
    getInstituteTestPricingMasterInstituteSuccess
} from '../action/getInstituteTestPricingMasterInstituteAction'

// api 
import getInstituteTestPricingMasterInstituteApi from '../api/getInstituteTestPricingMasterInstituteApi'

export function* getInstituteTestPricingMasterInstituteSaga(action) {
    try {
        const response = yield call(() => getInstituteTestPricingMasterInstituteApi(action.params))
        if (response.data.status === 1) {
            yield put(getInstituteTestPricingMasterInstituteSuccess(response, action))
        } else {
            yield put(getInstituteTestPricingMasterInstituteFail(response, action))
        }
    }
    catch (e) {
        yield put(getInstituteTestPricingMasterInstituteFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_INSTITUTE_TEST_PRICING_MASTER_INSTITUTE, getInstituteTestPricingMasterInstituteSaga)
}