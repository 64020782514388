import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PREGNANCY_HIGHLIGHTS_LIST,
    getPregnancyHighlightsListFail,
    getPregnancyHighlightsListSuccess
} from '../action/getPregnancyHighlightsListAction'

// api 
import getPregnancyHighlightsListApi from '../api/getPregnancyHighlightsListApi'

export function* getPregnancyHighlightsListSaga(action) {
    try {
        const response = yield call(() => getPregnancyHighlightsListApi(action.params))
        if (response.data.status === 1) {
            yield put(getPregnancyHighlightsListSuccess(response, action))
        } else {
            yield put(getPregnancyHighlightsListFail(response, action))
        }
    }
    catch (e) {
        yield put(getPregnancyHighlightsListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PREGNANCY_HIGHLIGHTS_LIST, getPregnancyHighlightsListSaga)
}