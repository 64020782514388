import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_LEDGER_ACCOUNT_LIST,
    getLedgerAccountListFail,
    getLedgerAccountListSuccess
} from '../action/getLedgerAccountListAction'

// api 
import getLedgerAccountListApi from '../api/getLedgerAccountListApi'

export function* getLedgerAccountListSaga(action) {
    try {
        const response = yield call(() => getLedgerAccountListApi(action.params))
        if (response.data.status === 1) {
            yield put(getLedgerAccountListSuccess(response, action))
        } else {
            yield put(getLedgerAccountListFail(response, action))
        }
    }
    catch (e) {
        yield put(getLedgerAccountListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_LEDGER_ACCOUNT_LIST, getLedgerAccountListSaga)
}