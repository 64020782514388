import React from "react"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"
import BulkUpload from "../../../component/BulkUpload/BulkUpload"

/* actions */

/* otehrs */

export const SampleContainerTestMapMasterBulkUpload = props => {
    /* variables */
    return (
        <>
            <Header
                backButton={"/sample-container-test-map-master/list"}
                title={"Sample Container Master Bulk Upload"}
            />
            <PageContainer>
                <LayoutContainer>
                    <BulkUpload master="sampleContainerTestMapMaster" backUrl="/sample-container-test-map-master/list" apiUrl="sample-container-test-map/bulk-upload" />
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

export default SampleContainerTestMapMasterBulkUpload;