import { call, put, takeLatest } from 'redux-saga/effects'

import {
    LOGOUT,
    logoutSuccess,
    logoutFail
} from '../action/logoutAction'

import logoutApi from '../api/logoutApi'

export function* logoutSaga(action) {
    try {
        const response = yield call(() => logoutApi(action.params))
        if (response) {
            yield put(logoutSuccess(response, action))
        }
    } catch (e) {
        yield put(logoutFail(e.response, action))
    }
}

export default function* MySaga() {
    yield takeLatest(LOGOUT, logoutSaga);
}