import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_ROLE_MODULE_MAPPING,
    saveRoleModuleMappingFail,
    saveRoleModuleMappingSuccess
} from '../action/saveRoleModuleMappingAction'

// api 
import saveRoleModuleMappingApi from '../api/saveRoleModuleMappingApi'

export function* saveRoleModuleMappingSaga(action) {
    try {
        const response = yield call(() => saveRoleModuleMappingApi(action.params))
        if (response.data.status === 1) {
            yield put(saveRoleModuleMappingSuccess(response, action))
        } else {
            yield put(saveRoleModuleMappingFail(response, action))
        }
    }
    catch (e) {
        yield put(saveRoleModuleMappingFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_ROLE_MODULE_MAPPING, saveRoleModuleMappingSaga)
}