import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_PAGE_CONTENT,
    savePageContentFail,
    savePageContentSuccess
} from '../action/savePageContentAction'

// api 
import savePageContentApi from '../api/savePageContentApi'

export function* savePageContentSaga(action) {
    try {
        const response = yield call(() => savePageContentApi(action.params))
        if (response.data.status === 1) {
            yield put(savePageContentSuccess(response, action))
        } else {
            yield put(savePageContentFail(response, action))
        }
    }
    catch (e) {
        yield put(savePageContentFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_PAGE_CONTENT, savePageContentSaga)
}