import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Form, Row, Col, Button, notification, Select } from 'antd'
import { Redirect } from "react-router-dom";

/* CUSTOM COMPONENT */

/* actions */
import { saveSystemUser, saveSystemUserReset } from '../../action/saveSystemUsersAction'
import { systemUserDetail, systemUserDetailReset } from '../../action/systemUserDetailAction'
import { getRoleList, getRoleListReset } from '../../action/getRoleListAction'


const AddUser = (props) => {
    /* VARIABLES */
    const {
        saveSystemUser, saveSystemUserReset, saveSystemUserState,
        systemUserDetail, systemUserDetailReset, systemUserDetailState,
        getRoleList, getRoleListReset, getRoleListState,
    } = props
    const [userData, setUserData] = useState({
        fname: "",
        lname: "",
        email: "",
        password: "",
        role: "",

    })
    const [roleList, setRoleList] = useState([])
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()
    const user_id = props.user_id
    const mode = user_id ? "edit" : "add"
    const { Option } = Select;

    if (mode === "edit") {
        userData.id = user_id
        userData.mode = mode
    }

    /* CALLBACKS */
    useEffect(() => {
        getRoleList({
            dropDownList: "/get-dropdown-list"
        })
        return (() => {
            saveSystemUserReset()
            systemUserDetailReset()
        })
    }, [])

    useEffect(() => {
        if (getRoleListState.apiState === "success") {
            setRoleList(getRoleListState.list)
            getRoleListReset()
        }
    }, [getRoleListState])
    useEffect(() => {
        if (mode === "edit") {
            systemUserDetail({
                id: user_id
            })
        }
    }, [])
    useEffect(() => {
        if (saveSystemUserState.apiState === "success") {
            notification["success"]({
                message: saveSystemUserState.data.message,
                placement: "topRight"
            })
            setRedirect([true, props.page_url])
        }
        if (saveSystemUserState.apiState === "error") {
            notification["error"]({
                message: saveSystemUserState.data,
                placement: "topRight",
            })
        }
    }, [saveSystemUserState])

    useEffect(() => {
        if (systemUserDetailState.apiState === "success") {
            let data = systemUserDetailState.data.data.result
            form.setFieldsValue({
                fname: data.fname,
                lname: data.lname,
                email: data.email,
                role: data.role?._id,
            })
            setUserData({
                ...userData,
                ["fname"]: data.fname,
                ["lname"]: data.lname,
                ["email"]: data.email,
                ["role"]: data.role?._id,
            })
        }
    }, [systemUserDetailState])

    /* FUNCTIONS */

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }
    const handleSelectChange = (name, value) => {
        setUserData({
            ...userData,
            [name]: value
        })
    }

    const handleSubmit = () => {
        saveSystemUser(userData)
    }
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Form form={form} layout="vertical" onFinish={handleSubmit} >
                <Row gutter={20}>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            name="fname"
                            label="First Name"
                            rules={[{
                                required: true,
                                message: "Required"
                            }]}
                            onChange={handleChange}
                        >
                            <Input name="fname" placeholder="Enter Your First Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            name="lname"
                            label="Last Name"
                            rules={[{
                                required: true,
                                message: "Required"
                            }]}
                            onChange={handleChange}
                        >
                            <Input name="lname" placeholder="Enter Your Last Name" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{
                                required: true,
                                message: "Required"
                            }]}
                            onChange={handleChange}
                        >
                            <Input name="email" placeholder="Enter Your Email Address" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[{
                                required: mode === "edit" ? false : true,
                                message: "Required"
                            }]}
                            onChange={handleChange}
                        >
                            <Input.Password name="password" placeholder="Enter Your Password" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            name="role"
                            label="Role"
                            rules={[{
                                required: mode === "edit" ? false : true,
                                message: "Required"
                            }]}
                        >
                            <Select
                                name="role"
                                placeholder="Select Role"
                                showSearch
                                allowClear
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={(v) => handleSelectChange("role", v)}
                                disabled={mode === "edit"}
                            >
                                {roleList.map((row, index) => {
                                    return (
                                        <Select.Option value={`${row._id}`} key={`${index}`}>{row.name}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    mode === "edit" ?
                        <Button loading={systemUserDetailState.apiState === "loading"} type="primary" htmlType="submit">Update User</Button> :
                        <Button loading={saveSystemUserState.apiState === "loading"} type="primary" htmlType="submit">Add User</Button>
                }
            </Form>

        </>
    )
}

const mapStateToProps = (state) => ({
    saveSystemUserState: state.saveSystemUser,
    systemUserDetailState: state.systemUserDetail,
    getRoleListState: state.getRoleList,

})
const mapDispatchToProps = (dispatch) => ({
    saveSystemUser: (params) => dispatch(saveSystemUser(params)),
    saveSystemUserReset: () => dispatch(saveSystemUserReset()),
    systemUserDetail: (params) => dispatch(systemUserDetail(params)),
    systemUserDetailReset: () => dispatch(systemUserDetailReset()),
    getRoleList: (params) => dispatch(getRoleList(params)),
    getRoleListReset: () => dispatch(getRoleListReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)

