import React, { useEffect } from 'react'
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { CloseOutlined, EditOutlined } from "@ant-design/icons"

/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { systemUserDetail, systemUserDetailReset } from '../../../action/systemUserDetailAction'

const SystemUserView = (props) => {
    /* varibles */
    const { systemUserDetail, systemUserDetailReset, systemUserDetailState } = props

    /* callbacks */
    useEffect(() => {
        return (() => {
            systemUserDetailReset()
        })
    }, [])

    useEffect(() => {
        if (props.id && props.visible) {
            systemUserDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`User View`}
            placement="right"
            closeIcon={<><Link to={`/user/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {systemUserDetailState.apiState === "loading" &&
                <Skeleton active />}

            {systemUserDetailState.apiState === "error" &&
                <p>{systemUserDetailState.message}</p>
            }
            {systemUserDetailState.apiState === "success" &&
                <Row gutter={20} >
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>First Name</ViewLabel>
                            <ViewValue>{systemUserDetailState.data.data.result.fname}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>Last Name</ViewLabel>
                            <ViewValue>{systemUserDetailState.data.data.result.lname}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>Email</ViewLabel>
                            <ViewValue>{systemUserDetailState.data.data.result.email}</ViewValue>
                        </ViewBox>
                    </Col>
                </Row>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    systemUserDetailState: state.systemUserDetail
});

const mapDispatchToProps = (dispatch) => ({
    systemUserDetail: (params) => dispatch(systemUserDetail(params)),
    systemUserDetailReset: () => dispatch(systemUserDetailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemUserView);
