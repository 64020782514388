
import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_NEW_DOCTOR_LIST,
    getNewDoctorListFail,
    getNewDoctorListSuccess
} from '../action/getNewDoctorListAction'

// api 
import getNewDoctorListApi from '../api/getNewDoctorListApi'

export function* getNewDoctorListSaga(action) {
    try {
        const response = yield call(() => getNewDoctorListApi(action.params))
        if (response.data.status === 1) {
            yield put(getNewDoctorListSuccess(response, action))
        } else {
            yield put(getNewDoctorListFail(response, action))
        }
    }
    catch (e) {
        yield put(getNewDoctorListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_NEW_DOCTOR_LIST, getNewDoctorListSaga)
}
