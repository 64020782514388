import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getContainerDetail, getContainerDetailReset } from "../../../action/getContainerDetailAction"
import { dateFormat } from '../../../utils';

const ContainerView = props => {
    /* variable */
    const { getContainerDetail, getContainerDetailReset, getContainerDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getContainerDetailReset()
        }
    }, [props.visible])

    useEffect(() => {
        if (props.id && props.visible) {
            getContainerDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`Container Master View ${getContainerDetailState.apiState === "success" ? "- " + getContainerDetailState.data.container_type : ""}`}
            placement="right"
            closeIcon={<><Link to={`/container-master/edit/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getContainerDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getContainerDetailState.apiState === "error" &&
                <p>{getContainerDetailState.message}</p>
            }

            {getContainerDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Container Type</ViewLabel>
                                <ViewValue>{getContainerDetailState.data.container_type}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Display Container Name</ViewLabel>
                                <ViewValue>{getContainerDetailState.data.display_container_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Container Cap Color</ViewLabel>
                                <ViewValue>{getContainerDetailState.data.container_cap_color}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>MRP</ViewLabel>
                                <ViewValue>{getContainerDetailState.data.mrp}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>SP</ViewLabel>
                                <ViewValue>{getContainerDetailState.data.sp}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CP</ViewLabel>
                                <ViewValue>{getContainerDetailState.data.cp}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getContainerDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getContainerDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>

                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getContainerDetailState: state.getContainerDetail
});

const mapDispatchToProps = (dispatch) => ({
    getContainerDetail: (params) => dispatch(getContainerDetail(params)),
    getContainerDetailReset: () => dispatch(getContainerDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerView);