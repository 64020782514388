import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_ROLE_LIST,
    getRoleListFail,
    getRoleListSuccess
} from '../action/getRoleListAction'

// api 
import getRoleListApi from '../api/getRoleListApi'

export function* getRoleListSaga(action) {
    try {
        const response = yield call(() => getRoleListApi(action.params))
        if (response.data.status === 1) {
            yield put(getRoleListSuccess(response, action))
        } else {
            yield put(getRoleListFail(response, action))
        }
    }
    catch (e) {
        yield put(getRoleListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_ROLE_LIST, getRoleListSaga)
}