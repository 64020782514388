import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { CloseOutlined, EditOutlined } from "@ant-design/icons"


/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getArticleDetail, getArticleDetailReset } from "../../../action/getArticleDetailAction"
import { dateFormat, s3_cdn } from '../../../utils';

const ContentRepoView = props => {
    /* variable */
    const { getArticleDetail, getArticleDetailReset, getArticleDetailState, pageUrl, pageType } = props
    const [removeData, setRemoveData] = useState({
        tribe: false,
        category: false,
        week: false,
        gender: false
    })
    /* callbacks */

    useEffect(() => {
        if (props.visible === false) {
            getArticleDetailReset()
        }
    }, [props.visible])
    useEffect(() => {
        if (props.id && props.visible) {
            getArticleDetail({
                id: props.id
            })
            if (pageType === 'Pregnancy') {
                setRemoveData({
                    tribe: true,
                    category: false,
                    week: false,
                    gender: true
                })
            }
            else if (pageType === 'Tribe') {
                setRemoveData({
                    tribe: false,
                    category: true,
                    week: true,
                    gender: false
                })
            }
            else if (pageType === 'General') {
                setRemoveData({
                    tribe: true,
                    category: false,
                    week: true,
                    gender: false
                })
            }
            else if (pageType === 'Doctor') {
                setRemoveData({
                    tribe: true,
                    category: true,
                    week: true,
                    gender: false
                })
            }
        }

    }, [props.id, props.visible])
    return (
        <Drawer
            title={`Content Repo ${pageType} View ${getArticleDetailState.apiState === "success" ? "- " + getArticleDetailState.data.title : ""}`}
            placement="right"
            closeIcon={<><Link to={`/${pageUrl}/edit/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getArticleDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getArticleDetailState.apiState === "error" &&
                <p>{getArticleDetailState.message}</p>
            }

            {getArticleDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Title</ViewLabel>
                                <ViewValue>{getArticleDetailState.data.title}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Slug</ViewLabel>
                                <ViewValue>{getArticleDetailState.data.slug}</ViewValue>
                            </ViewBox>
                        </Col>
                        {!removeData.category &&
                            <Col span="12">
                                <ViewBox>
                                    <ViewLabel>Category</ViewLabel>
                                    <ViewValue>{getArticleDetailState.data.category.length > 0 &&
                                        getArticleDetailState.data.category.map(obj => obj.value).join(", ")}</ViewValue>
                                </ViewBox>
                            </Col>
                        }
                        {!removeData.week &&
                            <Col span="12">
                                <ViewBox>
                                    <ViewLabel>Week</ViewLabel>
                                    <ViewValue>{getArticleDetailState.data.week && getArticleDetailState.data.week.join(", ")}</ViewValue>
                                </ViewBox>
                            </Col>
                        }

                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Type</ViewLabel>
                                <ViewValue>{getArticleDetailState.data.type}</ViewValue>
                            </ViewBox>
                        </Col>
                        {getArticleDetailState.data.type === "Video Article" &&
                            <Col span="12">
                                <ViewBox>
                                    <ViewLabel>Video: {getArticleDetailState.data.video_type}</ViewLabel>
                                    <ViewValue>
                                        {getArticleDetailState.data.video_type === "YouTube"
                                            ? <Link to={{ pathname: getArticleDetailState.data.video_link }} target="_blank">{getArticleDetailState.data.video_link}</Link>
                                            : <Link to={{
                                                pathname: `${s3_cdn()}article/${getArticleDetailState.data.video_path}`
                                            }} target="_blank">{getArticleDetailState.data.video_path ? "Video File" : ""}</Link>
                                        }
                                    </ViewValue>
                                </ViewBox>
                            </Col>
                        }
                        {getArticleDetailState.data.type === "Audio Article" &&
                            <Col span="12">
                                <ViewBox>
                                    <ViewLabel>Audio Path</ViewLabel>
                                    <ViewValue><Link to={{
                                        pathname: `${s3_cdn()}article/${getArticleDetailState.data.audio_path}`
                                    }} target="_blank">{getArticleDetailState.data.audio_path ? "Audio File" : ""}</Link></ViewValue>
                                </ViewBox>
                            </Col>
                        }
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Image Path</ViewLabel>
                                <ViewValue>
                                    <img src={`${s3_cdn()}article/${getArticleDetailState.data.image_path}`} style={{ width: 60 }} />
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Icon Image Path</ViewLabel>
                                <ViewValue>
                                    <img src={`${s3_cdn()}article/${getArticleDetailState.data.icon_image_path}`} style={{ width: 60 }} />
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        {!removeData.tribe &&
                            <Col span="12">
                                <ViewBox>
                                    <ViewLabel>Tribe Tags</ViewLabel>
                                    <ViewValue>{getArticleDetailState.data.support_tribe.length > 0 &&
                                        getArticleDetailState.data.support_tribe.map(obj => obj.value).join(", ")}</ViewValue>
                                </ViewBox>
                            </Col>
                        }
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Tags</ViewLabel>
                                <ViewValue>{getArticleDetailState.data.tags.length > 0 &&
                                    getArticleDetailState.data.tags.map(obj => obj.value).join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Gender</ViewLabel>
                                <ViewValue>{getArticleDetailState.data.gender}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Published At</ViewLabel>
                                <ViewValue>{getArticleDetailState.data.published_at ? dateFormat(getArticleDetailState.data.published_at) : ""}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="24">
                            <ViewBox>
                                <ViewLabel>Short Description</ViewLabel>
                                <ViewValue>{getArticleDetailState.data.short_description}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="24">
                            <ViewBox>
                                <ViewLabel>Description</ViewLabel>
                                <ViewValue>
                                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(getArticleDetailState.data.description) }} />
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getArticleDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getArticleDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>

                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getArticleDetailState: state.getArticleDetail
});

const mapDispatchToProps = (dispatch) => ({
    getArticleDetail: (params) => dispatch(getArticleDetail(params)),
    getArticleDetailReset: () => dispatch(getArticleDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(ContentRepoView);