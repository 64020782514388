import React, { useState, useEffect } from "react"
import { Form, Input, InputNumber, Row, Col, Button, notification, Tabs, Space, Popconfirm, Select, Tag } from "antd"
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import _ from "lodash"
/* custom component */
import Header from "../../component/Header/Header"
import { PageContainer, LayoutContainer, Xtable } from "../../component/Xcomponent"

/* actions */
import { getCommonMasterList, getCommonMasterListReset } from "../../action/getCommonMasterListAction"
import { getSubGroupList, getSubGroupListReset } from "../../action/getSubGroupListAction"
import { saveCommonMaster, saveCommonMasterReset } from "../../action/saveCommonMasterAction"
import { deleteCommonMaster, deleteCommonMasterReset } from "../../action/deleteCommonMasterAction"
/* otehrs */
import { CustomTabs, CustomTabPane } from "./CommonMasterStyle"

const { TextArea } = Input;



const CommonMaster = props => {
    // Variables
    const tab = props.match.params.tab
    const {
        getCommonMasterList, 
        getCommonMasterListState, 
        getCommonMasterListReset,
        saveCommonMaster, 
        saveCommonMasterState, 
        saveCommonMasterReset,
        deleteCommonMaster, 
        deleteCommonMasterState, 
        deleteCommonMasterReset,
        getSubGroupList,
        getSubGroupListReset,
        getSubGroupListState,
    } = props
    const [formData, setFormData] = useState()

    const [testGroupForm] = Form.useForm()
    const [sampleTypeForm] = Form.useForm()
    const [pincodeForm] = Form.useForm()
    const [doctorsAttachedForm] = Form.useForm()
    const [bdmsAttachedForm] = Form.useForm()
    const [practiceTypeForm] = Form.useForm()

    const [cancelButton, setCancelButton] = useState(false)
    const [defaultActiveKey, setDefaultActiveKey] = useState(tab ? tab : "")
    const [redirect, setRedirect] = useState()

    const [columns, setColumns] = useState([])
    const [dataSource, setDataSource] = useState([])

    const [tabPanOrder, setTabPaneOrder] = useState([])
    const [tabPanOrderFilter, setTabPanOrderFilter] = useState([])
    const [subGroupList, setSubGroupList] = useState([])

    //Callback
    useEffect(() => {
        getCommonMasterList()
        return (() => {
            getCommonMasterListReset()
        })
    }, [])

    useEffect(()=>{
        if(tab=="test-group")
        {
            getSubGroupList()
            return (() => {
                getSubGroupListReset()
            })
        }
    },[tab])

    useEffect(() => {
        setColumns([
            {
                title: "Name",
                dataIndex: "name",
            },
            tab === "collection-center" 
            &&{
                title: "Emails",
                dataIndex: "emails",
            } ,
            tab === "outsource-partner" 
            && {
                title: "Email",
                dataIndex: "outsource_partner_email",
            },
            tab === "outsource-partner" 
            && {
                title: "Mobile Number",
                dataIndex: "outsource_partner_mobile_number",
            },
            tab === "outsource-partner" 
            && {
                title: "Address",
                dataIndex: "outsource_partner_address",
            },
            tab === "test-group" 
            && {
                title: "Sub Group",
                dataIndex: "test_sub_group",
                render:(_,{test_sub_group}) =>  (
                    <>
                    {test_sub_group.map((tag)=>{if(tag) return <Tag style={{marginBottom:"5px"}} key={tag}>{tag}</Tag>})}
                    </>
                )

            },
            tab === "practice-type" 
            && {
                title: "Speciality Code",
                dataIndex: "speciality_code",
            } ,
            tab === "hq-primary-marketing-location"
            && {
                title: "Email",
                dataIndex: "hq_primary_marketing_location_email",
            },
            {
                title: "Actions",
                dataIndex: "action",
                render: item => (
                    <>
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EditOutlined />} onClick={() => editCommonMaster(
                                item.id, 
                                item.name, 
                                item.emails, 
                                item.speciality_code, 
                                item.outsource_partner_email, 
                                item.outsource_partner_mobile_number,
                                item.outsource_partner_address, 
                                item.test_sub_group,
                                item.hq_primary_marketing_location_email,
                                item.key,
                            )} />
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => deleteCommonMaster({ common_master_detail_id: item.id, common_master_id: item.common_master_id })}
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ].filter(Boolean))
    }, [tab])

    useEffect(() => {
        if (getCommonMasterListState.apiState === 'success') {
            let rows = []
            let rowItems = []
            let tabs = []
            if (!defaultActiveKey && getCommonMasterListState.list.length > 0) {
                callback(getCommonMasterListState.list[0].slug)
            }
            getCommonMasterListState.list.map((item) => {
                tabs.push({
                    "search": item.key,
                    "tab": item.slug
                })

                rowItems = []
                item.common_master_detail.map((data) => {
                    rowItems.push({
                        name: data.value,
                        emails: data.emails ? data.emails : "-",
                        speciality_code: data.speciality_code ? data.speciality_code : "-",
                        outsource_partner_email: data.outsource_partner_email ? data.outsource_partner_email : "-",
                        outsource_partner_mobile_number: data.outsource_partner_mobile_number ? data.outsource_partner_mobile_number : "-",
                        outsource_partner_address: data.outsource_partner_address ? data.outsource_partner_address : "-",
                        test_sub_group: data.test_sub_group ? data.test_sub_group : [],
                        hq_primary_marketing_location_email: data.hq_primary_marketing_location_email ? data.hq_primary_marketing_location_email : "-",
                        action: { 
                            id: data._id, 
                            name: data.value, 
                            emails: data.emails ? data.emails : "", 
                            speciality_code: data.speciality_code ? data.speciality_code : "", 
                            outsource_partner_email: data.outsource_partner_email ? data.outsource_partner_email : "", 
                            outsource_partner_mobile_number: data.outsource_partner_mobile_number ? data.outsource_partner_mobile_number : "", 
                            outsource_partner_address: data.outsource_partner_address ? data.outsource_partner_address : "", 
                            test_sub_group: data.test_sub_group ? data.test_sub_group : [],
                            hq_primary_marketing_location_email: data.hq_primary_marketing_location_email ? data.hq_primary_marketing_location_email : "-", 
                            key: item.key, 
                            common_master_id: item._id 
                        },
                    })
                })
                rows.push({
                    key: item.key,
                    slug: item.slug,
                    id: item._id,
                    items: rowItems
                })
            })
            setDataSource(rows)
            setTabPaneOrder(tabs)
        }
    }, [getCommonMasterListState])


    useEffect(() => {
        if (saveCommonMasterState.apiState === "success") {
            saveCommonMasterReset()
            getCommonMasterList()
            resetAllData()
            notification["success"]({
                message: saveCommonMasterState.message,
                placement: "topRight"
            })
        }
        if (saveCommonMasterState.apiState === "error") {
            saveCommonMasterReset()
            notification["error"]({
                message: saveCommonMasterState.message,
                placement: "topRight"
            })
        }
    }, [saveCommonMasterState])

    useEffect(() => {
        if (deleteCommonMasterState.apiState === "success") {
            deleteCommonMasterReset()
            getCommonMasterList()
            notification["success"]({
                message: deleteCommonMasterState.message,
                placement: "topRight"
            })
        }
        if (deleteCommonMasterState.apiState === "error") {
            deleteCommonMasterReset()
            notification["error"]({
                message: deleteCommonMasterState.message,
                placement: "topRight"
            })
        }
    }, [deleteCommonMasterState])

    // functions
    const callback = (key) => {
        resetAllData()
        setRedirect(key)
        setDefaultActiveKey(key)
    }

    const resetAllData = () => {
        setFormData()
        testGroupForm.resetFields()
        // sampleTypeForm.resetFields()
        // pincodeForm.resetFields()
        // doctorsAttachedForm.resetFields()
        // bdmsAttachedForm.resetFields()
        // practiceTypeForm.resetFields()
        setCancelButton(false)
    }

    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSpecialityCodeOnChange = value =>{
        setFormData({ ...formData, ["speciality_code"]: value })
    }

    const handleSelectChange = (name,value,node) =>{
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = () => {
        if (cancelButton) {
            saveCommonMaster(formData)
        }
        else {
            let data = _.find(dataSource, { slug: defaultActiveKey })
            saveCommonMaster({ 
                id: data.id, 
                value: formData.value , 
                emails: formData.emails, 
                speciality_code: formData.speciality_code,
                outsource_partner_email:formData.outsource_partner_email,
                outsource_partner_mobile_number:formData.outsource_partner_mobile_number,
                outsource_partner_address:formData.outsource_partner_address,
                test_sub_group: formData.test_sub_group,
                hq_primary_marketing_location_email:formData.hq_primary_marketing_location_email,
            })
        }
    }

    const editCommonMaster = (id, name, email,speciality_code, outsource_partner_email, outsource_partner_mobile_number,outsource_partner_address, test_sub_group,hq_primary_marketing_location_email, fieldName) => {

        setFormData({ 
            ['value']: name, 
            ['emails']: email,
            ['speciality_code']: speciality_code, 
            ['outsource_partner_email']: outsource_partner_email, 
            ['outsource_partner_mobile_number']: outsource_partner_mobile_number,
            ['outsource_partner_address']: outsource_partner_address,
            ['test_sub_group']: test_sub_group.lenght>0 ? test_sub_group : [], 
            ['hq_primary_marketing_location_email']:hq_primary_marketing_location_email,
            ['id']: id, 
            ['mode']: 'edit' 
        })

        testGroupForm.setFieldsValue({ 
            value: name,
            emails: email,
            speciality_code: speciality_code,
            outsource_partner_email:outsource_partner_email,
            outsource_partner_mobile_number:outsource_partner_mobile_number,
            outsource_partner_address:outsource_partner_address,
            test_sub_group:test_sub_group.lenght>0 ? test_sub_group : [],
            hq_primary_marketing_location_email:hq_primary_marketing_location_email,
        })  

        // if (fieldName === "Test Group") {
        //     testGroupForm.setFieldsValue({
        //         value: name
        //     })
        // }
        // else if (fieldName === "Sample Type") {
        //     sampleTypeForm.setFieldsValue({
        //         value: name
        //     })
        // }
        setCancelButton(true)
    }
    useEffect(() => {
        // let tabs = []
        // tabs.push({
        //     "search": "Test Group",
        //     "tab": "test-group"
        // })
        // tabs.push({
        //     "search": "Sample type",
        //     "tab": "sample-type"
        // })
        // tabs.push({
        //     "search": "Pincode",
        //     "tab": "pincode"
        // })
        // tabs.push({
        //     "search": "Doctors Attached",
        //     "tab": "doctors-attached"
        // })
        // tabs.push({
        //     "search": "BDMs Attached",
        //     "tab": "bdms-attached"
        // })
        // tabs.push({
        //     "search": "Practice Type",
        //     "tab": "practice-type"
        // })
        // setTabPaneOrder(tabs)
    }, [])

    useEffect(() => {
        setTabPanOrderFilter(tabPanOrder)
    }, [tabPanOrder])

    const searchMaster = e => {
        let searchTerm = e.target.value.toLowerCase()
        const filtered = _.filter(tabPanOrder, function (o) {
            if (o.search !== null) {
                return o.search.toLowerCase().indexOf(searchTerm) > -1
            }
        })
        setTabPanOrderFilter(filtered)
    }

    const OperationsSlot = {
        left: < Input name="search_master" placeholder="Search Master" onChange={searchMaster} />,
    };

    return (
        <>
            {redirect &&
                <Redirect to={`/common-master/${redirect}`} />}
            <Header
                title={"Common Master"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Row>
                        <Col style={{margin:"10px"}}>
                            <Input name="search_master" placeholder="Search Master" onChange={searchMaster} />
                        </Col>
                    </Row>
                    {/* tabBarExtraContent={OperationsSlot.left} */}
                    <CustomTabs tabPosition="left" activeKey={defaultActiveKey} defaultActiveKey={defaultActiveKey} onChange={callback} size="small">
                        {getCommonMasterListState.apiState === 'success' &&
                            <>
                                {getCommonMasterListState.list.map((item) => (
                                    <>
                                        {_.some(tabPanOrderFilter, { tab: item.slug }) &&
                                            <CustomTabPane tab={item.key} key={item.slug} >
                                                <Form 
                                                    form={testGroupForm} 
                                                    layout="vertical" 
                                                    onFinish={handleSubmit}
                                                >
                                                    <Row gutter={20}>
                                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                                            <Form.Item
                                                                name="value"
                                                                rules={[
                                                                    { required: true, message: 'Required' },
                                                                ]}
                                                            >
                                                                <Input 
                                                                    name="value" 
                                                                    placeholder={`Enter ${item.key}`}
                                                                    onChange={handleOnChange} 
                                                                    id={item.slug}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        {
                                                            item.slug=="collection-center" && 
                                                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                                                <Form.Item
                                                                    name="emails"
                                                                    extra="Please use semicolon as e-mail separator"
                                                                    rules={[
                                                                        () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                                                    ]}
                                                                >
                                                                    <Input 
                                                                        name="emails" 
                                                                        placeholder="test@domain.com;test@domain.com"
                                                                        onChange={handleOnChange} 
                                                                        id={item.slug}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        }
                                                        {
                                                            item.slug=="outsource-partner" &&
                                                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                                                <Form.Item
                                                                    name="outsource_partner_email"
                                                                    rules={[
                                                                        () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                                                    ]}
                                                                >
                                                                    <Input 
                                                                        name="outsource_partner_email" 
                                                                        placeholder="test@domain.com"
                                                                        onChange={handleOnChange} 
                                                                        id={item.slug}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        }
                                                        {
                                                            item.slug=="outsource-partner" && 
                                                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                                                <Form.Item
                                                                    name="outsource_partner_mobile_number"
                                                                    rules={[
                                                                        () => ({ validator(_, value) { console.log("mobile number=>",value); if (!value || value.toString().match(/^[0-9]{10}$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid 10 digit mobile number!')); }, })
                                                                    ]}
                                                                >
                                                                    <Input 
                                                                        name="outsource_partner_mobile_number" 
                                                                        placeholder="9785545588"
                                                                        onChange={handleOnChange} 
                                                                        id={item.slug}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            
                                                        }
                                                        {
                                                            item.slug=="outsource-partner" && 
                                                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                                <Form.Item
                                                                    name="outsource_partner_address"
                                                                >
                                                                    <TextArea 
                                                                        name="outsource_partner_address" 
                                                                        placeholder="Enter outsource partner address"
                                                                        onChange={handleOnChange} 
                                                                        id={item.slug}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            
                                                        }
                                                        {
                                                            item.slug=="test-group" && 
                                                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                                                <Form.Item
                                                                    name="test_sub_group"
                                                                >
                                                                    <Select
                                                                        showSearch
                                                                        allowClear
                                                                        name="test_sub_group"
                                                                        placeholder="Select Test Sub Group"
                                                                        onChange={(v) => handleSelectChange("test_sub_group", v)}
                                                                        id={item.slug}
                                                                        onSearch={_.debounce((v)=>{
                                                                            getSubGroupList({
                                                                                search: v,
                                                                                page: 1,
                                                                                records: 100,
                                                                            })
                                                                        },500)}
                                                                        mode="multiple"
                                                                    >
                                                                        {(getSubGroupListState.apiState=="success" && getSubGroupListState.data.length>0) && 
                                                                            <>
                                                                                {
                                                                                    getSubGroupListState.data.map((item)=>(
                                                                                        <Select.Option value={item.sub_group}>{item.sub_group}</Select.Option>
                                                                                    ))        
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Select>
                                                                    {/* <Input 
                                                                        name="test_sub_group" 
                                                                        
                                                                        onChange={handleOnChange} 
                                                                        id={item.slug}
                                                                    /> */}
                                                                </Form.Item>
                                                            </Col>
                                                            
                                                        }
                                                        {
                                                            item.slug=="practice-type" && 
                                                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                                                <Form.Item
                                                                    name="speciality_code"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <InputNumber
                                                                        style={{ width: 200 }}
                                                                        placeholder="Enter speciality code"
                                                                        name="speciality_code"
                                                                        onChange={handleSpecialityCodeOnChange} 
                                                                        id={item.slug}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        }
                                                        {
                                                            item.slug=="hq-primary-marketing-location" &&
                                                            <Col xs={24} sm={24} md={12} lg={6} xl={6} >
                                                                <Form.Item
                                                                    name="hq_primary_marketing_location_email"
                                                                    rules={[
                                                                        () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                                                    ]}
                                                                >
                                                                    <Input 
                                                                        name="hq_primary_marketing_location_email" 
                                                                        placeholder="test@domain.com"
                                                                        onChange={handleOnChange} 
                                                                        id={item.slug}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        }
                                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                                            <Space>
                                                                <Button type="primary" htmlType="submit" loading={saveCommonMasterState.apiState === "loading"} >Add</Button>
                                                                {cancelButton &&
                                                                    <Button type="default" onClick={resetAllData} >Cancel</Button>
                                                                }
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                                <Row gutter={22}>
                                                    <Xtable dataSource={_.find(dataSource, { slug: item.slug }) ? _.find(dataSource, { slug: item.slug }).items : null} columns={columns} pagination={false} scroll={{ x: 768 }} />
                                                </Row>
                                            </CustomTabPane>
                                        }
                                    </>
                                ))}
                            </>
                        }
                        {/* {_.some(tabPanOrderFilter, { tab: "test-group" }) &&
                            <CustomTabPane tab="Test Group" key="test-group" >
                                <Form form={testGroupForm} layout="vertical" onFinish={() => handleSubmit(_.find(dataSource, { key: "Test Group" }))} >
                                    <Row gutter={20}>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                name="value"
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Input name="value" placeholder="Enter Test Group" id="testgroup"
                                                    onChange={handleOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Space>
                                                <Button type="primary" htmlType="submit" loading={saveCommonMasterState.apiState === "loading"} >SUBMIT</Button>
                                                {cancelButton &&
                                                    <Button type="default" onClick={resetAllData} >Cancel</Button>
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row gutter={20}>
                                    <Xtable dataSource={_.find(dataSource, { key: "Test Group" }) ? _.find(dataSource, { key: "Test Group" }).items : null} columns={columns} pagination={false} scroll={{ x: 768 }} />
                                </Row>
                            </CustomTabPane>
                        }
                        {_.some(tabPanOrderFilter, { tab: "sample-type" }) &&
                            <CustomTabPane tab="Sample type" key="sample-type">
                                <Form form={sampleTypeForm} layout="vertical" onFinish={() => handleSubmit(_.find(dataSource, { key: "Sample Type" }))}  >
                                    <Row gutter={20}>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                name="value"
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Input name="value" placeholder="Enter Sample Type" id="sampletype"
                                                    onChange={handleOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Space>
                                                <Button type="primary" htmlType="submit" loading={saveCommonMasterState.apiState === "loading"} >SUBMIT</Button>
                                                {cancelButton &&
                                                    <Button type="default" onClick={resetAllData} >Cancel</Button>
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row gutter={20}>
                                    <Xtable dataSource={_.find(dataSource, { key: "Sample Type" }) ? _.find(dataSource, { key: "Sample Type" }).items : null} columns={columns} pagination={false} scroll={{ x: 768 }} />
                                </Row>
                            </CustomTabPane>
                        }
                        {_.some(tabPanOrderFilter, { tab: "pincode" }) &&
                            <CustomTabPane tab="Pincode" key="pincode">
                                <Form form={pincodeForm} layout="vertical" onFinish={() => handleSubmit(_.find(dataSource, { key: "Pincode" }))} >
                                    <Row gutter={20}>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                name="value"
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Input name="value" placeholder="Enter Pincode" id="pincode"
                                                    onChange={handleOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Space>
                                                <Button type="primary" htmlType="submit" loading={saveCommonMasterState.apiState === "loading"} >SUBMIT</Button>
                                                {cancelButton &&
                                                    <Button type="default" onClick={resetAllData} >Cancel</Button>
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row gutter={20}>
                                    <Xtable dataSource={_.find(dataSource, { key: "Pincode" }) ? _.find(dataSource, { key: "Pincode" }).items : null} columns={columns} pagination={false} scroll={{ x: 768 }} />
                                </Row>
                            </CustomTabPane>
                        }
                        {_.some(tabPanOrderFilter, { tab: "doctors-attached" }) &&
                            <CustomTabPane tab="Doctors Attached" key="doctors-attached">
                                <Form form={doctorsAttachedForm} layout="vertical" onFinish={() => handleSubmit(_.find(dataSource, { key: "Doctors Attached" }))} >
                                    <Row gutter={20}>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                name="value"
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Input name="value" placeholder="Enter Doctors Attached" id="doctorattached"
                                                    onChange={handleOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Space>
                                                <Button type="primary" htmlType="submit" loading={saveCommonMasterState.apiState === "loading"} >SUBMIT</Button>
                                                {cancelButton &&
                                                    <Button type="default" onClick={resetAllData} >Cancel</Button>
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row gutter={20}>
                                    <Xtable dataSource={_.find(dataSource, { key: "Doctors Attached" }) ? _.find(dataSource, { key: "Doctors Attached" }).items : null} columns={columns} pagination={false} scroll={{ x: 768 }} />
                                </Row>
                            </CustomTabPane>
                        }
                        {_.some(tabPanOrderFilter, { tab: "bdms-attached" }) &&
                            <CustomTabPane tab="BDMs Attached" key="bdms-attached">
                                <Form form={bdmsAttachedForm} layout="vertical" onFinish={() => handleSubmit(_.find(dataSource, { key: "BDMs Attached" }))} >
                                    <Row gutter={20}>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                name="value"
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Input name="value" placeholder="Enter BDMs Attached" id="bdmsattached"
                                                    onChange={handleOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Space>
                                                <Button type="primary" htmlType="submit" loading={saveCommonMasterState.apiState === "loading"} >SUBMIT</Button>
                                                {cancelButton &&
                                                    <Button type="default" onClick={resetAllData} >Cancel</Button>
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row gutter={20}>
                                    <Xtable dataSource={_.find(dataSource, { key: "BDMs Attached" }) ? _.find(dataSource, { key: "BDMs Attached" }).items : null} columns={columns} pagination={false} scroll={{ x: 768 }} />
                                </Row>
                            </CustomTabPane>
                        }
                        {_.some(tabPanOrderFilter, { tab: "practice-type" }) &&
                            <CustomTabPane tab="Practice Type" key="practice-type">
                                <Form form={practiceTypeForm} layout="vertical" onFinish={() => handleSubmit(_.find(dataSource, { key: "Practice Type" }))} >
                                    <Row gutter={20}>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                name="value"
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Input name="value" placeholder="Enter Practice Type" id="practicetype"
                                                    onChange={handleOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Space>
                                                <Button type="primary" htmlType="submit" loading={saveCommonMasterState.apiState === "loading"} >SUBMIT</Button>
                                                {cancelButton &&
                                                    <Button type="default" onClick={resetAllData} >Cancel</Button>
                                                }
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row gutter={20}>
                                    <Xtable dataSource={_.find(dataSource, { key: "Practice Type" }) ? _.find(dataSource, { key: "Practice Type" }).items : null} columns={columns} pagination={false} scroll={{ x: 768 }} />

                                </Row>
                            </CustomTabPane>
                        } */}
                    </CustomTabs>
                </LayoutContainer>
            </PageContainer>
        </>
    )

}

const mapStateToProps = (state) => ({
    getCommonMasterListState: state.getCommonMasterList,
    getSubGroupListState: state.getSubGroupList,
    saveCommonMasterState: state.saveCommonMaster,
    deleteCommonMasterState: state.deleteCommonMaster,
});

const mapDispatchToProps = (dispatch) => ({
    getCommonMasterList: (params) => dispatch(getCommonMasterList(params)),
    getSubGroupList: (params) => dispatch(getSubGroupList(params)),
    getSubGroupListReset: (params) => dispatch(getSubGroupListReset()),
    saveCommonMaster: (params) => dispatch(saveCommonMaster(params)),
    saveCommonMasterReset: () => dispatch(saveCommonMasterReset()),
    deleteCommonMaster: (params) => dispatch(deleteCommonMaster(params)),
    deleteCommonMasterReset: () => dispatch(deleteCommonMasterReset()),
    getCommonMasterListReset: () => dispatch(getCommonMasterListReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonMaster);