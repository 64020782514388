import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE,
    getRoleModuleMappingDetailByRoleFail,
    getRoleModuleMappingDetailByRoleSuccess
} from '../action/getRoleModuleMappingDetailByRoleAction'

// api 
import getRoleModuleMappingDetailByRoleApi from '../api/getRoleModuleMappingDetailByRoleApi'

export function* getRoleModuleMappingDetailByRoleSaga(action) {
    try {
        const response = yield call(() => getRoleModuleMappingDetailByRoleApi(action.params))
        if (response.data.status === 1) {
            yield put(getRoleModuleMappingDetailByRoleSuccess(response, action))
        } else {
            yield put(getRoleModuleMappingDetailByRoleFail(response, action))
        }
    }
    catch (e) {
        yield put(getRoleModuleMappingDetailByRoleFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_ROLE_MODULE_MAPPING_DETAIL_BY_ROLE, getRoleModuleMappingDetailByRoleSaga)
}