import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, InputNumber } from "antd"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveContainer, saveContainerReset } from "../../../action/saveContainerAction"
import { getContainerDetail, getContainerDetailReset } from "../../../action/getContainerDetailAction"

/* otehrs */
import { states, pincodes, pickup_time_slots, container_types, test_groups, display_container_names, container_cap_colors } from "../../../strings.json"

export const ContainerAdd = props => {
    /* variables */
    const container_id = props.match.params.container_id
    const mode = container_id ? "edit" : "add"
    const {
        saveContainer, saveContainerReset, saveContainerState,
        getContainerDetail, getContainerDetailReset, getContainerDetailState,
    } = props
    const [formData, setFormData] = useState({
        container_type: "",
        display_container_name: "",
        container_description:"",
        container_cap_color: "",
        mrp: 0,
        sp: 0,
        cp: 0,
    })
    if (mode === "edit") {
        formData.id = container_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    /* callbacks */
    useEffect(() => {
        return (() => {
            saveContainerReset()
            getContainerDetailReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getContainerDetail({
                id: container_id
            })
        }
    }, [])

    useEffect(() => {
        if (getContainerDetailState.apiState === "success") {
            form.setFieldsValue({
                container_type: getContainerDetailState.data.container_type,
                display_container_name: getContainerDetailState.data.display_container_name,
                container_description: getContainerDetailState.data.container_description,
                container_cap_color: getContainerDetailState.data.container_cap_color,
                mrp: getContainerDetailState.data.mrp,
                sp: getContainerDetailState.data.sp,
                cp: getContainerDetailState.data.cp,
            })
            setFormData({
                ...formData,
                ['container_type']: getContainerDetailState.data.container_type,
                ['display_container_name']: getContainerDetailState.data.display_container_name,
                ['container_description']: getContainerDetailState.data.container_description,
                ['container_cap_color']: getContainerDetailState.data.container_cap_color,
                ['mrp']: getContainerDetailState.data.mrp,
                ['sp']: getContainerDetailState.data.sp,
                ['cp']: getContainerDetailState.data.cp,
            })
        }
    }, [getContainerDetailState])

    useEffect(() => {
        if (saveContainerState.apiState === "success") {
            saveContainerReset()
            setRedirect([true, "/container-master/list"])
            notification["success"]({
                message: saveContainerState.message,
                placement: "topRight"
            })
        }
        if (saveContainerState.apiState === "error") {
            saveContainerReset()
            notification["error"]({
                message: saveContainerState.message,
                placement: "topRight"
            })
        }
    }, [saveContainerState])

    /* functions */
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }

    const handleSubmit = () => {
        saveContainer(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/container-master/list"}
                title={mode === "edit" ? "Edit Container Master" : "Add Container Master"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Container Type"
                                    name="container_type"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Input name="container_type" placeholder="Enter Container Type"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Display Container Name"
                                    name="display_container_name"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Input name="display_container_name" placeholder="Enter Container Name"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Container Description"
                                    name="container_description"
                                
                                >
                                    <Input name="container_description" placeholder="Enter Container Description"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Container Cap Color"
                                    name="container_cap_color"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        showSearch
                                        name="container_cap_color"
                                        placeholder="Select Container Cap Color"
                                        onChange={(v) => handleSelect("container_cap_color", v)}
                                    >
                                        {container_cap_colors.map(container_cap_color => (
                                            <Select.Option value={container_cap_color} >{container_cap_color}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="MRP"
                                    name="mrp"
                                >
                                    <InputNumber name="mrp" placeholder="Enter MRP" style={{ width: '100%' }}
                                        onChange={(v) => handleSelect("mrp", v)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="CP"
                                    name="cp"
                                >
                                    <InputNumber name="cp" placeholder="Enter CP" style={{ width: '100%' }}
                                        onChange={(v) => handleSelect("cp", v)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="SP"
                                    name="sp"
                                >
                                    <InputNumber name="sp" placeholder="Enter SP" style={{ width: '100%' }}
                                        onChange={(v) => handleSelect("sp", v)}
                                    />
                                </Form.Item>
                            </Col>

                        </Row>
                        {
                            saveContainerState.apiState=="loading" ? <Button type="primary" loading>Adding</Button> 
                            : <Button type="primary" htmlType="submit">SUBMIT</Button>
                        }

                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveContainerState: state.saveContainer,
    getContainerDetailState: state.getContainerDetail,
});

const mapDispatchToProps = (dispatch) => ({
    saveContainer: (params) => dispatch(saveContainer(params)),
    saveContainerReset: () => dispatch(saveContainerReset()),
    getContainerDetail: (params) => dispatch(getContainerDetail(params)),
    getContainerDetailReset: () => dispatch(getContainerDetailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerAdd);