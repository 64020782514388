import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_MODULE_DETAIL,
    getModuleDetailFail,
    getModuleDetailSuccess
} from '../action/getModuleDetailAction'

// api 
import getModuleDetailApi from '../api/getModuleDetailApi'

export function* getModuleDetailSaga(action) {
    try {
        const response = yield call(() => getModuleDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getModuleDetailSuccess(response, action))
        } else {
            yield put(getModuleDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getModuleDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_MODULE_DETAIL, getModuleDetailSaga)
}