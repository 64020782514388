import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_ROLE_MODULE_MAPPING,
    deleteRoleModuleMappingFail,
    deleteRoleModuleMappingSuccess
} from '../action/deleteRoleModuleMappingAction'

// api 
import deleteRoleModuleMappingApi from '../api/deleteRoleModuleMappingApi'

export function* deleteRoleModuleMappingSaga(action) {
    try {
        const response = yield call(() => deleteRoleModuleMappingApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteRoleModuleMappingSuccess(response, action))
        } else {
            yield put(deleteRoleModuleMappingFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteRoleModuleMappingFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_ROLE_MODULE_MAPPING, deleteRoleModuleMappingSaga)
}