import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_ARTICLE,
    deleteArticleFail,
    deleteArticleSuccess
} from '../action/deleteArticleAction'

// api 
import deleteArticleApi from '../api/deleteArticleApi'

export function* deleteArticleSaga(action) {
    try {
        const response = yield call(() => deleteArticleApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteArticleSuccess(response, action))
        } else {
            yield put(deleteArticleFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteArticleFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_ARTICLE, deleteArticleSaga)
}