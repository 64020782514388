import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";

/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getContactUsEnquriesDetail, getContactUsEnquriesDetailReset } from "../../../action/getContactUsEnquriesDetailAction"
import { dateFormat } from '../../../utils';

const ContactUsView = props => {
    /* variable */
    const { getContactUsEnquriesDetail, getContactUsEnquriesDetailReset, getContactUsEnquriesDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getContactUsEnquriesDetailReset()
        }
    }, [props.visible])
    useEffect(() => {
        if (props.id && props.visible) {
            getContactUsEnquriesDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`Contact Us Dashboard View ${getContactUsEnquriesDetailState.apiState === "success" ? "- " + getContactUsEnquriesDetailState.data.name : ""}`}
            placement="right"
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getContactUsEnquriesDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getContactUsEnquriesDetailState.apiState === "error" &&
                <p>{getContactUsEnquriesDetailState.message}</p>
            }

            {getContactUsEnquriesDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="24">
                            <ViewBox>
                                <ViewLabel>Name</ViewLabel>
                            <ViewValue>{getContactUsEnquriesDetailState.data.name}</ViewValue>
                            </ViewBox>
                        </Col>
                    <Col span="24">
                        <ViewBox>
                            <ViewLabel>Email</ViewLabel>
                            <ViewValue>{getContactUsEnquriesDetailState.data.email}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="24">
                        <ViewBox>
                            <ViewLabel>Phone</ViewLabel>
                            <ViewValue>{getContactUsEnquriesDetailState.data.phone}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="24">
                        <ViewBox>
                            <ViewLabel>Message</ViewLabel>
                            <ViewValue>{getContactUsEnquriesDetailState.data.message}</ViewValue>
                        </ViewBox>
                    </Col>
                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getContactUsEnquriesDetailState: state.getContactUsEnquriesDetail
});

const mapDispatchToProps = (dispatch) => ({
    getContactUsEnquriesDetail: (params) => dispatch(getContactUsEnquriesDetail(params)),
    getContactUsEnquriesDetailReset: () => dispatch(getContactUsEnquriesDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsView);