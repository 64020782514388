import React from "react"
/* custom component */
import Header from "../../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../../component/Xcomponent"
import ContentRepoAdd from "../../../../component/ContentRepo/ContentRepoAdd/ContentRepoAdd";

export const ContentRepoPregnancyAdd = props => {
    /* variables */
    const article_id = props.match.params.article_id
    const mode = article_id ? "edit" : "add"

    return (
        <>

            <Header
                backButton={"/content-repo-pregnancy/list"}
                title={mode === "edit" ? "Edit Content Repo Pregnancy" : "Add Content Repo Pregnancy"}
            />
            <PageContainer>
                <LayoutContainer>
                    <ContentRepoAdd pageUrl={`content-repo-pregnancy`} pageType="Pregnancy" article_id={article_id} />
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

export default ContentRepoPregnancyAdd;