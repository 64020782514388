import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_EMPLOYEE_DETAIL,
    getEmployeeDetailFail,
    getEmployeeDetailSuccess
} from '../action/getEmployeeDetailAction'

// api 
import getEmployeeDetailApi from '../api/getEmployeeDetailApi'

export function* getEmployeeDetailSaga(action) {
    try {
        const response = yield call(() => getEmployeeDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getEmployeeDetailSuccess(response, action))
        } else {
            yield put(getEmployeeDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getEmployeeDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_EMPLOYEE_DETAIL, getEmployeeDetailSaga)
}