import React from "react"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"
import BulkUpload from "../../../component/BulkUpload/BulkUpload"

/* actions */

/* otehrs */

export const InstituteMasterBulkUpload = props => {
    /* variables */
    return (
        <>
            <Header
                backButton={"/institute-master/list"}
                title={"Institute Master Bulk Upload"}
            />
            <PageContainer>
                <LayoutContainer>
                    <BulkUpload master="instituteMaster" backUrl="/institute-master/list" apiUrl="institute/bulk-upload" />
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

export default InstituteMasterBulkUpload;
