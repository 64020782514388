import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import moment from 'moment'


/* custom component */
import { ViewBox, ViewLabel, ViewValue, Heading } from "../../../component/Xcomponent"

/* action */
import { getPatientDetail, getPatientDetailReset } from "../../../action/getPatientDetailAction"

/* others */
import { dateFormat, s3_cdn } from '../../../utils';


const PatientMasterView = props => {
    /* variable */
    const { getPatientDetail, getPatientDetailReset, getPatientDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getPatientDetailReset()
        }
    }, [props.visible])
    useEffect(() => {
        if (props.id && props.visible) {
            getPatientDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])
    return (
        <Drawer
            title={`Patient Master View`}
            placement="right"
            closeIcon={<><Link to={`/patient-master/save/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getPatientDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getPatientDetailState.apiState === "error" &&
                <p>{getPatientDetailState.message}</p>
            }

            {getPatientDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>First Name</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.name.firstName}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Last Name</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.name.lastName}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Phone</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.phone}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Email</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.email}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Gender</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.gender}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>DOB</ViewLabel>
                                <ViewValue>{dateFormat(getPatientDetailState.data.dob)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>LMP</ViewLabel>
                                <ViewValue>{dateFormat(getPatientDetailState.data.lmp)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>LMP As Of</ViewLabel>
                                <ViewValue>{dateFormat(getPatientDetailState.data.lmp_as_of)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Week</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.week}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Interest</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.interest?.length > 0 &&
                                    getPatientDetailState.data.interest.map(obj => obj).join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Tribe</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.tribe?.length > 0 &&
                                    getPatientDetailState.data.tribe.map(obj => obj).join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Conceive Status</ViewLabel>
                                <ViewValue>{getPatientDetailState.data.conceive_status}</ViewValue>
                            </ViewBox>
                        </Col>
                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getPatientDetailState: state.getPatientDetail
});

const mapDispatchToProps = (dispatch) => ({
    getPatientDetail: (params) => dispatch(getPatientDetail(params)),
    getPatientDetailReset: () => dispatch(getPatientDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(PatientMasterView);