import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PINCODE_LIST,
    getPincodeListFail,
    getPincodeListSuccess
} from '../action/getPincodeListAction'

// api 
import getPincodeListApi from '../api/getPincodeListApi'

export function* getPincodeListSaga(action) {
    try {
        const response = yield call(() => getPincodeListApi(action.params))
        if (response.data.status === 1) {
            yield put(getPincodeListSuccess(response, action))
        } else {
            yield put(getPincodeListFail(response, action))
        }
    }
    catch (e) {
        yield put(getPincodeListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PINCODE_LIST, getPincodeListSaga)
}