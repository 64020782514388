import React from "react"
import { Link } from "react-router-dom";
import { Button } from "antd"
import { PlusOutlined } from "@ant-design/icons"
/* custom component */
import Header from "../../../../component/Header/Header"
import { PageContainer } from "../../../../component/Xcomponent"
import ContentRepoList from "../../../../component/ContentRepo/ContentRepoList/ContentRepoList";

const ContentRepoTribeList = props => {

    return (
        <>
            <Header
                title="Content Repo Tribe List"
                rightChildren={<Link to="/content-repo-tribe/add" ><Button type="primary" ><PlusOutlined />New</Button></Link>}
            />
            <PageContainer>
                <ContentRepoList refreshPageUrl={`content-repo-tribe/list`} pageUrl={`content-repo-tribe`} pageType="Tribe" />
            </PageContainer>
        </>
    )
}

export default ContentRepoTribeList