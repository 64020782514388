import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_COUPON_DETAIL,
    getCouponDetailFail,
    getCouponDetailSuccess
} from '../action/getCouponDetailAction'

// api 
import getCouponDetailApi from '../api/getCouponDetailApi'

export function* getCouponDetailSaga(action) {
    try {
        const response = yield call(() => getCouponDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getCouponDetailSuccess(response, action))
        } else {
            yield put(getCouponDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getCouponDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_COUPON_DETAIL, getCouponDetailSaga)
}