import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_COUPON_LIST,
    getCouponListFail,
    getCouponListSuccess
} from '../action/getCouponListAction'

// api 
import getCouponListApi from '../api/getCouponListApi'

export function* getCouponListSaga(action) {
    try {
        const response = yield call(() => getCouponListApi(action.params))
        if (response.data.status === 1) {
            yield put(getCouponListSuccess(response, action))
        } else {
            yield put(getCouponListFail(response, action))
        }
    }
    catch (e) {
        yield put(getCouponListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_COUPON_LIST, getCouponListSaga)
}