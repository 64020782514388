import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_ARTICLE,
    saveArticleFail,
    saveArticleSuccess
} from '../action/saveArticleAction'

// api 
import saveArticleApi from '../api/saveArticleApi'

export function* saveArticleSaga(action) {
    try {
        const response = yield call(() => saveArticleApi(action.params))
        if (response.data.status === 1) {
            yield put(saveArticleSuccess(response, action))
        } else {
            yield put(saveArticleFail(response, action))
        }
    }
    catch (e) {
        yield put(saveArticleFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_ARTICLE, saveArticleSaga)
}