import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_INSTITUTE_TEST_PRICING_MASTER,
    deleteInstituteTestPricingMasterFail,
    deleteInstituteTestPricingMasterSuccess
} from '../action/deleteInstituteTestPricingMasterAction'

// api 
import deleteInstituteTestPricingMasterApi from '../api/deleteInstituteTestPricingMasterApi'

export function* deleteInstituteTestPricingMasterSaga(action) {
    try {
        const response = yield call(() => deleteInstituteTestPricingMasterApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteInstituteTestPricingMasterSuccess(response, action))
        } else {
            yield put(deleteInstituteTestPricingMasterFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteInstituteTestPricingMasterFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_INSTITUTE_TEST_PRICING_MASTER, deleteInstituteTestPricingMasterSaga)
}