import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PAYMENT_HISTORY,
    getPaymentHistoryFail,
    getPaymentHistorySuccess
} from '../action/getPaymentHistoryAction'

// api 
import getPaymentHistoryApi from '../api/getPaymentHistoryApi'

export function* getPaymentHistorySaga(action) {
    try {
        const response = yield call(() => getPaymentHistoryApi(action.params))
        if (response.data.status === 1) {
            yield put(getPaymentHistorySuccess(response, action))
        } else {
            yield put(getPaymentHistoryFail(response, action))
        }
    }
    catch (e) {
        yield put(getPaymentHistoryFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PAYMENT_HISTORY, getPaymentHistorySaga)
}