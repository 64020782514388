import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_MOU,
    saveMouFail,
    saveMouSuccess
} from '../action/saveMouAction'

// api 
import saveMouApi from '../api/saveMouApi'

export function* saveMouSaga(action) {
    try {
        const response = yield call(() => saveMouApi(action.params))
        if (response.data.status === 1) {
            yield put(saveMouSuccess(response, action))
        } else {
            yield put(saveMouFail(response, action))
        }
    }
    catch (e) {
        yield put(saveMouFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_MOU, saveMouSaga)
}