import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getInstituteTestPricingDetail, getInstituteTestPricingDetailReset } from '../../../action/getInstituteTestPricingDetailAction'
import { dateFormat } from '../../../utils';

const InstituteTestPricingMasterView = props => {
    /* variable */
    const {
        getInstituteTestPricingDetail, getInstituteTestPricingDetailReset, getInstituteTestPricingDetailState,
    } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getInstituteTestPricingDetailReset()
        }
    }, [props.visible])
    useEffect(() => {
        if (props.id && props.visible) {
            getInstituteTestPricingDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`Institute Test Pricing Master View ${getInstituteTestPricingDetailState.apiState === "success" ? "- " + getInstituteTestPricingDetailState.data.instituteId?.lilac_id : ""}`}
            placement="right"
            closeIcon={<Button size="small" shape="circle" icon={<CloseOutlined />} />}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getInstituteTestPricingDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getInstituteTestPricingDetailState.apiState === "error" &&
                <p>{getInstituteTestPricingDetailState.message}</p>
            }

            {getInstituteTestPricingDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Institute Name</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.instituteId?.name}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Institute ID</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.instituteId?.lilac_id}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Test Name</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.test_id?.test_name}</span></ViewValue>
                            </ViewBox>
                        </Col>

                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Test ID</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.test_id?.lilac_test_id}</span></ViewValue>
                            </ViewBox>
                        </Col>



                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>LAP</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.lap}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Mrp</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.mrp}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Transfer Rate</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.transfer_rate}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Standard Transfer Rate</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.standard_transfer_rate}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Discount Amount</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.discount_amount}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Discount Percentage</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.discount_percentage}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Billing Category Id</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.billing_category_id}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Billing Category Name</ViewLabel>
                                <ViewValue><span>{getInstituteTestPricingDetailState.data.billing_category_name}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Billing Category Valid Till</ViewLabel>
                                <ViewValue>{getInstituteTestPricingDetailState.data.billing_category_valid_till ? dateFormat(getInstituteTestPricingDetailState.data.billing_category_valid_till) : ""}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Last Date of Price Update</ViewLabel>
                                <ViewValue>{getInstituteTestPricingDetailState.data.price_updated_on ? dateFormat(getInstituteTestPricingDetailState.data.price_updated_on) : ""}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getInstituteTestPricingDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getInstituteTestPricingDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getInstituteTestPricingDetailState: state.getInstituteTestPricingDetail,
});

const mapDispatchToProps = (dispatch) => ({
    getInstituteTestPricingDetail: (params) => dispatch(getInstituteTestPricingDetail(params)),
    getInstituteTestPricingDetailReset: () => dispatch(getInstituteTestPricingDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(InstituteTestPricingMasterView);