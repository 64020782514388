import React, { useEffect, useState } from "react"
import { Link, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Button, Input, Popconfirm, notification, Tag } from "antd"
import { EditOutlined, PlusOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons"
import _ from 'lodash'

/* CUSTOM COMPONENT */
import Header from "../../../component/Header/Header"
import { PageContainer, Xpagination, Xtable } from "../../../component/Xcomponent"

/* ACTION */
import { getQuickSupportQuestionAnswerList, getQuickSupportQuestionAnswerListReset } from '../../../action/getQuickSupportQuestionAnswerListAction'
import { deleteQuickSupportQuestionAnswer, deleteQuickSupportQuestionAnswerReset } from '../../../action/deleteQuickSupportQuestionAnswerAction'

/* OTHERS */
import { checkRights } from '../../../utils'


const QuickSupportQuestionAnswerList = (props) => {
    /* VARIABLES */
    const {
        getQuickSupportQuestionAnswerList, getQuickSupportQuestionAnswerListReset, getQuickSupportQuestionAnswerListState,
        deleteQuickSupportQuestionAnswer, deleteQuickSupportQuestionAnswerReset, deleteQuickSupportQuestionAnswerState,
    } = props
    const category_id = props.match.params.category_id
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [tableData, setTableData] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [redirect, setRedirect] = useState(["", false])

    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 20,
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }


    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });



    const columns = [
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            ...getColumnSearchProps('question', 'Question')
        },
        {
            title: 'Application Type',
            dataIndex: 'application_type',
            key: 'application_type',
            render: (application_type) => <Space wrap>
                {application_type?.length > 0 && application_type.map(item => (
                    <Tag style={{ marginBottom: 8 }}>{item} </Tag>
                ))}
            </Space>,
            ...getColumnSearchProps('application_type', 'Application Type'),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (action) => (
                <Space size="small">
                    {checkRights("quick_support_question_answer", "edit") &&
                        <Link to={`/quick-support-question-answer/save/${category_id}/${action.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    }
                    {checkRights("module", "delete") &&
                        <Popconfirm
                            title="Are you sure to delete this item?"
                            onConfirm={() => deleteQuickSupportQuestionAnswer({ id: action.id })}
                            okText="Delete"
                            cancelText="Cancel"
                            okType="danger"
                        >
                            <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                        </Popconfirm>
                    }
                </Space >
            )
        },
    ];
    /* CALLBACKS */

    useEffect(() => {
        getQuickSupportQuestionAnswerList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            quick_support_id: category_id,
            ...searchText
        })
        setRedirect([true, `/quick-support-question-answer/list/${category_id}?page=${pagination.page}`])

    }, [pagination, refresh, searchText])

    useEffect(() => {
        return (() => {
            getQuickSupportQuestionAnswerListReset()
            deleteQuickSupportQuestionAnswerReset()
        })
    }, [])

    useEffect(() => {
        if (deleteQuickSupportQuestionAnswerState.apiState === "success") {
            notification["success"]({
                message: "Delete Records Successfully"
            })
            deleteQuickSupportQuestionAnswerReset()
            setRefresh(refresh + 1)
        }
        if (deleteQuickSupportQuestionAnswerState.apiState === "error") {
            notification["error"]({
                message: "Something went wrong"
            })
            deleteQuickSupportQuestionAnswerReset()
        }
    }, [deleteQuickSupportQuestionAnswerState])


    useEffect(() => {
        if (getQuickSupportQuestionAnswerListState.apiState === "success") {
            let tableData = []
            getQuickSupportQuestionAnswerListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: row.key,
                        question: row.question,
                        application_type: row.application_type,
                        action: { id: row._id }
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getQuickSupportQuestionAnswerListState])

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                backButton={`/quick-support-question-answer`}
                title={`Question Answer List ${getQuickSupportQuestionAnswerListState.apiState === "success" ? " of " + getQuickSupportQuestionAnswerListState.category_name.name : ""}`}
                rightChildren={checkRights("quick_support_question_answer", "add") && <Link to={`/quick-support-question-answer/save/${category_id}`} ><Button type="primary" ><PlusOutlined />New</Button></Link>}
            />
            <PageContainer>
                {getQuickSupportQuestionAnswerListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getQuickSupportQuestionAnswerListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getQuickSupportQuestionAnswerListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getQuickSupportQuestionAnswerListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getQuickSupportQuestionAnswerListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                    </>
                }
            </PageContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    getQuickSupportQuestionAnswerListState: state.getQuickSupportQuestionAnswerList,
    deleteQuickSupportQuestionAnswerState: state.deleteQuickSupportQuestionAnswer,

})
const mapDispatchToProps = (dispatch) => ({
    getQuickSupportQuestionAnswerList: (params) => dispatch(getQuickSupportQuestionAnswerList(params)),
    getQuickSupportQuestionAnswerListReset: () => dispatch(getQuickSupportQuestionAnswerListReset()),
    deleteQuickSupportQuestionAnswer: (params) => dispatch(deleteQuickSupportQuestionAnswer(params)),
    deleteQuickSupportQuestionAnswerReset: () => dispatch(deleteQuickSupportQuestionAnswerReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickSupportQuestionAnswerList)
