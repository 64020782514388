import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_PREGNANCY_HIGHLIGHTS,
    savePregnancyHighlightsFail,
    savePregnancyHighlightsSuccess
} from '../action/savePregnancyHighlightsAction'

// api 
import savePregnancyHighlightsApi from '../api/savePregnancyHighlightsApi'

export function* savePregnancyHighlightsSaga(action) {
    try {
        const response = yield call(() => savePregnancyHighlightsApi(action.params))
        if (response.data.status === 1) {
            yield put(savePregnancyHighlightsSuccess(response, action))
        } else {
            yield put(savePregnancyHighlightsFail(response, action))
        }
    }
    catch (e) {
        yield put(savePregnancyHighlightsFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_PREGNANCY_HIGHLIGHTS, savePregnancyHighlightsSaga)
}