import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getUserTermAndConditionDetail, getUserTermAndConditionDetailReset } from "../../../action/getUserTermAndConditionDetailAction"
import { dateFormat } from '../../../utils';

const UserTermAndConditionView = props => {
    /* variable */
    const { getUserTermAndConditionDetail, getUserTermAndConditionDetailReset, getUserTermAndConditionDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getUserTermAndConditionDetailReset()
        }
    }, [props.visible])

    useEffect(() => {
        if (props.id && props.visible) {
            getUserTermAndConditionDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`User Term And Condition View ${getUserTermAndConditionDetailState.apiState === "success" ? "- " + getUserTermAndConditionDetailState.data.username : ""}`}
            placement="right"
            closeIcon={<> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getUserTermAndConditionDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getUserTermAndConditionDetailState.apiState === "error" &&
                <p>{getUserTermAndConditionDetailState.message}</p>
            }

            {getUserTermAndConditionDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>User Name</ViewLabel>
                                <ViewValue><span>{getUserTermAndConditionDetailState.data.username}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Phone</ViewLabel>
                                <ViewValue><span>{getUserTermAndConditionDetailState.data.phone}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Email</ViewLabel>
                                <ViewValue><span>{getUserTermAndConditionDetailState.data.email}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="24">
                            <ViewBox>
                                <ViewLabel>Description</ViewLabel>
                                <ViewValue>
                                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(getUserTermAndConditionDetailState.data.tnc_content) }} />
                                </ViewValue>
                            </ViewBox>
                        </Col>

                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getUserTermAndConditionDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>


                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getUserTermAndConditionDetailState: state.getUserTermAndConditionDetail
});

const mapDispatchToProps = (dispatch) => ({
    getUserTermAndConditionDetail: (params) => dispatch(getUserTermAndConditionDetail(params)),
    getUserTermAndConditionDetailReset: () => dispatch(getUserTermAndConditionDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(UserTermAndConditionView);