import React from "react"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"
import BulkUpload from "../../../component/BulkUpload/BulkUpload"

/* actions */

/* otehrs */

export const TestMasterBulkUpload = props => {
    /* variables */
    return (
        <>
            <Header
                backButton={"/test-master/list"}
                title={"Test Master Bulk Upload"}
            />
            <PageContainer>
                <LayoutContainer>
                    <BulkUpload master="testMaster" backUrl="/test-master/list" apiUrl="test-master/bulk-upload" />
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

export default TestMasterBulkUpload;