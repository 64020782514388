import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import { Tag, Menu } from 'antd';
import { Redirect } from 'react-router-dom'

/* CUSTOM COMPONENT */
import Header from "../../component/Header/Header"
import { PageContainer, Xtable, Xpagination } from "../../component/Xcomponent"


/* ACTION */
import { getPaymentHistoryCoupon, getPaymentHistoryCouponReset } from '../../action/getPaymentHistoryCouponAction';


const PaymentHistoryCoupon = props => {
    // VARIABLES
    const { getPaymentHistoryCoupon, getPaymentHistoryCouponReset, getPaymentHistoryCouponState } = props
    const [pagination, setPagination] = useState({
        page_no: 1,
        records_per_page: 10
    })
    const columns = [
        {
            title: 'Razorpay orderID',
            dataIndex: 'rzp_order_id',
            key: 'rzp_order_id',
        },
        {
            title: 'Patient',
            dataIndex: 'patient_name',
            key: 'patient_name',
        },
        {
            title: 'Patient Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Patient Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: 'Coupon',
            dataIndex: 'coupon',
            key: 'coupon',
        },
        {
            title: 'Coupon Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: status => {
                if (status === 'Cancelled') return <Tag color="magenta">CANCELLED</Tag>
                if (status === 'Success') return <Tag color="green">SUCCESS</Tag>
                if (status === 'Initiated') return <Tag color="orange">INITIATED</Tag>
            }
        }
    ]
    const [tableData, setTableData] = useState([])
    const [activeTab, setActiveTab] = useState('Coupons')
    const [redirect, setRedirect] = useState([false, ''])


    // CALLBACKS
    useEffect(() => {
        getPaymentHistoryCoupon({
            page_no: pagination.page_no,
            records_per_page: pagination.records_per_page
        })
    }, [pagination])

    useEffect(() => {
        if (getPaymentHistoryCouponState.apiState === 'success') {
            let tableRows = []
            getPaymentHistoryCouponState.list.map(row => {
                tableRows.push({
                    key: row.id,
                    rzp_order_id: row.razorpay_order_id,
                    patient_name: row.patient_coupon_docs.some_one_else ? row.patient_coupon_docs.first_name && row.patient_coupon_docs.last_name ?  `${row.patient_coupon_docs.first_name} ${row.patient_coupon_docs.last_name}` : row.patient_doc.name.firstName  && row.patient_doc.name.lastName ? `${row.patient_doc.name.firstName} ${row.patient_doc.name.lastName}` : "" : "",
                    email: row.patient_coupon_docs?.email ?? "",
                    mobile: row.patient_coupon_docs ?.phone ?? "",
                    amount: row.discount,
                    coupon: row.code,
                    status: row.computed_payment_status,
                })
            })
            setTableData(tableRows)
        }
    }, [getPaymentHistoryCouponState])




    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                title="Payment History for Coupons"
            />
            <PageContainer>
                <Menu mode="horizontal" selectedKeys={activeTab} onClick={(info) => {
                    setActiveTab(info.key)
                    if (info.key === 'Appointments') setRedirect(['true', '/payment-history'])
                    if (info.key === 'Coupons') setRedirect(['true', '/payment-history-coupon'])
                }}>
                    <Menu.Item key="Appointments" >
                        Appointments
                    </Menu.Item>
                    <Menu.Item key="Coupons" >
                        Coupons
                    </Menu.Item>
                </Menu>
                <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}

                />
                <Xpagination
                    pageSizeOptions={['25', '50', '100', '500']}
                    showSizeChanger
                    total={getPaymentHistoryCouponState.total_records}
                    onChange={(page, pageSize) => setPagination({ page_no: page, records_per_page: pageSize })}
                    current={pagination.page_no}
                    defaultPageSize={10}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getPaymentHistoryCouponState: state.getPaymentHistoryCoupon,
});
const mapDispatchToProps = (dispatch) => ({
    getPaymentHistoryCoupon: (params) => dispatch(getPaymentHistoryCoupon(params)),
    getPaymentHistoryCouponReset: () => dispatch(getPaymentHistoryCouponReset()),

});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistoryCoupon);
