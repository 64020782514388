import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, Switch, InputNumber, Tabs, Space, TimePicker, Modal, DatePicker, Popconfirm, Upload, Radio } from "antd"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash'
import { DeleteOutlined, EditOutlined, PlusOutlined, CloseCircleOutlined, CheckCircleFilled, UploadOutlined } from "@ant-design/icons";
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer, Xtable  } from "../../../component/Xcomponent"

/* actions */
import { saveInstitute, saveInstituteReset } from "../../../action/saveInstituteAction"
import { getInstituteDetail, getInstituteDetailReset } from "../../../action/getInstituteDetailAction"
import { getDoctorList, getDoctorListReset } from "../../../action/getDoctorListAction"
import { getPincodeList, getPincodeListReset } from "../../../action/getPincodeListAction"
import { getLedgerAccountList, getLedgerAccountListReset } from "../../../action/getLedgerAccountListAction"

/* otehrs */
import { states, pincodes, pickup_time_slots } from "../../../strings.json"
import { getCommonMasterList, getCommonMasterListReset } from "../../../action/getCommonMasterListAction";
import { getEmployeeDropdown, getEmployeeDropdownReset } from "../../../action/getEmployeeDropdownAction";

import { getMouList, getMouListReset } from "../../../action/getMouListAction"
import { saveMou, saveMouReset } from "../../../action/saveMouAction"
import { deleteMou, deleteMouReset } from "../../../action/deleteMouAction"
import { getUserToken, s3_cdn, getS3SingedUrl, validateImageFile, validateImageFileMou } from "../../../utils"
import axios from "axios"
import conf from '../../../config'
import { v4 as uuidv4 } from 'uuid'
export const InstituteMasterAdd = props => {
    /* variables */
    const institute_id = props.match.params.institute_id
    const mode = institute_id ? "edit" : "add"
    const fileList = [];
    const [imageFile, setImageFile] = useState({
        fileName: null,
        isChanged: false,
        edit: true
    })
    const {
        saveInstitute, saveInstituteReset, saveInstituteState,
        getInstituteDetail, getInstituteDetailReset, getInstituteDetailState,
        getDoctorList, getDoctorListReset, getDoctorListState,
        getPincodeList, getPincodeListReset, getPincodeListState,
        getLedgerAccountList, getLedgerAccountListReset, getLedgerAccountListState,
        getCommonMasterList, getCommonMasterListReset, getCommonMasterListState,
        getEmployeeDropdown, getEmployeeDropdownReset, getEmployeeDropdownState,
        getMouList, getMouListState, getMouListReset,
        saveMou, saveMouState, saveMouReset,
        deleteMou, deleteMouReset, deleteMouState,
    } = props
    const [formData, setFormData] = useState({
        lilac_id: "",
        name: "",
        address: "",
        address_2: "",
        city: "",
        state: "",
        pincode: "",
        email: "",
        type: "",
        credit_limit: "",
        pickup_start_time: "",
        pickup_end_time: "",
        bank_account_name: "",
        bank_account_number: "",
        ifsc_code: "",
        bank_branch: "",
        gst_number: "",
        pancard_number: "",
        tan_number: "",
        pcpndt_registration: "",
        pcpndt_expiration: "",
        doctors_attached: [],
        bdm: "",
        institute_type: "",
        report_release_scenario: "",
        report_email_cc: "",
        sample_knowledge_email: "",
        follow_up_email: "",
        finance_person_first_name: "",
        finance_person_last_name: "",
        finance_person_mobile_no: "",
        finance_person_email_cc: "",
        pick_up_partner: "",
        report_dispatch_partner: "",
        billing_account: false,
        account_receivable: false,
        ledger_account: false,
        general_ledger_account_code: "",
        billing_account_type: "",
        rate_at_booking: "",
        receipt_mode: "",
        hq_or_primary_marketing_location: "",
        district: "",
        title: "",
        mobile: "",
        country_code: "",
        collection_centre: "",
        clinic_timings: "",
        work_days: "",
        category_of_institute: "",
        status_of_institute: "",
        institute_login_id: "",
        institute_onboarded_on: "",
        institute_business_type: "",
        report_delivery_alert_mobile_number: "",
        report_email_bcc: "",
        report_sms_details_to: "",
        finance_person_email: "",
        finance_person_email_bcc: "",
        financial_sms_details_to: "",
        pick_up_partner_branch: "",
        pick_up_partner_area_code: "",
        transit_hours_from_institute_to_lcp: "",
        partner_pick_up_cut_off: "",
        mandatory_pick_up_info_to_pick_up_partner: "",
        pick_up_timing_slots: "",
        expiry_date_of_category: "",
        block_account: "",
        block_by: "",
        block_date: "",
        accounting_institute_type: "",
        tds_applicable: "",
        billing_cycle: "",
        billing_category: "",
        payment_mode: "",
        credit_term: "",
        tan_number: "",
        mou_done: "",
        mou_validity: "",
        key_decision_maker_mobile_name: "",
        key_decision_maker_mobile_num: "",
        key_decision_maker_email_id: "",
        expected_business: "",
        expected_minimum_business_per_month: "",
        expected_maximum_business_per_month: "",
        total_patient_footfall: "",
        number_of_pregnancies: "",
        uhid:"",
    })

    const [columns, setColumns] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [activeTab, setActiveTab] = useState('DETAILS-1')
    const [disableds, setdisableds] = useState(false)
    const [disabledsform, setdisabledsform] = useState(false)
    const [cancelButton, setCancelButton] = useState(false)
    const [testGroupForm] = Form.useForm()
    const [fileUniqueName, setFileUniqueName] = useState([])
    const { TabPane } = Tabs;
    const requiredFiledsTabWise = [
        { tab: "DETAILS-1", fields: ['fname', 'lname', 'dob', 'gender', 'email', 'mobile_number', 'pincode'] },
        { tab: "DETAILS-2", fields: [] },

    ]
    if (mode === "edit") {
        formData.id = institute_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()
    const [formDataMou, setFormDataMou] = useState({

        start_date: "",
        end_date: "",
        files: "",
        type_of_mou: "",
        status: false,
        institue_id: "",
    })
    /* callbacks */
    ///Mou start
    useEffect(() => {


    }, [])
    useEffect(() => {
        setColumns([
            {
                title: "Start Date",
                dataIndex: "start_date"
            },
            {
                title: "End Date",
                dataIndex: "end_date"
            },
           
            {
                title: "Type of Mou",
                dataIndex: "type_of_mou"
            },
            {
                title: "Status",
                dataIndex: "id",
                render: (id, row) => (
                    <>
                        <Space size="small">
                
                            {row.status && <Popconfirm
                                title="Are you sure to deactivate this Mou?"
                                onConfirm={() => {

                                    saveMou({
                                        id: row.id,
                                        start_date: row.start_date,
                                        end_date: row.end_date,
                                        files: row.files,
                                        type_of_mou: row.type_of_mou,
                                        status: false,
                                        institue_id: row.institue_id,
                                        mode: "edit"
                                    });
                                }}
                                okText="Deactivate"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                Accepted
                                {/*<Button size="small" shape="circle" icon={<CheckCircleFilled />} />*/}

                            </Popconfirm>}

                            {!row.status && <Popconfirm
                                title="Are you sure to activate this Mou?"
                                onConfirm={() => {
                                    saveMou({
                                        id: row.id,
                                        start_date: row.start_date,
                                        end_date: row.end_date,
                                        files: row.files,
                                        type_of_mou: row.type_of_mou,
                                        status: true,
                                        institue_id: row.institue_id,
                                        mode: "edit"
                                    })
                                }}
                                okText="Activate"
                                cancelText="Cancel"
                                okType="success"
                            >
                                {/*<Button size="small" shape="circle" icon={<CloseCircleOutlined />} />*/}

                            </Popconfirm>}
            
                        </Space>

                    </>
                )
            },
            {
                title: "File",
                dataIndex: "id",
                key: 'action',
                render: (id, row) => (
                    <>
                        <Space size="small">
                          

                            <Space size="small">

                                <a target="_blank" href={s3_cdn() + 'doctor/' + row.files}>Download</a>
                            </Space>
                        </Space>

                    </>
                )
            },
            {
                title: "Action",
                dataIndex: "id",
                key: 'action',
                render: (id, row) => (
                    <>
                        <Space size="small">
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => deleteMou({ id: id })}
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                     
                        </Space>

                    </>
                )
            },
        ])
    }, [])

    useEffect(() => {
        if (getMouListState.apiState === 'success') {
            let rows = []
            let rowItems = []
            let tabs = []
            if (getMouListState.list && getMouListState.list.length > 0) {


                getMouListState.list.map((item) => {


                    rowItems = []

                    rows.push({
                        start_date: item.start_date,
                        end_date: item.end_date,
                        files: item.files,
                        type_of_mou: item.type_of_mou,
                        id: item._id,
                        status: item.status,
                        institue_id: item.institue_id,
                    })
                })
                setDataSource(rows)
            
            }
        }
    }, [getMouListState])
    useEffect(() => {
        if (saveMouState.apiState === "success") {
            getMouList(formDataMou.institue_id)
            saveMouReset()
            //resetAllData()
            notification["success"]({
                message: saveMouState.message,
                placement: "topRight"
            })
        }
        if (saveMouState.apiState === "error") {
            saveMouReset()
            notification["error"]({
                message: saveMouState.message,
                placement: "topRight"
            })
        }
    }, [saveMouState])
    useEffect(() => {
        if (deleteMouState.apiState === "success") {
            getMouList(formDataMou.institue_id);
            notification["success"]({
                message: deleteMouState.message,
                placement: "topRight"
            })
          
        }
        if (deleteMouState.apiState === "error") {
            deleteMouReset()
            notification["error"]({
                message: deleteMouState.message,
                placement: "topRight"
            })
        }
    }, [deleteMouState])
      ///Mou end
    useEffect(() => {

        return (() => {
            saveInstituteReset()
            getInstituteDetailReset()
            // getDoctorListReset()

        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getInstituteDetail({
                id: institute_id
            })
        }
        getCommonMasterList({ slug: 'hq-primary-marketing-location,institute-business-type,collection-center,billing-category,pickup-partner' })
    }, [])

    useEffect(() => {
        if (getInstituteDetailState.apiState === "success") {
            // getDoctorList({ dropDownList: "/get-dropdown-list" })
            form.setFieldsValue({
                lilac_id: getInstituteDetailState.data.lilac_id,
                title: getInstituteDetailState.data.title,
                name: getInstituteDetailState.data.name,
                mobile: getInstituteDetailState.data.mobile,
                email: getInstituteDetailState.data.email,
                address: getInstituteDetailState.data.address,
                address_2: getInstituteDetailState.data.address_2 ? getInstituteDetailState.data.address_2 : "",
                city: getInstituteDetailState.data.city,
                district: getInstituteDetailState.data.district,
                state: getInstituteDetailState.data.state,
                pincode: getInstituteDetailState.data.pincode,
                collection_centre: getInstituteDetailState.data.collection_centre,
                clinic_timings:(getInstituteDetailState.data.clinic_timings_from && getInstituteDetailState.data.clinic_timings_to) ?
                [moment(getInstituteDetailState.data.clinic_timings_from, "HH:mm"), moment(getInstituteDetailState.data.clinic_timings_to, "HH:mm")] :
                [moment("10:00", "HH:mm"),moment("16:00", "HH:mm")],
                work_days: getInstituteDetailState.data.work_days && getInstituteDetailState.data.work_days.split(','),
                doctors_attached:getInstituteDetailState.data.doctors&&getInstituteDetailState.data.doctors.map(obj =>obj && `${obj.fname} ${obj.lname}`),
                category_of_institute: getInstituteDetailState.data.category_of_institute,
                status_of_institute: getInstituteDetailState.data.status_of_institute,
                institute_login_id: getInstituteDetailState.data.institute_login_id,
                institute_onboarded_on: getInstituteDetailState.data.institute_onboarded_on ? moment(getInstituteDetailState.data.institute_onboarded_on) : "",
                institute_business_type: getInstituteDetailState.data.institute_business_type,
                report_release_scenario: getInstituteDetailState.data.report_release_scenario,
                report_delivery_alert_mobile_number: getInstituteDetailState.data.report_delivery_alert_mobile_number,
                report_email_cc: getInstituteDetailState.data.report_email_cc,
                report_email_bcc: getInstituteDetailState.data.report_email_bcc,
                report_sms_details_to: getInstituteDetailState.data.report_sms_details_to,
                sample_knowledge_email: getInstituteDetailState.data.sample_knowledge_email,
                follow_up_email: getInstituteDetailState.data.follow_up_email,
                finance_person_first_name: getInstituteDetailState.data.finance_person_first_name,
                finance_person_last_name: getInstituteDetailState.data.finance_person_last_name,
                finance_person_mobile_no: getInstituteDetailState.data.finance_person_mobile_no,
                finance_person_email: getInstituteDetailState.data.finance_person_email,
                finance_person_email_cc: getInstituteDetailState.data.finance_person_email_cc,
                finance_person_email_bcc: getInstituteDetailState.data.finance_person_email_bcc,
                financial_sms_details_to: getInstituteDetailState.data.financial_sms_details_to,
                pick_up_partner: getInstituteDetailState.data.pick_up_partner,
                pick_up_partner_branch: getInstituteDetailState.data.pick_up_partner_branch,
                pick_up_partner_area_code: getInstituteDetailState.data.pick_up_partner_area_code,
                transit_hours_from_institute_to_lcp: getInstituteDetailState.data.transit_hours_from_institute_to_lcp,
                partner_pick_up_cut_off: getInstituteDetailState.data.partner_pick_up_cut_off,
                mandatory_pick_up_info_to_pick_up_partner: getInstituteDetailState.data.mandatory_pick_up_info_to_pick_up_partner,
                pick_up_timing_slots: getInstituteDetailState.data.pick_up_timing_slots,
                pickup_start_time: getInstituteDetailState.data.pickup_start_time,
                pickup_end_time: getInstituteDetailState.data.pickup_end_time,
                report_dispatch_partner: getInstituteDetailState.data.report_dispatch_partner,
                expiry_date_of_category: getInstituteDetailState.data.expiry_date_of_category?moment(getInstituteDetailState.data.expiry_date_of_category):"",
                block_account: getInstituteDetailState.data.block_account,
                block_by: getInstituteDetailState.data.block_by,
                block_date: getInstituteDetailState.data.block_date ? moment(getInstituteDetailState.data.block_date) : "",
                credit_term: getInstituteDetailState.data.credit_term,
                institute_type: getInstituteDetailState.data.institute_type,
                billing_account: getInstituteDetailState.data.billing_account,
                account_receivable: getInstituteDetailState.data.account_receivable,
                ledger_account: getInstituteDetailState.data.ledger_account,
                general_ledger_account_code: getInstituteDetailState.data.general_ledger_account_code,
                billing_account_type: getInstituteDetailState.data.billing_account_type,
                rate_at_booking: getInstituteDetailState.data.rate_at_booking,
                tds_applicable: getInstituteDetailState.data.tds_applicable,
                tds_percentage: getInstituteDetailState.data.tds_percentage,
                billing_cycle: getInstituteDetailState.data.billing_cycle,
                billing_category: getInstituteDetailState.data.billing_category,
                receipt_mode: getInstituteDetailState.data.receipt_mode && getInstituteDetailState.data.receipt_mode.split(','),
                payment_mode: getInstituteDetailState.data.payment_mode,
                credit_limit: getInstituteDetailState.data.credit_limit,
                pancard_number: getInstituteDetailState.data.pancard_number,
                gst_number: getInstituteDetailState.data.gst_number,
                tan_number: getInstituteDetailState.data.tan_number,
                bank_account_name: getInstituteDetailState.data.bank_account_name,
                bank_account_number: getInstituteDetailState.data.bank_account_number,
                ifsc_code: getInstituteDetailState.data.ifsc_code,
                bank_branch: getInstituteDetailState.data.bank_branch,
                pcpndt_registration: getInstituteDetailState.data.pcpndt_registration,
                pcpndt_expiration: getInstituteDetailState.data.pcpndt_expiration,
                hq_or_primary_marketing_location: getInstituteDetailState.data.hq_or_primary_marketing_location,
                bdm: getInstituteDetailState.data.bdm,
                mou_done: getInstituteDetailState.data.mou_done,
                mou_validity: getInstituteDetailState.data.mou_validity ? moment(getInstituteDetailState.data.mou_validity) : "",
                key_decision_maker_mobile_name: getInstituteDetailState.data.key_decision_maker_mobile_name,
                key_decision_maker_mobile_num: getInstituteDetailState.data.key_decision_maker_mobile_num,
                key_decision_maker_email_id: getInstituteDetailState.data.key_decision_maker_email_id,
                expected_business: getInstituteDetailState.data.expected_business,
                expected_minimum_business_per_month: getInstituteDetailState.data.expected_minimum_business_per_month,
                expected_maximum_business_per_month: getInstituteDetailState.data.expected_maximum_business_per_month,
                total_patient_footfall: getInstituteDetailState.data.total_patient_footfall,
                number_of_pregnancies: getInstituteDetailState.data.number_of_pregnancies,
                pan: getInstituteDetailState.data.pan,
                billing_category_id: getInstituteDetailState.data.billing_category_id,
                uhid: getInstituteDetailState.data.uhid,
                accounting_institute_type: getInstituteDetailState.data.accounting_institute_type,
                // type: getInstituteDetailState.data.type,
                // pickup_start_time: getInstituteDetailState.data.pickup_start_time,
                // pickup_end_time: getInstituteDetailState.data.pickup_end_time,
            });
            setFormData({
                ...formData,
                ['lidp_id']: getInstituteDetailState.data.lidp_id,
                ['lilac_id']: getInstituteDetailState.data.lilac_id,
                ['title']: getInstituteDetailState.data.title,
                ['name']: getInstituteDetailState.data.name,
                ['mobile']: getInstituteDetailState.data.mobile,
                ['email']: getInstituteDetailState.data.email,
                ['address']: getInstituteDetailState.data.address,
                ['address_2']: getInstituteDetailState.data.address_2 ? getInstituteDetailState.data.address_2 : "",
                ['city']: getInstituteDetailState.data.city,
                ['district']: getInstituteDetailState.data.district,
                ['state']: getInstituteDetailState.data.state,
                ['pincode']: getInstituteDetailState.data.pincode,
                ['collection_centre']: getInstituteDetailState.data.collection_centre,
                ['clinic_timings']: getInstituteDetailState.data.clinic_timings,
                ['clinic_timings_from']: getInstituteDetailState.data.clinic_timings_from,
                ['clinic_timings_to']: getInstituteDetailState.data.clinic_timings_to,
                ['work_days']: getInstituteDetailState.data.work_days,
                // ['doctors_attached']: getInstituteDetailState.data.doctors_attached&&getInstituteDetailState.data.doctors_attached.map(obj => obj && obj._id),
                ['category_of_institute']: getInstituteDetailState.data.category_of_institute,
                ['status_of_institute']: getInstituteDetailState.data.status_of_institute,
                ['institute_login_id']: getInstituteDetailState.data.institute_login_id,
                ['institute_onboarded_on']: getInstituteDetailState.data.institute_onboarded_on ? moment(getInstituteDetailState.data.institute_onboarded_on) : "",
                ['institute_business_type']: getInstituteDetailState.data.institute_business_type,
                ['report_release_scenario']: getInstituteDetailState.data.report_release_scenario,
                ['report_delivery_alert_mobile_number']: getInstituteDetailState.data.report_delivery_alert_mobile_number,
                ['report_email_cc']: getInstituteDetailState.data.report_email_cc,
                ['report_email_bcc']: getInstituteDetailState.data.report_email_bcc,
                ['report_sms_details_to']: getInstituteDetailState.data.report_sms_details_to,
                ['sample_knowledge_email']: getInstituteDetailState.data.sample_knowledge_email,
                ['follow_up_email']: getInstituteDetailState.data.follow_up_email,
                ['finance_person_first_name']: getInstituteDetailState.data.finance_person_first_name,
                ['finance_person_last_name']: getInstituteDetailState.data.finance_person_last_name,
                ['finance_person_mobile_no']: getInstituteDetailState.data.finance_person_mobile_no,
                ['finance_person_email']: getInstituteDetailState.data.finance_person_email,
                ['finance_person_email_cc']: getInstituteDetailState.data.finance_person_email_cc,
                ['finance_person_email_bcc']: getInstituteDetailState.data.finance_person_email_bcc,
                ['financial_sms_details_to']: getInstituteDetailState.data.financial_sms_details_to,
                ['pick_up_partner']: getInstituteDetailState.data.pick_up_partner,
                ['pick_up_partner_branch']: getInstituteDetailState.data.pick_up_partner_branch,
                ['pick_up_partner_area_code']: getInstituteDetailState.data.pick_up_partner_area_code,
                ['transit_hours_from_institute_to_lcp']: getInstituteDetailState.data.transit_hours_from_institute_to_lcp,
                ['partner_pick_up_cut_off']: getInstituteDetailState.data.partner_pick_up_cut_off,
                ['mandatory_pick_up_info_to_pick_up_partner']: getInstituteDetailState.data.mandatory_pick_up_info_to_pick_up_partner,
                ['pick_up_timing_slots']: getInstituteDetailState.data.pick_up_timing_slots,
                ['pickup_start_time']: getInstituteDetailState.data.pickup_start_time,
                ['pickup_end_time']: getInstituteDetailState.data.pickup_end_time,
                ['report_dispatch_partner']: getInstituteDetailState.data.report_dispatch_partner,
                ['expiry_date_of_category']: getInstituteDetailState.data.expiry_date_of_category?moment(getInstituteDetailState.data.expiry_date_of_category):'',
                ['block_account']: getInstituteDetailState.data.block_account,
                ['block_by']: getInstituteDetailState.data.block_by,
                ['block_date']: getInstituteDetailState.data.block_date ? moment(getInstituteDetailState.data.block_date) : "",
                ['credit_term']: getInstituteDetailState.data.credit_term,
                ['accounting_institute_type']: getInstituteDetailState.data.accounting_institute_type,
                ['billing_account']: getInstituteDetailState.data.billing_account,
                ['account_receivable']: getInstituteDetailState.data.account_receivable,
                ['ledger_account']: getInstituteDetailState.data.ledger_account,
                ['general_ledger_account_code']: getInstituteDetailState.data.general_ledger_account_code,
                ['billing_account_type']: getInstituteDetailState.data.billing_account_type,
                ['rate_at_booking']: getInstituteDetailState.data.rate_at_booking,
                ['tds_applicable']: getInstituteDetailState.data.tds_applicable,
                ['tds_percentage']: getInstituteDetailState.data.tds_percentage,
                ['billing_cycle']: getInstituteDetailState.data.billing_cycle,
                ['billing_category']: getInstituteDetailState.data.billing_category,
                ['receipt_mode']: getInstituteDetailState.data.receipt_mode,
                ['payment_mode']: getInstituteDetailState.data.payment_mode,
                ['credit_limit']: getInstituteDetailState.data.credit_limit,
                ['pancard_number']: getInstituteDetailState.data.pancard_number,
                ['pan']: getInstituteDetailState.data.pan,
                ['gst_number']: getInstituteDetailState.data.gst_number,
                ['tan_number']: getInstituteDetailState.data.tan_number,
                ['bank_account_name']: getInstituteDetailState.data.bank_account_name,
                ['bank_account_number']: getInstituteDetailState.data.bank_account_number,
                ['ifsc_code']: getInstituteDetailState.data.ifsc_code,
                ['bank_branch']: getInstituteDetailState.data.bank_branch,
                ['pcpndt_registration']: getInstituteDetailState.data.pcpndt_registration,
                ['pcpndt_expiration']: getInstituteDetailState.data.pcpndt_expiration,
                ['hq_or_primary_marketing_location']: getInstituteDetailState.data.hq_or_primary_marketing_location,
                ['bdm']: getInstituteDetailState.data.bdm,
                ['reporting_manager']: getInstituteDetailState.data.reporting_manager,
                ['reporting_head']: getInstituteDetailState.data.reporting_head,
                ['mou_done']: getInstituteDetailState.data.mou_done,
                ['mou_validity']: getInstituteDetailState.data.mou_validity ? moment(getInstituteDetailState.data.mou_validity) : "",
                ['key_decision_maker_mobile_name']: getInstituteDetailState.data.key_decision_maker_mobile_name,
                ['key_decision_maker_mobile_num']: getInstituteDetailState.data.key_decision_maker_mobile_num,
                ['key_decision_maker_email_id']: getInstituteDetailState.data.key_decision_maker_email_id,
                ['expected_minimum_business_per_month']: getInstituteDetailState.data.expected_minimum_business_per_month,
                ['expected_maximum_business_per_month']: getInstituteDetailState.data.expected_maximum_business_per_month,
                ['total_patient_footfall']: getInstituteDetailState.data.total_patient_footfall,
                ['number_of_pregnancies']: getInstituteDetailState.data.number_of_pregnancies,
                ['billing_category_id']: getInstituteDetailState.data.billing_category_id,
                ['uhid']: getInstituteDetailState.data.uhid,
            });
            setFormDataMou({
                ...formDataMou,
                ['institue_id']: getInstituteDetailState.data.lilac_id,
            });
            getMouList(getInstituteDetailState.data.lilac_id)
            if(getInstituteDetailState.data?.hq_or_primary_marketing_location){
                getEmployeeDropdown(`hqLocation=${getInstituteDetailState.data?.hq_or_primary_marketing_location}`);
            }

        }
    }, [getInstituteDetailState])

    useEffect(() => {
        if (saveInstituteState.apiState === "success") {
            saveInstituteReset()
            if (mode != "edit") {
                setRedirect([true, "/institute-master/list"])
            }

            notification["success"]({
                message: saveInstituteState.message,
                placement: "topRight"
            })
        }
        if (saveInstituteState.apiState === "error") {
            saveInstituteReset()
            notification["error"]({
                message: saveInstituteState.message,
                placement: "topRight"
            })
        }
    }, [saveInstituteState])

    useEffect(()=>{
     if(getEmployeeDropdownState.apiState==="success"){
        if(getEmployeeDropdownState.list.length!==0){
            form.setFieldsValue({
                bdm:`${getEmployeeDropdownState.list[0].employee_name} ${getEmployeeDropdownState.list[0].employee_middle_name} ${getEmployeeDropdownState.list[0].employee_last_name}`
            });
            setFormData({...formData,['bdm']:`${getEmployeeDropdownState.list[0].employee_name} ${getEmployeeDropdownState.list[0].employee_middle_name} ${getEmployeeDropdownState.list[0].employee_last_name}`})
    
        }else{
            form.setFieldsValue({
                bdm:` `});
            setFormData({...formData,['bdm']:` `})

        }
     }
    },[getEmployeeDropdownState])

    /* functions */
    const handleOnChange = e => {
        console.log(formData.general_ledger_account_code)
        if (e.target.name === "name") {
            if (formData.general_ledger_account_code === "Credit") {
                console.log("onchhage", e.target.value)

                form.setFieldsValue({
                    general_ledger_account_code: e.target.value
                })
                setFormData({ ...formData, ['general_ledger_account_code']: e.target.value });
            }
        }
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const transitOnChange = (moments) => {
        setFormData({ ...formData, ['transit_hours_from_institute_to_lcp']: moments._d })
        console.log("formData", formData);
    };
    const PickOnChange = (moments) => {
        setFormData({ ...formData, ['partner_pick_up_cut_off']: moments._d })
        console.log("formData", formData);
    };
    const PickSlotOnChange = (moments) => {
        setFormData({ ...formData, ['pick_up_timing_slots']: moments._d })
        console.log("formData", formData);
    };
    const handleSelectChange = (name, value) => {
        if (name === "billing_account_type") {
       
            if (value === "Debit") {
                form.setFieldsValue({
                    general_ledger_account_code: "Patient Revenue",
                })
                setFormData({ ...formData, ['general_ledger_account_code']: "Patient Revenue" });
            } else {
                form.setFieldsValue({
                    general_ledger_account_code: formData.name,
                })
                setFormData({ ...formData, ['general_ledger_account_code']: formData.name });
            }
            setFormData({ ...formData, ['billing_account_type']: value.toString() })
        }
        else {
            setFormData({ ...formData, [name]: value })
        }
        

    }
    const handleSelectChangeb = (name, value) => {
        const values = value
     
            if (value === "Debit") {
                form.setFieldsValue({
                    general_ledger_account_code: "Patient Revenue",
                })
                setFormData({
                    ...formData,
                    ['general_ledger_account_code']: "Patient Revenue",
                    ['billing_account_type']: values
                });

            } else {
                form.setFieldsValue({
                    general_ledger_account_code: formData.name,
                })
                setFormData({
                    ...formData,
                    ['general_ledger_account_code']: formData.name,
                    ['billing_account_type']: values
                });
            }
          

   
        console.log("formData", values, formData)


    }
   const handleTimeRangeChange = (from_name, to_name, dateArr) => {
        setFormData({ ...formData, [from_name]: moment(dateArr[0]).format("HH:mm"), [to_name]: moment(dateArr[1]).format("HH:mm") })
    }

    const handleSelect = (v, name) => {
        if (name == "work_days"  || name==="receipt_mode") {
            setFormData({
                ...formData,
                [name]: v.toString()
            })
        } else if (name === "pincode") {

            if (v) {
                const value = JSON.parse(v)
                setFormData({
                    ...formData,
                    ["pincode"]: value.pincode,
                    ["city"]: value.taluk_name && value.taluk_name == "NA" ? value.district_name : value.taluk_name,
                    ["district"]: value.district_name,
                    ["state"]: value.state_name,
                })
                form.setFieldsValue({
                    city: value.taluk_name && value.taluk_name == "NA" ? value.district_name : value.taluk_name,
                    district: value.district_name,
                    state: value.state_name,
                })
            }
            else {
                setFormData({
                    ...formData,
                    ["city"]: "",
                    ["district"]: "",
                    ["state"]: "",
                })
                form.setFieldsValue({
                    city: "",
                    district: "",
                    state: "",
                })
            }
        }
        else {
         
            setFormData({
                ...formData,
                [name]: v
            })
          
        }
        if (name === "hq_or_primary_marketing_location") {
            console.log("in location")
            getEmployeeDropdown(`hqLocation=${v}`);
        }
        if (name === "accounting_institute_type") {
            console.log("v", formData);
        }

    }
    useEffect(() => {
        if (getEmployeeDropdownState.apiState === "success") {

            let bdmvle = getEmployeeDropdownState.list[0]?.employee_name + " " + getEmployeeDropdownState.list[0]?.employee_middle_name + " " + getEmployeeDropdownState.list[0]?.employee_last_name
            setFormData({ ...formData, ['bdm']: bdmvle })
            form.setFieldsValue({
                bdm: bdmvle
            })
        }
    }, [getEmployeeDropdownState])
    const handleDateChange = (name, date, dateString) => {
        
            setFormData({ ...formData, [name]: moment(date) })
        
        
    }
    const handleSubmit =  () => {
        
        saveInstitute(formData)
        console.log("save", formData)
    }
    const handleValidation = () => {
        form.validateFields()
            .then(handleSubmit)
            .catch(e => {
                requiredFiledsTabWise.map(obj => {
                    if (obj.fields.includes(e.errorFields[0].name[0])) {
                        setActiveTab(obj.tab)
                    }
                })
            })
    }
    ///Mou
    const handleMouOnChange = e => {
        setFormDataMou({ ...formDataMou, [e.target.name]: e.target.value })
    }
    const handleMouSubmit = async () => {
        console.log("check", formDataMou)
        let imagefilename = uuidv4()
        let imageValidationRes = validateImageFileMou(imageFile.fileName)
        console.log("stus", imageFile.fileName)
        if (formDataMou.start_date == "") {
           
            formDataMou.start_date = formDataMou.end_date
     
        }
        if (imageValidationRes.status) {
            let s3UrlResImage = await getS3SingedUrl(imagefilename, imageValidationRes.ext, imageFile.fileName, 'doctor')
            await axios.put(s3UrlResImage.url, imageFile.fileName)
            formDataMou.files = `${imagefilename}.${imageValidationRes.ext}`
            saveMou(formDataMou)
        }
        else {
            alert("Select image type JPG or PNG.")
            return
        }
       
        setdisabledsform(false)
    }
    const handleMouSelect = (name, v) => {
        setFormDataMou({
            ...formDataMou,
            [name]: v
        })
    }
    const resetMouAllData = () => {
        setFormDataMou()
        testGroupForm.resetFields()
        setCancelButton(false)
    }
    const handleMoufileOnChange = e => {
        setFormDataMou({ ...formDataMou, [e.target.name]: e.target.files[0].name })

    }

     ///Mou
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/institute-master/list"}
                title={mode === "edit" ? "Edit Institute Master" : "Add Institute Master"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={{ mobile_number: { country_code: '+91' } }}>
                        <Tabs activeKey={activeTab} onChange={setActiveTab} >
                            <TabPane tab="PROFILE" disabled={activeTab!="DETAILS-1"} key="DETAILS-1">
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="LIMS ID"
                                            name="lilac_id"
                                            rules={[
                                             () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabets and numbers!')); }, })]}
                                        >
                                            <Input name="lilac_id" placeholder="Enter LIMS ID"
                                                onChange={handleOnChange} disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Name of the Institute"
                                            name="name"
                                            rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Input name="name" placeholder="Enter Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Official Mobile Number"
                                            name="mobile"
                                            rules={[

                                                () => ({ validator(_, value) { if (!value || value.match(/^[-+0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input number!')); }, })
                                                , {
                                                    required: false,
                                                    message: "Required"
                                                }]}
                                        >
                                            <Input name="mobile" placeholder="Enter Mobile Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>

                                    {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        
                                        <Form.Item label="Official Mobile Number">
                                            <Input.Group compact style={{disply:"flex",flexDirection:"row"}}>
                                                
                                                <Form.Item
                                                name={["mobile_number","country_code"]}
                                                style={{width:"20%"}}
                                                >
                                                  <Input name="country_code" placeholder=""
                                                        onChange={handleOnChange}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                name={["mobile_number","mobile"]}
                                                style={{width:"80%"}}
                                                rules={[

                                                    () => ({ validator(_, value) { if (!value || value.match(/^[0-9]*$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valide mobile number!')); }, })
                                                    , {
                                                        required: false,
                                                        message: "Required"
                                                    }]}
                                                >
                                                    <Input name="mobile" placeholder="Enter Mobile Number"
                                                        onChange={handleOnChange}
                                                    />

                                                </Form.Item>
                                            </Input.Group>

                                        </Form.Item>


                                       



                                    </Col> */}
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Official Email"
                                            name="email"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/
                                                )) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                                ,
                                                { required: true, message: 'Required' },

                                            ]}
                                        >
                                            <Input name="email" placeholder="Enter Email"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pincode"
                                            name="pincode"
                                            rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Select
                                                name="pincode"
                                                placeholder="Select Pincode"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "pincode")}
                                                showSearch
                                                onSearch={_.debounce(function (v) {
                                                    getPincodeList({
                                                        pincode: v
                                                    })
                                                }, 500)}
                                                filterOption={false}
                                            >
                                                {getPincodeListState.list.map(obj => (
                                                    <Select.Option value={JSON.stringify(obj)} key={obj.pincode} >{obj.pincode}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="State"
                                            name="state"
                                            rules={[{ required: true, message: "Required" }, () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabet!')); }, })]}
                                        >
                                            <Input name="state" placeholder="Enter State" onChange={handleOnChange} />

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="City"
                                            name="city"
                                            rules={[{ required: true, message: "Required" }, () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabet!')); }, })]}
                                        >
                                            <Input name="city" placeholder="Enter City" onChange={handleOnChange} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="District"
                                            name="district"
                                        //rules={[
                                        //  { required: true, message: 'Required' },
                                        //]}
                                        >
                                            <Input name="district" placeholder="Enter District"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Form.Item
                                            label="Address Line 1"
                                            name="address"
                                            rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Input.TextArea showCount name="address" placeholder="Enter Address Line 1"
                                                maxLength={100} onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Form.Item
                                            label="Address Line 2"
                                            name="address_2"
                                        >
                                            <Input.TextArea showCount name="address_2" placeholder="Enter Address Line 2"
                                                maxLength={100} onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Collection Centre"
                                            name="collection_centre"
                                        >
                                            <Select
                                                showSearch
                                                name="collection_centre"
                                                onSelect={(v) => handleSelect(v, "collection_centre")}
                                                placeholder="Select Collection Centre"
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "collection-center" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "collection-center" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item.value} >{item.value}</Select.Option>
                                                        ))}

                                                    </>
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Clinic Timings"
                                            name="clinic_timings"
                                        >
                                            <TimePicker.RangePicker format={"HH:mm"}
                                                onChange={dateArr => handleTimeRangeChange("clinic_timings_from", "clinic_timings_to", dateArr)}
                                               
                                            />

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Work Days"
                                            name="work_days"

                                        >
                                            <Select
                                                name="work_days"
                                                placeholder="Select Work Days"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "work_days")}
                                                mode="multiple"

                                            >
                                                <Select.Option value={"Sunday"} >Sunday</Select.Option>
                                                <Select.Option value={"Monday"} >Monday</Select.Option>
                                                <Select.Option value={"Tuesday"} >Tuesday</Select.Option>
                                                <Select.Option value={"Wednesday"} >Wednesday</Select.Option>
                                                <Select.Option value={"Thursday"} >Thursday</Select.Option>
                                                <Select.Option value={"Friday"} >Friday</Select.Option>
                                                <Select.Option value={"Saturday"} >Saturday</Select.Option>

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Status Of Institute"
                                            name="status_of_institute"

                                        >
                                            <Select
                                                name="status_of_institute"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "status_of_institute")}
                                            >
                                                <Select.Option value={"New"} >New</Select.Option>
                                                <Select.Option value={"INCIP"} >INCIP</Select.Option>
                                                <Select.Option value={"Lost"} >Lost</Select.Option>
                                                <Select.Option value={"Win Back"} >Win Back</Select.Option>
                                                <Select.Option value={"Current"} >Current</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Institute Login Id"
                                            name="institute_login_id"
                                        >
                                            <Input name="institute_login_id" placeholder="Enter Institute Login Id"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Institute Onboarded On"
                                            name="institute_onboarded_on"

                                        >
                                            <DatePicker name="institute_onboarded_on" placeholder="Institute Onboarded On"
                                                onChange={(date, dateString) => handleDateChange("institute_onboarded_on", date, dateString)}
                                                style={{ width: "100%" }}
                                                format={'DD/MM/YYYY'}
                                            />

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Institute Business Type"
                                            name="institute_business_type"

                                        >
                                            <Select
                                                name="institute_business_type"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "institute_business_type")}
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "institute-business-type" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "institute-business-type" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item.value} >{item.value}</Select.Option>
                                                        ))}

                                                    </>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Category Of Institute"
                                            name="category_of_institute"

                                        >
                                            <Select
                                                name="category_of_institute"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "category_of_institute")}

                                            >
                                                <Select.Option value={"Platinum"} >Platinum</Select.Option>
                                                <Select.Option value={"Gold"} >Gold</Select.Option>
                                                <Select.Option value={"Silver"} >Silver</Select.Option>
                                                <Select.Option value={"Bronze"} >Bronze</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Expiry Date Of Category"
                                            name="expiry_date_of_category"

                                        >
                                            <DatePicker name="expiry_date_of_category" placeholder="Expiry Date Of Category"
                                                onChange={(date, dateString) => handleDateChange("expiry_date_of_category", date, dateString)}
                                                style={{ width: "100%" }}
                                                format={'DD/MM/YYYY'}
                                            />
                                           
                                        </Form.Item>
                                    </Col>



                                    {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Expected Business"
                                            name="expected_business"
                                            rules={[() => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, }),]}

                                        >
                                            <Input name="expected_business" placeholder="Enter Key Expected Business"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col> */}
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Expected Minimum Business Per Month"
                                            name="expected_minimum_business_per_month"
                                            rules={[() => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, }),]}

                                        >
                                            <Input name="expected_minimum_business_per_month" placeholder="Enter Expected Minimum Business Per Month"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Expected Maximum Business Per Month"
                                            name="expected_maximum_business_per_month"
                                            rules={[() => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, }),]}

                                        >
                                            <Input name="expected_maximum_business_per_month" placeholder="Enter Expected Maximum Business Per Month"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Total Patient Footfall"
                                            name="total_patient_footfall"
                                            rules={[() => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, }),]}

                                        >
                                            <Input name="total_patient_footfall" placeholder="Enter Total Patient Footfall"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Number Of Pregnancies"
                                            name="number_of_pregnancies"
                                            rules={[() => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, }),]}

                                        >
                                            <Input name="number_of_pregnancies" placeholder="Enter Of Pregnancies"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="UHID"
                                            name="uhid"
                                            rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Radio.Group onChange={handleOnChange} name="uhid" value={getInstituteDetailState.data.uhid}>
                                                <Radio value={1}>Yes</Radio>
                                                <Radio value={0}>No</Radio>
                                            </Radio.Group>
                                            {/* <Input name="uhid" placeholder="Enter Of UHID"
                                                onChange={handleOnChange}
                                            /> */}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={24} xl={24}>
                                        <Form.Item
                                            label="Doctors Attached"
                                            name="doctors_attached"
                                        // rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Select
                                                name="doctors_attached"
                                                placeholder="Select Doctors Attached"
                                                allowClear
                                                showSearch
                                                mode="multiple"
                                                disabled
                                                onChange={(v) => handleSelectChange("doctors_attached", v)}
                                                filterOption={false}
                                                onSearch={_.debounce(value => {
                                                    getDoctorList({ searchTerm: value, dropDownList: "/get-dropdown-list" })
                                                }, 500)}
                                                notFoundContent={getDoctorListState.apiState === "loading" ? "wait.." : ""}
                                            >
                                                {getDoctorListState.list.map((item, index) => {
                                                    return (
                                                        < Select.Option value={`${item._id}`
                                                        } key={`${index}`}>{`${item.fname} ${item.lname}`}</Select.Option>
                                                    )
                                                })
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>












                                </Row>
                                <Space>
                                    {mode === "edit" && <Button type="primary" onClick={handleValidation} >SUBMIT</Button>}
                                    <Button type="primary" onClick={() =>
                                        form.validateFields().then(() => {
                                            setActiveTab('DETAILS-2')
                                            setdisableds(false)
                                        }).catch(e => {
                                            setdisableds(true)
                                        })

                                    }>NEXT</Button>
                                </Space>
                            </TabPane>

                            <TabPane tab="CONTACT" disabled={activeTab!="DETAILS-2"} key="DETAILS-2">
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Report Delivery Alert Mobile Number"
                                            name="report_delivery_alert_mobile_number"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^[-+0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input number!')); }, })
                                            ]}
                                        >
                                            <Input name="report_delivery_alert_mobile_number" placeholder="Enter Mobile Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Report Email Bcc"
                                            name="report_email_bcc"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                            ]}

                                        >
                                            <Input name="report_email_bcc" placeholder="Report Email Bcc"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Report SMS Details To"
                                            name="report_sms_details_to"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^[-+0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input number!')); }, })
                                            ]}
                                        >
                                            <Input name="report_sms_details_to" placeholder="Enter Mobile Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Finance Person Email"
                                            name="finance_person_email"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                            ]}

                                        >
                                            <Input name="finance_person_email" placeholder="Finance Person Email"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Finance Person Email Bcc"
                                            name="finance_person_email_bcc"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                            ]}

                                        >
                                            <Input name="finance_person_email_bcc" placeholder="Finance Person Email Bcc"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Financial SMS Details To"
                                            name="financial_sms_details_to"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^[-+0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input number!')); }, })
                                            ]}
                                        >
                                            <Input name="financial_sms_details_to" placeholder="Enter Mobile Number" maxLength={13}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Report Email CC"
                                            name="report_email_cc"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                            ]}
                                        >
                                            <Input name="report_email_cc" placeholder="Enter Report Email CC"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Sample Acknowledgement  Email"
                                            name="sample_knowledge_email"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                            ]}
                                        >
                                            <Input name="sample_knowledge_email" placeholder="Enter Sample Knowledge Email"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Follow-up Email"
                                            name="follow_up_email"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                            ]}

                                        >
                                            <Input name="follow_up_email" placeholder="Enter Follow-up Email"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>







                                </Row>
                                <Space>
                                    <Button type="primary" onClick={() =>{  
                                            setActiveTab('DETAILS-1')
                                            setdisableds(false)
                                        }
                                        // form.validateFields().then(() => {
                                        //     setActiveTab('DETAILS-1')
                                        //     setdisableds(false)
                                        // }).catch(e => {
                                        //     setdisableds(true)
                                        // })

                                    }>PREV</Button>
                                    {mode === "edit" && <Button type="primary" onClick={handleValidation} >SUBMIT</Button>}
                                    <Button type="primary" onClick={() =>
                                        form.validateFields().then(() => {
                                            setActiveTab('LOGISTICS')
                                            setdisableds(false)
                                        }).catch(e => {
                                            setdisableds(true)
                                        })

                                    }>NEXT</Button>
                                </Space>

                            </TabPane>

                            <TabPane tab="LOGISTICS" disabled={activeTab!="LOGISTICS"} key="LOGISTICS">
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pick Up Partner"
                                            name="pick_up_partner"
                                        // rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Select
                                                name="pick_up_partner"
                                                placeholder="Select Pick Up Partner"
                                                allowClear
                                                onChange={(v) => handleSelectChange("pick_up_partner", v)}
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "pickup-partner" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "pickup-partner" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item.value} >{item.value}</Select.Option>
                                                        ))}

                                                    </>
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pickup Start Time"
                                            name="pickup_start_time"
                                        >
                                            <Select
                                                name="pickup_start_time"
                                                placeholder="Select Pickup Start Time"
                                                allowClear
                                                showSearch
                                                onChange={(v) => handleSelectChange("pickup_start_time", v)}
                                            >
                                                {pickup_time_slots.map((pickup_time_slot, index) => (
                                                    <Select.Option value={pickup_time_slot} key={index} >{pickup_time_slot}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pickup End Time"
                                            name="pickup_end_time"
                                        >
                                            <Select
                                                name="pickup_end_time"
                                                placeholder="Select Pickup End Time"
                                                allowClear
                                                showSearch
                                                onChange={(v) => handleSelectChange("pickup_end_time", v)}
                                            >
                                                {pickup_time_slots.map((pickup_time_slot, index) => (
                                                    <Select.Option value={pickup_time_slot} key={index} >{pickup_time_slot}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pick UP Partner Branch"
                                            name="pick_up_partner_branch"
                                        >
                                            <Input name="pick_up_partner_branch" placeholder="Enter Pick UP Partner Branch"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pick Up Partner Area Code"
                                            name="pick_up_partner_area_code"
                                        >
                                            <Input name="pick_up_partner_area_code" placeholder="Enter Pick Up Partner Area Code"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Report Dispatch Partner"
                                            name="report_dispatch_partner"
                                        // rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Input name="report_dispatch_partner" placeholder="Enter Report Dispatch Partner"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Mandatory Pick Up Info To Pick Up Partner"
                                            name="mandatory_pick_up_info_to_pick_up_partner"

                                        >
                                            <Select
                                                name="mandatory_pick_up_info_to_pick_up_partner"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "mandatory_pick_up_info_to_pick_up_partner")}
                                            >
                                                <Select.Option value={"Yes"} >Yes</Select.Option>
                                                <Select.Option value={"No"} >No</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Transit Hours From Institute To Lcp"
                                            name="transit_hours_from_institute_to_lcp"
                                        >
                                            <Select
                                                name="transit_hours_from_institute_to_lcp"
                                                placeholder="Transit Hours From Institute To Lcp"
                                                allowClear
                                                showSearch
                                                onChange={(v) => handleSelectChange("transit_hours_from_institute_to_lcp", v)}
                                            >
                                                {pickup_time_slots.map((pickup_time_slot, index) => (
                                                    <Select.Option value={pickup_time_slot} key={index} >{pickup_time_slot}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item label="Partner Pick Up Cut Off"
                                            name="partner_pick_up_cut_off"
                                        >

                                            <TimePicker value={formData.partner_pick_up_cut_off} onChange={PickOnChange} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pick Up Timing Slots"
                                            name="pick_up_timing_slots"
                                        >

                                            <TimePicker value={formData.pick_up_timing_slots} onChange={PickSlotOnChange} />
                                        </Form.Item>
                                    </Col> */}
                                </Row>
                                <Space>
                                    <Button type="primary" onClick={() =>
                                        {
                                            setActiveTab('DETAILS-2')
                                            setdisableds(false)
                                        }
                                        // form.validateFields().then(() => {
                                            // setActiveTab('ACCOUNT')
                                            // setdisableds(false)
                                        // }).catch(e => {
                                        //     setdisableds(true)
                                        // })

                                    }>PREV</Button>
                                    {mode === "edit" && <Button type="primary" onClick={handleValidation} >SUBMIT</Button>}
                                    <Button type="primary" onClick={() =>
                                        form.validateFields().then(() => {
                                            setActiveTab('ACCOUNT')
                                            setdisableds(false)
                                        }).catch(e => {
                                            setdisableds(true)
                                        })

                                    }>NEXT</Button>
                                </Space>
                            </TabPane>

                            <TabPane tab="ACCOUNT" disabled={activeTab!="ACCOUNT"} key="ACCOUNT">

                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Billing Account"
                                            name="billing_account"
                                        // rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Switch checked={formData.billing_account} onChange={(v) => handleSelect(v, "billing_account")} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Account Receivable"
                                            name="account_receivable"
                                        // rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Switch checked={formData.account_receivable} onChange={(v) => handleSelect(v, "account_receivable")} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Billing Account Type"
                                            name="billing_account_type"
                                        //rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Select
                                                name="billing_account_type"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) =>
                                                    handleSelectChangeb("billing_account_type", v)
                                                  
                                                }
                                            >
                                                <Select.Option value={"Debit"} >Debit</Select.Option>
                                                <Select.Option value={"Credit"} >Credit</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Credit Limit"
                                            name="credit_limit"
                                            rules={[() => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid credit limit!')); }, }),]}

                                        >
                                            <Input name="credit_limit" placeholder="Enter Credit Limit"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Bank A/c Name"
                                            name="bank_account_name"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || value.match(/^[A-Za-z\s\.]+$/)) {
                                                            return Promise.resolve();
                                                        } return Promise.reject(new Error('* Please input alphabet!'));
                                                    },
                                                })]}
                                        >
                                            <Input name="bank_account_name" placeholder="Enter Bank A/c Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Bank A/c Number"
                                            name="bank_account_number"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please Enter valid bank account number!')); }, })
                                            ]}
                                        >
                                            <Input name="bank_account_number" placeholder="Enter Bank A/c Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="IFSC Code"
                                            name="ifsc_code"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z]+\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please input alphabet and number!')); }, })]}
                                        >
                                            <Input name="ifsc_code" placeholder="Enter IFSC Code"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Branch"
                                            name="bank_branch"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^[ A-Za-z0-9~@#$^&*()_+={}|\,.?:-]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please input alphabet or number!')); }, })]}
                                        >
                                            <Input name="bank_branch" placeholder="Enter Branch"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="GST"
                                            name="gst_number"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid gst number!')); }, })]}
                                        >
                                            <Input maxLength="15" name="gst_number" placeholder="Enter GST"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="PAN"
                                            name="pancard_number"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid pan number!')); }, })]}
                                        >
                                            <Input name="pancard_number" placeholder="Enter PAN"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Ledger Account"
                                            name="ledger_account"
                                        // rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Switch checked={formData.ledger_account} onChange={(v) => handleSelect(v, "ledger_account")} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        {/* <Form.Item
                                            label="General Ledger Account Code"
                                            name="general_ledger_account_code"
                                        // rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Input name="general_ledger_account_code" placeholder="Enter General Ledger Account Code"
                                                onChange={handleOnChange} readOnly
                                            />
                                        </Form.Item> */}
                                        <Form.Item
                                            label="General Ledger Account Code"
                                            name="general_ledger_account_code"
                                        >
                                            <Select
                                                name="general_ledger_account_code"
                                                placeholder="Select General Ledger Account Code"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "general_ledger_account_code")}
                                                showSearch
                                                onSearch={_.debounce(function (v) {
                                                    if(v.length>3)
                                                    {
                                                        getLedgerAccountList({
                                                            general_ledger_account_code: v
                                                        })
                                                    }
                                                }, 500)}
                                                filterOption={false}
                                            >
                                                {getLedgerAccountListState.list.map(obj => (
                                                    <Select.Option value={obj.value.toString()} key={obj.value} >{obj.value}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Rate At Booking"
                                            name="rate_at_booking"
                                        // rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Select
                                                name="rate_at_booking"
                                                placeholder="Rate At Booking"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "rate_at_booking")}
                                            >
                                                <Select.Option value={"Patient Billing Rate"} >Patient Billing Rate</Select.Option>
                                                <Select.Option value={"MRP Billing Rate"} >MRP Billing Rate</Select.Option>
                                                <Select.Option value={"Franchise Billing Rate"} >Franchise Billing Rate</Select.Option>

                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Receipt Mode"
                                            name="receipt_mode"
                                        //rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Select
                                                name="receipt_mode"
                                                placeholder="Receipt Mode"
                                                allowClear
                                                mode="multiple"
                                                onChange={(v) => handleSelect(v, "receipt_mode")}
                                            >
                                                <Select.Option value={"Cash"} >Cash</Select.Option>
                                                <Select.Option value={"Credit Card"} >Credit Card</Select.Option>
                                                <Select.Option value={"DD"} >DD</Select.Option>
                                                <Select.Option value={"Cheque"} >Cheque</Select.Option>
                                                <Select.Option value={"On Account"} >On Account</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Block Account"
                                            name="block_account"

                                        >
                                            <Select
                                                name="block_account"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "block_account")}
                                            >

                                                <Select.Option value={"Yes"} >Yes</Select.Option>
                                                <Select.Option value={"No"} >No</Select.Option>

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Block By"
                                            name="block_by"
                                        >
                                            <Input name="block_by" placeholder="Enter Block By"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Block Date"
                                            name="block_date"
                                        >
                                            <DatePicker name="block_date" placeholder="Block Date"
                                                onChange={(date, dateString) => handleDateChange("block_date", date, dateString)}
                                                style={{ width: "100%" }}
                                                format={'DD/MM/YYYY'}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Accounting Institute Type"
                                            name="accounting_institute_type"
                                        >
                                            <Select
                                                name="accounting_institute_type"
                                                placeholder="Select Accounting Institute Type"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "accounting_institute_type")}
                                            >
                                                <Select.Option value={"Type 1"} >Type 1</Select.Option>
                                                <Select.Option value={"Type 2"} >Type 2</Select.Option>
                                                <Select.Option value={"Type 3"} >Type 3</Select.Option>
                                                <Select.Option value={"Type 4"} >Type 4</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="TDS Applicable"
                                            name="tds_applicable"
                                        >
                                            <Select
                                                name="tds_applicable"
                                                placeholder="Select TDS Applicable"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "tds_applicable")}
                                            >
                                                <Select.Option value={"Yes"} >Yes</Select.Option>
                                                <Select.Option value={"No"} >No</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Billing Cycle"
                                            name="billing_cycle"

                                        >
                                            <Select
                                                name="billing_cycle"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "billing_cycle")}
                                            >
                                                <Select.Option value={"Daily"} >Daily</Select.Option>
                                                <Select.Option value={"Weekly"} >Weekly</Select.Option>
                                                <Select.Option value={"Monthly"} >Monthly</Select.Option>
                                                <Select.Option value={"Quarterly"} >Quarterly</Select.Option>
                                                <Select.Option value={"Fortnightly"} >Fortnightly</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Billing Category Name"
                                            name="billing_category"
                                        >
                                            <Select
                                                showSearch
                                                name="billing_category"
                                                onSelect={(v) => handleSelect(v, "billing_category")}
                                                placeholder="Select Billing Category Name"
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "billing-category" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "billing-category" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item.value} >{item.value}</Select.Option>
                                                        ))}

                                                    </>
                                                }

                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Billing Category Id"
                                            name="billing_category_id"
                                        >
                                            <Input name="billing_category_id" placeholder="Billing Category Id"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Payment Mode"
                                            name="payment_mode"
                                        >
                                            <Select
                                                name="payment_mode"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "payment_mode")}
                                            >
                                                <Select.Option value={"Cash"} >Cash</Select.Option>
                                                <Select.Option value={"Credit Card"} >Credit Card</Select.Option>
                                                <Select.Option value={"DD"} >DD</Select.Option>
                                                <Select.Option value={"Cheque"} >Cheque</Select.Option>
                                                <Select.Option value={"On Account"} >On Account</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Credit Term"
                                            name="credit_term"
                                        >
                                            <Input name="credit_term" placeholder="Enter Credit Term"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col> */}
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Tan Number"
                                            name="tan_number"
                                        >
                                            <Input name="tan_number" placeholder="Enter Tan Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Space>
                                    <Button type="primary" onClick={() =>
                                        {
                                            setActiveTab('LOGISTICS')
                                            setdisableds(false)
                                        }
                                        // form.validateFields().then(() => {
                                            // setActiveTab('ACCOUNT')
                                            // setdisableds(false)
                                        // }).catch(e => {
                                        //     setdisableds(true)
                                        // })

                                    }>PREV</Button>
                                    {mode === "edit" && <Button type="primary" onClick={handleValidation} >SUBMIT</Button>}
                                    <Button type="primary" onClick={() =>
                                        form.validateFields().then(() => {
                                            setActiveTab('LILAC REPORTING AND LEGAL')
                                            setdisableds(false)
                                        }).catch(e => {
                                            setdisableds(true)
                                        })

                                    }>NEXT</Button>
                                </Space>
                            </TabPane>

                            <TabPane tab="LILAC REPORTING AND LEGAL" disabled={activeTab!="LILAC REPORTING AND LEGAL"} key="LILAC REPORTING AND LEGAL">
                               
                                <Row gutter={20}>

                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Report Release Scenario"
                                            name="report_release_scenario"
                                        // rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Select
                                                name="report_release_scenario"
                                                placeholder="Select Report Release Scenario"
                                                allowClear
                                                onChange={(v) => handleSelect(v, "report_release_scenario")}


                                            >
                                                <Select.Option value={"Report Printed at Center With Header"} >Report Printed at Center With Header</Select.Option>
                                                <Select.Option value={"Report Printed at Center W/O Header"} >Report Printed at Center W/O Header</Select.Option>


                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="HQ or Primary Marketing Location"
                                            name="hq_or_primary_marketing_location"
                                            rules={[{ required: false, message: "Required" }]}
                                        >
                                            <Select
                                                showSearch
                                                name="hq_or_primary_marketing_location"
                                                onSelect={(v) => handleSelect(v, "hq_or_primary_marketing_location")}
                                                placeholder="Select HQ or Primary Marketing Location"
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "hq-primary-marketing-location" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "hq-primary-marketing-location" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item.value} >{item.value}</Select.Option>
                                                        ))}

                                                    </>
                                                }

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="BDMs Attached"
                                            name="bdm"
                                            rules={[{ required: true, message: "Required" }]}
                                        >
                                           <Input  placeholder="BDM" readOnly/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="PCPNDT Registration"
                                            name="pcpndt_registration"
                                        //rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Input name="pcpndt_registration" placeholder="Enter PCPNDT Registration"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="PCPNDT Expiration"
                                            name="pcpndt_expiration"
                                        // rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Input name="pcpndt_expiration" placeholder="Enter PCPNDT Expiration" type="date" min={Date.now()}
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {/*<Col xs={24} sm={24} md={12} lg={6} xl={6}>*/}
                                    {/*    <Form.Item*/}
                                    {/*        label="Mou Done"*/}
                                    {/*        name="mou_done"*/}
                                    {/*    >*/}
                                    {/*        <Select*/}
                                    {/*            name="mou_done"*/}
                                    {/*            placeholder="Select Type"*/}
                                    {/*            allowClear*/}
                                    {/*            onChange={(v) => handleSelect(v, "mou_done")}*/}
                                    {/*        >*/}
                                    {/*            <Select.Option value={"Yes"} >Yes</Select.Option>*/}
                                    {/*            <Select.Option value={"No"} >No</Select.Option>*/}
                                    {/*        </Select>*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                    {/*<Col xs={24} sm={24} md={12} lg={6} xl={6}>*/}
                                    {/*    <Form.Item*/}
                                    {/*        label="Mou Validity"*/}
                                    {/*        name="mou_validity"*/}
                                    {/*    >*/}
                                    {/*        <DatePicker name="mou_validity" placeholder="Mou Validity"*/}
                                    {/*            onChange={(date, dateString) => handleDateChange("mou_validity", date, dateString)}*/}
                                    {/*            style={{ width: "100%" }}*/}
                                    {/*            format={'DD/MM/YYYY'}*/}
                                    {/*        />*/}
                                    {/*    </Form.Item>*/}
                                    {/*</Col>*/}
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Key Decision Maker Name"
                                            name="key_decision_maker_mobile_name"
                                        >
                                            <Input name="key_decision_maker_mobile_name" placeholder="Enter Key Decision Maker Mobil Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Key Decision Maker Mobile Number"
                                            name="key_decision_maker_mobile_num"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^[-+0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input number!')); }, })
                                            ]}

                                        >
                                            <Input name="key_decision_maker_mobile_num" placeholder="Enter Key Decision Maker Mobil Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Key Decision Maker Email"
                                            name="key_decision_maker_email_id"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25}([;]([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})*$/)) { return Promise.resolve(); } return Promise.reject(new Error('Please enter valid email!')); }, })
                                            ]}
                                        >
                                            <Input name="key_decision_maker_email_id" placeholder="Key Decision Maker Email"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {
                                    saveInstituteState.apiState=="loading" ? <Button type="primary" loading>Adding</Button> : <Space>
                                        <Button type="primary" onClick={() =>
                                                {
                                                    setActiveTab('ACCOUNT')
                                                    setdisableds(false)
                                                }
                                                // form.validateFields().then(() => {
                                                    // setActiveTab('ACCOUNT')
                                                    // setdisableds(false)
                                                // }).catch(e => {
                                                //     setdisableds(true)
                                                // })

                                        }>PREV</Button>
                                        <Button type="primary" onClick={handleValidation} >SUBMIT</Button>
                                    </Space>
                                }
                            </TabPane>

                            {mode === "edit" && <TabPane tab="MOU" key="MOU">
                                {disabledsform &&
                                    
                                <Form form={testGroupForm} layout="vertical" onFinish={handleMouSubmit}>
                                    <Row gutter={20}>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                name="start_date"
                                                label="Start Date"
                                              
                                            >
                                                <Input name="start_date" type="date"
                                                    onChange={handleMouOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="End Date"
                                                name="end_date"
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Input name="end_date" type="date"
                                                    onChange={handleMouOnChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Doc Upload"
                                                name="files"

                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Input 
                                                    type="file"
                                                    onChange={e => setImageFile({
                                                        fileName: e.target.files[0]
                                                    })}
                                                    name="files"
                                                    accept="image/png, image/jpeg,application/pdf"
                                                  
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Type Of MOU"
                                                name="type_of_mou"
                                                rules={[
                                                    { required: true, message: 'Required' },
                                                ]}
                                            >
                                                <Select
                                                    name="type_of_mou"

                                                    placeholder="Select type of MOU"
                                                    onChange={(v) => handleMouSelect("type_of_mou", v)}>
                                                    <Select.Option value="price_mou">Price MOU</Select.Option>
                                                    <Select.Option value="legal_mou">Legal MOU</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Space>
                                                <Button type="primary" htmlType="submit" loading={saveMouState.apiState === "loading"} >SUBMIT</Button>
                                           
                                                    <Button type="default" onClick={() => { setdisabledsform(false) }} >Cancel</Button>
                                               
                                            </Space>
                                        </Col>
                                    </Row>
                                    </Form>
                                }
                                {!disabledsform &&
                                    <>
                                    <div style={{marginBottom:"20px"}}>
                                    <Button type="primary" onClick={() =>
                                        {
                                            setActiveTab('LILAC REPORTING AND LEGAL')
                                            setdisableds(false)
                                        }
                                        // form.validateFields().then(() => {
                                            // setActiveTab('ACCOUNT')
                                            // setdisableds(false)
                                        // }).catch(e => {
                                        //     setdisableds(true)
                                        // })
                                    }>PREV</Button>
                                    <Button type="primary" style={{float:"right"}} onClick={() => { setdisabledsform(true) }} ><PlusOutlined />New</Button>
                                        </div>
                                    <Row gutter={20}>
                                        <Xtable dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: 768 }} />
                                    </Row>
                                    </>
                                }
                               
                            </TabPane>}
                            
                        </Tabs >

                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveInstituteState: state.saveInstitute,
    getInstituteDetailState: state.getInstituteDetail,
    getDoctorListState: state.getDoctorList,
    getPincodeListState: state.getPincodeList,
    getLedgerAccountListState: state.getLedgerAccountList,
    getCommonMasterListState: state.getCommonMasterList,
    getEmployeeDropdownState: state.getEmployeeDropdown,
    getMouListState: state.getMouList,
    saveMouState: state.saveMou,
    deleteMouState: state.deleteMou

});

const mapDispatchToProps = (dispatch) => ({
    saveInstitute: (params) => dispatch(saveInstitute(params)),
    saveInstituteReset: () => dispatch(saveInstituteReset()),
    getInstituteDetail: (params) => dispatch(getInstituteDetail(params)),
    getInstituteDetailReset: () => dispatch(getInstituteDetailReset()),
    getDoctorList: (params) => dispatch(getDoctorList(params)),
    getDoctorListReset: () => dispatch(getDoctorListReset()),
    getPincodeList: (params) => dispatch(getPincodeList(params)),
    getLedgerAccountList: (params) => dispatch(getLedgerAccountList(params)),
    getPincodeListReset: () => dispatch(getPincodeListReset()),
    getCommonMasterList: (params) => dispatch(getCommonMasterList(params)),
    getCommonMasterListReset: () => dispatch(getCommonMasterListReset()),
    getEmployeeDropdown: (params) => dispatch(getEmployeeDropdown(params)),
    getEmployeeDropdownReset: () => dispatch(getEmployeeDropdownReset()),
    getMouList: (params) => dispatch(getMouList(params)),
    getMouList: (params) => dispatch(getMouList(params)),
    saveMou: (params) => dispatch(saveMou(params)),
    saveMouReset: () => dispatch(saveMouReset()),
    deleteMou: (params) => dispatch(deleteMou(params)),
    deleteMouReset: () => dispatch(deleteMouReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstituteMasterAdd);