import config from '../config'
import axios from 'axios'

export const loginApi = async (params) => {

    const response = await axios({
        method: 'post',
        url: `${config.api.base_url}user/login`,
        data: {
            "email": params.email,
            "password": params.password,
        },
        headers: {
            "Content-type": "application/json; charset=UTF-8",
        }
    })

    return response
}

export default loginApi