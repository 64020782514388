import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_MODULE_LIST_BY_ROLE,
    getModuleListByRoleFail,
    getModuleListByRoleSuccess
} from '../action/getModuleListByRoleAction'

// api 
import getModuleListByRoleApi from '../api/getModuleListByRoleApi'

export function* getModuleListByRoleSaga(action) {
    try {
        const response = yield call(() => getModuleListByRoleApi(action.params))
        if (response.data.status === 1) {
            yield put(getModuleListByRoleSuccess(response, action))
        } else {
            yield put(getModuleListByRoleFail(response, action))
        }
    }
    catch (e) {
        yield put(getModuleListByRoleFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_MODULE_LIST_BY_ROLE, getModuleListByRoleSaga)
}