import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_TEST_BOOK_LIST,
    getTestBookListFail,
    getTestBookListSuccess
} from '../action/getTestBookListAction'

// api 
import getTestBookListApi from '../api/getTestBookListApi'

export function* getTestBookListSaga(action) {
    try {
        const response = yield call(() => getTestBookListApi(action.params))
        if (response.data.status === 1) {
            yield put(getTestBookListSuccess(response, action))
        } else {
            yield put(getTestBookListFail(response, action))
        }
    }
    catch (e) {
        yield put(getTestBookListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_TEST_BOOK_LIST, getTestBookListSaga)
}