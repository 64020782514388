import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_INSTITUTE_TEST_PRICING_DETAIL,
    getInstituteTestPricingDetailFail,
    getInstituteTestPricingDetailSuccess
} from '../action/getInstituteTestPricingDetailAction'

// api 
import getInstituteTestPricingDetailApi from '../api/getInstituteTestPricingDetailApi'

export function* getInstituteTestPricingDetailSaga(action) {
    try {
        const response = yield call(() => getInstituteTestPricingDetailApi(action.params))
        if (response) {
            yield put(getInstituteTestPricingDetailSuccess(response, action))
        }
        else {
            yield put(getInstituteTestPricingDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getInstituteTestPricingDetailFail(e.response, action))
    }

}
export default function* mySaga() {
    yield takeLatest(GET_INSTITUTE_TEST_PRICING_DETAIL, getInstituteTestPricingDetailSaga)
}