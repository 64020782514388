import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_MODULE,
    deleteModuleFail,
    deleteModuleSuccess
} from '../action/deleteModuleAction'

// api 
import deleteModuleApi from '../api/deleteModuleApi'

export function* deleteModuleSaga(action) {
    try {
        const response = yield call(() => deleteModuleApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteModuleSuccess(response, action))
        } else {
            yield put(deleteModuleFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteModuleFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_MODULE, deleteModuleSaga)
}