import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Menu, Dropdown } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"

/* actions */
import { getSampleContainerTestMapList, getSampleContainerTestMapListReset } from "../../../action/getSampleContainerTestMapListAction"
import { deleteSampleContainerTestMap, deleteSampleContainerTestMapReset } from "../../../action/deleteSampleContainerTestMapAction"
import SampleContainerTestMapView from "../SampleContainerTestMapView/SampleContainerTestMapView";

const SampleContainerTestMapList = props => {
    /* variables */
    const {
        getSampleContainerTestMapList, getSampleContainerTestMapListReset, getSampleContainerTestMapListState,
        deleteSampleContainerTestMap, deleteSampleContainerTestMapReset, deleteSampleContainerTestMapState
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
    const columns = [
        {
            title: 'Sample',
            dataIndex: 'sample',
            key: 'sample',
            ...getColumnSearchProps('sample', 'Sample')
        },
        {
            title: 'Test Group',
            dataIndex: 'test_group',
            key: 'test_group',
            render: test_group => (
                <>
                    <Space>
                        <div>
                            {test_group.length > 0 && test_group.map(item => (
                                <XtagTable>{item.value}</XtagTable>
                            ))}
                        </div>
                    </Space>
                </>
            ),
            ...getColumnSearchProps('test_group', 'Test Group')
        },
        {
            title: 'Container',
            dataIndex: 'container',
            key: 'container',
            ...getColumnSearchProps('container', 'Container')
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/sample-container-test-map-master/edit/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteSampleContainerTestMap({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    /* callbacks */
    useEffect(() => {
        return (() => {
            getSampleContainerTestMapListReset()
        })
    }, [])
    useEffect(() => {
        getSampleContainerTestMapList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getSampleContainerTestMapListState.apiState === "success") {
            let tableData = []
            getSampleContainerTestMapListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        sample: row.sample?.sample_type,
                        test_group: row.test_group,
                        container: row.container?.display_container_name,
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getSampleContainerTestMapListState])

    useEffect(() => {
        setRedirect([true, `/sample-container-test-map-master/list?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteSampleContainerTestMapState.apiState === "success") {
            deleteSampleContainerTestMapReset()
            notification["success"]({
                message: deleteSampleContainerTestMapState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteSampleContainerTestMapState.apiState === "error") {
            deleteSampleContainerTestMapReset()
            notification["error"]({
                message: deleteSampleContainerTestMapState.message,
                placement: "topRight"
            })
        }
    }, [deleteSampleContainerTestMapState])
    // Functions
    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/common-master/test-group">
                    Test Group
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Sample Container Test Map List"
                rightChildren={
                    <>
                        <Dropdown overlay={menu} placement="bottomCenter" arrow>
                            <Button>Common Master</Button>
                        </Dropdown>
                        <Link to="/sample-container-test-map-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Upload</Button></Link>
                        <Link to="/sample-container-test-map-master/add" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                {getSampleContainerTestMapListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getSampleContainerTestMapListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getSampleContainerTestMapListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getSampleContainerTestMapListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getSampleContainerTestMapListState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <SampleContainerTestMapView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getSampleContainerTestMapListState: state.getSampleContainerTestMapList,
    deleteSampleContainerTestMapState: state.deleteSampleContainerTestMap
});

const mapDispatchToProps = (dispatch) => ({
    getSampleContainerTestMapList: (params) => dispatch(getSampleContainerTestMapList(params)),
    getSampleContainerTestMapListReset: () => dispatch(getSampleContainerTestMapListReset()),
    deleteSampleContainerTestMap: (params) => dispatch(deleteSampleContainerTestMap(params)),
    deleteSampleContainerTestMapReset: () => dispatch(deleteSampleContainerTestMapReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(SampleContainerTestMapList);