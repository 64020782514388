import React, { useEffect, useState } from "react"
import { Button, Skeleton, Space, Tree } from "antd"
import { connect } from "react-redux"
import { PlusCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { flattenDeep } from "lodash"

// component
import Header from "../../../component/Header/Header"
import { PageContainer } from "../../../component/Xcomponent"
// actions
import { getQuickSupportCategoryList, getQuickSupportCategoryListReset } from '../../../action/getQuickSupportCategoryListAction'

const QuickSupportCategoryList = props => {

    // variables
    const { getQuickSupportCategoryList, getQuickSupportCategoryListState, getQuickSupportCategoryListReset } = props
    const [redirect, setRedirect] = useState([false, ""])
    const [expand, setExpand] = useState({ expandedKeys: [] })
    var treeKeys = ''
    const location = useLocation()

    // callbacks
    useEffect(() => {
        getQuickSupportCategoryList()
        return (() => {
            getQuickSupportCategoryListReset()
        })
    }, [])

    // functions
    const getTreeData = (tree) => {
        let finalArray = []
        tree.map(node => {
            if (node.children.length > 0) {
                finalArray.push(
                    {
                        title: node.name,
                        key: node._id,
                        children: getTreeData(node.children)
                    }
                )
            } else {
                finalArray.push(
                    {
                        title: node.name,
                        key: node._id,
                    }
                )
            }

        })
        treeKeys = getAllKeys(finalArray)
        return finalArray
    }

    const onSelect = (selectedKeys, info) => {
        if (location.pathname !== "/quick-support-question-answer") {
            setRedirect([true, `/quick-support-category/save/${selectedKeys[0]}`])
        }
        else {

            setRedirect([true, `/quick-support-question-answer/list/${selectedKeys[0]}`])
        }
    }


    const getAllKeys = data => {
        const nestedKeys = data.map(node => {
            let childKeys = []
            if (node.children) {
                childKeys = getAllKeys(node.children)
            }
            return [childKeys, node.key]
        })
        return flattenDeep(nestedKeys)
    }

    const onExpand = expandedKeys => {
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setExpand({
            expandedKeys
        })
    }

    const expandAll = () => {
        setExpand({
            expandedKeys: treeKeys
        })
    }

    const collapseAll = () => {
        setExpand({
            expandedKeys: []
        })
    }



    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title={location.pathname !== "/quick-support-question-answer" ? `Quick Support Category List` : `Quick Support Category List of Question Answer`}
                rightChildren={
                    <>
                        {location.pathname !== "/quick-support-question-answer" &&
                            <Link to="/quick-support-category/save" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                        }
                    </>
                }
            />
            <PageContainer  >
                {getQuickSupportCategoryListState.apiState === "loading" &&
                    <Skeleton active />}

                {getQuickSupportCategoryListState.apiState === "error" &&
                    <h1>Something is not right</h1>}

                {getQuickSupportCategoryListState.apiState === "success" &&
                    <>
                        <Space style={{ marginBottom: 32 }} >
                            <Button type="ghost" size="small" icon={<PlusCircleOutlined />} onClick={expandAll}>Expand all</Button>
                            <Button type="ghost" size="small" icon={<MinusCircleOutlined />} onClick={collapseAll}>Collapse all</Button>
                        </Space>
                        <div style={{ border: "solid 1px #ccc", padding: 10 }} >
                            <Tree
                                treeData={getTreeData(getQuickSupportCategoryListState.list)}
                                onSelect={onSelect}
                                showLine={true}
                                showIcon={false}
                                expandedKeys={expand.expandedKeys}
                                onExpand={onExpand}
                            />
                        </div>
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getQuickSupportCategoryListState: state.getQuickSupportCategoryList,
})

const mapDispatchToProps = (dispatch) => ({
    getQuickSupportCategoryList: () => dispatch(getQuickSupportCategoryList()),
    getQuickSupportCategoryListReset: () => dispatch(getQuickSupportCategoryListReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickSupportCategoryList)