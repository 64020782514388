import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PAYMENT_HISTORY_COUPON,
    getPaymentHistoryCouponFail,
    getPaymentHistoryCouponSuccess
} from '../action/getPaymentHistoryCouponAction'

// api 
import getPaymentHistoryCouponApi from '../api/getPaymentHistoryCouponApi'

export function* getPaymentHistoryCouponSaga(action) {
    try {
        const response = yield call(() => getPaymentHistoryCouponApi(action.params))
        if (response.data.status === 1) {
            yield put(getPaymentHistoryCouponSuccess(response, action))
        } else {
            yield put(getPaymentHistoryCouponFail(response, action))
        }
    }
    catch (e) {
        yield put(getPaymentHistoryCouponFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PAYMENT_HISTORY_COUPON, getPaymentHistoryCouponSaga)
}