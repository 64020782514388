import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination } from "../../../component/Xcomponent"
import InstituteTestPricingMasterList from '../InstituteTestPricingMasterList/InstituteTestPricingMasterList'

/* actions */
import { getInstituteTestPricingMasterInstitute, getInstituteTestPricingMasterInstituteReset } from "../../../action/getInstituteTestPricingMasterInstituteAction" 



const InstituteTestPricingMasterInstituteList = props => {
    /* variables */
    const {
        getInstituteTestPricingMasterInstitute, getInstituteTestPricingMasterInstituteReset, 
        getInstituteTestPricingMasterInstituteState,
       // deleteInstituteTestPricingMaster, deleteInstituteTestPricingMasterReset, deleteInstituteTestPricingMasterState
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    const columns = [
        {
            title: 'Lilac ID',
            dataIndex: 'lilac_id',
            key: 'lilac_id',
            width: 150,
            ...getColumnSearchProps('lilac_id', 'Lilac ID'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
            ...getColumnSearchProps('name', 'Name')
        },
        {
            title: 'Marketing Location',
            dataIndex: 'hq_or_primary_marketing_location',
            key: 'hq_or_primary_marketing_location',
            width: 300,
            ...getColumnSearchProps('hq_or_primary_marketing_location', 'Marketing Location')
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: 300,
            ...getColumnSearchProps('city_state', 'City')
        },


        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    
                    <Link to={`/institute-test-pricing-master/list?id=${id}&page=1`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    
                </Space>
            )
        },
    ];

    /* callbacks */
    useEffect(() => {
        return (() => {
            getInstituteTestPricingMasterInstitute()
        })
    }, [])
   
    useEffect(() => {
        getInstituteTestPricingMasterInstitute({
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])
    
    useEffect(() => {
        if (getInstituteTestPricingMasterInstituteState.apiState === "success") {
            let tableData = []
            console.log("getInstituteTestPricingMasterInstituteState",getInstituteTestPricingMasterInstituteState)
            getInstituteTestPricingMasterInstituteState.list.map((row, index) => {
                tableData.push(
                    {
                        id: row._id,
                        key: index,
                        lilac_id: row.lilac_id,
                        name: row.name?row.name:"",
                        hq_or_primary_marketing_location: row.hq_or_primary_marketing_location?row.hq_or_primary_marketing_location:"",
                        city: `${row.city}, ${row.state}`,
                        is_active:row.is_active
                    }
                )
            })
            setTableData(tableData)

        }
    }, [getInstituteTestPricingMasterInstituteState])

    useEffect(() => {
        setRedirect([true, `/institute-test-pricing-master-institute/list?page=${pagination.page}`])
    }, [pagination])

    // useEffect(() => {
    //     if (deleteInstituteTestPricingMasterState.apiState === "success") {
    //         deleteInstituteTestPricingMasterReset()
    //         notification["success"]({
    //             message: deleteInstituteTestPricingMasterState.message,
    //             placement: "topRight"
    //         })
    //         setRefresh(refresh + 1)
    //     }
    //     if (deleteInstituteTestPricingMasterState.apiState === "error") {
    //         deleteInstituteTestPricingMasterReset()
    //         notification["error"]({
    //             message: deleteInstituteTestPricingMasterState.message,
    //             placement: "topRight"
    //         })
    //     }
    // }, [deleteInstituteTestPricingMasterState])


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Institute Test Pricing Master List"
                rightChildren={
                    <>
                        <Link to="/institute-test-pricing-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Upload</Button></Link>
                        <Link to="/institute-test-pricing-master/add" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                {getInstituteTestPricingMasterInstituteState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getInstituteTestPricingMasterInstituteState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getInstituteTestPricingMasterInstituteState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getInstituteTestPricingMasterInstituteState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getInstituteTestPricingMasterInstituteState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        {/* <InstituteTestPricingMasterView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} /> */}
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getInstituteTestPricingMasterInstituteState: state.getInstituteTestPricingMasterInstitute,
    deleteInstituteTestPricingMasterState: state.deleteInstituteTestPricingMaster
});

const mapDispatchToProps = (dispatch) => ({
    getInstituteTestPricingMasterInstitute: (params) => dispatch(getInstituteTestPricingMasterInstitute(params)),
    getInstituteTestPricingMasterInstituteReset: () => dispatch(getInstituteTestPricingMasterInstituteReset()),
    // deleteInstituteTestPricingMaster: (params) => dispatch(deleteInstituteTestPricingMaster(params)),
    // deleteInstituteTestPricingMasterReset: () => dispatch(deleteInstituteTestPricingMasterReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(InstituteTestPricingMasterInstituteList);