import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getSampleDetail, getSampleDetailReset } from "../../../action/getSampleDetailAction"
import { dateFormat } from '../../../utils';

const SampleView = props => {
    /* variable */
    const { getSampleDetail, getSampleDetailReset, getSampleDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getSampleDetailReset()
        }
    }, [props.visible])

    useEffect(() => {
        if (props.id && props.visible) {
            getSampleDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`Simple Master View ${getSampleDetailState.apiState === "success" ? "- " + getSampleDetailState.data.lims_id : ""}`}
            placement="right"
            closeIcon={<><Link to={`/sample-master/edit/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getSampleDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getSampleDetailState.apiState === "error" &&
                <p>{getSampleDetailState.message}</p>
            }

            {getSampleDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>LIMS Sample ID</ViewLabel>
                                <ViewValue><span>{getSampleDetailState.data.lims_id}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sample Type</ViewLabel>
                                <ViewValue>{getSampleDetailState.data.sample_type}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sub Sample Type</ViewLabel>
                                <ViewValue>{getSampleDetailState.data.sub_sample_type.length > 0 &&
                                    getSampleDetailState.data.sub_sample_type.map(obj => obj).join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sample Transport Condition</ViewLabel>
                                <ViewValue>{getSampleDetailState.data.sample_transport_condition}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pcpndp Applicable</ViewLabel>
                                <ViewValue>{getSampleDetailState.data.pcpndp_applicable}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Home Collection Possible</ViewLabel>
                                <ViewValue>{getSampleDetailState.data.home_collection_possible}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getSampleDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getSampleDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>

                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getSampleDetailState: state.getSampleDetail
});

const mapDispatchToProps = (dispatch) => ({
    getSampleDetail: (params) => dispatch(getSampleDetail(params)),
    getSampleDetailReset: () => dispatch(getSampleDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(SampleView);