import React from "react"
/* custom component */
import Header from "../../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../../component/Xcomponent"
import ContentRepoAdd from "../../../../component/ContentRepo/ContentRepoAdd/ContentRepoAdd";

export const ContentRepoGeneralAdd = props => {
    /* variables */
    const article_id = props.match.params.article_id
    const mode = article_id ? "edit" : "add"

    return (
        <>

            <Header
                backButton={"/content-repo-general/list"}
                title={mode === "edit" ? "Edit Content Repo General" : "Add Content Repo General"}
            />
            <PageContainer>
                <LayoutContainer>
                    <ContentRepoAdd pageUrl={`content-repo-general`} pageType="General" article_id={article_id} />
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

export default ContentRepoGeneralAdd;