import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_QUICK_SUPPORT_CATEGORY,
    deleteQuickSupportCategoryFail,
    deleteQuickSupportCategorySuccess
} from '../action/deleteQuickSupportCategoryAction'

// api 
import deleteQuickSupportCategoryApi from '../api/deleteQuickSupportCategoryApi'

export function* deleteQuickSupportCategorySaga(action) {
    try {
        const response = yield call(() => deleteQuickSupportCategoryApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteQuickSupportCategorySuccess(response, action))
        } else {
            yield put(deleteQuickSupportCategoryFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteQuickSupportCategoryFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_QUICK_SUPPORT_CATEGORY, deleteQuickSupportCategorySaga)
}