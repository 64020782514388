import { Select, Affix, Button } from "antd";
import React from "react";
import { useState } from "react";
import Header from "../Header/Header";
import { LayoutContainer, PageContainer } from "../Xcomponent";
import NotificationSendTo from "./NotificationSendTo";

const NotificationDetail = (props) => {
  console.log("props", props);
  const [selected, setSelected] = useState([]);
  const [sendToTypes, setSendToTypes] = useState([]);
  return (
    <>
      <LayoutContainer>
     
        <h2>From {" " + props.app_name}</h2>

        <Select
          name="module"
          placeholder="Select Recipient App"
          showSearch
          allowClear
          onChange={(v) => {
            console.log(v)
            const temp = [];
            v.map((data) => {
              temp.push(
                props.send_to_list.find((type) => type.send_to == data)
              );
              props.send_to_list.map((type)=>{
                if(type.send_to == data){
                  type.isSelected = true
                }
              })
            });
            setSelected(v);
            setSendToTypes(temp);
          }}
          value={selected}
          mode="multiple"
        >
          {props.send_to_list.map((row, index) => {
            return (
              <Select.Option value={row.send_to} key={`${index}`}>
                {row.send_to}
              </Select.Option>
            );
          })}
        </Select>
        {sendToTypes.map((value) => (
          <NotificationSendTo
            {...value}
            id={props.id}
            app_name={props.app_name}
            sendToTypes={sendToTypes}
          />
        ))}
      </LayoutContainer>
    </>
  );
};

export default NotificationDetail;
