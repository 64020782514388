import React, { useEffect, useState } from "react"
import { UploadOutlined, PlusOutlined } from '@ant-design/icons'
import { Link, useLocation, Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { Input, Button, Popconfirm, notification, Space, Menu, Dropdown } from 'antd'
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xpagination, Xtable, LayoutContainer } from "../../../component/Xcomponent"
// actions
import { getTestBookList, getTestBookListReset } from '../../../action/getTestBookListAction'

/* OTHERS */
import { checkRights, dateFormat } from '../../../utils'

const TestBookList = props => {
    // variables
    const {
        getTestBookList, getTestBookListReset, getTestBookListState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [columns, setColumns] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [redirect, setRedirect] = useState(["", false])
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 20,
    })


    // Functions
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }


    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });

    // callbacks
    useEffect(() => {
        return (() => {
            getTestBookListReset()
        })
    }, [])

    useEffect(() => {
        getTestBookList({
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/patient-test-book/list?page=${pagination.page}`])

    }, [pagination, refresh, searchText])

    useEffect(() => {
        if (getTestBookListState.apiState === "success") {
            setColumns([
                {
                    title: "Test ID",
                    dataIndex: "lilac_test_id",
                    render: lilac_test_id => (
                        <span>{lilac_test_id}</span>
                    ),
                    ...getColumnSearchProps('lilac_test_id', 'Lilac ID')

                },
                {
                    title: "Test Name",
                    dataIndex: "display_test_name",
                    render: item => (
                        <span>{item.display_test_name}</span>
                    ),
                    ...getColumnSearchProps('display_test_name', 'Name')

                },
                {
                    title: "Patient Name",
                    dataIndex: "patient_name",
                    render: item => (
                        <span>{item.patient_name}</span>
                    ),
                    ...getColumnSearchProps('patient_name', 'Patient Name')

                },
            ])
            let rows = []
            getTestBookListState.list.map((result) => {
                rows.push({
                    lilac_test_id: result.test_id.lilac_test_id,
                    display_test_name: { display_test_name: result.test_id.display_test_name },
                    patient_name: { patient_name: `${result.patient_id.name.firstName} ${result.patient_id.name.lastName} | Gender: ${result.patient_id.gender} | DOB: ${dateFormat(result.patient_id.dob)} | Phone: ${result.patient_id.phone}` },
                })
            })
            setDataSource(rows)
        }
    }, [getTestBookListState])


    // Functions
    return (
        <>
            {
                getTestBookListState.apiState !== "error" &&
                <>
                    {redirect[0] &&
                        <Redirect to={redirect[1]} />}
                    <Header
                        title="Patient Test Book List"
                    />
                    <PageContainer>
                        <LayoutContainer>

                            <Xtable dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: 768 }}
                                loading={getTestBookListState.apiState === "loading"} />
                            <Xpagination
                                pageSizeOptions={['10', '15', '25', '50']}
                                showSizeChanger
                                onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                                current={pagination.page}
                                defaultPageSize={pagination?.records}
                                total={getTestBookListState.pagination.total_records}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            />
                        </LayoutContainer>
                    </PageContainer>
                </>
            }
            <>
                {
                    getTestBookListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getTestBookListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

            </>
        </>
    )
}
const mapStateToProps = (state) => ({
    getTestBookListState: state.getTestBookList,
})

const mapDispatchToProps = (dispatch) => ({
    getTestBookList: (params) => dispatch(getTestBookList(params)),
    getTestBookListReset: () => dispatch(getTestBookListReset()),
})




export default connect(mapStateToProps, mapDispatchToProps)(TestBookList)