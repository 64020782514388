import { Pagination, Table, Button, Skeleton, Layout, Col, Tag } from "antd";
import styled, { css, keyframes } from 'styled-components'


export const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #f5f5f5;
`

export const PageContainer = styled.div`
    background: #f5f5f5;
    padding: 20px 40px;
    min-height: calc(100vh - 50px);

    ${({ list }) => list && `
        background: #fff;
        padding: 0;
    `}
`

export const Xtable = styled(Table)`
    .ant-table-thead > tr > th {
        font-family: Helvetica;
        font-weight: bold;
        color: rgb(0 0 0 / 1);
        background-color: #ffffff;
        font-size: 13px;
        padding: 0.5rem 1rem;
        white-space: nowrap;
        &.hide{
            display: none;
        }
    }

    .ant-table-thead .ant-table-column-sorters {
        padding: 0;
    }

    .ant-table-tbody > tr > td {
        font-family: Helvetica;
        font-weight: normal;
        color: rgb(0 0 0 / 0.75);
        background-color: #ffffff;
        font-size: 13px;
        padding: 0.3rem 1rem;
         &.hide{
            display: none;
        }
    }
    
    .ant-table-tbody > tr:nth-child(odd) > td {
        background: #f5f5f5;
    }

    #actionFreeze .ant-table-tbody > tr:nth-child(odd) > td:last-child {
        background: #f5f5f5;
    }

`

export const Xpagination = styled(Pagination)`
    display: flex;
    justify-content: center;
    margin:1rem 0;
`

export const Xskeleton = styled(Skeleton.Button)`

    margin-bottom: ${props => props.mb ? props.mb : 0};
    margin-right: ${props => props.mr ? props.mr : 0};

    ${props => props.type === "box" && css`
        width: 100%;
        height: 100%;

        .ant-skeleton-button {
            width: ${props => props.w ? props.w : "100%"};
            height: ${props => props.h ? props.h : "100%"};
            border-radius: ${props => props.br ? props.br : "0"};
        }
    `}

    ${props => props.type === "text" && css`
        width: ${props => props.w ? props.w : "100%"};
        height: ${props => props.h ? props.h : "100%"};

        .ant-skeleton-button {
            width: 100%;
            height: 100%;
        }
    `}
`
export const LayoutContainer = styled(Layout)`
    padding: 10px 24px 24px;
    background: #fff;
`

export const ViewBox = styled.div`
    height: 100%;
    border-bottom: solid 1px #eaeaec;
    padding: 10px 0;
`

export const ViewLabel = styled.p`
    margin: 0;
    font-size: 12px;
    color: #7e818c;
`

export const ViewValue = styled.p`
    margin: 0;
    font-size: 14px;
    color: #000;
`
export const XtagTable = styled(Tag)`
&.ant-tag{
    margin-top: 4px;
    margin-bottom: 4px;
}
`
export const Heading = styled.div`
    font-size: 17px;
    font-weight: 600;
    width: 100%;
    padding-left: 10px;
    margin-top: 20px;
`