import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, Switch} from "antd"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveSample, saveSampleReset } from "../../../action/saveSampleAction"
import { getSampleDetail, getSampleDetailReset } from "../../../action/getSampleDetailAction"

/* otehrs */
import { container_types, container_cap_colors } from "../../../strings.json"

export const SampleAdd = props => {
    /* variables */
    const sample_id = props.match.params.sample_id
    const mode = sample_id ? "edit" : "add"
    const {
        saveSample, saveSampleReset, saveSampleState,
        getSampleDetail, getSampleDetailReset, getSampleDetailState,
        getCommonMasterList, getCommonMasterListReset, getCommonMasterListState,
    } = props
    const [formData, setFormData] = useState({
        id: "",
        lims_id: "",
        sample_type: "",
        sub_sample_type: [],
        pcpndp_applicable: "",
        home_collection_possible: "",
        sample_transport_condition: "",
    })
    if (mode === "edit") {
        formData.id = sample_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    /* callbacks */
    useEffect(() => {
        return (() => {
            saveSampleReset()
            getSampleDetailReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getSampleDetail({
                id: sample_id
            })
        }
    }, [])

    useEffect(() => {
        if (getSampleDetailState.apiState === "success") {
            form.setFieldsValue({
                lims_id: getSampleDetailState.data.lims_id,
                sample_type: getSampleDetailState.data.sample_type,
                sub_sample_type: getSampleDetailState.data.sub_sample_type,
                pcpndp_applicable: getSampleDetailState.data.pcpndp_applicable,
                home_collection_possible: getSampleDetailState.data.home_collection_possible,
                sample_transport_condition: getSampleDetailState.data.sample_transport_condition,
            })
            setFormData({
                ...formData,
                ['lims_id']: getSampleDetailState.data.lims_id,
                ['sample_type']: getSampleDetailState.data.sample_type,
                ['sub_sample_type']: getSampleDetailState.data.sub_sample_type,
                ['pcpndp_applicable']: getSampleDetailState.data.pcpndp_applicable,
                ['home_collection_possible']: getSampleDetailState.data.home_collection_possible,
                ['sample_transport_condition']: getSampleDetailState.data.sample_transport_condition,
            })
        }
    }, [getSampleDetailState])

    useEffect(() => {
        if (saveSampleState.apiState === "success") {
            saveSampleReset()
            setRedirect([true, "/sample-master/list"])
            notification["success"]({
                message: saveSampleState.message,
                placement: "topRight"
            })
        }
        if (saveSampleState.apiState === "error") {
            saveSampleReset()
            notification["error"]({
                message: saveSampleState.message,
                placement: "topRight"
            })
        }
    }, [saveSampleState])

    /* functions */
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }

    const handleSwitchChange = (name, checked) => {
        setFormData({ ...formData, [name]: checked })
    }

    const handleSubmit = () => {
        saveSample(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/sample-master/list"}
                title={mode === "edit" ? "Edit Sample Master" : "Add Sample Master"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="LIMS Sample ID"
                                    name="lims_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Required"
                                        },
                                        () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please input alphabet or number!')); }, })
                                    ]}
                                >
                                    <Input name="lims_id" placeholder="Enter LIMS SAMPLE ID"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Sample Type"
                                    name="sample_type"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Input name="sample_type" placeholder="Enter Sample Type"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Sub Sample Type"
                                    name="sub_sample_type"
                                    // rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        name="sub_sample_type"
                                        placeholder="Select Sub Sample Type"
                                        showSearch
                                        allowClear
                                        mode="tags"
                                        onChange={(v) => handleSelect("sub_sample_type", v)}
                                    >
                                        {/* <Select.Option value={`${formData.sub_sample_type}`}>{formData.sub_sample_type}</Select.Option> */}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Sample Transport Condition"
                                    name="sample_transport_condition"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input name="sample_transport_condition" placeholder="Enter Sample Transport Condition"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="PCPNDT Applicable"
                                    name="pcpndp_applicable"
                                >
                                    <Switch onChange={checked => handleSwitchChange("pcpndp_applicable", checked ? "Yes" : "No")}
                                        checked={formData.pcpndp_applicable === "Yes"}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Home Collection Possible"
                                    name="home_collection_possible"
                                >
                                    <Switch onChange={checked => handleSwitchChange("home_collection_possible", checked ? "Yes" : "No")}
                                        checked={formData.home_collection_possible === "Yes"}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        {
                            saveSampleState.apiState=="loading" ? <Button type="primary" loading>Adding</Button> 
                            : <Button type="primary" htmlType="submit">SUBMIT</Button>
                        }
                        

                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveSampleState: state.saveSample,
    getSampleDetailState: state.getSampleDetail,
});

const mapDispatchToProps = (dispatch) => ({
    saveSample: (params) => dispatch(saveSample(params)),
    saveSampleReset: () => dispatch(saveSampleReset()),
    getSampleDetail: (params) => dispatch(getSampleDetail(params)),
    getSampleDetailReset: () => dispatch(getSampleDetailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SampleAdd);