import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, DatePicker, TimePicker, Tabs, Switch, InputNumber, Space, Dropdown, Menu } from "antd"
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import moment from 'moment'
import _ from "lodash"
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { getDoctorDetail, getDoctorDetailReset } from "../../../action/getDoctorDetailAction"
import { saveDoctor, saveDoctorReset } from "../../../action/saveDoctorAction"
import { getInstituteList, getInstituteListReset } from "../../../action/getInstituteListAction"
import { getCommonMasterList, getCommonMasterListReset } from "../../../action/getCommonMasterListAction"
import { getPincodeList, getPincodeListReset } from "../../../action/getPincodeListAction"
import { activateDoctor, activateDoctorReset } from "../../../action/activateDoctorAction"

/* otehrs */
import { s3_cdn, validateImageFile, getS3SingedUrl,capitalizeFirst } from '../../../utils'
import { DownOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;



const DoctorMasterSave = props => {
    /* variables */
    const doctor_id = props.match.params.doctor_id
    const mode = doctor_id ? "edit" : "add"
    const {
        getDoctorDetail, getDoctorDetailReset, getDoctorDetailState,
        saveDoctor, saveDoctorReset, saveDoctorState,
        getInstituteList, getInstituteListReset, getInstituteListState,
        getCommonMasterList, getCommonMasterListReset, getCommonMasterListState,
        getPincodeList, getPincodeListReset, getPincodeListState,
        activateDoctor, activateDoctorReset, activateDoctorState,

    } = props
    const [imageFile, setImageFile] = useState({
        fileName: null,
        isChanged: false,
        edit: true
    })
    const [formData, setFormData] = useState({
        pronoun: "",
        salutation: {},
        fname: "",
        lname: "",
        dob: "",
        gender: "",
        email: "",
        mobile_number: "",
        whatsapp_number: "",
        landline_number: "",
        pincode: "",
        city: "",
        district: "",
        state: "",
        home_address: "",
        adhaar_number: "",
        is_dtrf_activated: "No",
        lims_id: "",
        status: "",
        hospital: [],
        speciality: [],
        sonographer: "",
        referring_doctor: "Yes",
        nt_status_for_reporting: "",
        fmf_code: "",
        fmf_certified_for: [],
        fmf_license_validity_date_from: "",
        fmf_license_validity_date_to: "",
        pndt_registration: "",
        pndt_registration_validity_date_from: "",
        pndt_registration_validity_date_to: "",
        hospital_visit_time_from: "",
        hospital_visit_time_to: "",
        mci_registration_number: "",
        pan_card_number: "",
        date_of_anniversary: "",
        preferred_mode_of_contact: "",
        is_dcp_activated: "No",
        type: "",
        qualification: "",
        exprience: "",
        profile_picture: "",
        profile_picture_changed: "",
        description: "",
        tier_1_fee: "",
        tier_2_fee: "",
        area_of_expertise: "",
        core_area: "",
        can_give_medicine: "",
        self_fee: "",
        couple_fee: "",
        family_fee: "",
        medicine_fee: "",
        hidden: "",
        short_description: "",
        facebook_id: "",
        instagram_id: "",
        linkedin_id: "",
        interests: "",
        expert_type: "",
        consultation_type: "",
        concerns: [],
        expert_approaches: [],
        language: [],
    })
    if (mode === "edit") {
        formData.id = doctor_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()
    const [activeTab, setActiveTab] = useState('KYC')
    const [disableds, setdisableds] = useState(false)
    const requiredFiledsTabWise = [
        { tab: "KYC", fields: ['fname', 'lname', 'dob', 'gender', 'email', 'mobile_number', 'pincode'] },
        { tab: "DTRF", fields: ['lims_id', 'status', 'hospital', 'speciality', 'referring_doctor'] },
        {
            tab: "DCP", fields:
                formData.type === "MHC" ? ['expert_type', 'consultation_type', 'concerns', 'expert_approaches']
                    : ['type', 'description', 'tier_1_fee', 'tier_2_fee', 'short_description', 'profile_picture', 'language']
        },
    ]

    /* callbacks */
    useEffect(() => {
        return (() => {
            saveDoctorReset()
            getDoctorDetailReset()
            getCommonMasterListReset()
            getInstituteListReset()
            getPincodeListReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getDoctorDetail({
                id: doctor_id
            })
        }
        getCommonMasterList({
            slug: "practice-type,area-of-expertise,salutation,language,expert-type,consultation-type,concerns,expert-approaches,core-area"
        })
    }, [])
    useEffect(() => {
        if (activateDoctorState.apiState === "success") {
            activateDoctorReset()
            notification["success"]({
                message: activateDoctorState.message,
                placement: "topRight"
            })
        }
        if (activateDoctorState.apiState === "error") {
            activateDoctorReset()
            notification["error"]({
                message: activateDoctorState.message,
                placement: "topRight"
            })
        }
    }, [activateDoctorState])



    useEffect(() => {
        if (getDoctorDetailState.apiState === "success") {
            let sp = getDoctorDetailState.data?.speciality.map(obj => (obj && obj != null) && obj._id)
            
            console.log("speciality", getDoctorDetailState.data.preferred_mode_of_contact);

            form.setFieldsValue({
                pronoun: getDoctorDetailState.data.pronoun,
                salutation: getDoctorDetailState.data.salutation && getDoctorDetailState.data.salutation["_id"],
                fname: getDoctorDetailState.data.fname,
                mname: getDoctorDetailState.data.mname,
                lname: getDoctorDetailState.data.lname,
                dob: getDoctorDetailState.data.dob?moment(getDoctorDetailState.data.dob):null,
                gender: getDoctorDetailState.data.gender,
                email: getDoctorDetailState.data.email,
                mobile_number: getDoctorDetailState.data.mobile_number,
                whatsapp_number: getDoctorDetailState.data.whatsapp_number,
                landline_number: getDoctorDetailState.data.landline_number,
                pincode: getDoctorDetailState.data.pincode,
                city: getDoctorDetailState.data.city,
                district: getDoctorDetailState.data.district,
                state: getDoctorDetailState.data.state,
                home_address: getDoctorDetailState.data.home_address,
                adhaar_number: getDoctorDetailState.data.adhaar_number,
                is_dtrf_activated: getDoctorDetailState.data.is_dtrf_activated,
                lims_id: getDoctorDetailState.data.lims_id,
                status: getDoctorDetailState.data.status,
                hospital:getDoctorDetailState.data.hospital&& getDoctorDetailState.data.hospital.map(obj => obj && obj._id),
                speciality: getDoctorDetailState.data?.speciality.map(obj => (obj && obj != null) && obj._id).filter(x=> x !=null),
                sonographer: getDoctorDetailState.data.sonographer,
                referring_doctor: "Yes",
                nt_status_for_reporting: getDoctorDetailState.data.nt_status_for_reporting,
                fmf_code: getDoctorDetailState.data.fmf_code,
                fmf_certified_for: getDoctorDetailState.data.fmf_certified_for,
                fmf_license_validity: (getDoctorDetailState.data.fmf_license_validity_date_from && getDoctorDetailState.data.fmf_license_validity_date_to) ?
                    [moment(getDoctorDetailState.data.fmf_license_validity_date_from), moment(getDoctorDetailState.data.fmf_license_validity_date_to)] :
                    [],
                pndt_registration: getDoctorDetailState.data.pndt_registration,
                pndt_registration_validity: (getDoctorDetailState.data.pndt_registration_validity_date_from && getDoctorDetailState.data.pndt_registration_validity_date_to) ?
                    [moment(getDoctorDetailState.data.pndt_registration_validity_date_from), moment(getDoctorDetailState.data.pndt_registration_validity_date_to)] :
                    [],
                hospital_visit: (getDoctorDetailState.data.hospital_visit_time_from && getDoctorDetailState.data.hospital_visit_time_to) ?
                    [moment(getDoctorDetailState.data.hospital_visit_time_from, "HH:mm"), moment(getDoctorDetailState.data.hospital_visit_time_to, "HH:mm")] :
                    [],
                mci_registration_number: getDoctorDetailState.data.mci_registration_number,
                pan_card_number: getDoctorDetailState.data.pan_card_number,
                date_of_anniversary: getDoctorDetailState.data.date_of_anniversary ? moment(getDoctorDetailState.data.date_of_anniversary) : "",
                preferred_mode_of_contact: getDoctorDetailState.data.preferred_mode_of_contact ? getDoctorDetailState.data.preferred_mode_of_contact.split(','):[],
                is_dcp_activated: getDoctorDetailState.data.is_dcp_activated,
                type: getDoctorDetailState.data.type,
                qualification: getDoctorDetailState.data.qualification,
                exprience: getDoctorDetailState.data.exprience ?
                moment().diff(moment(getDoctorDetailState.data.exprience),'years') : "",
                // profile_picture: getDoctorDetailState.data.profile_picture,
                description: getDoctorDetailState.data.description,
                tier_1_fee: getDoctorDetailState.data.tier_1_fee,
                tier_2_fee: getDoctorDetailState.data.tier_2_fee,
                area_of_expertise: getDoctorDetailState.data.area_of_expertise ? getDoctorDetailState.data.area_of_expertise.map(obj =>obj && obj._id) : null,
                core_area: getDoctorDetailState.data.core_area ? getDoctorDetailState.data.core_area._id : null,
                can_give_medicine: getDoctorDetailState.data.can_give_medicine,
                self_fee: getDoctorDetailState.data.self_fee,
                couple_fee: getDoctorDetailState.data.couple_fee,
                family_fee: getDoctorDetailState.data.family_fee,
                medicine_fee: getDoctorDetailState.data.medicine_fee,
                hidden: getDoctorDetailState.data.hidden,
                short_description: getDoctorDetailState.data.short_description,
                facebook_id: getDoctorDetailState.data.facebook_id,
                instagram_id: getDoctorDetailState.data.instagram_id,
                linkedin_id: getDoctorDetailState.data.linkedin_id,
                interests: getDoctorDetailState.data.interests,
                expert_type: getDoctorDetailState.data.expert_type && getDoctorDetailState.data.expert_type._id,
                consultation_type: getDoctorDetailState.data.consultation_type && getDoctorDetailState.data.consultation_type._id,
                concerns:getDoctorDetailState.data.concerns? getDoctorDetailState.data.concerns.map(obj =>obj && obj._id):null,
                expert_approaches: getDoctorDetailState.data.expert_approaches?getDoctorDetailState.data.expert_approaches.map(obj =>obj && obj._id):null,
                language: getDoctorDetailState.data.language?getDoctorDetailState.data.language.map(obj =>obj && obj._id):null,
                sonographer: getDoctorDetailState.data.sonographer,

            })
            setFormData({
                ...formData,
                ['pronoun']: getDoctorDetailState.data.pronoun,
                ['salutation']: getDoctorDetailState.data.salutation && getDoctorDetailState.data.salutation["_id"],
                ['fname']: getDoctorDetailState.data.fname,
                ['lname']: getDoctorDetailState.data.lname,
                ['mname']: getDoctorDetailState.data.mname,
                ['dob']: getDoctorDetailState.data.dob?moment(getDoctorDetailState.data.dob):'',
                ['gender']: getDoctorDetailState.data.gender,
                ['email']: getDoctorDetailState.data.email,
                ['mobile_number']: getDoctorDetailState.data.mobile_number,
                ['whatsapp_number']: getDoctorDetailState.data.whatsapp_number,
                ['landline_number']: getDoctorDetailState.data.landline_number,
                ['pincode']: getDoctorDetailState.data.pincode,
                ['city']: getDoctorDetailState.data.city,
                ['district']: getDoctorDetailState.data.district,
                ['state']: getDoctorDetailState.data.state,
                ['home_address']: getDoctorDetailState.data.home_address,
                ['adhaar_number']: getDoctorDetailState.data.adhaar_number,
                ['is_dtrf_activated']: getDoctorDetailState.data.is_dtrf_activated,
                ['lims_id']: getDoctorDetailState.data.lims_id,
                ['status']: getDoctorDetailState.data.status,
                ['hospital']: getDoctorDetailState.data.hospital?getDoctorDetailState.data.hospital.map(obj => obj && obj._id):null,
                ['speciality']: getDoctorDetailState.data.speciality?getDoctorDetailState.data.speciality.map(obj => obj && obj._id):null,
                ['referring_doctor']:"Yes",
                ['nt_status_for_reporting']: getDoctorDetailState.data.nt_status_for_reporting,
                ['fmf_code']: getDoctorDetailState.data.fmf_code,
                ['fmf_certified_for']: getDoctorDetailState.data.fmf_certified_for,
                ['fmf_license_validity_date_from']: getDoctorDetailState.data.fmf_license_validity_date_from ?
                    moment(getDoctorDetailState.data.fmf_license_validity_date_from) : "",
                ['fmf_license_validity_date_to']: getDoctorDetailState.data.fmf_license_validity_date_to ?
                    moment(getDoctorDetailState.data.fmf_license_validity_date_to) : "",
                ['pndt_registration']: getDoctorDetailState.data.pndt_registration,
                ['pndt_registration_validity_date_from']: getDoctorDetailState.data.pndt_registration_validity_date_from ?
                    moment(getDoctorDetailState.data.pndt_registration_validity_date_from) : "",
                ['pndt_registration_validity_date_to']: getDoctorDetailState.data.pndt_registration_validity_date_to ?
                    moment(getDoctorDetailState.data.pndt_registration_validity_date_to) : "",
                ['hospital_visit_time_from']: getDoctorDetailState.data.hospital_visit_time_from,
                ['hospital_visit_time_to']: getDoctorDetailState.data.hospital_visit_time_to,
                ['mci_registration_number']: getDoctorDetailState.data.mci_registration_number,
                ['pan_card_number']: getDoctorDetailState.data.pan_card_number,
                ['date_of_anniversary']: getDoctorDetailState.data.date_of_anniversary ?
                    moment(getDoctorDetailState.data.date_of_anniversary) : "",
                ['preferred_mode_of_contact']: getDoctorDetailState.data.preferred_mode_of_contact.toString(),
                ['is_dcp_activated']: getDoctorDetailState.data.is_dcp_activated,
                ['type']: getDoctorDetailState.data.type,
                ['qualification']: getDoctorDetailState.data.qualification,
                ['exprience']: getDoctorDetailState.data.exprience ?
                moment().diff(moment(getDoctorDetailState.data.exprience),'years') : "", 
                ['profile_picture']: getDoctorDetailState.data.profile_picture,
                ['profile_picture_changed']: false,
                ['description']: getDoctorDetailState.data.description,
                ['tier_1_fee']: getDoctorDetailState.data.tier_1_fee,
                ['tier_2_fee']: getDoctorDetailState.data.tier_2_fee,
                ['area_of_expertise']: getDoctorDetailState.data.area_of_expertise ? getDoctorDetailState.data.area_of_expertise.map(obj => obj && obj._id) : null,
                ['core_area']: getDoctorDetailState.data.core_area ? getDoctorDetailState.data.core_area._id : null,
                ['can_give_medicine']: getDoctorDetailState.data.can_give_medicine,
                ['self_fee']: getDoctorDetailState.data.self_fee,
                ['couple_fee']: getDoctorDetailState.data.couple_fee,
                ['family_fee']: getDoctorDetailState.data.family_fee,
                ['medicine_fee']: getDoctorDetailState.data.medicine_fee,
                ['hidden']: getDoctorDetailState.data.hidden,
                ['short_description']: getDoctorDetailState.data.short_description,
                ['facebook_id']: getDoctorDetailState.data.facebook_id,
                ['instagram_id']: getDoctorDetailState.data.instagram_id,
                ['linkedin_id']: getDoctorDetailState.data.linkedin_id,
                ['interests']: getDoctorDetailState.data.interests,
                ['expert_type']: getDoctorDetailState.data.expert_type && getDoctorDetailState.data.expert_type._id,
                ['consultation_type']: getDoctorDetailState.data.consultation_type && getDoctorDetailState.data.consultation_type._id,
                ['concerns']: getDoctorDetailState.data.concerns && getDoctorDetailState.data.concerns.map(obj => obj && obj._id),
                ['expert_approaches']: getDoctorDetailState.data.expert_approaches && getDoctorDetailState.data.expert_approaches.map(obj => obj && obj._id),
                ['language']:  getDoctorDetailState.data.language && getDoctorDetailState.data.language.map(obj => obj && obj._id),
                ['sonographer']: getDoctorDetailState.data.sonographer,
            })
            getInstituteListState.list = getDoctorDetailState.data.hospital
        }
    }, [getDoctorDetailState])


    useEffect(() => {
        if (saveDoctorState.apiState === "success") {
            saveDoctorReset()
            if (mode != "edit") {
                setRedirect([true, "/doctor-master/list"])
            }
            notification["success"]({
                message: saveDoctorState.message,
                placement: "topRight"
            })
        }
        if (saveDoctorState.apiState === "error") {
            saveDoctorReset()
            notification["error"]({
                message: saveDoctorState.message,
                placement: "topRight"
            })
        }
    }, [saveDoctorState])

    // useEffect(() => {
    //     if (getPincodeListState.apiState === "success" && getPincodeListState.data !== null) {
    //         setFormData({
    //             ...formData,
    //             ["pincode"]: getPincodeListState.data.pincode,
    //             ["city"]: getPincodeListState.data.taluk_name,
    //             ["district"]: getPincodeListState.data.district_name,
    //             ["state"]: getPincodeListState.data.state_name,
    //         })
    //         form.setFieldsValue({
    //             pincode: getPincodeListState.data.pincode,
    //             city: getPincodeListState.data.taluk_name,
    //             district: getPincodeListState.data.district_name,
    //             state: getPincodeListState.data.state_name,
    //         })
    //     }
    //     else if (getPincodeListState.apiState === "success" && getPincodeListState.data === null) {
    //         setFormData({
    //             ...formData,
    //             ["city"]: "",
    //             ["district"]: "",
    //             ["state"]: "",
    //         })
    //         form.setFieldsValue({
    //             city: "",
    //             district: "",
    //             state: "",
    //         })
    //     }
    // }, [getPincodeListState])

    /* functions */
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleDateChange = (name, date, dateString) => {
        setFormData({ ...formData, [name]: moment(date) })
    }

    const handleDateRangeChange = (from_name, to_name, dateArr) => {
        setFormData({ ...formData, [from_name]: moment(dateArr[0]), [to_name]: moment(dateArr[1]) })
    }

    const handleTimeRangeChange = (from_name, to_name, dateArr) => {
        setFormData({ ...formData, [from_name]: moment(dateArr[0]).format("HH:mm"), [to_name]: moment(dateArr[1]).format("HH:mm") })
    }

    const handleSelectChange = (name, value, node) => {
        if (name === "type" && value === "GC") {
            setFormData({ ...formData, 
                ["expert_type"]: "",
                [name]: value
            })
        }
        else if(name==="preferred_mode_of_contact"){
           
            setFormData({
                ...formData,
                [name]: value.toString()
            })
            console.log("Preferred mode",formData);
        }
        else if (name === 'speciality') {
            let sonographer_yes_arr = node.filter(el => el.children.toLowerCase() === 'sonographer' || el.children.toLowerCase() === 'radiologist');
            if (sonographer_yes_arr.length > 0) {
                form.setFieldsValue({
                    'sonographer': 'Yes',
                    [name]: value
                })
                setFormData({
                    ...formData,
                    'sonographer': 'Yes',
                    [name]: value
                })
                

            } else {
                
                form.setFieldsValue({
                    'sonographer': undefined,
                    [name]: value
                })
                setFormData({
                    ...formData,
                    ['sonographer']: '',
                    [name]: value
                })
            }
            // setFormData({
            //     ...formData,
            //     [name]: value
            // })
            console.log('sono',formData)
        }
        else if (name === "pincode") {
            if (value) {
                value = JSON.parse(value)
                setFormData({
                    ...formData,
                    ["pincode"]: value.pincode,
                    ["city"]: value.taluk_name && value.taluk_name == "NA" ? value.district_name : value.taluk_name,
                    ["district"]: capitalizeFirst(value.district_name),
                    ["state"]: capitalizeFirst(value.state_name),
                })
                form.setFieldsValue({
                    city: value.taluk_name && value.taluk_name == "NA" ? value.district_name : value.taluk_name,
                    district:capitalizeFirst(value.district_name),
                    state: capitalizeFirst(value.state_name),
                })
            }
            else {
                setFormData({
                    ...formData,
                    ["city"]: "",
                    ["district"]: "",
                    ["state"]: "",
                })
                form.setFieldsValue({
                    city: "",
                    district: "",
                    state: "",
                })
            }
        } else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSwitchChange = (name, checked) => {
        setFormData({ ...formData, [name]: checked })

        if (checked === "No") {
            form.validateFields().then(() => {

            }).catch(e => {
                e.errorFields=[]
                setdisableds(false)

            })
        }
    }

    const handleNumberChange = (name, value) => {
        if(name==="exprience"){
            setFormData({ ...formData, [name]:moment().subtract(value, 'y')  })
        }else{
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubmit = async () => {

        if (imageFile.isChanged) {
            let imagefilename = uuidv4()
            let imageValidationRes = validateImageFile(imageFile.fileName)
            if (imageValidationRes.status) {
                let s3UrlResImage = await getS3SingedUrl(imagefilename, imageValidationRes.ext, imageFile.fileName, 'doctor')
                await axios.put(s3UrlResImage.url, imageFile.fileName)
                formData.profile_picture = `${imagefilename}.${imageValidationRes.ext}`
                formData.profile_picture_changed = true
            }
            else {
                alert("Select image type JPG or PNG.")
                return
            }
        }
        else {
            formData.profile_picture_changed = false
        }
        console.log("-->form",formData);
        saveDoctor(formData)
    }

    const handleValidation = () => {
        form.validateFields()
            .then(handleSubmit)
            .catch(e => {
                requiredFiledsTabWise.map(obj => {
                    if (obj.fields.includes(e.errorFields[0].name[0])) {
                        setActiveTab(obj.tab)
                    }
                })
            })
    }

    // const handleOnChangePincode = (e) => {
    //     if (e.target.value.length === 6) {
    //         getPincodeList({ pincode: e.target.value })
    //     }
    // }
    // const validatePincode = (rule, value, callback) => {
    //     if (value.length === 6) {
    //         if (getPincodeListState.apiState === "success" && getPincodeListState.data !== null) {
    //             callback();
    //         } else {
    //             callback(`Please enter correct Pincode.`);
    //         }
    //     }
    //     else {
    //         callback();
    //     }
    // }

    const menu = (
        <Menu>
            {(getDoctorDetailState.data.activate_status === "pending" || getDoctorDetailState.data.activate_status === "deactive") &&
                <Menu.Item>
                    <Link onClick={() => activateDoctor({ id: getDoctorDetailState.data._id, status: "active" })}>
                        Active
                    </Link>
                </Menu.Item>
            }
            {(getDoctorDetailState.data.activate_status === "pending" || getDoctorDetailState.data.activate_status === "active") &&

                <Menu.Item>
                    <Link onClick={() => activateDoctor({ id: getDoctorDetailState.data._id, status: "deactive" })}>
                        Deactive
                    </Link>
                </Menu.Item>
            }
        </Menu>
    )

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/doctor-master/list"}
                title={mode === "edit" ? "Edit Doctor Master" : "Add Doctor Master"}
                rightChildren={
                    <>
                        <Dropdown overlay={menu} placement="bottomCenter" arrow>
                            <Button> Status  <DownOutlined /></Button>
                        </Dropdown>
                        {/* <span style={{ textTransform: "capitalize" }}>{getDoctorDetailState.data.activate_status}</span> */}
                    </>
                }
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={{referring_doctor:'Yes'}} >
                        <Tabs activeKey={activeTab} onChange={setActiveTab} >
                            <TabPane tab="KYC"  disabled={activeTab!="KYC"} key="KYC">
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={3} xl={3}>
                                        <Form.Item
                                            label="Pronoun"
                                            name="pronoun"
                                        >
                                            <Input name="pronoun" placeholder="Enter Pronoun"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={3} xl={3}>
                                        <Form.Item
                                            label="Salutation"
                                            name="salutation"
                                            rules={[{ required: true, message: "Required" }]}
                                        >
                                            <Select
                                                name="salutation"
                                                placeholder="Select Salutation"
                                                allowClear
                                                onChange={(v, node) => handleSelectChange("salutation", v, node)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "salutation" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "salutation" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                        ))}
                                                    </>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="First Name"
                                            name="fname"
                                            rules={[{ required: true, message: "Required" },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || value.match(/^[A-Za-z\s\.]+$/)) {

                                                            return Promise.resolve();

                                                        }

                                                        return Promise.reject(new Error('* Please input alphabet!'));
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input name="fname" placeholder="Enter First Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Middle Name"
                                            name="mname"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || value.match(/^[A-Za-z\s\.]+$/)) {

                                                            return Promise.resolve();

                                                        }

                                                        return Promise.reject(new Error('* Please input alphabet!'));
                                                    },
                                                })
                                            ]}
                                        >
                                            <Input name="mname" placeholder="Enter First Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Last Name"
                                            name="lname"
                                            rules={[{ required: true, message: "Required" },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || value.match(/^[A-Za-z\s\.]+$/)) {

                                                            return Promise.resolve();

                                                        }

                                                        return Promise.reject(new Error('* Please input alphabet!'));
                                                    },
                                                })                                            ]}
                                        >
                                            <Input name="lname" placeholder="Enter Last Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="DOB"
                                            name="dob"
                                     
                                        >
                                            <DatePicker name="dob" placeholder="Enter DOB"
                                                onChange={(date, dateString) => handleDateChange("dob", date, dateString)}
                                                style={{ width: "100%" }}
                                                format={'DD/MM/YYYY'}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Gender"
                                            name="gender"
                                          
                                        >
                                            <Select
                                                name="gender"
                                                placeholder="Select Gender"
                                                allowClear
                                                onChange={(v) => handleSelectChange("gender", v)}
                                            >
                                                <Select.Option value={"Male"} >Male</Select.Option>
                                                <Select.Option value={"Female"} >Female</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid Email!')); }, })
                                            ]}
                                        >
                                            <Input name="email" placeholder="Enter Email"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        
                                        <Form.Item label="Mobile Number">
                                            <Input.Group compact style={{disply:"flex",flexDirection:"row"}}>
                                                
                                                <Form.Item
                                                name={["mobile","country_code"]}
                                                style={{width:"20%"}}
                                                >
                                                  <Input name="country_code" placeholder=""
                                                        onChange={handleOnChange}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                name={["mobile","mobile_number"]}
                                                style={{width:"80%"}}
                                                rules={[

                                                    () => ({ validator(_, value) { if (!value || value.match(/^[0-9]*$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valide mobile number!')); }, })
                                                    , {
                                                        required: false,
                                                        message: "Required"
                                                    }]}
                                                >
                                                    <Input name="mobile" placeholder="Enter Mobile Number"
                                                        onChange={handleOnChange}
                                                    />

                                                </Form.Item>
                                            </Input.Group>

                                        </Form.Item>


                                       



                                    </Col> */}
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Mobile number"
                                            name="mobile_number"
                                            
                                            rules={[

                                                () => ({ validator(_, value) { if (!value || value.match(/^[-+0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid mobile number!')); }, })
                                                , {
                                                    required: false,
                                                    message: "Required"
                                                }]}

                                        >
                                            <Input name="mobile_number" maxLength={13} placeholder="Enter Mobile number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="WhatsApp Number"
                                            name="whatsapp_number"
                                            rules={[() => ({ validator(_, value) { if (!value || value.match(/^[-+0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid mobile number!')); }, })                                        ]}
                                        >
                                            <Input name="whatsapp_number" placeholder="Enter WhatsApp Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Landline Number"
                                            name="landline_number"
                                            rules={[() => ({ validator(_, value) { if (!value || value.match(/^[-+0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid Landline number!')); }, })                                        ]}

                                        >
                                            <Input name="landline_number" placeholder="Enter Landline Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pincode"
                                            name="pincode"
                                      
                                        >
                                            <Select
                                                name="pincode"
                                                placeholder="Select Pincode"
                                                allowClear
                                                onChange={(v) => handleSelectChange("pincode", v)}
                                                showSearch
                                                onSearch={_.debounce(function (v) {
                                                    getPincodeList({
                                                        pincode: v
                                                    })
                                                }, 500)}
                                                filterOption={false}
                                            >
                                                {getPincodeListState.list.map(obj => (
                                                    <Select.Option value={JSON.stringify(obj)} key={obj.pincode} >{obj.pincode}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="City"
                                            name="city"
                                    
                                        >
                                            <Input name="city" placeholder="Enter City"  />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="District"
                                            name="district"
                                        
                                        >
                                            <Input name="district" placeholder="Enter District"  />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="State"
                                            name="state"
                                       
                                        >
                                            <Input name="state" placeholder="Enter State"  />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Form.Item
                                            label="Home Address"
                                            name="home_address"
                                        >
                                            <Input.TextArea name="home_address" placeholder="Enter Home Address"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Aadhar Card Number"
                                            name="adhaar_number"
                                            rules={[() => ({
                                                validator(_, value) {
                                                    if (!value || value.match(/^\d+$/)) {
                                                        return Promise.resolve();

                                                    }
                                                    return Promise.reject(new Error('* Enter valid Aadhar Card Number!'));
                                                },
                                            }),]}
                                        >
                                            <Input name="adhaar_number" placeholder="Enter Aadhar Card Number"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Space>
                                    {mode === "edit" && <Button type="primary" onClick={handleValidation} >SUBMIT</Button>}
                                    <Button type="primary" onClick={() =>
                                        form.validateFields().then(() => {
                                            setActiveTab('DTRF')
                                            setdisableds(false)
                                        }).catch(e => {
                                            setdisableds(true)
                                        })
                                     
                                       }>NEXT</Button>
                                </Space>
                            </TabPane>
                            <TabPane tab="DTRF" disabled={activeTab!="DTRF"} key="DTRF">
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Activate for DTRF"
                                            name="is_dtrf_activated"
                                        >
                                            <Switch onChange={checked => handleSwitchChange("is_dtrf_activated", checked ? "Yes" : "No")}
                                                checked={formData.is_dtrf_activated === "Yes"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="LIMS ID"
                                            name="lims_id"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || value.match(/^[A-Za-z]+\d+$/)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Please enter alphabet and number!'));
                                                    },
                                                })]}
                                        >
                                            <Input name="lims_id" placeholder="Enter LIMS ID"
                                                onChange={handleOnChange}
                                                // disabled={formData.is_dtrf_activated === "No"}
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Status"
                                            name="status"
                                            rules={[{ required: formData.is_dtrf_activated === "Yes", message: "Required" }]}

                                           
                                        >
                                            <Select
                                                name="status"
                                                placeholder="Select Status"
                                                allowClear
                                                onChange={(v) => handleSelectChange("status", v)}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            >
                                                <Select.Option value={"Lead"} >Lead</Select.Option>
                                                <Select.Option value={"Active"} >Active</Select.Option>
                                                <Select.Option value={"Inactive"} >Inactive</Select.Option>
                                                <Select.Option value={"Cold"} >Cold</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Hospital"
                                            name="hospital"
                                            rules={[{ required: formData.is_dtrf_activated === "Yes", message: "Required" }]}
                                        >
                                            <Select
                                                name="hospital"
                                                placeholder="Select Hospital"
                                                allowClear
                                                onChange={(v) => handleSelectChange("hospital", v)}
                                                showSearch
                                                onSearch={_.debounce(function (v) {
                                                    getInstituteList({
                                                        name: v,
                                                        page: 1,
                                                        records: 100
                                                    })
                                                }, 500)}
                                                filterOption={false}
                                                mode="multiple"
                                                disabled={formData.is_dtrf_activated === "No"}
                                            >
                                                {mode === "edit" && getDoctorDetailState.apiState=="success" &&getDoctorDetailState.data.hospital.map(obj=>(
                                                    <Select.Option value={obj._id} key={obj._id} >{obj.name}</Select.Option>
                                                ))}
                                                {getInstituteListState.apiState=="success" && getInstituteListState.list.filter((inst)=>{
                                                    if(mode=="edit")
                                                    {
                                                        if(getDoctorDetailState.data.hospital.filter((ob)=>ob._id==inst._id).length==0&&inst.is_active)
                                                        {
                                                            return inst
                                                        }
                                                    }
                                                    else
                                                    {
                                                        if(inst.is_active)
                                                        {
                                                            return inst
                                                        }
                                                    }
                                                }).map(obj => (
                                                    <Select.Option value={obj._id} key={obj._id} >{obj.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Speciality"
                                            name="speciality"
                                            rules={[{ required: formData.is_dtrf_activated === "Yes", message: "Required" }]}
                                        >
                                            <Select
                                                name="speciality"
                                                placeholder="Select Speciality"
                                                allowClear
                                                onChange={(v, node) => handleSelectChange("speciality", v, node)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                mode="multiple"
                                                disabled={formData.is_dtrf_activated === "No"}
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "practice-type" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "practice-type" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                        ))}
                                                    </>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Sonographer"
                                            name="sonographer"
                                            rules={[{ required: formData.is_dtrf_activated === "Yes", message: "Required" }]}
                                        >
                                            <Select
                                                name="sonographer"
                                                placeholder="Select Sonographer"
                                                allowClear
                                                onChange={(v) => handleSelectChange("sonographer", v)}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            >
                                                <Select.Option value={"Yes"} >Yes</Select.Option>
                                                <Select.Option value={"No"} >No</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Referring Doctor"
                                            name="referring_doctor"
                                            rules={[{ required: formData.is_dtrf_activated === "Yes", message: "Required" }]}
                                        >
                                            <Input maxLength="15" name="referring_doctor" placeholder="Select Referring Doctor"
                                                onChange={handleOnChange}
                                                disabled={formData.is_dtrf_activated === "No"}
                                                readOnly
                                            />
                                           
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="NT Status for Reporting"
                                            name="nt_status_for_reporting"
                                        >
                                            <Select
                                                name="nt_status_for_reporting"
                                                placeholder="Select NT Status for Reporting"
                                                allowClear
                                                onChange={(v) => handleSelectChange("nt_status_for_reporting", v)}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            >
                                                <Select.Option value={"NT To Be Audited"} >NT To Be Audited</Select.Option>
                                                <Select.Option value={"Report with NT"} >Report With NT</Select.Option>
                                                <Select.Option value={"Report without NT"} >Report Without NT</Select.Option>
                                                <Select.Option value={"Blank"} >Blank</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="FMF code"
                                            name="fmf_code"
                                            rules={[() => ({
                                                validator(_, value) {
                                                    if (!value || value.match(/^[A-Za-z0-9]*$/)) {
                                                        return Promise.resolve();

                                                    }
                                                    return Promise.reject(new Error('Enter alphabet and number'));
                                                },
                                            }),]}
                                        >
                                            <Input maxLength="15" name="fmf_code" placeholder="Enter FMF code"
                                                onChange={handleOnChange}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="FMF Certified For"
                                            name="fmf_certified_for"
                                        >
                                            <Select
                                                name="fmf_certified_for"
                                                placeholder="Select FMF Certified For"
                                                allowClear
                                                onChange={(v) => handleSelectChange("fmf_certified_for", v)}
                                                mode="multiple"
                                                disabled={formData.is_dtrf_activated === "No"}
                                            >
                                                <Select.Option value={"NT"} >NT</Select.Option>
                                                <Select.Option value={"DV"} >DV</Select.Option>
                                                <Select.Option value={"PE"} >PE</Select.Option>
                                                <Select.Option value={"NB"} >NB</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="FMF License Validity"
                                            name="fmf_license_validity"
                                        >
                                            <RangePicker format="DD/MM/YYYY"
                                                onChange={dateArr => handleDateRangeChange("fmf_license_validity_date_from", "fmf_license_validity_date_to", dateArr)}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="PNDT Registration"
                                            name="pndt_registration"
                                            rules={[
                                            () => ({
                                                validator(_, value) {
                                                    if (!value || value.match(/^[A-Za-z]+\d+$/)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('* Please input alphabet and number!'));
                                                },
                                            })]}                                         
                                        >
                                            <Input maxLength="30" name="pndt_registration" placeholder="Enter PNDT Registration"
                                                onChange={handleOnChange}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="PNDT Registration Validity"
                                            name="pndt_registration_validity"
                                        >
                                            <RangePicker format="DD/MM/YYYY"
                                                onChange={dateArr => handleDateRangeChange("pndt_registration_validity_date_from", "pndt_registration_validity_date_to", dateArr)}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Hospital Visit/Meeting Timings"
                                            name="hospital_visit"
                                        >
                                            <TimePicker.RangePicker format={"HH:mm"}
                                                onChange={dateArr => handleTimeRangeChange("hospital_visit_time_from", "hospital_visit_time_to", dateArr)}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="MCI Registration Number"
                                            name="mci_registration_number"
                                            rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value || value.match(/^[A-Za-z0-9]*$/)) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('* Please input alphabet and number!'));
                                                    },
                                                })]}
                                        >
                                            <Input maxLength="30" name="mci_registration_number" placeholder="Enter MCI Registration Number"
                                                onChange={handleOnChange}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Pan Card Number"
                                            name="pan_card_number"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid pan number!')); }, })]}
                                        >
                                            <Input name="pan_card_number" placeholder="Enter Pan Card Number"
                                                onChange={handleOnChange}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Date of Anniversary"
                                            name="date_of_anniversary"
                                        >
                                            <DatePicker name="date_of_anniversary" placeholder="Enter Date of Anniversary"
                                                onChange={(date, dateString) => handleDateChange("date_of_anniversary", date, dateString)}
                                                style={{ width: "100%" }}
                                                format={'DD/MM/YYYY'}
                                                disabled={formData.is_dtrf_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Preferred Mode of Contact"
                                            name="preferred_mode_of_contact"
                                        >
                                            <Select
                                                name="preferred_mode_of_contact"
                                                placeholder="Select Preferred Mode of Contact"
                                                allowClear
                                                onChange={(v) => handleSelectChange("preferred_mode_of_contact", v)}
                                                disabled={formData.is_dtrf_activated === "No"}
                                                mode="multiple"
                                            >
                                                <Select.Option value={"Email"} >Email</Select.Option>
                                                <Select.Option value={"SMS"} >SMS</Select.Option>
                                                <Select.Option value={"WhatsApp"} >WhatsApp</Select.Option>
                                                <Select.Option value={"F2F Visit"} >F2F Visit</Select.Option>
                                                <Select.Option value={"Phone Call"} >Phone Call</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Space>
                                    {mode === "edit" && <Button type="primary" onClick={handleValidation} >SUBMIT</Button>}
                                    <Button type="primary" onClick={() => {
                                        if (formData.is_dtrf_activated === "Yes") {
                                            
                                            setActiveTab('KYC')
                                            setdisableds(false)
                                            
                                            // form.validateFields().then(() => {
                                                // setActiveTab('KYC')
                                                // setdisableds(false)
                                            // }).catch(e => {

                                            //     setdisableds(true)

                                            // })
                                        }
                                        else {
                                            setActiveTab('KYC')
                                            setdisableds(false)
                                           
                                        
                                        }
                                    }
                                    
                                    }>PREV</Button>
                                    <Button type="primary" onClick={() => {
                                        if (formData.is_dtrf_activated === "Yes") {

                                            console.log(formData.is_dtrf_activated)
                                            form.validateFields().then(() => {
                                                setActiveTab('DCP')
                                                setdisableds(false)
                                            }).catch(e => {

                                                setdisableds(true)

                                            })
                                        }
                                        else {
                                            setActiveTab('DCP')
                                            setdisableds(false)
                                           
                                        
                                        }
                                    }
                                    
                                    }>NEXT</Button>
                                </Space>
                            </TabPane>
                            <TabPane tab="DCP"  disabled={activeTab!="DCP"} key="DCP">
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Activate for DCP"
                                            name="is_dcp_activated"
                                        >
                                            <Switch onChange={checked => handleSwitchChange("is_dcp_activated", checked ? "Yes" : "No")}
                                                checked={formData.is_dcp_activated === "Yes"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Type"
                                            name="type"
                                            rules={[{ required: formData.is_dcp_activated === "Yes", message: "Required" }]}
                                        >
                                            <Select
                                                name="type"
                                                placeholder="Select Type"
                                                allowClear
                                                onChange={(v) => handleSelectChange("type", v)}
                                                disabled={formData.is_dcp_activated === "No"}
                                            >
                                                <Select.Option value={"GC"} >GC</Select.Option>
                                                <Select.Option value={"MHC"} >MHC</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Language"
                                            name="language"
                                            
                                        >
                                            <Select
                                                name="language"
                                                placeholder="Select Language"
                                                allowClear
                                                onChange={(v, node) => handleSelectChange("language", v, node)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                mode="multiple"
                                                disabled={formData.is_dcp_activated === "No"}
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "language" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "language" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                        ))}
                                                    </>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {formData.type === "MHC" &&
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Expert Type"
                                                name="expert_type"
                                                
                                            >
                                                <Select
                                                    name="expert_type"
                                                    placeholder="Select Expert Type"
                                                    allowClear
                                                    onChange={(v) => handleSelectChange("expert_type", v)}
                                                    disabled={formData.is_dcp_activated === "No"}
                                                >
                                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "expert-type" })) &&
                                                        <>
                                                            {_.find(getCommonMasterListState.list, { slug: "expert-type" }).common_master_detail.map((item) => (
                                                                <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                            ))}
                                                        </>
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    {formData.type === "MHC" &&
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Consultation Type"
                                                name="consultation_type"
                                            
                                            >
                                                <Select
                                                    name="consultation_type"
                                                    placeholder="Select Consultation Type"
                                                    allowClear
                                                    onChange={(v) => handleSelectChange("consultation_type", v)}
                                                    disabled={formData.is_dcp_activated === "No"}
                                                >
                                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "consultation-type" })) &&
                                                        <>
                                                            {_.find(getCommonMasterListState.list, { slug: "consultation-type" }).common_master_detail.map((item) => (
                                                                <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                            ))}
                                                        </>
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    {formData.type === "MHC" &&
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Concerns"
                                                name="concerns"
                                                rules={[{ required: formData.is_dcp_activated === "Yes", message: "Required" }]}
                                            >
                                                <Select
                                                    name="concerns"
                                                    placeholder="Select Concerns"
                                                    allowClear
                                                    onChange={(v, node) => handleSelectChange("concerns", v, node)}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    mode="multiple"
                                                    disabled={formData.is_dcp_activated === "No"}
                                                >
                                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "concerns" })) &&
                                                        <>
                                                            {_.find(getCommonMasterListState.list, { slug: "concerns" }).common_master_detail.map((item) => (
                                                                <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                            ))}
                                                        </>
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    {formData.type === "MHC" &&
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Expert Approaches"
                                                name="expert_approaches"
                                                rules={[{ required: formData.is_dcp_activated === "Yes", message: "Required" }]}
                                            >
                                                <Select
                                                    name="expert_approaches"
                                                    placeholder="Select Expert Approaches"
                                                    allowClear
                                                    onChange={(v, node) => handleSelectChange("expert_approaches", v, node)}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    mode="multiple"
                                                    disabled={formData.is_dcp_activated === "No"}
                                                >
                                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "expert-approaches" })) &&
                                                        <>
                                                            {_.find(getCommonMasterListState.list, { slug: "expert-approaches" }).common_master_detail.map((item) => (
                                                                <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                            ))}
                                                        </>
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    }
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Qualification"
                                            name="qualification"
                                        >
                                            <Input name="qualification" placeholder="Enter Qualification"
                                                onChange={handleOnChange}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Experience"
                                            name="exprience"
                                        >
                                            <InputNumber name="exprience" placeholder="Enter Experience"
                                                onChange={v => handleNumberChange("exprience", v)}
                                                style={{ width: "100%" }}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                            {/* <DatePicker name="exprience" placeholder="Enter Exprience"
                                                onChange={(date, dateString) => handleDateChange("exprience", date, dateString)}
                                                style={{ width: "100%" }}
                                                format={'DD/MM/YYYY'}
                                                disabled={formData.is_dcp_activated === "No"}
                                            /> */}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Profile Picture"
                                            name="profile_picture"
                                           // rules={[
                                            //    { required: (formData.is_dcp_activated === "Yes") ? (mode === 'edit' && imageFile.edit ? (formData.profile_picture ? imageFile.isChanged : !imageFile.isChanged) : !imageFile.isChanged) : imageFile.isChanged, message: "Required" },
                                          
                                           // ]}
                                        >
                                            <Input name="profile_picture" placeholder="Enter Profile Picture"
                                                type="file"
                                                onChange={e => setImageFile({
                                                    fileName: e.target.files[0],
                                                    isChanged: e.target.files[0] ? true : false,
                                                    edit: false
                                                })}
                                                accept="image/png, image/jpeg"
                                                suffix={(formData.profile_picture && mode === 'edit') ? <img src={`${s3_cdn()}doctor/${formData.profile_picture}`} style={{ width: 26 }} /> : null}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Form.Item
                                            label="Description"
                                            name="description"
                                           // rules={[{ required: formData.is_dcp_activated === "Yes", message: "Required" }]}
                                        >
                                            <Input.TextArea name="description" placeholder="Enter Description"
                                                onChange={handleOnChange}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Tier 1 fee"
                                            name="tier_1_fee"
                                           rules={[{ required: formData.is_dcp_activated === "Yes", message: "Required" }]}
                                        >
                                            <InputNumber name="tier_1_fee" placeholder="Enter Tier 1 fee"
                                                onChange={v => handleNumberChange("tier_1_fee", v)}
                                                style={{ width: "100%" }}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Tier 2 fee"
                                            name="tier_2_fee"
                                          //  rules={[{ required: formData.is_dcp_activated === "Yes", message: "Required" }]}
                                        >
                                            <InputNumber name="tier_2_fee" placeholder="Enter Tier 2 fee"
                                                onChange={v => handleNumberChange("tier_2_fee", v)}
                                                style={{ width: "100%" }}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Area of Expertise"
                                            name="area_of_expertise"
                                        >
                                            <Select
                                                name="area_of_expertise"
                                                placeholder="Select Area of Expertise"
                                                allowClear
                                                onChange={(v) => handleSelectChange("area_of_expertise", v)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                mode="multiple"

                                                disabled={formData.is_dcp_activated === "No"}
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "area-of-expertise" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "expert-approaches" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                        ))}
                                                    </>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Core Area"
                                            name="core_area"
                                        >
                                            <Select
                                                name="core_area"
                                                placeholder="Select Core Area"
                                                allowClear
                                                onChange={(v) => handleSelectChange("core_area", v)}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                disabled={formData.is_dcp_activated === "No"}
                                            >
                                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "core-area" })) &&
                                                    <>
                                                        {_.find(getCommonMasterListState.list, { slug: "core-area" }).common_master_detail.map((item) => (
                                                            <Select.Option value={item._id} key={item._id} >{item.value}</Select.Option>
                                                        ))}
                                                    </>
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Can give Medicine"
                                            name="can_give_medicine"
                                        >
                                            <Switch checkedChildren="Yes" unCheckedChildren="No"
                                                onChange={checked => handleSwitchChange("can_give_medicine", checked ? "Yes" : "No")}
                                                disabled={formData.is_dcp_activated === "No"}
                                                checked={formData.can_give_medicine === "Yes"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {formData.type === "MHC" &&
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Self Fee"
                                            name="self_fee"
                                        >
                                            <InputNumber name="self_fee" placeholder="Enter Self Fee"
                                                onChange={v => handleNumberChange("self_fee", v)}
                                                style={{ width: "100%" }}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>}
                                    {formData.type === "MHC" &&
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Couple Fee"
                                            name="couple_fee"
                                        >
                                            <InputNumber name="couple_fee" placeholder="Enter Couple Fee"
                                                onChange={v => handleNumberChange("couple_fee", v)}
                                                style={{ width: "100%" }}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>}

                                    {formData.type === "MHC" &&
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Family Fee"
                                            name="family_fee"
                                        >
                                            <InputNumber name="family_fee" placeholder="Enter Family Fee"
                                                onChange={v => handleNumberChange("family_fee", v)}
                                                style={{ width: "100%" }}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>}
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Medicine Fee"
                                            name="medicine_fee"
                                        >
                                            <InputNumber name="medicine_fee" placeholder="Enter Medicine Fee"
                                                onChange={v => handleNumberChange("medicine_fee", v)}
                                                style={{ width: "100%" }}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Hidden"
                                            name="hidden"
                                        >
                                            <Switch checkedChildren="Yes" unCheckedChildren="No"
                                                onChange={checked => handleSwitchChange("hidden", checked ? "Yes" : "No")}
                                                disabled={formData.is_dcp_activated === "No"}
                                                checked={formData.hidden === "Yes"}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                        <Form.Item
                                            label="Short Description"
                                            name="short_description"
                                           // rules={[{ required: formData.is_dcp_activated === "Yes", message: "Required" }]}
                                        >
                                            <Input.TextArea name="short_description" placeholder="Enter Short Description"
                                                onChange={handleOnChange}
                                                disabled={formData.is_dcp_activated === "No"}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Space>
                                    {mode === "edit" && <Button type="primary" onClick={handleValidation} >SUBMIT</Button>}
                                    <Button type="primary" onClick={() => {
                                        if (formData.is_dcp_activated === "Yes") {

                                            setActiveTab('DTRF')
                                            setdisableds(false)
                                            // console.log(formData.is_dcp_activated)
                                            // form.validateFields().then(() => {
                                            //     setActiveTab('DTRF')
                                            //     setdisableds(false)
                                            // }).catch(e => {

                                            //     setdisableds(true)

                                            // })
                                        }
                                        else {
                                            setActiveTab('DTRF')
                                            setdisableds(false)
                                           
                                        
                                        }
                                    }
                                    
                                    }>PREV</Button>
                                    <Button type="primary" onClick={() => {
                                        if (formData.is_dcp_activated === "Yes") {

                                            console.log(formData.is_dtrf_activated)
                                            form.validateFields().then(() => {
                                                setActiveTab('Social')
                                                setdisableds(false)
                                            }).catch(e => {

                                                setdisableds(true)

                                            })
                                        }
                                        else {
                                            setActiveTab('Social')
                                            setdisableds(false)


                                        }
                                    }}>NEXT</Button>
                                </Space>
                            </TabPane>
                            <TabPane tab="Social"  disabled={activeTab!="Social"} key="Social">
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Facebook ID"
                                            name="facebook_id"
                                        >
                                            <Input name="facebook_id" placeholder="Enter Facebook ID"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Instagram ID"
                                            name="instagram_id"
                                        >
                                            <Input name="instagram_id" placeholder="Enter Instagram ID"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="LinkedIn ID"
                                            name="linkedin_id"
                                        >
                                            <Input name="linkedin_id" placeholder="Enter LinkedIn ID"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Interests"
                                            name="interests"
                                        >
                                            <Input name="interests" placeholder="Enter Interests"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                

                                {
                                    saveDoctorState.apiState=="loading" ? <Button type="primary" loading>Adding</Button> : 
                                    <Space>
                                    {mode === "edit" && <Button type="primary" onClick={handleValidation} >SUBMIT</Button>}
                                    <Button type="primary" onClick={() => {
                                        if (formData.is_social_activated === "Yes") {

                                            setActiveTab('DCP')
                                            setdisableds(false)

                                            // console.log(formData.is_social_activated)
                                            // form.validateFields().then(() => {
                                            //     setActiveTab('DCP')
                                            //     setdisableds(false)
                                            // }).catch(e => {

                                            //     setdisableds(true)

                                            // })
                                        }
                                        else {
                                            setActiveTab('DCP')
                                            setdisableds(false)
                                           
                                        
                                        }
                                    }
                                    
                                    }>PREV</Button>
                                    <Button type="primary" onClick={handleValidation} >SUBMIT</Button>
                                </Space>
                                }
                            </TabPane>
                        </Tabs >



                        {/* <Button type="primary" htmlType="submit" loading={saveDoctorState.apiState === "loading"} >SUBMIT</Button> */}

                    </Form >
                </LayoutContainer >
            </PageContainer >
        </>
    )
}

const mapStateToProps = (state) => ({
    getDoctorDetailState: state.getDoctorDetail,
    saveDoctorState: state.saveDoctor,
    getInstituteListState: state.getInstituteList,
    getCommonMasterListState: state.getCommonMasterList,
    getPincodeListState: state.getPincodeList,
    activateDoctorState: state.activateDoctor,
});

const mapDispatchToProps = (dispatch) => ({
    getDoctorDetail: (params) => dispatch(getDoctorDetail(params)),
    getDoctorDetailReset: () => dispatch(getDoctorDetailReset()),
    saveDoctor: (params) => dispatch(saveDoctor(params)),
    saveDoctorReset: () => dispatch(saveDoctorReset()),
    getInstituteList: (params) => dispatch(getInstituteList(params)),
    getInstituteListReset: () => dispatch(getInstituteListReset()),
    getCommonMasterList: (params) => dispatch(getCommonMasterList(params)),
    getCommonMasterListReset: () => dispatch(getCommonMasterListReset()),
    getPincodeList: (params) => dispatch(getPincodeList(params)),
    getPincodeListReset: () => dispatch(getPincodeListReset()),
    activateDoctor: (params) => dispatch(activateDoctor(params)),
    activateDoctorReset: () => dispatch(activateDoctorReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorMasterSave);