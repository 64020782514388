import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined,CheckCircleFilled,CloseCircleOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"
import InstituteMasterView from "../InstituteMasterView/InstituteMasterView"

/* actions */
import { getInstituteList, getInstituteListReset } from "../../../action/getInstituteListAction"
import { deleteInstitute, deleteInstituteReset } from "../../../action/deleteInstituteAction"
import { getNewInstituteList, getNewInstituteListReset } from "../../../action/getNewInstituteListAction"
import { changeInstituteStatus,changeInstituteStatusReset } from "../../../action/changeInstituteStatus"
import { instituteMasterHeaders } from "../../../utils";

var Excel = require("exceljs");

const InstituteMasterList = props => {
    /* variables */
    const {
        getInstituteList, getInstituteListReset, getInstituteListState,
        deleteInstitute, deleteInstituteReset, deleteInstituteState,
        changeInstituteStatus,changeInstituteStatusReset,changeInstituteStatusState,
        getNewInstituteList, getNewInstituteListReset, getNewInstituteListState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
    const columns = [
        {
            title: 'Lilac ID',
            dataIndex: 'lilac_id',
            key: 'lilac_id',
            width: 300,
            // sortDirections:['ascend', 'descend', 'ascend'],
            // sorter: (a, b) => a.lilac_id.length - b.lilac_id.length,
            ...getColumnSearchProps('lilac_id', 'Lilac ID'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 400,
            ...getColumnSearchProps('name', 'Name')
        },
        {
            title: 'Marketing Location',
            dataIndex: 'hq_or_primary_marketing_location',
            key: 'hq_or_primary_marketing_location',
            width: 400,
            ...getColumnSearchProps('hq_or_primary_marketing_location', 'Marketing Location')
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: 400,
            ...getColumnSearchProps('city_state', 'City')
        },
        // {
        //     title: 'Doctors Attached',
        //     dataIndex: 'doctors_attached',
        //     key: 'doctors_attached',
        //     width: 600,
        //     render: doctors_attached => (
        //         <>
        //             <Space>
        //                 <div>
        //                     {doctors_attached?.map(item => (
        //                         <XtagTable>{`${item.fname} ${item.lname}`}</XtagTable>
        //                     ))}
        //                 </div>
        //             </Space>
        //         </>
        //     ),
        //     ...getColumnSearchProps('doctors_attached', 'Doctors Attached')
        // },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            width: 200,
            render: (id,row) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/institute-master/edit/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteInstitute({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                    {row.is_active && <Popconfirm
                        title="Are you sure to deactivate this institute?"
                        onConfirm={() => {
                            
                            changeInstituteStatus({ id: row.id });
                        }}
                        okText="Deactivate"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<CloseCircleOutlined />} />
                    </Popconfirm>}

                    {!row.is_active && <Popconfirm
                        title="Are you sure to activate this intitute?"
                        onConfirm={() => {
                            console.log(row.is_active);
                            changeInstituteStatus({ id: row.id })
                        }}
                        okText="Activate"
                        cancelText="Cancel"
                        okType="success"
                    >
                        <Button size="small" shape="circle" icon={<CheckCircleFilled />} />
                    </Popconfirm>}
                </Space>
            )
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }


    /* callbacks */
    useEffect(() => {
        return (() => {
            getInstituteListReset()
        })
    }, [])

    useEffect(() => {
        getInstituteList({
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getInstituteListState.apiState === "success") {
            let tableData = []
            getInstituteListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        lilac_id: row.lilac_id,
                        name: row.name,
                        hq_or_primary_marketing_location:row.hq_or_primary_marketing_location,
                        city: `${row.city}, ${row.state}`,
                        // doctors_attached: row.doctors_attached,
                        id: row._id,
                        is_active:row.is_active
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getInstituteListState])

    useEffect(() => {
        setRedirect([true, `/institute-master/list?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteInstituteState.apiState === "success") {
            deleteInstituteReset()
            notification["success"]({
                message: deleteInstituteState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteInstituteState.apiState === "error") {
            deleteInstituteReset()
            notification["error"]({
                message: deleteInstituteState.message,
                placement: "topRight"
            })
        }
    }, [deleteInstituteState])

    useEffect(() => {
        if (changeInstituteStatusState.apiState === "success") {
            notification["success"]({
                message: changeInstituteStatusState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (changeInstituteStatusState.apiState === "error") {
            deleteInstituteReset()
            notification["error"]({
                message: changeInstituteStatusState.message,
                placement: "topRight"
            })
        }
    }, [changeInstituteStatusState])

    useEffect(()=>{
        if(getNewInstituteListState.apiState=="success" && getNewInstituteListState.data.new_institute_dtrfs?.length)
        {   
            let new_institute_list = getNewInstituteListState.data.new_institute_dtrfs;
            var wb = new Excel.Workbook();
            const workbook = wb.addWorksheet("Sheet1");
            const headers = instituteMasterHeaders;

            workbook.getRow(1).values = headers;
            
            new_institute_list.forEach((element,index) => {
                const rowIndex = index + 2;
                const rows = Array(44).fill("");
                rows[3] = element.name
                rows[87] = 'TRUE'
                rows[88] = element.super_dtrf_id
                workbook.getRow(rowIndex).values = rows;      
            });
            wb.xlsx.writeBuffer().then((buffer) => {
                const fileData = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileUrl = URL.createObjectURL(fileData);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'NewInstituteList.xlsx';
                link.click();
            });
            getNewInstituteListReset();
        }
        else if(getNewInstituteListState=="error")
        {
            console.log("No new institutes");
            getNewInstituteListReset();
        }
    },[getNewInstituteListState]);

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Institute Master List"
                rightChildren={
                    <>
                        <Button type="primary" onClick={()=>{getNewInstituteList({})}} >Download New Institutes</Button>
                        <Link to="/institute-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Upload</Button></Link>
                        <Link to="/institute-master/add" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer  >


                {getInstituteListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getInstituteListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getInstituteListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768,y:600 }}
                            loading={getInstituteListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getInstituteListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <InstituteMasterView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getInstituteListState: state.getInstituteList,
    deleteInstituteState: state.deleteInstitute,
    changeInstituteStatusState:state.changeInstituteStatus,
    getNewInstituteListState: state.getNewInstituteList,
});

const mapDispatchToProps = (dispatch) => ({
    getInstituteList: (params) => dispatch(getInstituteList(params)),
    getInstituteListReset: () => dispatch(getInstituteListReset()),
    deleteInstitute: (params) => dispatch(deleteInstitute(params)),
    deleteInstituteReset: () => dispatch(deleteInstituteReset()),
    changeInstituteStatus:(params)=>dispatch(changeInstituteStatus(params)),
    changeInstituteStatusReset:()=>dispatch(changeInstituteStatusReset()),
    getNewInstituteList: (params) => dispatch(getNewInstituteList(params)),
    getNewInstituteListReset: () => dispatch(getNewInstituteListReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstituteMasterList);