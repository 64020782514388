import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_QUICK_SUPPORT_CATEGORY_LIST,
    getQuickSupportCategoryListFail,
    getQuickSupportCategoryListSuccess
} from '../action/getQuickSupportCategoryListAction'

// api 
import getQuickSupportCategoryListApi from '../api/getQuickSupportCategoryListApi'

export function* getQuickSupportCategoryListSaga(action) {
    try {
        const response = yield call(() => getQuickSupportCategoryListApi(action.params))
        if (response.data.status === 1) {
            yield put(getQuickSupportCategoryListSuccess(response, action))
        } else {
            yield put(getQuickSupportCategoryListFail(response, action))
        }
    }
    catch (e) {
        yield put(getQuickSupportCategoryListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_QUICK_SUPPORT_CATEGORY_LIST, getQuickSupportCategoryListSaga)
}