import axios from 'axios'
import config from '../config'
import { getUserToken } from "../utils"

const getPincodeDetailApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}pincode/detail`,
        method: "get",
        params: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getUserToken()}`
        }
    })

    return response

}

export default getPincodeDetailApi