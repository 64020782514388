import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Tag } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined, CheckOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination } from "../../../component/Xcomponent"
import DoctorMasterView from "../DoctorMasterView/DoctorMasterView"

/* actions */
import { getDoctorList, getDoctorListReset } from "../../../action/getDoctorListAction"
import { deleteDoctor, deleteDoctorReset } from "../../../action/deleteDoctorAction"
import { getNewDoctorList, getNewDoctorListReset } from "../../../action/getNewDoctorListAction";
import { doctorMasterHeaders } from "../../../utils";

var Excel = require("exceljs");

const DoctorMasterList = props => {
    /* variables */
    const {
        getDoctorList, getDoctorListReset, getDoctorListState,
        getNewDoctorList, getNewDoctorListReset, getNewDoctorListState,
        deleteDoctor, deleteDoctorReset, deleteDoctorState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    const columns = [
        {
            title: 'LIMS ID',
            dataIndex: 'lims_id',
            key: 'lims_id',
            ...getColumnSearchProps('lims_id', 'LIMS ID')
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name', 'Name')
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            ...getColumnSearchProps('city', 'City')
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            ...getColumnSearchProps('state', 'State')
        },
        {
            title: 'Stauts',
            dataIndex: 'status',
            key: 'status',
            render: status => {
                if (status === 'deactive') return <Tag color="magenta">Deactive</Tag>
                if (status === 'active') return <Tag color="green">Active</Tag>
                if (status === 'pending') return <Tag color="orange">Pending</Tag>
            },
            ...getColumnSearchProps('status', 'Stauts')

        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            // ...getColumnSearchProps('type', 'Type')
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        //     key: 'type',
        //     // ...getColumnSearchProps('type', 'Type')
        // },
        {
            title: 'Dcp Activated',
            dataIndex: 'is_dcp_activated',
            key: 'is_dcp_activated',
            // ...getColumnSearchProps('is_dcp_activated', 'Dcp Activated')
        },
        {
            title: 'Dtrf Activated',
            dataIndex: 'is_dtrf_activated',
            key: 'is_dtrf_activated',
            // ...getColumnSearchProps('is_dtrf_activated', 'Dtrf Activated')
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/doctor-master/save/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteDoctor({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        },
    ];

    /* callbacks */
    useEffect(() => {
        return (() => {
            getDoctorListReset()
        })
    }, [])
    useEffect(() => {
        getDoctorList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getDoctorListState.apiState === "success") {
            let tableData = []
            getDoctorListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        lims_id: row.lims_id,
                        name: `${row.fname} ${row.mname??''} ${row.lname}`,
                        city: `${row.city}`,
                        state: `${row.state}`,
                        id: row._id,
                        status: row.activate_status,
                        type: row.type,
                        is_dcp_activated:row.is_dcp_activated,
                        is_dtrf_activated:row.is_dtrf_activated
                        
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getDoctorListState])

    useEffect(()=>{

        if(getNewDoctorListState.apiState=="success")
        {   
            let new_doctor_list = getNewDoctorListState.data.new_doctor_dtrfs;
            var wb = new Excel.Workbook();
            
            const workbook = wb.addWorksheet("Sheet1");
            const headers = doctorMasterHeaders;

            workbook.getRow(1).values = headers;

            new_doctor_list.forEach((element,index) => {
                const rowIndex = index + 2;
                const rows = Array(44).fill("");
                rows[3] = "Dr."
                rows[5] = element.fname
                rows[6] = element.mname
                rows[7] = element.lname
                rows[32] = element.institute_lilac_id
                rows[34] = element.sonographer?"Yes":"No"
                rows[44] = 'TRUE'
                rows[45] = element.super_dtrf_id
                workbook.getRow(rowIndex).values = rows;      
            });
            wb.xlsx.writeBuffer().then((buffer) => {
                const fileData = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileUrl = URL.createObjectURL(fileData);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'NewDoctorList.xlsx';
                link.click();
            });
            getNewDoctorListReset();
        }
        else if(getNewDoctorListState=="error")
        {
            console.log("No new doctors");
            getNewDoctorListReset();
        }
    },[getNewDoctorListState]);

    useEffect(() => {
        setRedirect([true, `/doctor-master/list?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteDoctorState.apiState === "success") {
            deleteDoctorReset()
            notification["success"]({
                message: deleteDoctorState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteDoctorState.apiState === "error") {
            deleteDoctorReset()
            notification["error"]({
                message: deleteDoctorState.message,
                placement: "topRight"
            })
        }
    }, [deleteDoctorState])




    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Doctor Master List"
                rightChildren={
                    <>
                        <Button type="primary" onClick={()=>{getNewDoctorList({})}} >Download New Doctors</Button>
                        <Link to="/doctor-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Upload</Button></Link>
                        <Link to="/doctor-master/save" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                {getDoctorListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getDoctorListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getDoctorListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768,y:600 }}
                            loading={getDoctorListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getDoctorListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <DoctorMasterView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getDoctorListState: state.getDoctorList,
    getNewDoctorListState: state.getNewDoctorList,
    deleteDoctorState: state.deleteDoctor,
});

const mapDispatchToProps = (dispatch) => ({
    getDoctorList: (params) => dispatch(getDoctorList(params)),
    getDoctorListReset: () => dispatch(getDoctorListReset()),
    getNewDoctorList: (params) => dispatch(getNewDoctorList(params)),
    getNewDoctorListReset: () => dispatch(getNewDoctorListReset()),
    deleteDoctor: (params) => dispatch(deleteDoctor(params)),
    deleteDoctorReset: () => dispatch(deleteDoctorReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorMasterList);