import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_ADMIN_TYPE,
    deleteAdminTypeFail,
    deleteAdminTypeSuccess
} from '../action/deleteAdminTypeAction'

// api 
import deleteAdminTypeApi from '../api/deleteAdminTypeApi'

export function* deleteAdminTypeSaga(action) {
    try {
        const response = yield call(() => deleteAdminTypeApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteAdminTypeSuccess(response, action))
        } else {
            yield put(deleteAdminTypeFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteAdminTypeFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_ADMIN_TYPE, deleteAdminTypeSaga)
}