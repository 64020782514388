import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_EMPLOYEE,
    deleteEmployeeFail,
    deleteEmployeeSuccess
} from '../action/deleteEmployeeAction'

// api 
import deleteEmployeeApi from '../api/deleteEmployeeApi'

export function* deleteEmployeeSaga(action) {
    try {
        const response = yield call(() => deleteEmployeeApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteEmployeeSuccess(response, action))
        } else {
            yield put(deleteEmployeeFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteEmployeeFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_EMPLOYEE, deleteEmployeeSaga)
}