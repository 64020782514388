import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Button, notification, Tabs,Select, Space, Popconfirm } from "antd"
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined, CheckOutlined } from "@ant-design/icons";
import _ from "lodash"
/* custom component */
import Header from "../../component/Header/Header"
import { PageContainer, LayoutContainer, Xtable } from "../../component/Xcomponent"

/* actions */
import { getMouList, getMouListReset } from "../../action/getMouListAction"
import { saveMou, saveMouReset } from "../../action/saveMouAction"
/* otehrs */
import { CustomTabs, CustomTabPane } from "./MouStyle"

import { getUser } from '../../utils'


const Mou = props => {
    // Variables
    const tab = props.match.params.tab
    const {
        getMouList, getMouListState, getMouListReset,
        saveMou, saveMouState, saveMouReset,
    } = props
    const [formData, setFormData] = useState({
        start_date: "",
        end_date: "",
        files: "",
        type_of_mou: "",
    })

    const [testGroupForm] = Form.useForm()


    const [cancelButton, setCancelButton] = useState(false)
    const [defaultActiveKey, setDefaultActiveKey] = useState(tab ? tab : "")
    const [redirect, setRedirect] = useState()

    const [columns, setColumns] = useState([])
    const [dataSource, setDataSource] = useState([])

    const [tabPanOrder, setTabPaneOrder] = useState([])
    const [tabPanOrderFilter, setTabPanOrderFilter] = useState([])

    //Callback
    useEffect(() => {
        getMouList()
    }, [])

    useEffect(() => {
        setColumns([
            {
                title: "Start Date",
                dataIndex: "start_date"
            },
            {
                title: "End Date",
                dataIndex: "end_date"
            },
            {
                title: "MOU type",
                dataIndex: "type_of_mou"
            },
            {
                title: "Status",
                dataIndex: "status"
            },
            {
                title: "Action",
                dataIndex: "action",
                render: item => (
                    <>
                        <Space size="small">
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ])
    }, [])

    useEffect(() => {
        if (getMouListState.apiState === 'success') {
            let rows = []
            let rowItems = []
            let tabs = []
            if (getMouListState.list && getMouListState.list.length > 0) {
             
            
            getMouListState.list.map((item) => {
              
                rowItems = []
           
                rows.push({
                    start_date: item.start_date,
                    end_date: item.end_date,
                    files: item.files,
                    type_of_mou: item.type_of_mou=="price_mou" ? "Price MOU" : item.type_of_mou=="legal_mou" ? "Legal MOU" : "-",
                    status: item.status=="pending" ? "Pending" : item.status=="accepted" ? "Accepted": item.status=="declined" ? "Declined" : "-",
                })
            })
            setDataSource(rows)
                setTabPaneOrder(tabs)
            }
        }
    }, [getMouListState])


    useEffect(() => {
        if (saveMouState.apiState === "success") {
            getMouList()
            saveMouReset()
            //resetAllData()
            notification["success"]({
                message: saveMouState.message,
                placement: "topRight"
            })
        }
        if (saveMouState.apiState === "error") {
            saveMouReset()
            notification["error"]({
                message: saveMouState.message,
                placement: "topRight"
            })
        }
    }, [saveMouState])

    //useEffect(() => {
    //    if (deleteCommonMasterState.apiState === "success") {
    //        deleteCommonMasterReset()
    //        getCommonMasterList()
    //        notification["success"]({
    //            message: deleteCommonMasterState.message,
    //            placement: "topRight"
    //        })
    //    }
    //    if (deleteCommonMasterState.apiState === "error") {
    //        deleteCommonMasterReset()
    //        notification["error"]({
    //            message: deleteCommonMasterState.message,
    //            placement: "topRight"
    //        })
    //    }
    //}, [deleteCommonMasterState])

    // functions
    const callback = (key) => {
        resetAllData()
        setRedirect(key)
        setDefaultActiveKey(key)
    }

    const resetAllData = () => {
        setFormData()
        testGroupForm.resetFields()
        setCancelButton(false)
    }

    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    
    const handlefileOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.files[0].name })

    }
    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }
    const handleSubmit = () => {
        console.log("check", formData)
            saveMou(formData)
        console.log("checks")
    }

    const editCommonMaster = (id, name, fieldName) => {
        setFormData({ ['value']: name, ['id']: id, ['mode']: 'edit' })
        testGroupForm.setFieldsValue({
            value: name
        })
        // if (fieldName === "Test Group") {
        //     testGroupForm.setFieldsValue({
        //         value: name
        //     })
        // }
        // else if (fieldName === "Sample Type") {
        //     sampleTypeForm.setFieldsValue({
        //         value: name
        //     })
        // }
        setCancelButton(true)
    }


    useEffect(() => {
        setTabPanOrderFilter(tabPanOrder)
    }, [tabPanOrder])

    const searchMaster = e => {
        let searchTerm = e.target.value
        const filtered = _.filter(tabPanOrder, function (o) {
            if (o.search !== null) {
                return o.search.toLowerCase().indexOf(searchTerm) > -1
            }
        })
        setTabPanOrderFilter(filtered)
    }

    const OperationsSlot = {
        left: < Input name="search_master" placeholder="Search mou" onChange={searchMaster} />,
    };

    return (
        <>
            {redirect && <Redirect to={`/mou/${redirect}`} />}
            <Header
                title={"Mou"}
                rightChildren={
                    <>
                        <Link to="/mou/save" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                <LayoutContainer>

                    
                    {/* <Form form={testGroupForm} layout="vertical" onFinish={handleSubmit}>
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    name="start_date"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                <Input name="start_date" type="date" onChange={handleOnChange} /></Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    name="end_date"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input name="end_date" type="date"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    name="files"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input name="files" type="file"
                                        onChange={handlefileOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    name="type_of_mou"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Select
                                        name="type_of_mou"

                                        placeholder="Select type_of_mou"
                                        onChange={(v) => handleSelect("type_of_mou", v)}>
                                        <Select.Option value="price_mou">Price Mou</Select.Option>
                                        <Select.Option value="ligal_mou">Ligal Mou</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={saveMouState.apiState === "loading"} >SUBMIT</Button>
                                    {cancelButton &&
                                        <Button type="default" onClick={resetAllData} >Cancel</Button>
                                    }
                                </Space>
                            </Col>
                        </Row>
                    </Form> */}
                    <Row gutter={20}>
                        <Xtable dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: 768 }} />
                    </Row>  
                </LayoutContainer>
            </PageContainer>
        </>
    )

}

const mapStateToProps = (state) => ({
    getMouListState: state.getMouList,
    saveMouState: state.saveMou,
});

const mapDispatchToProps = (dispatch) => ({
    getMouList: (params) => dispatch(getMouList(params)),
    getMouList: (params) => dispatch(getMouList(params)),
    saveMou: (params) => dispatch(saveMou(params)),
    saveMouReset: () => dispatch(saveMouReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mou);