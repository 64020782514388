import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_MOU,
    deleteMouFail,
    deleteMouSuccess
} from '../action/deleteMouAction'

// api 
import deleteMouApi from '../api/deleteMouApi'

export function* deleteMouSaga(action) {
    try {
        const response = yield call(() => deleteMouApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteMouSuccess(response, action))
        } else {
            yield put(deleteMouFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteMouFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_MOU, deleteMouSaga)
}