import styled from "styled-components";

export const PatientCard = styled.div`
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    border-bottom: solid 1px #f5f5f5;

    &:hover {
        background-color: #8f5386;
        p{
            color: #fff;
        }
    }

    background-color:${props => props.active ? '#8f5386' : '#fff'};
    p{
        color: ${props => props.active ? '#fff' : '#000000bf'};
    }
`
export const PatientName = styled.p`
    font-size: 16px;
    color: #000000bf;
    margin: 0;
`
export const Status = styled.p`
    font-size: 14px;
    font-weight: bold;
    color: #000000bf;
    margin: 0;
`
export const MessageRigth = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
`
export const MessageLeft = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
`
export const RightMessageBody = styled.div`
    color: black;
    width: 70%;
    justify-content: flex-end;
    background-color: #ddd;
    border-radius: 4px;
    padding: 8px;
`
export const LeftMessageBody = styled.div`
    width: 70%;
    justify-content: flex-start;
    /* background-color: #8f5386; */
    background: linear-gradient(
145deg, rgb(0 0 0 / 70%) 0%, rgba(143,83,134,1) 100%, rgba(253,240,233,1) 100%);
    color: white;
    border-radius: 4px;
    padding: 8px;
`