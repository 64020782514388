import React, { useEffect, useState } from "react"
import { Button, TreeSelect, Form, Row, Col, Input, notification, Space, Popconfirm } from "antd"
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import _ from "lodash"
// component
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

// actions
import { getQuickSupportCategoryList, getQuickSupportCategoryListReset } from '../../../action/getQuickSupportCategoryListAction'
import { saveQuickSupportCategory, saveQuickSupportCategoryReset } from '../../../action/saveQuickSupportCategoryAction'
import { getQuickSupportCategoryDetail, getQuickSupportCategoryDetailReset } from '../../../action/getQuickSupportCategoryDetailAction'
import { deleteQuickSupportCategory, deleteQuickSupportCategoryReset } from '../../../action/deleteQuickSupportCategoryAction'

const { TreeNode } = TreeSelect

const QuickSupportCategorySave = props => {

    // Variables
    const {
        getQuickSupportCategoryList, getQuickSupportCategoryListState, getQuickSupportCategoryListReset,
        saveQuickSupportCategory, saveQuickSupportCategoryState, saveQuickSupportCategoryReset,
        getQuickSupportCategoryDetail, getQuickSupportCategoryDetailState, getQuickSupportCategoryDetailReset,
        deleteQuickSupportCategory, deleteQuickSupportCategoryState, deleteQuickSupportCategoryReset
    } = props
    const id = props.match.params.id
    const mode = id ? "edit" : "add"
    const [form] = Form.useForm()
    const [formData, setFormData] = useState({
        parent_id: null,
        name: ""
    })
    const [hasChildren, setHasChildren] = useState(false)
    const [redirect, setRedirect] = useState([false, ""])
    if (mode === "edit") {
        formData.id = id
        formData.mode = mode
    }
    // callbacks
    /* To fetch categories */
    useEffect(() => {
        getQuickSupportCategoryList()
        if (mode === "edit") {
            getQuickSupportCategoryDetail({
                id: id
            })
        }
        return (() => {
            getQuickSupportCategoryDetailReset()
            saveQuickSupportCategoryReset()
            getQuickSupportCategoryListReset()
        })
    }, [])
    /* Watching add api to display notifications */
    useEffect(() => {
        if (saveQuickSupportCategoryState.apiState === "success") {
            notification["success"]({
                message: saveQuickSupportCategoryState.message,
                placement: "bottomRight"
            })
            saveQuickSupportCategoryReset()
            setRedirect([true, "/quick-support-category/list"])
        }

        if (saveQuickSupportCategoryState.apiState === "error") {
            notification["error"]({
                message: saveQuickSupportCategoryState.message,
                placement: "bottomRight"
            })
        }
    }, [saveQuickSupportCategoryState])
    // Delete Category notification
    useEffect(() => {
        if (deleteQuickSupportCategoryState.apiState === "success") {
            notification["success"]({
                message: deleteQuickSupportCategoryState.message,
                placement: "bottomRight"
            })
            deleteQuickSupportCategoryReset()
            setRedirect([true, "/quick-support-category/list"])
        }

        if (deleteQuickSupportCategoryState.apiState === "error") {
            notification["error"]({
                message: deleteQuickSupportCategoryState.message,
                placement: "bottomRight"
            })
        }
    }, [deleteQuickSupportCategoryState])

    useEffect(() => {
        if (getQuickSupportCategoryDetailState.apiState === "success") {
            let item = getQuickSupportCategoryDetailState.data
            form.setFieldsValue({
                parent_id: item.parent_id,
                name: item.name,
            })
            setFormData({ ...formData, ['name']: item.name, ['parent_id']: item.parent_id })
        }
    }, [getQuickSupportCategoryDetailState])

    useEffect(() => {
        if (getQuickSupportCategoryDetailState.apiState === "success" && getQuickSupportCategoryDetailState.apiState === "success") {
            let a = _(getQuickSupportCategoryListState.list)
                .thru(function (coll) {
                    return _.union(coll, _.map(coll, 'children') || []);
                })
                .flatten()
                .find({ parent_id: getQuickSupportCategoryDetailState.data.parent_id });
            if (a) {
                setHasChildren(true)
            }
            else {
                setHasChildren(false)
            }

        }
    }, [getQuickSupportCategoryDetailState, getQuickSupportCategoryListState])
    // functions
    const handleTreeSelect = (value, node) => {
        setFormData({
            ...formData,
            ["parent_id"]: value
        })
    }

    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const categoryOptions = (tree) => {
        let finalJsx = []
        tree.map(node => {
            if (node.children.length > 0) {

                finalJsx.push(
                    <TreeNode value={node._id} title={node.name}>{categoryOptions(node.children)}</TreeNode>
                )
            } else {
                finalJsx.push(
                    <TreeNode value={node._id} title={node.name} />
                )
            }
        })
        return finalJsx
    }

    const onFinish = () => {
        saveQuickSupportCategory(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/quick-support-category/list"}
                title={mode === "edit" ? "Edit Quick Support Category" : "Add Quick Support Category"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={onFinish} >
                        <Row>
                            <Col span="12">
                                <Form.Item
                                    label="Parent Category (Empty for Root level category)"
                                    name="parent_id"
                                >
                                    <TreeSelect
                                        showSearch
                                        filterTreeNode={(input, treeNode) =>
                                            treeNode.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        style={{ width: '100%' }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder="Blank for root category"
                                        allowClear
                                        treeDefaultExpandAll
                                        onChange={handleTreeSelect}
                                    >
                                        {categoryOptions(getQuickSupportCategoryListState.list)}
                                    </TreeSelect>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span="12">
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input name="name" placeholder="Enter category name" onChange={handleOnChange} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Space>
                            <Button type="primary" htmlType="submit" loading={saveQuickSupportCategoryState.apiState === "loading" ? true : false} >SAVE</Button>
                            {hasChildren ?

                                <Popconfirm
                                    title="You can not delete a parent record when child records exist."
                                    okText="Ok"
                                    cancelText="Cancel"
                                    okType="success"
                                >
                                    <Button type="ghost" >Delete</Button>
                                </Popconfirm>
                                :
                                <Popconfirm
                                    title="Are you sure to delete this item?"
                                    onConfirm={() => deleteQuickSupportCategory({ id: getQuickSupportCategoryDetailState.data._id })}
                                    okText="Delete"
                                    cancelText="Cancel"
                                    okType="danger"
                                >
                                    <Button type="ghost" loading={deleteQuickSupportCategoryState.apiState === "loading" ? true : false} >Delete</Button>
                                </Popconfirm>
                            }
                        </Space>
                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getQuickSupportCategoryListState: state.getQuickSupportCategoryList,
    saveQuickSupportCategoryState: state.saveQuickSupportCategory,
    getQuickSupportCategoryDetailState: state.getQuickSupportCategoryDetail,
    deleteQuickSupportCategoryState: state.deleteQuickSupportCategory,
})

const mapDispatchToProps = (dispatch) => ({
    getQuickSupportCategoryList: () => dispatch(getQuickSupportCategoryList()),
    getQuickSupportCategoryListReset: () => dispatch(getQuickSupportCategoryListReset()),
    saveQuickSupportCategory: (params) => dispatch(saveQuickSupportCategory(params)),
    saveQuickSupportCategoryReset: () => dispatch(saveQuickSupportCategoryReset()),
    getQuickSupportCategoryDetail: (params) => dispatch(getQuickSupportCategoryDetail(params)),
    getQuickSupportCategoryDetailReset: () => dispatch(getQuickSupportCategoryDetailReset()),
    deleteQuickSupportCategory: (params) => dispatch(deleteQuickSupportCategory(params)),
    deleteQuickSupportCategoryReset: () => dispatch(deleteQuickSupportCategoryReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickSupportCategorySave)