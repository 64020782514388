import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_INSTITUTE_TEST_PRICING_MASTER,
    getInstituteTestPricingMasterFail,
    getInstituteTestPricingMasterSuccess
} from '../action/getInstituteTestPricingListAction'

// api 
import getInstituteTestPricingMasterApi from '../api/getInstituteTestPricingMasterApi'

export function* getInstituteTestPricingMasterSaga(action) {
    try {
        const response = yield call(() => getInstituteTestPricingMasterApi(action.params))
        if (response.data.status === 1) {
            yield put(getInstituteTestPricingMasterSuccess(response, action))
        } else {
            yield put(getInstituteTestPricingMasterFail(response, action))
        }
    }
    catch (e) {
        yield put(getInstituteTestPricingMasterFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_INSTITUTE_TEST_PRICING_MASTER, getInstituteTestPricingMasterSaga)
}