import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    CHANGE_INSTITUTE_STATUS,
    changeInstituteStatusFail,
    changeInstituteStatusSuccess
} from '../action/changeInstituteStatus'

// api 
import changeInstituteStatusApi from '../api/changeInstituteStatusApi'

export function* changeInstituteStatusSaga(action) {
    try {
        const response = yield call(() => changeInstituteStatusApi(action.params))
        if (response.data.status === 1) {
            yield put(changeInstituteStatusSuccess(response, action))
        } else {
            yield put(changeInstituteStatusFail(response, action))
        }
    } catch (e) {
        yield put(changeInstituteStatusFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(CHANGE_INSTITUTE_STATUS, changeInstituteStatusSaga)
}