import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_CONTAINER,
    deleteContainerFail,
    deleteContainerSuccess
} from '../action/deleteContainerAction'

// api 
import deleteContainerApi from '../api/deleteContainerApi'

export function* deleteContainerSaga(action) {
    try {
        const response = yield call(() => deleteContainerApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteContainerSuccess(response, action))
        } else {
            yield put(deleteContainerFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteContainerFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_CONTAINER, deleteContainerSaga)
}