import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_ADMIN_TYPE,
    saveAdminTypeFail,
    saveAdminTypeSuccess
} from '../action/saveAdminTypeAction'

// api 
import saveAdminTypeApi from '../api/saveAdminTypeApi'

export function* saveAdminTypeSaga(action) {
    try {
        const response = yield call(() => saveAdminTypeApi(action.params))
        if (response.data.status === 1) {
            yield put(saveAdminTypeSuccess(response, action))
        } else {
            yield put(saveAdminTypeFail(response, action))
        }
    }
    catch (e) {
        yield put(saveAdminTypeFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_ADMIN_TYPE, saveAdminTypeSaga)
}