import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_PATIENT,
    savePatientFail,
    savePatientSuccess
} from '../action/savePatientAction'

// api 
import savePatientApi from '../api/savePatientApi'

export function* savePatientSaga(action) {
    try {
        const response = yield call(() => savePatientApi(action.params))
        if (response.data.status === 1) {
            yield put(savePatientSuccess(response, action))
        } else {
            yield put(savePatientFail(response, action))
        }
    }
    catch (e) {
        yield put(savePatientFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_PATIENT, savePatientSaga)
}