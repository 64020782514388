import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getPageContentDetail, getPageContentDetailReset } from "../../../action/getPageContentDetailAction"
import { dateFormat } from '../../../utils';

const PageContentView = props => {
    /* variable */
    const { getPageContentDetail, getPageContentDetailReset, getPageContentDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getPageContentDetailReset()
        }
    }, [props.visible])

    useEffect(() => {
        if (props.id && props.visible) {
            getPageContentDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`Page Content View ${getPageContentDetailState.apiState === "success" ? "- " + getPageContentDetailState.data.name : ""}`}
            placement="right"
            closeIcon={<><Link to={`/page-content/edit/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getPageContentDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getPageContentDetailState.apiState === "error" &&
                <p>{getPageContentDetailState.message}</p>
            }

            {getPageContentDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Name</ViewLabel>
                                <ViewValue><span>{getPageContentDetailState.data.title}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Slug</ViewLabel>
                                <ViewValue><span>{getPageContentDetailState.data.slug}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="24">
                            <ViewBox>
                                <ViewLabel>Description</ViewLabel>
                                <ViewValue>
                                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(getPageContentDetailState.data.description) }} />
                                </ViewValue>
                            </ViewBox>
                        </Col>

                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getPageContentDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getPageContentDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>

                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getPageContentDetailState: state.getPageContentDetail
});

const mapDispatchToProps = (dispatch) => ({
    getPageContentDetail: (params) => dispatch(getPageContentDetail(params)),
    getPageContentDetailReset: () => dispatch(getPageContentDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(PageContentView);