import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_SAMPLE_CONTAINER_TEST_MAP_LIST,
    getSampleContainerTestMapListFail,
    getSampleContainerTestMapListSuccess
} from '../action/getSampleContainerTestMapListAction'

// api 
import getSampleContainerTestMapListApi from '../api/getSampleContainerTestMapListApi'

export function* getSampleContainerTestMapListSaga(action) {
    try {
        const response = yield call(() => getSampleContainerTestMapListApi(action.params))
        if (response.data.status === 1) {
            yield put(getSampleContainerTestMapListSuccess(response, action))
        } else {
            yield put(getSampleContainerTestMapListFail(response, action))
        }
    }
    catch (e) {
        yield put(getSampleContainerTestMapListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_SAMPLE_CONTAINER_TEST_MAP_LIST, getSampleContainerTestMapListSaga)
}