import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Menu, Dropdown, Card, InputNumber, Switch, DatePicker } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"

/* actions */
import { getCouponList, getCouponListReset } from "../../../action/getCouponListAction"
import { deleteCoupon, deleteCouponReset } from "../../../action/deleteCouponAction"
import CouponView from "../CouponView/CouponView";
import { dateTimeFormat } from "../../../utils";
import Header from "../../../component/Header/Header";

const CouponList = props => {
    /* variables */
    const {
        getCouponList, getCouponListReset, getCouponListState,
        deleteCoupon, deleteCouponReset, deleteCouponState
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name, dataType) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                {dataType === 'Text' &&
                    <Input
                        placeholder={`Search ${name}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                }
                {dataType === 'Number' &&
                    <InputNumber
                        min={0}
                        placeholder={`Search ${name}`}
                        value={selectedKeys[0]}
                        onChange={value => setSelectedKeys(value ? [value] : [])}
                        onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        style={{ marginBottom: 8, display: 'block', width: '100%' }}
                    />
                }
                {dataType === 'Boolean' &&
                    <Switch name="is_percentage"
                        onChange={checked => setSelectedKeys(checked ? ['Yes'] : ['No'])}
                        checked={selectedKeys[0] === 'Yes' ? true : false}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                }
                {dataType === 'DateTime' &&
                    <DatePicker
                        showTime
                        onChange={(value, string) => setSelectedKeys(value ? [value] : [])}
                        style={{ marginBottom: 8, display: 'block', width: '100%' }}
                    />
                }
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
    const columns = [

        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            ...getColumnSearchProps('code', 'Code', 'Text')
        },
        {
            title: 'Valid Till',
            dataIndex: 'valid_till',
            key: 'valid_till',
            ...getColumnSearchProps('valid_till', 'Valid Till', 'DateTime')
        },
        {
            title: 'Minimum Order',
            dataIndex: 'minimum_order',
            key: 'minimum_order',
            ...getColumnSearchProps('minimum_order', 'Minimum Order', 'Number')
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            ...getColumnSearchProps('active', 'Active', 'Boolean')
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/coupon/save/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteCoupon({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    /* callbacks */
    useEffect(() => {
        return (() => {
            getCouponListReset()
        })
    }, [])
    useEffect(() => {
        getCouponList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getCouponListState.apiState === "success") {
            let tableData = []
            getCouponListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        code: row.code,
                        valid_till: dateTimeFormat(row.valid_till),
                        minimum_order: row.minimum_order,
                        active: row.active ? 'Yes' : 'No',
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getCouponListState])

    useEffect(() => {
        setRedirect([true, `/coupon/list?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteCouponState.apiState === "success") {
            deleteCouponReset()
            notification["success"]({
                message: deleteCouponState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteCouponState.apiState === "error") {
            deleteCouponReset()
            notification["error"]({
                message: deleteCouponState.message,
                placement: "topRight"
            })
        }
    }, [deleteCouponState])
    // Functions

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Coupon List"
                rightChildren={
                    <>
                        <Link to="/coupon/save" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                {getCouponListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getCouponListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getCouponListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getCouponListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getCouponListState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <CouponView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getCouponListState: state.getCouponList,
    deleteCouponState: state.deleteCoupon
});

const mapDispatchToProps = (dispatch) => ({
    getCouponList: (params) => dispatch(getCouponList(params)),
    getCouponListReset: () => dispatch(getCouponListReset()),
    deleteCoupon: (params) => dispatch(deleteCoupon(params)),
    deleteCouponReset: () => dispatch(deleteCouponReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(CouponList);