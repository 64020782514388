import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_SUB_GROUP_LIST,
    getSubGroupListFail,
    getSubGroupListSuccess
} from '../action/getSubGroupListAction'

// api 
import getSubGroupListApi from '../api/getSubGroupListApi'

export function* getSubGroupListSaga(action) {
    try {
        const response = yield call(() => getSubGroupListApi(action.params))
        if (response.data.status === 1) {
            yield put(getSubGroupListSuccess(response, action))
        } else {
            yield put(getSubGroupListFail(response, action))
        }
    }
    catch (e) {
        yield put(getSubGroupListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_SUB_GROUP_LIST, getSubGroupListSaga)
}