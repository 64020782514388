import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, InputNumber, DatePicker, Popconfirm, Space, Card } from "antd"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash"
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer, Xtable } from "../../../component/Xcomponent"
import { Xcard, CustomTabs, CustomTabPane } from './PregnancyHighlightsStyle'
/* actions */
import { savePregnancyHighlights, savePregnancyHighlightsReset } from "../../../action/savePregnancyHighlightsAction"
import { getPregnancyHighlightsDetail, getPregnancyHighlightsDetailReset } from "../../../action/getPregnancyHighlightsDetailAction"

/* otehrs */
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { validateImageFile, getS3SingedUrl, s3_cdn } from '../../../utils'
import ContentRepoList from "../../../component/ContentRepo/ContentRepoList/ContentRepoList";

const { TextArea } = Input

export const PregnancyHighlightsAdd = props => {
    /* variables */
    const pregnancy_highlights_id = props.match.params.pregnancy_highlights_id
    const mode = pregnancy_highlights_id ? "edit" : "add"

    const [highlightsMomColumns, setHighlightsMomColumns] = useState([])
    const [highlightsMomDataSource, setHighlightsMomDataSource] = useState([])
    const [highlightsMomRefresh, setHighlightsMomRefresh] = useState(0)

    const [highlightsBabyColumns, setHighlightsBabyColumns] = useState([])
    const [highlightsBabyDataSource, setHighlightsBabyDataSource] = useState([])
    const [highlightsBabyRefresh, setHighlightsBabyRefresh] = useState(0)

    const [precautionsMomColumns, setPrecautionsMomColumns] = useState([])
    const [precautionsMomDataSource, setPrecautionsMomDataSource] = useState([])
    const [precautionsMomRefresh, setPrecautionsMomRefresh] = useState(0)

    const [precautionsBabyColumns, setPrecautionsBabyColumns] = useState([])
    const [precautionsBabyDataSource, setPrecautionsBabyDataSource] = useState([])
    const [precautionsBabyRefresh, setPrecautionsBabyRefresh] = useState(0)

    const [recommendationMomColumns, setRecommendationMomColumns] = useState([])
    const [recommendationMomDataSource, setRecommendationMomDataSource] = useState([])
    const [recommendationMomRefresh, setRecommendationMomRefresh] = useState(0)

    const [recommendationBabyColumns, setRecommendationBabyColumns] = useState([])
    const [recommendationBabyDataSource, setRecommendationBabyDataSource] = useState([])
    const [recommendationBabyRefresh, setRecommendationBabyRefresh] = useState(0)
    const [refresh, setRefresh] = useState(0)
    const [defaultActiveKey, setDefaultActiveKey] = useState("weekly-progress")
    const {
        savePregnancyHighlights, savePregnancyHighlightsReset, savePregnancyHighlightsState,
        getPregnancyHighlightsDetail, getPregnancyHighlightsDetailReset, getPregnancyHighlightsDetailState
    } = props
    const [formData, setFormData] = useState({
        week: "",
        weight: "",
        height: "",
        size: "",
        size_image_path: "",
        size_image_changed: "",
        days_left_for_delivery: "",
        highlights_of_the_week_mom: "",
        highlights_of_the_week_mom_index: "",
        highlights_of_the_week_baby: "",
        highlights_of_the_week_baby_index: "",
        highlights_of_the_week: { "mom": [], "baby": [] },
        precautions_mom: "",
        precautions_mom_index: "",
        precautions_baby: "",
        precautions_baby_index: "",
        precautions: { "mom": [], "baby": [] },
        recommendation_for_you: { "mom": [], "baby": [] },
        recommendation_for_you_mom: "",
        recommendation_for_you_mom_index: "",
        recommendation_for_you_baby: "",
        recommendation_for_you_baby_index: "",
        description_for_baby: "",
        description_for_mom: "",
    })
    if (mode === "edit") {
        formData.id = pregnancy_highlights_id
        formData.mode = mode
    }
    const [sizeImage, setSizeImage] = useState({
        fileName: null,
        isChanged: false,
        edit: true
    })
    const [imageFilePath, setImageFilePath] = useState("")
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    /* callbacks */
    useEffect(() => {
        setHighlightsBabyColumns([
            {
                title: "Highlights",
                dataIndex: "value"
            },
            {
                title: "Actions",
                dataIndex: "action",
                render: item => (
                    <>
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EditOutlined />} onClick={() => editHighLightsBaby(item.name, item.key)} />
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => deleteHighLightsBaby(item.name)}
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ])
        let rows = []
        formData.highlights_of_the_week.baby.map((item, index) => {
            rows.push({
                value: item,
                action: { name: item, key: index },
            })
        })
        setHighlightsBabyDataSource(rows)
    }, [highlightsBabyRefresh, refresh])

    useEffect(() => {
        setHighlightsMomColumns([
            {
                title: "Highlights",
                dataIndex: "value"
            },
            {
                title: "Actions",
                dataIndex: "action",
                render: item => (
                    <>
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EditOutlined />} onClick={() => editHighLightsMom(item.name, item.key)} />
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => deleteHighLightsMom(item.name)}
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ])
        let rows = []
        formData.highlights_of_the_week.mom.map((item, index) => {
            rows.push({
                value: item,
                action: { name: item, key: index },
            })
        })
        setHighlightsMomDataSource(rows)
    }, [highlightsMomRefresh, refresh])

    useEffect(() => {
        setPrecautionsMomColumns([
            {
                title: "Precautions",
                dataIndex: "value"
            },
            {
                title: "Actions",
                dataIndex: "action",
                render: item => (
                    <>
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EditOutlined />} onClick={() => editPrecautionsMom(item.name, item.key)} />
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => deletePrecautionsMom(item.name)}
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ])
        let rows = []
        formData.precautions.mom.map((item, index) => {
            rows.push({
                value: item,
                action: { name: item, key: index },
            })
        })
        setPrecautionsMomDataSource(rows)
    }, [precautionsMomRefresh, refresh])

    useEffect(() => {
        setPrecautionsBabyColumns([
            {
                title: "Precautions",
                dataIndex: "value"
            },
            {
                title: "Actions",
                dataIndex: "action",
                render: item => (
                    <>
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EditOutlined />} onClick={() => editPrecautionsBaby(item.name, item.key)} />
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => deletePrecautionsBaby(item.name)}
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ])
        let rows = []
        formData.precautions.baby.map((item, index) => {
            rows.push({
                value: item,
                action: { name: item, key: index },
            })
        })
        setPrecautionsBabyDataSource(rows)
    }, [precautionsBabyRefresh, refresh])

    useEffect(() => {
        setRecommendationBabyColumns([
            {
                title: "Recommendation",
                dataIndex: "value"
            },
            {
                title: "Actions",
                dataIndex: "action",
                render: item => (
                    <>
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EditOutlined />} onClick={() => editRecommendationBaby(item.name, item.key)} />
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => deleteRecommendationBaby(item.name)}
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ])
        let rows = []
        formData.recommendation_for_you.baby.map((item, index) => {
            rows.push({
                value: item,
                action: { name: item, key: index },
            })
        })
        setRecommendationBabyDataSource(rows)
    }, [recommendationBabyRefresh, refresh])

    useEffect(() => {
        setRecommendationMomColumns([
            {
                title: "Recommendation",
                dataIndex: "value"
            },
            {
                title: "Actions",
                dataIndex: "action",
                render: item => (
                    <>
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EditOutlined />} onClick={() => editRecommendationMom(item.name, item.key)} />
                            <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => deleteRecommendationMom(item.name)}
                                okText="Delete"
                                cancelText="Cancel"
                                okType="danger"
                            >
                                <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Space>
                    </>
                )
            },
        ])
        let rows = []
        formData.recommendation_for_you.mom.map((item, index) => {
            rows.push({
                value: item,
                action: { name: item, key: index },
            })
        })
        setRecommendationMomDataSource(rows)
    }, [recommendationMomRefresh, refresh])


    useEffect(() => {
        return (() => {
            savePregnancyHighlightsReset()
            getPregnancyHighlightsDetailReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getPregnancyHighlightsDetail({
                id: pregnancy_highlights_id
            })
        }
    }, [])

    useEffect(() => {
        if (getPregnancyHighlightsDetailState.apiState === "success") {
            form.setFieldsValue({
                week: getPregnancyHighlightsDetailState.data.week,
                weight: getPregnancyHighlightsDetailState.data.weight,
                height: getPregnancyHighlightsDetailState.data.height,
                size: getPregnancyHighlightsDetailState.data.size,
                days_left_for_delivery: getPregnancyHighlightsDetailState.data.days_left_for_delivery,
                description_for_baby: getPregnancyHighlightsDetailState.data.description_for_baby,
                description_for_mom: getPregnancyHighlightsDetailState.data.description_for_mom,
            })
            setFormData({
                ...formData,
                ['week']: getPregnancyHighlightsDetailState.data.week,
                ['weight']: getPregnancyHighlightsDetailState.data.weight,
                ['height']: getPregnancyHighlightsDetailState.data.height,
                ['size']: getPregnancyHighlightsDetailState.data.size,
                ['days_left_for_delivery']: getPregnancyHighlightsDetailState.data.days_left_for_delivery,
                ['highlights_of_the_week']: getPregnancyHighlightsDetailState.data.highlights_of_the_week,
                ['precautions']: getPregnancyHighlightsDetailState.data.precautions,
                ['recommendation_for_you']: getPregnancyHighlightsDetailState.data.recommendation_for_you,
                ['size_image_path']: getPregnancyHighlightsDetailState.data.size_image_path,
                ['description_for_baby']: getPregnancyHighlightsDetailState.data.description_for_baby,
                ['description_for_mom']: getPregnancyHighlightsDetailState.data.description_for_mom,
                ['size_image_changed']: false,
            })
            setImageFilePath(getPregnancyHighlightsDetailState.data.size_image_path)
            setRefresh(refresh + 1)
        }
    }, [getPregnancyHighlightsDetailState])

    useEffect(() => {
        if (savePregnancyHighlightsState.apiState === "success") {
            savePregnancyHighlightsReset()
            setRedirect([true, "/pregnancy-highlights/list"])
            notification["success"]({
                message: savePregnancyHighlightsState.message,
                placement: "topRight"
            })
        }
        if (savePregnancyHighlightsState.apiState === "error") {
            savePregnancyHighlightsReset()
            notification["error"]({
                message: savePregnancyHighlightsState.message,
                placement: "topRight"
            })
        }
    }, [savePregnancyHighlightsState])

    /* functions */

    const deleteHighLightsBaby = (item) => {
        let remainData = _.remove(formData.highlights_of_the_week.baby, function (value) {
            return value === item;
        })
        setFormData({ ...formData, ["highlights_of_the_week.baby"]: remainData })
        setHighlightsBabyRefresh(highlightsBabyRefresh + 1)
    }
    const editHighLightsBaby = (name, index) => {
        setFormData({ ...formData, ["highlights_of_the_week_baby"]: name, ["highlights_of_the_week_baby_index"]: index })
        form.setFieldsValue({
            highlights_of_the_week_baby: name
        })
    }

    const deleteHighLightsMom = (item) => {
        let remainData = _.remove(formData.highlights_of_the_week.mom, function (value) {
            return value === item;
        })

        setFormData({ ...formData, ["highlights_of_the_week.mom"]: remainData })
        setHighlightsMomRefresh(highlightsMomRefresh + 1)
    }
    const editHighLightsMom = (name, index) => {
        setFormData({ ...formData, ["highlights_of_the_week_mom"]: name, ["highlights_of_the_week_mom_index"]: index })
        form.setFieldsValue({
            highlights_of_the_week_mom: name
        })
    }

    const deletePrecautionsMom = (item) => {
        let remainData = _.remove(formData.precautions.mom, function (value) {
            return value === item;
        })
        setFormData({ ...formData, ["precautions.mom"]: remainData })
        setPrecautionsMomRefresh(precautionsMomRefresh + 1)
    }
    const editPrecautionsMom = (name, index) => {
        setFormData({ ...formData, ["precautions_mom"]: name, ["precautions_mom_index"]: index })
        form.setFieldsValue({
            precautions_mom: name
        })
    }

    const deletePrecautionsBaby = (item) => {
        let remainData = _.remove(formData.precautions.baby, function (value) {
            return value === item;
        })
        setFormData({ ...formData, ["precautions.baby"]: remainData })
        setPrecautionsBabyRefresh(precautionsBabyRefresh + 1)
    }
    const editPrecautionsBaby = (name, index) => {
        setFormData({ ...formData, ["precautions_baby"]: name, ["precautions_baby_index"]: index })
        form.setFieldsValue({
            precautions_baby: name
        })
    }

    const deleteRecommendationMom = (item) => {
        let remainData = _.remove(formData.recommendation_for_you.mom, function (value) {
            return value === item;
        })
        setFormData({ ...formData, ["recommendation_for_you.mom"]: remainData })
        setRecommendationMomRefresh(recommendationMomRefresh + 1)
    }
    const editRecommendationMom = (name, index) => {
        setFormData({ ...formData, ["recommendation_for_you_mom"]: name, ["recommendation_for_you_mom_index"]: index })
        form.setFieldsValue({
            recommendation_for_you_mom: name
        })
    }

    const deleteRecommendationBaby = (item) => {
        let remainData = _.remove(formData.recommendation_for_you.baby, function (value) {
            return value === item;
        })
        setFormData({ ...formData, ["recommendation_for_you.baby"]: remainData })
        setRecommendationBabyRefresh(recommendationBabyRefresh + 1)
    }

    const editRecommendationBaby = (name, index) => {
        setFormData({ ...formData, ["recommendation_for_you_baby"]: name, ["recommendation_for_you_baby_index"]: index })
        form.setFieldsValue({
            recommendation_for_you_baby: name
        })
    }


    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }



    const handleOnChangeHeighlightBaby = e => {
        if (formData.highlights_of_the_week_baby) {
            let baby = formData.highlights_of_the_week.baby
            baby.push(formData.highlights_of_the_week_baby)
            setFormData({ ...formData, ["highlights_of_the_week.baby"]: baby, ["highlights_of_the_week_baby"]: "" })
            form.setFieldsValue({
                highlights_of_the_week_baby: ""
            })
            setHighlightsBabyRefresh(highlightsBabyRefresh + 1)
        }
    }

    const handleOnChangeHeighlightBabyUpdate = e => {
        if (formData.highlights_of_the_week_baby) {
            formData.highlights_of_the_week.baby[formData.highlights_of_the_week_baby_index] = formData.highlights_of_the_week_baby
            setFormData({ ...formData, ["highlights_of_the_week_baby"]: "", ["highlights_of_the_week_baby_index"]: "" })
            form.setFieldsValue({
                highlights_of_the_week_baby: ""
            })
            setHighlightsBabyRefresh(highlightsBabyRefresh + 1)
        }
    }


    const handleOnChangeHeighlightMom = e => {
        if (formData.highlights_of_the_week_mom) {
            let mom = formData.highlights_of_the_week.mom
            mom.push(formData.highlights_of_the_week_mom)
            setFormData({ ...formData, ["highlights_of_the_week.mom"]: mom, ["highlights_of_the_week_mom"]: "" })
            form.setFieldsValue({
                highlights_of_the_week_mom: ""
            })
            setHighlightsMomRefresh(highlightsMomRefresh + 1)
        }
    }
    const handleOnChangeHeighlightMomUpdate = e => {
        if (formData.highlights_of_the_week_mom) {
            formData.highlights_of_the_week.mom[formData.highlights_of_the_week_mom_index] = formData.highlights_of_the_week_mom
            setFormData({ ...formData, ["highlights_of_the_week_mom"]: "", ["highlights_of_the_week_mom_index"]: "" })
            form.setFieldsValue({
                highlights_of_the_week_mom: ""
            })
            setHighlightsMomRefresh(highlightsMomRefresh + 1)
        }
    }

    const handleOnChangePrecautionsBaby = e => {
        if (formData.precautions_baby) {
            let baby = formData.precautions.baby
            baby.push(formData.precautions_baby)
            setFormData({ ...formData, ["precautions.baby"]: baby, ["precautions_baby"]: "" })
            form.setFieldsValue({
                precautions_baby: ""
            })
            setPrecautionsBabyRefresh(precautionsBabyRefresh + 1)
        }
    }
    const handleOnChangePrecautionsBabyUpdate = e => {
        if (formData.precautions_baby) {
            formData.precautions.baby[formData.precautions_baby_index] = formData.precautions_baby
            setFormData({ ...formData, ["precautions_baby"]: "", ["precautions_baby_index"]: "" })
            form.setFieldsValue({
                precautions_baby: ""
            })
            setPrecautionsBabyRefresh(precautionsBabyRefresh + 1)
        }
    }

    const handleOnChangePrecautionsMom = e => {
        if (formData.precautions_mom) {
            let mom = formData.precautions.mom
            mom.push(formData.precautions_mom)
            setFormData({ ...formData, ["precautions.mom"]: mom, ["precautions_mom"]: "" })
            form.setFieldsValue({
                precautions_mom: ""
            })
            setPrecautionsMomRefresh(precautionsMomRefresh + 1)
        }
    }
    const handleOnChangePrecautionsMomUpdate = e => {
        if (formData.precautions_mom) {
            formData.precautions.mom[formData.precautions_mom_index] = formData.precautions_mom
            setFormData({ ...formData, ["precautions_mom"]: "", ["precautions_mom_index"]: "" })
            form.setFieldsValue({
                precautions_mom: ""
            })
            setPrecautionsMomRefresh(precautionsMomRefresh + 1)
        }
    }

    const handleOnChangeRecommendationBaby = e => {
        if (formData.recommendation_for_you_baby) {
            let baby = formData.recommendation_for_you.baby
            baby.push(formData.recommendation_for_you_baby)
            setFormData({ ...formData, ["recommendation_for_you.baby"]: baby, ["recommendation_for_you_baby"]: "" })
            form.setFieldsValue({
                recommendation_for_you_baby: ""
            })
            setRecommendationBabyRefresh(recommendationBabyRefresh + 1)
        }
    }
    const handleOnChangeRecommendationBabyUpdate = e => {
        if (formData.recommendation_for_you_baby) {
            formData.recommendation_for_you.baby[formData.recommendation_for_you_baby_index] = formData.recommendation_for_you_baby
            setFormData({ ...formData, ["recommendation_for_you_baby"]: "", ["recommendation_for_you_baby_index"]: "" })
            form.setFieldsValue({
                recommendation_for_you_baby: ""
            })
            setRecommendationBabyRefresh(recommendationBabyRefresh + 1)
        }
    }

    const handleOnChangeRecommendationMom = e => {
        if (formData.recommendation_for_you_mom) {
            let mom = formData.recommendation_for_you.mom
            mom.push(formData.recommendation_for_you_mom)
            setFormData({ ...formData, ["recommendation_for_you.mom"]: mom, ["recommendation_for_you_mom"]: "" })
            form.setFieldsValue({
                recommendation_for_you_mom: ""
            })
            setRecommendationMomRefresh(recommendationMomRefresh + 1)
        }
    }
    const handleOnChangeRecommendationMomUpdate = e => {
        if (formData.recommendation_for_you_mom) {
            formData.recommendation_for_you.mom[formData.recommendation_for_you_mom_index] = formData.recommendation_for_you_mom
            setFormData({ ...formData, ["recommendation_for_you_mom"]: "", ["recommendation_for_you_mom_index"]: "" })
            form.setFieldsValue({
                recommendation_for_you_mom: ""
            })
            setRecommendationMomRefresh(recommendationMomRefresh + 1)
        }
    }

    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }


    const handleSubmit = async () => {
        if (sizeImage.isChanged) {
            let sizefilename = uuidv4()
            let iconValidationRes = validateImageFile(sizeImage.fileName)
            if (iconValidationRes.status) {
                let s3UrlResIcon = await getS3SingedUrl(sizefilename, iconValidationRes.ext, sizeImage, 'pregnancy-data')
                await axios.put(s3UrlResIcon.url, sizeImage.fileName)
                formData.size_image_path = `${sizefilename}.${iconValidationRes.ext}`
                formData.size_image_changed = true
            }
            else {
                alert("Select image type JPG or PNG.")
                return
            }
        }
        else {
            formData.size_image_changed = false
        }
        savePregnancyHighlights(formData)
    }

    const callback = (key) => {
        setDefaultActiveKey(key)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/pregnancy-highlights/list"}
                title={mode === "edit" ? "Edit Pregnancy Data" : "Add Pregnancy Data"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <CustomTabs tabPosition="top" activeKey={defaultActiveKey} size="small" onChange={callback}>
                            <CustomTabPane tab={`Weekly Progress`} key={`weekly-progress`} >
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Week"
                                            name="week"
                                            rules={[
                                                { required: true, message: 'Required' },
                                            ]}
                                        >
                                            <Select
                                                name="week"
                                                placeholder="Select Week"
                                                showSearch
                                                allowClear
                                                disabled={mode === 'edit'}
                                                onChange={(v) => handleSelect("week", v)}
                                            >
                                                {[...Array(40)].map((week, index) => {
                                                    return (
                                                        <Select.Option value={`${index + 1}`} key={`${index}`}>{index + 1}</Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Weight"
                                            name="weight"
                                            rules={[
                                                { required: true, message: 'Required' },
                                            ]}
                                        >
                                            <Input name="weight" placeholder="Enter Weight"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Height"
                                            name="height"
                                            rules={[
                                                { required: true, message: 'Required' },
                                            ]}
                                        >
                                            <Input name="height" placeholder="Enter Height"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Days Left for Delivery"
                                            name="days_left_for_delivery"
                                            rules={[
                                                { required: true, message: 'Required' },
                                            ]}
                                        >
                                            <InputNumber name="days_left_for_delivery" placeholder="Enter Days Left for Delivery"
                                                onChange={(v) => handleSelect("days_left_for_delivery", v)} style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Size"
                                            name="size"
                                            rules={[
                                                { required: true, message: 'Required' },
                                            ]}
                                        >
                                            <Input name="size" placeholder="Enter Size"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Size Image"
                                            name="size_image_path"
                                            rules={[
                                                { required: (mode === 'edit' && sizeImage.edit) ? sizeImage.isChanged : !sizeImage.isChanged, message: 'Required' },
                                            ]}
                                        >
                                            <Input name="size_image_path" placeholder="Enter Size Image" type="file"
                                                onChange={e => setSizeImage({
                                                    fileName: e.target.files[0],
                                                    isChanged: e.target.files[0] ? true : false,
                                                    edit: false
                                                })}
                                                accept="image/png, image/jpeg"
                                                suffix={(imageFilePath && mode === 'edit') ? <img src={`${s3_cdn()}pregnancy-data/${formData.size_image_path}`} style={{ width: 26 }} /> : null}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </CustomTabPane>
                            <CustomTabPane tab={`Highlights of The Week`} key={`highlights`} >
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Xcard title="Description for Baby">
                                            <Form.Item
                                                name="description_for_baby"
                                            >
                                                <Input.TextArea rows={5} name="description_for_baby" placeholder="Enter Description for Baby" onChange={handleOnChange} />
                                            </Form.Item>
                                        </Xcard>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Xcard title="Description for Mom">
                                            <Form.Item
                                                name="description_for_mom"
                                            >
                                                <Input.TextArea rows={5} name="description_for_mom" placeholder="Enter Description for Mom" onChange={handleOnChange} />
                                            </Form.Item>
                                        </Xcard>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Xcard title="Highlights of The Week for Baby">
                                            <Form.Item
                                                name="highlights_of_the_week_baby"
                                            >
                                                <Input name="highlights_of_the_week_baby" placeholder="Enter Highlights of The Week for Baby" onChange={handleOnChange}
                                                    suffix={formData.highlights_of_the_week_baby_index !== "" ? <Button type="primary" size="small" onClick={handleOnChangeHeighlightBabyUpdate}>UPDATE</Button> : <Button type="primary" size="small" onClick={handleOnChangeHeighlightBaby}>ADD</Button>}
                                                />
                                            </Form.Item>
                                            <Xtable dataSource={highlightsBabyDataSource} columns={highlightsBabyColumns} defaultPageSize={5} />
                                        </Xcard>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Xcard title="Highlights of The Week for Mom">
                                            <Form.Item
                                                name="highlights_of_the_week_mom"
                                            >
                                                <Input name="highlights_of_the_week_mom" placeholder="Enter Highlights of The Week for Mom" onChange={handleOnChange}
                                                    suffix={formData.highlights_of_the_week_mom_index !== "" ? <Button type="primary" size="small" onClick={handleOnChangeHeighlightMomUpdate}>UPDATE</Button> : <Button type="primary" size="small" onClick={handleOnChangeHeighlightMom}>ADD</Button>}
                                                />
                                            </Form.Item>
                                            <Xtable dataSource={highlightsMomDataSource} columns={highlightsMomColumns} defaultPageSize={5} />
                                        </Xcard>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Xcard title="Precautions for Baby">
                                            <Form.Item
                                                name="precautions_baby"
                                            >
                                                <Input name="precautions_baby" placeholder="Enter Precautions for Baby" onChange={handleOnChange}
                                                    suffix={formData.precautions_baby_index !== "" ? <Button type="primary" size="small" onClick={handleOnChangePrecautionsBabyUpdate}>UPDATE</Button> : <Button type="primary" size="small" onClick={handleOnChangePrecautionsBaby}>ADD</Button>}
                                                />
                                            </Form.Item>
                                            <Xtable dataSource={precautionsBabyDataSource} columns={precautionsBabyColumns} defaultPageSize={5} />
                                        </Xcard>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Xcard title="Precautions for Mom">
                                            <Form.Item
                                                name="precautions_mom"
                                            >
                                                <Input name="precautions_mom" placeholder="Enter Precautions for Mom" onChange={handleOnChange}
                                                    suffix={formData.precautions_mom_index !== "" ? <Button type="primary" size="small" onClick={handleOnChangePrecautionsMomUpdate}>UPDATE</Button> : <Button type="primary" size="small" onClick={handleOnChangePrecautionsMom}>ADD</Button>}
                                                />
                                            </Form.Item>
                                            <Xtable dataSource={precautionsMomDataSource} columns={precautionsMomColumns} defaultPageSize={5} />
                                        </Xcard>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Xcard title="Recommendation for Baby">
                                            <Form.Item
                                                name="recommendation_for_you_baby"
                                            >
                                                <Input name="recommendation_for_you_baby" placeholder="Enter Recommendation for Baby" onChange={handleOnChange}
                                                    suffix={formData.recommendation_for_you_baby_index !== "" ? <Button type="primary" size="small" onClick={handleOnChangeRecommendationBabyUpdate}>UPDATE</Button> : <Button type="primary" size="small" onClick={handleOnChangeRecommendationBaby}>ADD</Button>}
                                                />
                                            </Form.Item>
                                            <Xtable dataSource={recommendationBabyDataSource} columns={recommendationBabyColumns} defaultPageSize={5} />
                                        </Xcard>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Xcard title="Recommendation for Mom">
                                            <Form.Item
                                                name="recommendation_for_you_mom"
                                            >
                                                <Input name="recommendation_for_you_mom" placeholder="Enter Recommendation for Mom" onChange={handleOnChange}
                                                    suffix={formData.recommendation_for_you_mom_index !== "" ? <Button type="primary" size="small" onClick={handleOnChangeRecommendationMomUpdate}>UPDATE</Button> : <Button type="primary" size="small" onClick={handleOnChangeRecommendationMom}>ADD</Button>}
                                                />
                                            </Form.Item>
                                            <Xtable dataSource={recommendationMomDataSource} columns={recommendationMomColumns} defaultPageSize={5} />
                                        </Xcard>
                                    </Col>
                                </Row>
                            </CustomTabPane>
                            {mode === 'edit' &&
                                <CustomTabPane tab={`Content Repo Pregnancy`} key={`content-repo`} >
                                    <ContentRepoList heighlightsWeek={formData.week} refreshPageUrl={`pregnancy-highlights/edit/${pregnancy_highlights_id}`} pageUrl={`content-repo-pregnancy`} pageType="Pregnancy" />
                                </CustomTabPane>
                            }
                        </CustomTabs>
                        <Space>
                            {mode === 'edit' ?
                                mode === 'edit' && defaultActiveKey === 'content-repo' ? null : <Button type="primary" htmlType="submit">SUBMIT</Button> :
                                defaultActiveKey === 'highlights' ? <Button type="primary" htmlType="submit">SUBMIT</Button> : null
                            }
                            {defaultActiveKey === 'weekly-progress' ? <Button type="primary" onClick={() => setDefaultActiveKey('highlights')} >NEXT</Button> : null}
                        </Space>
                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    savePregnancyHighlightsState: state.savePregnancyHighlights,
    getPregnancyHighlightsDetailState: state.getPregnancyHighlightsDetail
});

const mapDispatchToProps = (dispatch) => ({
    savePregnancyHighlights: (params) => dispatch(savePregnancyHighlights(params)),
    savePregnancyHighlightsReset: () => dispatch(savePregnancyHighlightsReset()),
    getPregnancyHighlightsDetail: (params) => dispatch(getPregnancyHighlightsDetail(params)),
    getPregnancyHighlightsDetailReset: () => dispatch(getPregnancyHighlightsDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(PregnancyHighlightsAdd);