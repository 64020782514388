import React, { useEffect, useState } from "react"
import { Layout, Menu, Alert, Button, Form, Input, Space } from "antd"
import { connect } from "react-redux"
import { Redirect, Link, useHistory  } from "react-router-dom"
/* custom component */
import { Container, Box, ContainerHeader, Left, Right, LoginBox, XContent, SiteLayoutBackground } from './LoginStyle'
import LoginHeader from '../../component/LoginHeader/LoginHeader';

/* Actions */
import { login } from '../../action/loginAction'

const { Footer } = Layout;

const Login = props => {

    // variables
    const {
        login, loginState,
    } = props

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    })
    const [redirect, setRedirect] = useState(false)
    let history = useHistory();
    const location = {
        pathname: '/reset-password',
        state: {reset:false,email:'',password:''}
    }

    /* Callback */
    useEffect(() => {
        if (loginState.apiState === "success" && loginState.message === "Reset") {
            location.state.email = formData.email
            location.state.password = formData.password
            loginState.apiState=""
            history.push(location)

           // router.push("result_page")
        }
        else if (loginState.apiState === "success") {
            loginState.apiState = ""
            localStorage.setItem("LilacUserToken", loginState.data.token)
            localStorage.setItem("LilacUser", JSON.stringify(loginState.data.user))
            localStorage.setItem("LilacRoleRights", JSON.stringify(loginState.data.roleModuleMappingMaster?.rights))
            setRedirect(true)
        }

        else {
            loginState.apiState = ""
        }
    }, [loginState.apiState])

    // functions
    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onFormSubmit = () => {
        login(formData)
    }

    return (
        <>
            {redirect &&
                <Redirect to="/"  />
            }
            <Layout style={{ backgroundColor: "#fff" }}>
                <LoginHeader />
                <XContent className="site-layout">
                    <SiteLayoutBackground className="site-layout-background">
                        <Left>
                            <img height='100%' width='100%' src={process.env.PUBLIC_URL + "/images/svg/AdminPanle.svg"} alt="" />
                        </Left>
                        <Right>
                            <LoginBox>
                                {loginState.apiState === "error" &&
                                    <Alert style={{ marginBottom: 8 }} message={loginState.message} type="error" showIcon />}

                                <Form layout="vertical" onFinish={onFormSubmit}>
                                    <Form.Item
                                        label="Super Admin Email"
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Required' },
                                            { type: 'email', message: 'Email is not valid' },
                                        ]}
                                    >
                                        <Input
                                            name="email"
                                            placeholder="Email"
                                            onChange={handleOnChange}
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Link style={{ float: "right" }} to={{ pathname: "/reset-password", state: {reset:true} }}>
                                        <span> Reset Password?</span>
                                    </Link>
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[{ required: true, message: 'Required' }]}
                                        style={{ width: "100%" }}
                                    >
                                       
                                       
                                        <Input.Password name="password" placeholder="Password" onChange={handleOnChange}
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Button htmlType="submit" type="primary"
                                        size="large"
                                        loading={loginState.apiState === "loading" ? true : false}
                                        block >LOGIN</Button>
                                </Form>
                            </LoginBox>
                        </Right>
                    </SiteLayoutBackground>
                </XContent>
                <Footer style={{ textAlign: 'center', position: "fixed", bottom: 0, width: "100%", backgroundColor: "#15558d", color: "#fff" }}>Lilac Insights ©2021</Footer>
            </Layout>
        </>
    )
}

const mapStateToProps = (state) => ({
    loginState: state.login
})

const mapDispatchToProps = (dispatch) => ({
    login: (params) => dispatch(login(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)