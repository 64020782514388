import { Space, Layout } from 'antd'
import styled from "styled-components";
const { Header } = Layout;


export const Xheader = styled(Header)`
    height: 85px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 30px;
    display: flex;
    align-items: center;
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 1;
`