import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_EMPLOYEE,
    saveEmployeeFail,
    saveEmployeeSuccess
} from '../action/saveEmployeeAction'

// api 
import saveEmployeeApi from '../api/saveEmployeeApi'

export function* saveEmployeeSaga(action) {
    try {
        const response = yield call(() => saveEmployeeApi(action.params))
        if (response.data.status === 1) {
            yield put(saveEmployeeSuccess(response, action))
        } else {
            yield put(saveEmployeeFail(response, action))
        }
    }
    catch (e) {
        yield put(saveEmployeeFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_EMPLOYEE, saveEmployeeSaga)
}