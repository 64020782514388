import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Menu, Dropdown } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"

/* actions */
import { getSampleList, getSampleListReset } from "../../../action/getSampleListAction"
import { deleteSample, deleteSampleReset } from "../../../action/deleteSampleAction"
import SampleView from "../SampleView/SampleView";

const SampleList = props => {
    /* variables */
    const {
        getSampleList, getSampleListReset, getSampleListState,
        deleteSample, deleteSampleReset, deleteSampleState
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
    const columns = [
        {
            title: 'LIMS Sample ID',
            dataIndex: 'lims_id',
            key: 'lims_id',
            ...getColumnSearchProps('lims_id', 'Sample ID')
        },
        {
            title: 'Sample Type',
            dataIndex: 'sample_type',
            key: 'sample_type',
            render: sample_type => (<span>{sample_type}</span>),
            ...getColumnSearchProps('sample_type', 'Sample Type')
        },
        {
            title: 'Sample Transport Condition',
            dataIndex: 'sample_transport_condition',
            key: 'sample_transport_condition',
            ...getColumnSearchProps('sample_transport_condition', 'Sample Transport Condition')
        },
        {
            title: 'PCPNDT Applicable',
            dataIndex: 'pcpndp_applicable',
            key: 'pcpndp_applicable',
            ...getColumnSearchProps('pcpndp_applicable', 'Pcpndp Applicable')
        },
        {
            title: 'Home Collection Possible',
            dataIndex: 'home_collection_possible',
            key: 'home_collection_possible',
            ...getColumnSearchProps('home_collection_possible', 'Home Collection Possible')
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/sample-master/edit/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteSample({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    /* callbacks */
    useEffect(() => {
        return (() => {
            getSampleListReset()
        })
    }, [])
    useEffect(() => {
        getSampleList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getSampleListState.apiState === "success") {
            let tableData = []
            getSampleListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        lims_id: row.lims_id,
                        sample_type: row.sample_type,
                        pcpndp_applicable: row.pcpndp_applicable,
                        home_collection_possible: row.home_collection_possible,
                        sample_transport_condition: row.sample_transport_condition,
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getSampleListState])

    useEffect(() => {
        setRedirect([true, `/sample-master/list?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteSampleState.apiState === "success") {
            deleteSampleReset()
            notification["success"]({
                message: deleteSampleState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteSampleState.apiState === "error") {
            deleteSampleReset()
            notification["error"]({
                message: deleteSampleState.message,
                placement: "topRight"
            })
        }
    }, [deleteSampleState])
    // Functions
    // const menu = (
    //     <Menu>
    //         <Menu.Item>
    //             <Link to="/common-master/test-group">
    //                 Test Group
    //             </Link>
    //         </Menu.Item>
    //     </Menu>
    // )

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Sample List"
                rightChildren={
                    <>
                        {/* <Dropdown overlay={menu} placement="bottomCenter" arrow>
                            <Button>Common Master</Button>
                        </Dropdown> */}
                        <Link to="/sample-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Upload</Button></Link>
                        <Link to="/sample-master/add" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                {getSampleListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getSampleListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getSampleListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768,y:600 }}
                            loading={getSampleListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getSampleListState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <SampleView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getSampleListState: state.getSampleList,
    deleteSampleState: state.deleteSample
});

const mapDispatchToProps = (dispatch) => ({
    getSampleList: (params) => dispatch(getSampleList(params)),
    getSampleListReset: () => dispatch(getSampleListReset()),
    deleteSample: (params) => dispatch(deleteSample(params)),
    deleteSampleReset: () => dispatch(deleteSampleReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(SampleList);