import styled from "styled-components";
import { Col } from 'antd'

export const Column = styled(Col)`
margin-bottom: 30px;
.ant-checkbox-wrapper{
    margin-left: 0px !important;
}
`

export const UserRightTable = styled.table`
width:100%;
margin-bottom: 30px;
border: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
.ant-checkbox-wrapper{
    margin-left: 0px !important;
}
.centerAlign{
    text-align: center;
}
th{
    background: #fafafa;
}
th,td{
    border: 1px solid #f0f0f0;
    padding: 6px;
}
`