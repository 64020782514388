import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Menu, Dropdown } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"

/* actions */
import { getPageContentList, getPageContentListReset } from "../../../action/getPageContentListAction"
import { deletePageContent, deletePageContentReset } from "../../../action/deletePageContentAction"
import PageContentView from "../PageContentView/PageContentView";
import { dateFormat } from "../../../utils";

const PageContentList = props => {
    /* variables */
    const {
        getPageContentList, getPageContentListReset, getPageContentListState,
        deletePageContent, deletePageContentReset, deletePageContentState
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            ...getColumnSearchProps('title', 'Title')
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
            ...getColumnSearchProps('slug', 'Slug')
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/page-content/edit/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deletePageContent({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    /* callbacks */
    useEffect(() => {
        return (() => {
            getPageContentListReset()
        })
    }, [])
    useEffect(() => {
        getPageContentList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getPageContentListState.apiState === "success") {
            let tableData = []
            getPageContentListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        title: row.title,
                        slug: row.slug,
                        created_at: dateFormat(row.created_at),
                        updated_at: dateFormat(row.updated_at),
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getPageContentListState])

    useEffect(() => {
        setRedirect([true, `/page-content/list?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deletePageContentState.apiState === "success") {
            deletePageContentReset()
            notification["success"]({
                message: deletePageContentState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deletePageContentState.apiState === "error") {
            deletePageContentReset()
            notification["error"]({
                message: deletePageContentState.message,
                placement: "topRight"
            })
        }
    }, [deletePageContentState])
    // Functions
    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/common-master/test-group">
                    Test Group
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Page Content List"
                rightChildren={
                    <>
                        <Link to="/page-content/add" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                {getPageContentListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getPageContentListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getPageContentListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getPageContentListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getPageContentListState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <PageContentView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getPageContentListState: state.getPageContentList,
    deletePageContentState: state.deletePageContent
});

const mapDispatchToProps = (dispatch) => ({
    getPageContentList: (params) => dispatch(getPageContentList(params)),
    getPageContentListReset: () => dispatch(getPageContentListReset()),
    deletePageContent: (params) => dispatch(deletePageContent(params)),
    deletePageContentReset: () => dispatch(deletePageContentReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(PageContentList);