import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

/* CUSTOM COMPONENT */
import Header from '../../../component/Header/Header';
import AddUser from '../../../component/saveSystemUser/AddUser';
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */

const AddSystemUser = (props) => {
    /* VARIABLES */
    const user_id = props.match.params.id
    const mode = user_id ? "edit" : "add"

    return (
        <>
            <Header
                backButton={"/user/list"}
                title={mode === "edit" ? "Edit User" : "Add User"}
            />
            <PageContainer>
                <LayoutContainer>
                    <AddUser user_id={user_id} page_url="/user/list" />
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
})
const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemUser)

