import axios from 'axios'
import config from '../config'
import { getUserToken } from "../utils"

const saveBulkUploadApi = async (params, apiUrl) => {
    const response = await axios({
        url: `${config.api.base_url}upload/master-upload`,
        method: "post",
        data: params,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            "Authorization": `Bearer ${getUserToken()}`
        }
    })

    return response

}

export default saveBulkUploadApi