import axios from 'axios'
import config from '../config'
import { getUserToken } from "../utils"

const saveMouApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}mou`,
        method: params.mode === "edit" ? "put" : "post",
        data: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getUserToken()}`
        }
    })

    return response

}

export default saveMouApi