import { Layout } from "antd"

import styled from "styled-components"

const { Content } = Layout;
export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    .shake {
        animation: shake 1s cubic-bezier(.36,.07,.19,.97) both infinite;
        transform: translate3d(0, 0, 0);

        }
`

export const Box = styled.div`
    width: 350px;
    height: auto;
    padding: 1rem;
`
export const XContent = styled(Content)`
    display: flex;
    align-self: center;
    margin: 26px;
    flex-wrap:wrap;
    justify-content: center;
    margin-top: 120px;
    margin-bottom: 120px;
    padding: 0 50px;
     @media only screen and (max-width: 770px) {
       padding: 0px !important;
    }
`

export const ContainerHeader = styled.div`
    height: 85px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 30px;
    display: flex;
    align-items: center;
`
export const Left = styled.div`
    width: 46%;
    margin-right: 26px;
     @media only screen and (max-width: 770px) {
       width: 80%;
       margin-right: 0;
       margin-bottom: 13px;
    }
    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`
export const Right = styled.div`
    width: 46%;
    margin-left: 26px;
    /* padding: 30px; */
    /* display: flex; */
    /* justify-content: flex-end; */
     @media only screen and (max-width: 770px) {
       width: 80%;
       margin-left: 0;
       margin-top: 13px;
    }
    @media only screen and (max-width: 500px) {
        width: 100%;
    }
`
export const LoginBox = styled.div`
   border: solid 1px #f5f5f5;
   padding: 30px;
   /* width: 550px; */
`

export const SiteLayoutBackground = styled.div`
 display: flex;
 flex-wrap: wrap;
 justify-content: center;
`