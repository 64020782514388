import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, Modal, Upload } from "antd"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import moment from 'moment'

/* custom component */

/* actions */
import { saveBulkUpload, saveBulkUploadReset } from "../../action/saveBulkUploadAction"

/* otehrs */

export const BulkUpload = props => {
    /* variables */
    const {
        saveBulkUpload, saveBulkUploadReset, saveBulkUploadState,
        backUrl,
        apiUrl,
        master
    } = props

    const [fileList, setFileList] = useState([])

    const [redirect, setRedirect] = useState([false, ""])
    const [log, setLog] = useState([])

    /* callbacks */
    useEffect(() => {
        if (saveBulkUploadState.apiState === "success") {
            console.log("saveBulkUploadState => ",saveBulkUploadState);
            if (saveBulkUploadState.data.hasOwnProperty("log")) {
                downloadLogFile(saveBulkUploadState.data.log)
            }
            saveBulkUploadReset()
            // setRedirect([true, backUrl])
            notification["success"]({
                message: saveBulkUploadState.message,
                placement: "topRight"
            })
        }
        if (saveBulkUploadState.apiState === "error") {
            saveBulkUploadReset()
            notification["error"]({
                message: saveBulkUploadState.message,
                placement: "topRight"
            })
        }
    }, [saveBulkUploadState])

    /* functions */

    const downloadLogFile = async (content) => {

        setLog(content)
        const fileName = `${master}_${moment().format("YYYYMMDDhhmm")}.txt`;
        let content_formated = []
        const element = document.createElement("a");
        const file = new Blob([content.join('\n')], {type: "text/plain", endings: 'native'});
        element.href = URL.createObjectURL(file);
        element.download = fileName;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    }

    const handleBeforeUpload = (file) => {
        const isCSV = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "text/csv"].includes(file.type)
        if (!isCSV) {
            Modal.error({
                title: 'Only CSV file type is allowed.',
            })
        } else {
            setFileList([file])
        }
        return false
    }

    const onFinish = () => {
        const formData = new FormData()
        formData.append('master', master)
        formData.append('file', fileList[0])
        saveBulkUpload(formData, { apiUrl: apiUrl })
    }
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            {saveBulkUploadState.apiState != "success" &&
                <>
                    <Form layout="vertical" onFinish={onFinish}>
                        <Form.Item
                            label="Select CSV file"
                            name="file"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Upload
                                name="file"
                                multiple={false}
                                beforeUpload={handleBeforeUpload}
                                fileList={fileList}
                            >
                                <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                        </Form.Item>
                        <Button type="primary" htmlType="submit" icon={<UploadOutlined />} loading={saveBulkUploadState.apiState === "loading" ? true : false} >
                            {saveBulkUploadState.apiState === "loading" ? "Uploading..." : "START UPLOAD"}
                        </Button>
                    </Form>

                    {log.length > 0 && <div style={{marginTop:"20px"}}>
                        <h2>Bulk Upload Report:</h2>
                        {log.map((message) => <span>{message}<br></br></span>)}
                    </div>
                    }
                </>
            }

        </>
    )
}

const mapStateToProps = (state) => ({
    saveBulkUploadState: state.saveBulkUpload,
});

const mapDispatchToProps = (dispatch) => ({
    saveBulkUpload: (params, apiUrl) => dispatch(saveBulkUpload(params, apiUrl)),
    saveBulkUploadReset: () => dispatch(saveBulkUploadReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkUpload);