import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import moment from 'moment'


/* custom component */
import { ViewBox, ViewLabel, ViewValue, Heading } from "../../../component/Xcomponent"

/* action */
import { getDoctorDetail, getDoctorDetailReset } from "../../../action/getDoctorDetailAction"

/* others */
import { dateFormat, s3_cdn } from '../../../utils';


const DoctorMasterView = props => {
    /* variable */
    const { getDoctorDetail, getDoctorDetailReset, getDoctorDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getDoctorDetailReset()
        }
    }, [props.visible])
    useEffect(() => {
        if (props.id && props.visible) {
            getDoctorDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])
    return (
        <Drawer
            title={`Doctor Master View`}
            placement="right"
            closeIcon={<><Link to={`/doctor-master/save/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getDoctorDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getDoctorDetailState.apiState === "error" &&
                <p>{getDoctorDetailState.message}</p>
            }

            {getDoctorDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Heading>KYC</Heading>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pronoun</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.pronoun}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Salutation</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.salutation && getDoctorDetailState.data.salutation["value"]}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>LastName</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.lname}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>DOB</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.dob ? dateFormat(getDoctorDetailState.data.dob) : ""}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Gender</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.gender}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Email</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.email}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Mobile Number</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.mobile_number}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>WhatsApp Number</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.whatsapp_number}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Landline Number</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.landline_number}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pincode</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.pincode}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>City</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.city}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>District</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.district}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>State</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.state}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Home Address</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.home_address}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Aadhar Card Number</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.adhaar_number}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Heading>DTRF</Heading>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Activate for DTRF</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.is_dtrf_activated}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>LIMS ID</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.lims_id}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Status</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.status}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Hospital</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.hospital.length > 0 &&
                                    getDoctorDetailState.data.hospital.map(obj => obj && obj.name).join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Speciality</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.speciality.length > 0 &&
                                    getDoctorDetailState.data.speciality.map(obj =>  obj && obj.value).join(", ")}</ViewValue>

                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sonographer</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.sonographer}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Referring Doctor</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.referring_doctor}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>NT Status for Reporting</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.nt_status_for_reporting}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>FMF code</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.fmf_code}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>FMF Certified For</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.fmf_certified_for && getDoctorDetailState.data.fmf_certified_for.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>FMF License Validity</ViewLabel>
                                <ViewValue>{dateFormat(getDoctorDetailState.data.fmf_license_validity_date_from)}-{dateFormat(getDoctorDetailState.data.fmf_license_validity_date_to)}</ViewValue>
                                <ViewValue></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>PNDT Registration</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.pndt_registration}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>PNDT Registration Validity</ViewLabel>
                                <ViewValue>{dateFormat(getDoctorDetailState.data.pndt_registration_validity_date_from)}-{dateFormat(getDoctorDetailState.data.pndt_registration_validity_date_to)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Hospital Visit/Meeting Timings</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.hospital_visit_time_from}-{getDoctorDetailState.data.hospital_visit_time_to}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>MCI Registration Number</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.mci_registration_number}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pan Card Number</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.pan_card_number}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Date of Anniversary</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.date_of_anniversary ? dateFormat(getDoctorDetailState.data.date_of_anniversary) : ""}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Preferred Mode of Contact</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.preferred_mode_of_contact}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Heading>DCP</Heading>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Activate for DCP</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.is_dcp_activated}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Type</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.type}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Language</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.language.length > 0 &&
                                    getDoctorDetailState.data.language.map(obj =>  obj&& obj.value).join(", ")
                                }</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Expart Type</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.expert_type?.value}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Consultation Type</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.consultation_type?.value}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Concerns</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.concerns.length > 0 &&
                                    getDoctorDetailState.data.concerns.map(obj =>  obj&& obj.value).join(", ")
                                }</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Expert Approaches</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.expert_approaches.length > 0 &&
                                    getDoctorDetailState.data.expert_approaches.map(obj => obj&& obj.value).join(", ")
                                }</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Qualification</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.qualification}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Exprience</ViewLabel>
                                <ViewValue>{dateFormat(getDoctorDetailState.data.exprience)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Tier 1 fee</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.tier_1_fee}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Tier 2 fee</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.tier_2_fee}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Area of Expertise</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.area_of_expertise ? getDoctorDetailState.data.area_of_expertise.value : ""}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Core Area</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.core_area ? getDoctorDetailState.data.core_area.value : ""}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Can give Medicine</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.can_give_medicine}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Self Fee</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.self_fee}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Couple Fee</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.couple_fee}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Family Fee</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.family_fee}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Medicine Fee</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.medicine_fee}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Hidden</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.hidden}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Profile</ViewLabel>
                                <ViewValue><img src={`${s3_cdn()}doctor/${getDoctorDetailState.data.profile_picture}`} style={{ width: 60 }} /> </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Description</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.description}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Short Description</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.short_description}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Heading>Social</Heading>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Facebook ID</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.facebook_id}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Instagram ID</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.instagram_id}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>LinkedIn ID</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.linkedin_id}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Interests</ViewLabel>
                                <ViewValue>{getDoctorDetailState.data.interests}</ViewValue>
                            </ViewBox>
                        </Col>
                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getDoctorDetailState: state.getDoctorDetail
});

const mapDispatchToProps = (dispatch) => ({
    getDoctorDetail: (params) => dispatch(getDoctorDetail(params)),
    getDoctorDetailReset: () => dispatch(getDoctorDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorMasterView);