import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Menu, Dropdown } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, DownloadOutlined,UploadOutlined,CheckCircleFilled,CloseCircleOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"

/* actions */
import { getEmployeeList, getEmployeeListReset } from "../../../action/getEmployeeListAction"
import { getEmployeeMaster } from "../../../action/getEmployeeMasterAction"
import { deleteEmployee, deleteEmployeeReset } from "../../../action/deleteEmployeeAction"
import EmployeeView from "../EmployeeView/EmployeeView";
import { changeEmployeeStatus,changeEmployeeStatusReset } from "../../../action/changeEmployeeStatus";


var Excel = require("exceljs");
var wb = new Excel.Workbook();

const EmployeeList = props => {
    /* variables */
    const {
        getEmployeeList, getEmployeeListReset, getEmployeeMaster, getEmployeeMasterState, getEmployeeListState,
        deleteEmployee, deleteEmployeeReset, deleteEmployeeState,
        changeEmployeeStatus,changeEmployeeStatusReset,changeEmployeeStatusState
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
    const columns = [
        {
            title: 'Employee ID',
            dataIndex: 'employee_id',
            key: 'employee_id',
            ...getColumnSearchProps('employee_id', 'Employee ID')
        },
        {
            title: 'Employee Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
            ...getColumnSearchProps('employee_fname_lname', 'Employee Name')
        },
        {
            title: 'Designation',
            dataIndex: 'designation',
            key: 'designation',
            ...getColumnSearchProps('designation', 'Designation')
        },
        {
            title: 'Official Mobile',
            dataIndex: 'official_mobile',
            key: 'official_mobile',
            ...getColumnSearchProps('official_mobile', 'Official Mobile')
        },
        {
            title: 'Official Email ID',
            dataIndex: 'official_email_id',
            key: 'official_email_id',
            ...getColumnSearchProps('official_email_id', 'Official Email ID')
        },
        {
            title: 'Function',
            dataIndex: 'function',
            key: 'function',
            ...getColumnSearchProps('function', 'Function')
        },
        {
            title: 'Department',
            dataIndex: 'department',
            key: 'department',
            ...getColumnSearchProps('department', 'Department')
        },
      
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id,row) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: row.id, visible: true })} />
                    <Link to={`/employee-master/save/${row.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteEmployee({ id: row.id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                    {row.is_active && <Popconfirm
                        title="Are you sure to deactivate this employee?"
                        onConfirm={() => {
                            console.log(row.is_active);
                            changeEmployeeStatus({ id: row.id });
                        }}
                        okText="Deactivate"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<CloseCircleOutlined />} />
                    </Popconfirm>}

                    {!row.is_active && <Popconfirm
                        title="Are you sure to activate this employee?"
                        onConfirm={() => changeEmployeeStatus({ id: row.id })}
                        okText="Activate"
                        cancelText="Cancel"
                        okType="success"
                    >
                        <Button size="small" shape="circle" icon={<CheckCircleFilled />} />
                    </Popconfirm>}
                </Space>
            )
        },
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    /* callbacks */
    useEffect(() => {
        return (() => {
            getEmployeeListReset()
        })
    }, [])
    useEffect(() => {
        getEmployeeList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getEmployeeListState.apiState === "success") {
            let tableData = []
            getEmployeeListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        employee_id: row.employee_id,
                        employee_name: `${row.employee_name} ${row.employee_middle_name} ${row.employee_last_name}`,
                        designation: row.designation,
                        official_mobile: row.official_mobile,
                        official_email_id: row.official_email_id,
                        function: row.function,
                        department: row.department,
                        id: row._id,
                        is_active:row.is_active
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getEmployeeListState])

    useEffect(()=>{

        if(getEmployeeMasterState.apiState=="success")
        {
            let employee_list = getEmployeeMasterState.list;
            
            const workbook = wb.addWorksheet("Employer_Master");
            const headers = [
                'DP Employee ID',
                'Lilac ID',
                'Employee First Name',
                'Employee Middle Name',
                'Employee Last Name',
                'Designation',
                'Official Email ID',
                'City',
                'State',
                'Zone',
                'Department',
                'Role',
                'Primary Marketing Location',
                'Reporting Manager',
                'Reporting Head',
                'SBU Head',
                'Status',
                'Deleted',
            ];

            workbook.getRow(1).values = headers;

            employee_list.forEach((element,index) => {
                const rowIndex = index + 2;
                workbook.getRow(rowIndex).values = [
                    "",
                    element.employee_id,
                    element.employee_name,
                    element.employee_middle_name,
                    element.employee_last_name,
                    element.designation,
                    element.official_email_id,
                    element.city,
                    element.state,
                    element.zone,
                    element.department,
                    element.role,
                    element.primary_marketing_location,
                    element.reporting_manager,
                    element.reporting_head,
                    element.sbu_head,
                    element.is_active ? "Active": "Inactive",
                    element.is_deleted ? "Yes": "No",
                ];        
            });
            wb.xlsx.writeBuffer().then((buffer) => {
                const fileData = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const fileUrl = URL.createObjectURL(fileData);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Employee Master.xlsx';
                link.click();
            });
        }
        else if(getEmployeeMasterState=="error")
        {
            console.log("No employee Master");
        }
    },[getEmployeeMasterState]);

    useEffect(() => {
        setRedirect([true, `/employee-master/list?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteEmployeeState.apiState === "success") {
            deleteEmployeeReset()
            notification["success"]({
                message: deleteEmployeeState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteEmployeeState.apiState === "error") {
            deleteEmployeeReset()
            notification["error"]({
                message: deleteEmployeeState.message,
                placement: "topRight"
            })
        }
    }, [deleteEmployeeState])
    useEffect(() => {
        if (changeEmployeeStatusState.apiState === "success") {
            notification["success"]({
                message: changeEmployeeStatusState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteEmployeeState.apiState === "error") {
            notification["error"]({
                message: changeEmployeeStatusState.message,
                placement: "topRight"
            })
        }
    }, [changeEmployeeStatusState])
    // Functions

    
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Employee List"
                rightChildren={
                    <>
                        {/* Button for downloading formatted master data */}
                        {/* <Button type="primary" onClick={()=>getEmployeeMaster()}><DownloadOutlined />Download Master</Button> */}
                        <Link to="/employee-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Upload</Button></Link>
                        <Link to="/employee-master/save" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                {getEmployeeListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getEmployeeListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getEmployeeListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768,y:600 }}
                            loading={getEmployeeListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getEmployeeListState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <EmployeeView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getEmployeeListState: state.getEmployeeList,
    deleteEmployeeState: state.deleteEmployee,
    changeEmployeeStatusState:state.changeEmployeeStatus,
    getEmployeeMasterState: state.getEmployeeMaster,
});

const mapDispatchToProps = (dispatch) => ({
    getEmployeeList: (params) => dispatch(getEmployeeList(params)),
    getEmployeeListReset: () => dispatch(getEmployeeListReset()),
    deleteEmployee: (params) => dispatch(deleteEmployee(params)),
    deleteEmployeeReset: () => dispatch(deleteEmployeeReset()),
    changeEmployeeStatus:(params)=>dispatch(changeEmployeeStatus(params)),
    getEmployeeMaster: (params) => dispatch(getEmployeeMaster(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);