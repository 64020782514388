import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, InputNumber } from "antd"
import { connect } from "react-redux";
import { Redirect,useHistory } from "react-router-dom";
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"
import AddUser from '../../../component/saveSystemUser/AddUser';

/* actions */
import { saveEmployee, saveEmployeeReset } from "../../../action/saveEmployeeAction"
import { getEmployeeDetail, getEmployeeDetailReset } from "../../../action/getEmployeeDetailAction"
import { getCommonMasterList, getCommonMasterListReset } from "../../../action/getCommonMasterListAction";
import { getRoleList, getRoleListReset } from '../../../action/getRoleListAction'
import { getCentreLocations, getCentreLocationsReset } from '../../../action/getCentreLocationsAction'

/* otehrs */
import { Xcard, CustomTabs, CustomTabPane } from './EmployeeStyle'
import AddUserRights from "../../../component/UserRights/AddUserRights";
import { checkRights, getUser } from "../../../utils";


export const EmployeeSave = props => {
    /* variables */
    let user = getUser();
    const hisyory=useHistory();
    const employee_id = props.match.params.employee_id
    const mode = employee_id ? "edit" : "add"
    const {
        saveEmployee, saveEmployeeReset, saveEmployeeState,
        getEmployeeDetail, getEmployeeDetailReset, getEmployeeDetailState,
        getCommonMasterList, getCommonMasterListReset, getCommonMasterListState,
        getRoleList, getRoleListReset, getRoleListState,
        getCentreLocations, getCentreLocationsReset, getCentreLocationsState,
    } = props
    const [roleList, setRoleList] = useState([]);
    const [centreLocations, setCentreLocations] = useState([]);
    const [formData, setFormData] = useState({
        employee_id: "",
        employee_name: "",
        employee_middle_name: "",
        employee_last_name: "",
        designation: "",
        country_code: "",
        official_mobile: "",
        official_email_id: "",
        city: "",
        state: "",
        zone: "",
        department: "",
        role: "",
        primary_marketing_location: "",
        reporting_manager: "",
        reporting_head: "",
        sbu_head: "",
        centre_location: "",
    })
    if (mode === "edit") {
        formData.id = employee_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()
    const [defaultActiveKey, setDefaultActiveKey] = useState("employeee")

    /* callbacks */
    useEffect(() => {
        getCommonMasterList({ slug: 'hq-primary-marketing-location,test-group' })
        getRoleList({
            dropDownList: "/get-dropdown-list"
        })
        getCentreLocations();
        console.log("--->",user);
        return (() => {
            saveEmployeeReset()
            getEmployeeDetailReset()
            getCommonMasterListReset()
            // getRoleListReset()
        })
        
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getEmployeeDetail({
                id: employee_id
            })
        }
    }, [])

    useEffect(() => {
        if (getRoleListState.apiState === "success") {
            setRoleList(getRoleListState.list)
            getRoleListReset()
        }
    }, [getRoleListState])

    useEffect(()=>{
        if(getCentreLocationsState.apiState==="success")
        {
            setCentreLocations(getCentreLocationsState.list)
            getCentreLocationsReset();
        }
    },[getCentreLocationsState])

    useEffect(() => {
        if (getEmployeeDetailState.apiState === "success") {
            form.setFieldsValue({
                employee_id: getEmployeeDetailState.data.employee_id,
                employee_name: getEmployeeDetailState.data.employee_name,
                employee_middle_name: getEmployeeDetailState.data.employee_middle_name,
                employee_last_name: getEmployeeDetailState.data.employee_last_name,
                designation: getEmployeeDetailState.data.designation,
                country_code: getEmployeeDetailState.data.country_code,
                official_mobile: getEmployeeDetailState.data.official_mobile,
                official_email_id: getEmployeeDetailState.data.official_email_id,
                city: getEmployeeDetailState.data.city,
                state: getEmployeeDetailState.data.state,
                zone: getEmployeeDetailState.data.zone,
                department: getEmployeeDetailState.data.department,
                role: getEmployeeDetailState.data.role?._id,
                primary_marketing_location: getEmployeeDetailState.data.primary_marketing_location,
                reporting_manager: getEmployeeDetailState.data.reporting_manager,
                reporting_head: getEmployeeDetailState.data.reporting_head,
                sbu_head: getEmployeeDetailState.data.sbu_head,
                centre_location: getEmployeeDetailState.data.centre_location,
            })
            setFormData({
                ...formData,
                ['employee_id']: getEmployeeDetailState.data.employee_id,
                ['employee_name']: getEmployeeDetailState.data.employee_name,
                ['employee_middle_name']: getEmployeeDetailState.data.employee_middle_name,
                ['employee_last_name']: getEmployeeDetailState.data.employee_last_name,
                ['designation']: getEmployeeDetailState.data.designation,
                ['country_code']: getEmployeeDetailState.data.country_code,
                ['official_mobile']: getEmployeeDetailState.data.official_mobile,
                ['official_email_id']: getEmployeeDetailState.data.official_email_id,
                ['city']: getEmployeeDetailState.data.city,
                ['state']: getEmployeeDetailState.data.state,
                ['zone']: getEmployeeDetailState.data.zone,
                ['department']: getEmployeeDetailState.data.department,
                ['role']: getEmployeeDetailState.data.role?._id,
                ['primary_marketing_location']: getEmployeeDetailState.data.primary_marketing_location,
                ['reporting_manager']: getEmployeeDetailState.data.reporting_manager,
                ['reporting_head']: getEmployeeDetailState.data.reporting_head,
                ['sbu_head']: getEmployeeDetailState.data.sbu_head,
                ['centre_location']: getEmployeeDetailState.data.centre_location,
            })
        }
    }, [getEmployeeDetailState])

    useEffect(() => {
        if (saveEmployeeState.apiState === "success") {
            saveEmployeeReset()
            if(mode != "edit"){
               setRedirect([true, "/employee-master/list"])
            }
           
            notification["success"]({
                message: saveEmployeeState.message,
                placement: "topRight"
            })
        }
        if (saveEmployeeState.apiState === "error") {
            saveEmployeeReset()
            notification["error"]({
                message: saveEmployeeState.message,
                placement: "topRight"
            })
        }
    }, [saveEmployeeState])

    /* functions */
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }

    const handleSubmit = () => {
        
        saveEmployee(formData)
    }
    const callback = (key) => {
        setDefaultActiveKey(key)
    }
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/employee-master/list"}
                title={mode === "edit" ? "Edit Employee Master" : "Add Employee Master"}
            />
            <PageContainer>
                <LayoutContainer>
                    <CustomTabs tabPosition="top" activeKey={defaultActiveKey} size="small" onChange={callback}>
                        <CustomTabPane tab={`Employee`} key={`employeee`} >
                            <Form form={form} layout="vertical" onFinish={handleSubmit} initialValues={{country_code:'+91'}}>
                                <Row gutter={20}>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Employee ID"
                                            name="employee_id"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please input alphabet and number!')); }, })
                                            ]}
                                        >
                                            <Input name="employee_id" placeholder="Enter Employee ID"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Employee First Name"
                                            name="employee_name"
                                            rules={[{ required: true, message: "Required" },
                                                () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabet!')); }, })]}
                                        >
                                            <Input name="employee_name" placeholder="Enter Employee Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Employee Middle Name"
                                            name="employee_middle_name"
                                            rules={[{ required: true, message: "Required" },
                                                () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabet!')); }, })]}
                                        >
                                            <Input name="employee_middle_name" placeholder="Enter Employee Middle Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Employee Last Name"
                                            name="employee_last_name"
                                            rules={[{ required: true, message: "Required" },
                                                () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabet!')); }, })]}
                                        >
                                            <Input name="employee_last_name" placeholder="Enter Employee Last Name"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Designation"
                                            name="designation"
                                            rules={[{ required: true, message: "Required" },
                                                () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabet!')); }, })]}
                                        >
                                            <Input name="designation" placeholder="Enter Designation"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            
                                            label="Country Code"
                                            name="country_code"
                                            rules={[
                                                () => ({ validator(_, value) { if (!value || value.match(/^\+\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid input!')); }, })
                                            ]}
                                        >
                                            <Input name="country_code" placeholder="Enter Country Code (+91)"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Official Mobile"
                                            name="official_mobile"
                                            rules={[{ required: true, message: "Required" },
                                                () => ({ validator(_, value) { if (!value || value.match(/^\d{10}$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input numbers!')); }, })
                                            ]}
                                        >
                                            <Input name="official_mobile" placeholder="Enter Official Mobile"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Official Email ID"
                                            name="official_email_id"
                                            rules={[{ required: true, message: "Required" },
                                                () => ({ validator(_, value) { if (!value || value.match(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter valid Email!')); }, })
                                            ]}
                                        >
                                            <Input name="official_email_id" placeholder="Enter Official Email ID"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="City"
                                            name="city"
                                            rules={[{ required: true, message: "Required" },() => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabet!')); }, })]}

                                        >
                                            <Input name="city" placeholder="Enter City"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="State"
                                            name="state"
                                            rules={[{ required: true, message: "Required" },() => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabet!')); }, })]}
                                        >
                                            <Input name="state" placeholder="Enter State"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Zone"
                                            name="zone"
                                            rules={[{ required: true, message: "Required" },() => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabets and numbers!')); }, })]}
                                        >
                                            <Input name="zone" placeholder="Enter Zone"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Department"
                                            name="department"
                                            rules={[{ required: true, message: "Required" },() => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabets and numbers!')); }, })]}

                                        >
                                            <Input name="department" placeholder="Enter Department"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Primary Marketing Location"
                                            name="primary_marketing_location"
                                            rules={[{ required: true, message: "Required" }]}
                                        >
                                           <Select
                                                    showSearch
                                                    name="primary_marketing_location"
                                                    onSelect={(v) => handleSelect("primary_marketing_location",v)}
                                                    placeholder="Select HQ or Primary Marketing Location"
                                                >
                                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "hq-primary-marketing-location" })) &&
                                                        <>
                                                            {_.find(getCommonMasterListState.list, { slug: "hq-primary-marketing-location" }).common_master_detail.map((item) => (
                                                                <Select.Option value={item.value} >{item.value}</Select.Option>
                                                            ))}

                                                        </>
                                                    }

                                                </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Reporting Manager"
                                            name="reporting_manager"
                                            
                                        >
                                            <Input name="reporting_manager" placeholder="Enter Reporting Manager"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Reporting Head"
                                            name="reporting_head"
                                        >
                                            <Input name="reporting_head" placeholder="Enter Reporting Head"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="SBU Head"
                                            name="sbu_head"
                                        >
                                            <Input name="sbu_head" placeholder="Enter SBU Head"
                                                onChange={handleOnChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Role"
                                            name="role"
                                            rules={[{ required: true, message: "Required" },() => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabets and numbers!')); }, })]}

                                        >
                                            <Select
                                                name="role"
                                                placeholder="Select Role"
                                                showSearch
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(v) => handleSelect("role", v)}
                                            >
                                                {roleList.map((row, index) => {
                                                    return (
                                                        <Select.Option value={`${row._id}`} key={`${index}`}>{row.name}</Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                        <Form.Item
                                            label="Centre Location"
                                            name="centre_location"
                                            rules={[
                                                { 
                                                    required: true, 
                                                    message: "Required" 
                                                },
                                                () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9\s\.]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input alphabets and numbers!')); }, })
                                            ]}

                                        >
                                            <Select
                                                name="centre_location"
                                                placeholder="Select Centre Location"
                                                showSearch
                                                allowClear
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={(v) => handleSelect("centre_location", v)}
                                            >
                                                {centreLocations.map((row, index) => {
                                                    return (
                                                        <Select.Option value={`${row.server_code}`} key={`${index}`}>{row.server_location}</Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>


                                </Row>
                                <Button type="primary" htmlType="submit" style={{'marginRight':12}}>SUBMIT</Button>
                                <Button type="secondary" htmlType="cancle" onClick={()=>{hisyory.goBack()}}>Cancel</Button>
                            </Form>
                            
                        </CustomTabPane>
                        {(getEmployeeDetailState.apiState === "success" && mode === "edit" && getEmployeeDetailState.data.user) &&
                            <>
                                {/* {checkRights("user", "edit") &&
                                    <CustomTabPane tab={`User`} key={`user`} >
                                        <AddUser user_id={getEmployeeDetailState.data.user._id} page_url={`/employee-master/save/${getEmployeeDetailState.data._id}`} />
                                    </CustomTabPane>
                                } */}
                                {user && user.role.name === 'Super Admin' &&
                                    <CustomTabPane tab={`User Rights`} key={`user_rights`} >
                                        <AddUserRights user_id={getEmployeeDetailState.data.user._id} />
                                    </CustomTabPane>
                                }
                            </>
                        }
                    </CustomTabs>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveEmployeeState: state.saveEmployee,
    getEmployeeDetailState: state.getEmployeeDetail,
    getCommonMasterListState: state.getCommonMasterList,
    getRoleListState: state.getRoleList,
    getCentreLocationsState: state.getCentreLocations,
});

const mapDispatchToProps = (dispatch) => ({
    saveEmployee: (params) => dispatch(saveEmployee(params)),
    saveEmployeeReset: () => dispatch(saveEmployeeReset()),
    getEmployeeDetail: (params) => dispatch(getEmployeeDetail(params)),
    getEmployeeDetailReset: () => dispatch(getEmployeeDetailReset()),
    getCommonMasterList: (params) => dispatch(getCommonMasterList(params)),
    getCommonMasterListReset: () => dispatch(getCommonMasterListReset()),
    getRoleList: (params) => dispatch(getRoleList(params)),
    getRoleListReset: () => dispatch(getRoleListReset()),
    getCentreLocations: (params) => dispatch(getCentreLocations(params)),
    getCentreLocationsReset: () => dispatch(getCentreLocationsReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSave);