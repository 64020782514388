import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    CHANGE_EMPLOYEE_STATUS,
    changeEmployeeStatusFail,
    changeEmployeeStatusSuccess
} from '../action/changeEmployeeStatus'

// api 
import changeEmployeeStatusApi from '../api/changeEmployeeStatusApi'

export function* changeEmployeeStatusSaga(action) {
    try {
        const response = yield call(() => changeEmployeeStatusApi(action.params))
        if (response.data.status === 1) {
            yield put(changeEmployeeStatusSuccess(response, action))
        } else {
            yield put(changeEmployeeStatusFail(response, action))
        }
    } catch (e) {
        yield put(changeEmployeeStatusFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(CHANGE_EMPLOYEE_STATUS, changeEmployeeStatusSaga)
}