import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getEmployeeDetail, getEmployeeDetailReset } from "../../../action/getEmployeeDetailAction"
import { dateFormat } from '../../../utils';

const EmployeeView = props => {
    /* variable */
    const { getEmployeeDetail, getEmployeeDetailReset, getEmployeeDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getEmployeeDetailReset()
        }
    }, [props.visible])

    useEffect(() => {
        if (props.id && props.visible) {
            getEmployeeDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`Employee Master View ${getEmployeeDetailState.apiState === "success" ? "- " + getEmployeeDetailState.data.employee_id : ""}`}
            placement="right"
            closeIcon={<><Link to={`/employee-master/save/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getEmployeeDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getEmployeeDetailState.apiState === "error" &&
                <p>{getEmployeeDetailState.message}</p>
            }

            {getEmployeeDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Employee ID</ViewLabel>
                                <ViewValue><span>{getEmployeeDetailState.data.employee_id}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Employee Name</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.employee_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        {/* <Col span="12">
                            <ViewBox>
                                <ViewLabel>Employee Middle Name</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.employee_middle_name}</ViewValue>
                            </ViewBox>
                        </Col> */}
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Employee Last Name</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.employee_last_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Designation</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.designation}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Official Mobile</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.official_mobile}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Official Email ID</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.official_email_id}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Function</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.function}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Department</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.department}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Primary Marketing Location</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.primary_marketing_location}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Region</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.region}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Reporting Manager</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.reporting_manager}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Reporting Head</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.reporting_head}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Reporting Administrator</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.reporting_administrator}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Territory</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.territory}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Zone</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.zone}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Grades</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.grades}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Email Id for Invoice,Report,Followup and Acknowledgement</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.invoice_email}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Role</ViewLabel>
                                <ViewValue>{getEmployeeDetailState.data.role?.name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getEmployeeDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getEmployeeDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>

                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getEmployeeDetailState: state.getEmployeeDetail
});

const mapDispatchToProps = (dispatch) => ({
    getEmployeeDetail: (params) => dispatch(getEmployeeDetail(params)),
    getEmployeeDetailReset: () => dispatch(getEmployeeDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeView);