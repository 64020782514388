import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Button, Input, Tooltip } from "antd"
import { EyeOutlined, EditOutlined, SettingOutlined, SearchOutlined, PlusOutlined, } from "@ant-design/icons"
import _ from 'lodash'

/* CUSTOM COMPONENT */
import Header from "../../../component/Header/Header"
import { PageContainer, Xpagination, Xtable } from "../../../component/Xcomponent"
import SystemUserView from "../SystemUserView/SystemUserView";

/* ACTION */
import { systemUserList, systemUserListReset } from '../../../action/systemUserListAction'

/* OTHERS */
import { checkRights } from '../../../utils' 


const UserList = (props) => {
    /* VARIABLES */
    const { systemUserList, systemUserListReset, systemUserListState } = props
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [isSuperAdmin, setIsSuperAdmin] = useState(null)
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [redirect, setRedirect] = useState(["", false])
    const [columns, setColumns] = useState([])
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 20
    })


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
 
    /* CALLBACKS */
    useEffect(() => {
        return (() => {
            systemUserListReset()
        })
    }, [])


    useEffect(() => {
        systemUserList({
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/user/listt?page=${pagination.page}`])
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (systemUserListState.apiState === "success") {
            setColumns([
                {
                    title: 'First Name',
                    dataIndex: 'fname',
                    key: 'fname',
                    render: fname => (
                        <span>{fname}</span>
                    ),
                    ...getColumnSearchProps('fname', 'First Name')
                },
                {
                    title: 'Last Name',
                    dataIndex: 'lname',
                    key: 'lname',
                    render: lname => (
                        <span>{lname}</span>
                    ),
                    ...getColumnSearchProps('lname', 'Last Name')
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    render: email => (
                        <span>{email}</span>
                    ),
                    ...getColumnSearchProps('email', 'Email')
                },

                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    render: (action) => (
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: action.id, visible: true })} />
                            {
                                (checkRights("user", "edit")) &&
                                <Link to={`/user/${action.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                            }
                            {checkRights("user_rights", "edit") &&
                                <>
                                    {isSuperAdmin !== action.role &&
                                        < Tooltip title="User Right" placement="bottom" >
                                            <Link to={`/user/${action.id}/rights`}><Button size="small" shape="circle" icon={<SettingOutlined />} /></Link>
                                        </Tooltip>
                                    }
                                </>
                            }
                        </Space >
                    )
                },
            ])
            let data = systemUserListState.data
            let findAdminData = _.find(data, { 'role': "admin" })
            let findAdminRole = findAdminData?.role
            setIsSuperAdmin(findAdminRole)
            let tableData = []
            data.map((row, index) => {
                tableData.push(
                    {
                        
                        fname: row.fname,
                        lname: row.lname,
                        email: row.email,
                        action: { id: row._id, role: row.role },
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [systemUserListState])

    return (
        <>
            <Header
                title="User List"
                rightChildren={checkRights("test_master", "add") && <Link to="/user/add" ><Button type="primary" ><PlusOutlined />New</Button></Link>}
            />
            <PageContainer>
                {systemUserListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{systemUserListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {systemUserListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={systemUserListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={systemUserListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                    </>
                }
                <SystemUserView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />

            </PageContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    systemUserListState: state.systemUserList
})
const mapDispatchToProps = (dispatch) => ({
    systemUserList: (params) => dispatch(systemUserList(params)),
    systemUserListReset: () => dispatch(systemUserListReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
