import Axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { LayoutContainer, PageContainer } from "../../../component/Xcomponent";
import NotificationDetail from "../../../component/Notification/NotificationDetail";
import Header from "../../../component/Header/Header";
import { Button, Affix, Divider, Select } from "antd";
import axios from "axios";
import config from "../../../config";
import { getUserToken } from "../../../utils";
const dynamicProperties = [
  {
    label: "Patient name",
    value: "##patient_name##",
  },
  {
    label: "SMS to",
    value: "##sms_to##",
  },
  {
    label: "Test name",
    value: "##test_name##",
  },
  {
    label: "Institute name",
    value: "##institute_name##",
  },
  {
    label: "Patient phone number",
    value: "##patient_number##",
  },
  {
    label: "Doctor name",
    value: "##doctor_name##",
  },
  {
    label: "Employee name",
    value: "##employee_name##",
  },
  {
    label: "OTP",
    value: "##otp##",
  },
  {
    label: "Payment link",
    value: "##payment_link##",
  },
  {
    label: "Incomplete DTRF link",
    value: "##incomplete_dtrf_link##",
  },
];
const NotificationDetailView = (props) => {
  const [notificationDetail, setNotificationDetail] = useState(false);
  const [valueSelectedForCopy, setValueSelectedForCopy] = useState("");
  useEffect(() => {
    if (!notificationDetail && props.match.params) {
      const response = axios({
        url: `${config.api.base_url}notification/notification-detail`,
        method: "post",
        data: { id: props.match.params.notification_type },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getUserToken()}`,
        },
      });

      response
        .then((resp) => {
          setNotificationDetail(resp.data.data);
        })
        .catch(() => {
          alert("Error");
        });
    }
  }, []);

  return (
    <>
      <Header title="Notification Detail View" />
      <PageContainer>
        <LayoutContainer>
        <div>
          <h2>For {notificationDetail.notification_name}</h2>
        </div>
          <Divider></Divider>
          <Affix offsetTop={10}>
            <div style={{ padding: "5px" }}>
              <Select
                style={{ marginRight: "5px" }}
                name="variable_type"
                placeholder="Select dynamic variable name"
                allowClear
                mode="single"
                onChange={(v) => {
                  setValueSelectedForCopy(v);
                  console.log(v);
                  return v;
                }}
              >
                {dynamicProperties.map((data) => (
                  <Select.Option value={data.value}>{data.label}</Select.Option>
                ))}
              </Select>
              <Button
                style={{ marginLeft: "5px" }}
                disabled={valueSelectedForCopy ? false : true}
                type={"primary"}
                size="medium"
                onClick={(event) => {
                  navigator.clipboard.writeText(valueSelectedForCopy);
                }}
              >
                Copy to clipboard
              </Button>
            </div>
          </Affix>

          <Divider></Divider>
        </LayoutContainer>
        {notificationDetail &&
          notificationDetail.send_from_module_list.map((module) => (
            <NotificationDetail {...module} id={notificationDetail._id} />
            // <h1>ASd</h1>
          ))}
      </PageContainer>
    </>
  );
};

export default NotificationDetailView;
