import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_SAMPLE_CONTAINER_TEST_MAP,
    saveSampleContainerTestMapFail,
    saveSampleContainerTestMapSuccess
} from '../action/saveSampleContainerTestMapAction'

// api 
import saveSampleContainerTestMapApi from '../api/saveSampleContainerTestMapApi'

export function* saveSampleContainerTestMapSaga(action) {
    try {
        const response = yield call(() => saveSampleContainerTestMapApi(action.params))
        if (response) {
            yield put(saveSampleContainerTestMapSuccess(response, action))
        }
        else {
            yield put(saveSampleContainerTestMapFail(response, action))
        }
    }
    catch (e) {
        yield put(saveSampleContainerTestMapFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_SAMPLE_CONTAINER_TEST_MAP, saveSampleContainerTestMapSaga)
}