import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SYSTEM_USER_LIST,
    systemUserListFail,
    systemUserListSuccess
} from '../action/systemUserListAction'

// api 
import systemUserListApi from '../api/systemUserListApi'

export function* systemUserListSaga(action) {
    try {
        const response = yield call(() => systemUserListApi(action.params))
        if (response) {
            yield put(systemUserListSuccess(response, action))
        }
    }
    catch (e) {
        yield put(systemUserListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SYSTEM_USER_LIST, systemUserListSaga)
}