import axios from 'axios'
import config from '../config'
import { getUserToken } from "../utils"

const getContactUsEnquriesListApi = async (params) => {
    let cancelToken
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const response = await axios({
        url: `${config.api.base_url}contact-us-enquiry`,
        method: "get",
        params: params,
        cancelToken: cancelToken.token,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getUserToken()}`
        }
    })

    return response

}

export default getContactUsEnquriesListApi