import React, { useEffect, useState } from "react"
import { UploadOutlined, PlusOutlined } from '@ant-design/icons'
import { Link, useLocation, Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { Input, Button, Popconfirm, notification, Space, Menu, Dropdown } from 'antd'
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xpagination, Xtable, LayoutContainer } from "../../../component/Xcomponent"
import { } from './TestMasterListStyle'
import TestMasterView from '../TestMasterView/TestMasterView'
// actions
import { getListTestMaster, getListTestMasterReset } from '../../../action/getListTestMasterActions'
import { deleteTestMaster, deleteTestMasterReset } from '../../../action/deleteTestMasterAction'

/* OTHERS */
import { checkRights } from '../../../utils'

const TestMasterList = props => {
    // variables
    const {
        getListTestMaster, getListTestMasterReset, getListTestMasterState,
        deleteTestMaster, deleteTestMasterReset, deleteTestMasterState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [columns, setColumns] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [redirect, setRedirect] = useState(["", false])
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 20,
    })
    const [view, setView] = useState({
        visible: false,
        id: null,
    })

    // Functions
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }


    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });

    // callbacks
    useEffect(() => {
        return (() => {
            getListTestMasterReset()
            deleteTestMasterReset()
        })
    }, [])

    useEffect(() => {
        getListTestMaster({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/test-master/list?page=${pagination.page}`])

    }, [pagination, refresh, searchText])

    useEffect(() => {
        if (getListTestMasterState.apiState === "success") {
            setColumns([
                {
                    title: "ID",
                    dataIndex: "lilac_test_id",
                    render: lilac_test_id => (
                        <span>{lilac_test_id}</span>
                    ),
                    ...getColumnSearchProps('lilac_test_id', 'Lilac ID')

                },
                {
                    title: "Name",
                    dataIndex: "display_test_name",
                    render: item => (
                        <span>{item.display_test_name}</span>
                    ),
                    ...getColumnSearchProps('display_test_name', 'Name')

                },
                {
                    title: "Patient Stage",
                    dataIndex: "patient_stage",
                    render: item => (
                        <span>{item.patient_stage}</span>
                    ),
                    ...getColumnSearchProps('patient_stage', 'Patient Stage')

                },
                {
                    title: "Test Group",
                    dataIndex: "test_group",
                    render: test_group => (

                        <span>{
                            test_group && (
                                <span>{test_group.value}</span>
                            )
                        }</span>
                    ),
                    ...getColumnSearchProps('test_group', 'Test Group')
                },
                {
                    title: "Actions",
                    dataIndex: "id",
                    render: id => (
                        <>
                            <Space size="small">
                                {checkRights("test_master", "view") &&
                                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                                }
                                {checkRights("test_master", "edit") &&
                                    <Link to={`/test-master/edit/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                                }
                                {checkRights("test_master", "delete") &&
                                    <Popconfirm
                                        title="Are you sure to delete this item?"
                                        onConfirm={() => deleteTestMaster({ id: id })}
                                        okText="Delete"
                                        cancelText="Cancel"
                                        okType="danger"
                                    >
                                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                }
                            </Space>
                        </>
                    )
                },
            ])
            let rows = []
            getListTestMasterState.list.map((result) => {
                rows.push({
                    lilac_test_id: result.lilac_test_id,
                    patient_stage: { patient_stage: result.patient_stage },
                    test_group: result.test_group,
                    display_test_name: { display_test_name: result.display_test_name },
                    id: result._id,
                })
            })
            setDataSource(rows)
        }
    }, [getListTestMasterState])

    useEffect(() => {
        if (deleteTestMasterState.apiState === "success") {
            notification["success"]({
                message: "Delete Records Successfully"
            })
            deleteTestMasterReset()
            getListTestMaster({ dropDownList: "" })
        }
        if (deleteTestMasterState.apiState === "error") {
            notification["error"]({
                message: "Something went wrong"
            })
            deleteTestMasterReset()
        }
    }, [deleteTestMasterState])

    // Functions
    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/common-master/test-group">
                    Test Group
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            {
                getListTestMasterState.apiState !== "error" &&
                <>
                    {redirect[0] &&
                        <Redirect to={redirect[1]} />}
                    <Header
                        title="Test Master List"
                        rightChildren={
                            <>
                                <Dropdown overlay={menu} placement="bottomCenter" arrow>
                                    <Button>Common Master</Button>
                                </Dropdown>
                                <Link to="/test-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Upload</Button></Link>
                                <Link to="/test-master/add">
                                    <Button type="primary" icon={<PlusOutlined />} >New</Button>
                                </Link>
                            </>
                        }
                    />
                    <PageContainer>
                        <LayoutContainer>

                            <Xtable dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: 768,y:600 }}
                                loading={getListTestMasterState.apiState === "loading"} />
                            <Xpagination
                                pageSizeOptions={['10', '15', '25', '50']}
                                showSizeChanger
                                onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                                current={pagination.page}
                                defaultPageSize={pagination?.records}
                                total={getListTestMasterState.pagination.total_records}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            />
                            <TestMasterView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                        </LayoutContainer>
                    </PageContainer>
                </>
            }
            <>
                {
                    getListTestMasterState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getListTestMasterState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

            </>
        </>
    )
}
const mapStateToProps = (state) => ({
    getListTestMasterState: state.getListTestMaster,
    deleteTestMasterState: state.deleteTestMaster,
})

const mapDispatchToProps = (dispatch) => ({
    getListTestMaster: (params) => dispatch(getListTestMaster(params)),
    getListTestMasterReset: () => dispatch(getListTestMasterReset()),
    deleteTestMaster: (params) => dispatch(deleteTestMaster(params)),
    deleteTestMasterReset: () => dispatch(deleteTestMasterReset()),
})




export default connect(mapStateToProps, mapDispatchToProps)(TestMasterList)