import React, { useEffect, useState } from "react"
import { Link, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Button, Input, Popconfirm, notification } from "antd"
import { EditOutlined, PlusOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons"
import _ from 'lodash'

/* CUSTOM COMPONENT */
import Header from "../../../component/Header/Header"
import { PageContainer, Xpagination, Xtable } from "../../../component/Xcomponent"

/* ACTION */
import { getRoleList, getRoleListReset } from '../../../action/getRoleListAction'
import { deleteRole, deleteRoleReset } from '../../../action/deleteRoleAction'

/* OTHERS */
import { checkRights } from '../../../utils'


const RoleMasterList = (props) => {
    /* VARIABLES */
    const {
        getRoleList, getRoleListReset, getRoleListState,
        deleteRole, deleteRoleReset, deleteRoleState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [tableData, setTableData] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [redirect, setRedirect] = useState(["", false])

    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 20,
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }


    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });



    const columns = [
        {
            title: 'Role Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name', 'Role Name')
        },
        {
            title: 'Admin Type',
            dataIndex: 'admin_type',
            key: 'admin_type',
            ...getColumnSearchProps('admin_type', 'Admin Type')
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (action) => (
                <Space size="small">
                    {checkRights("role", "edit") &&
                        <Link to={`/role/save/${action.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    }
                    {checkRights("role", "delete") &&
                        <Popconfirm
                            title="Are you sure to delete this item?"
                            onConfirm={() => deleteRole({ id: action.id })}
                            okText="Delete"
                            cancelText="Cancel"
                            okType="danger"
                        >
                            <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                        </Popconfirm>
                    }
                </Space >
            )
        },
    ];
    /* CALLBACKS */

    useEffect(() => {
        getRoleList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/role/list?page=${pagination.page}`])

    }, [pagination, refresh, searchText])

    useEffect(() => {
        return (() => {
            getRoleListReset()
            deleteRoleReset()
        })
    }, [])

    useEffect(() => {
        if (deleteRoleState.apiState === "success") {
            notification["success"]({
                message: "Delete Records Successfully"
            })
            deleteRoleReset()
            getRoleList({ dropDownList: "" })
        }
        if (deleteRoleState.apiState === "error") {
            notification["error"]({
                message: "Something went wrong"
            })
            deleteRoleReset()
        }
    }, [deleteRoleState])

    useEffect(() => {
        if (getRoleListState.apiState === "success") {
            let tableData = []
            getRoleListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        name: row.name,
                        admin_type: row.admin_type.name,
                        action: { id: row._id }
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getRoleListState])

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                title="Role List"
                rightChildren={checkRights("role", "add") && <Link to="/role/save" ><Button type="primary" ><PlusOutlined />New</Button></Link>}
            />
            <PageContainer>
                {getRoleListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getRoleListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getRoleListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getRoleListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getRoleListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                    </>
                }
            </PageContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    getRoleListState: state.getRoleList,
    deleteRoleState: state.deleteRole,

})
const mapDispatchToProps = (dispatch) => ({
    getRoleList: (params) => dispatch(getRoleList(params)),
    getRoleListReset: () => dispatch(getRoleListReset()),
    deleteRole: (params) => dispatch(deleteRole(params)),
    deleteRoleReset: () => dispatch(deleteRoleReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleMasterList)
