import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";

/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getInstituteDetail, getInstituteDetailReset } from "../../../action/getInstituteDetailAction"
import { dateFormat } from '../../../utils';

const InstituteMasterView = props => {
    /* variable */
    const { getInstituteDetail, getInstituteDetailReset, getInstituteDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getInstituteDetailReset()
        }
    }, [props.visible])
    useEffect(() => {
        if (props.id && props.visible) {
            getInstituteDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`Institute Master View ${getInstituteDetailState.apiState === "success" ? "- " + getInstituteDetailState.data.lilac_id : ""}`}
            placement="right"
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getInstituteDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getInstituteDetailState.apiState === "error" &&
                <p>{getInstituteDetailState.message}</p>
            }

            {getInstituteDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Name</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Address</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.address}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>City</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.city}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>District</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.district}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>State</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.state}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pincode</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.pincode}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Email</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.email}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Type</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.type}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Credit Limit</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.credit_limit}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pickup Start Time</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.pickup_start_time}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pickup End Time</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.pickup_end_time}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Bank A/c Name</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.bank_account_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Bank A/c Number</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.bank_account_number}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>IFSC Code</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.ifsc_code}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Branch</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.bank_branch}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>GST</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.gst}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>PAN</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.pan}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>PCPNDT Registration</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.pcpndt_registration}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>PCPNDT Expiration</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.pcpndt_expiration}</ViewValue>
                            </ViewBox>
                        </Col>
                        {/* <Col span="12">
                            <ViewBox>
                                <ViewLabel>Doctors Attached</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.doctors_attached.length > 0 &&
                                    getInstituteDetailState.data.doctors_attached.map(obj => `${obj.fname} ${obj.lname}`).join(", ")}</ViewValue>
                            </ViewBox>
                        </Col> */}
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>BDMs Attached</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.bdm}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Institute Type</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.institute_type}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Report Release Scenario</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.report_release_scenario}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Report Email CC</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.report_email_cc}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sample Knowledge Email</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.sample_knowledge_email}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Follow-up Email</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.follow_up_email}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Finance Person First Name</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.finance_person_first_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Finance Person Last Name</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.finance_person_last_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Finance Person Mobile No.</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.finance_person_mobile_no}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Finance Person Email CC</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.finance_person_email_cc}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pick Up Partner</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.pick_up_partner}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Report Dispatch Partner</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.report_dispatch_partner}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Billing Account</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.billing_account === true ? "Yes" : 'No'}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Account Receivable</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.account_receivable === true ? "Yes" : 'No'}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Ledger Account</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.ledger_account === true ? "Yes" : 'No'}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>General Ledger Account Code</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.general_ledger_account_code}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Billing Account Type</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.billing_account_type}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Rate At Booking</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.rate_at_booking}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Receipt Mode</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.receipt_mode}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>HQ or Primary Marketing Location"</ViewLabel>
                                <ViewValue>{getInstituteDetailState.data.hq_or_primary_marketing_location}</ViewValue>
                            </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>UHID</ViewLabel>
                            <ViewValue>{getInstituteDetailState.data.uhid}</ViewValue>
                        </ViewBox>
                    </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getInstituteDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getInstituteDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getInstituteDetailState: state.getInstituteDetail
});

const mapDispatchToProps = (dispatch) => ({
    getInstituteDetail: (params) => dispatch(getInstituteDetail(params)),
    getInstituteDetailReset: () => dispatch(getInstituteDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(InstituteMasterView);