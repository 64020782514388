import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_PAGE_CONTENT,
    deletePageContentFail,
    deletePageContentSuccess
} from '../action/deletePageContentAction'

// api 
import deletePageContentApi from '../api/deletePageContentApi'

export function* deletePageContentSaga(action) {
    try {
        const response = yield call(() => deletePageContentApi(action.params))
        if (response.data.status === 1) {
            yield put(deletePageContentSuccess(response, action))
        } else {
            yield put(deletePageContentFail(response, action))
        }
    }
    catch (e) {
        yield put(deletePageContentFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_PAGE_CONTENT, deletePageContentSaga)
}