import React, { useState } from "react";
import axios from "axios";
import Header from "../../../component/Header/Header";
import {
  PageContainer,
  Xpagination,
  Xtable,
  LayoutContainer,
} from "../../../component/Xcomponent";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Input,
  Button,
  Popconfirm,
  notification,
  Space,
  Menu,
  Dropdown,
} from "antd";
import { Link, useLocation, Redirect } from "react-router-dom";
import { useEffect } from "react";
import config from "../../../config";

const NotificationList = () => {
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    records: 25,
  });
  const [notificationList, setNotificationList] = useState([]);
  const [listAccordingToPagination, setListAccordingToPagination] = useState(
    []
  );
  const [onFirstLoad, setOnFirstLoad] = useState(true);

  const columns = [
    {
      title: "Notification name",
      dataIndex: "notification_name",
      render: (notificationType) => <span>{notificationType}</span>,
      sorter: (a, b) => {
        return a.notification_name.localeCompare(b.notification_name);
      },
      sortDirections: ["ascend"],
    },
    {
      title: "Description",
      dataIndex: "notification_description",
      render: (description) => <span>{description}</span>,
    },
    {
      title: "Actions",
      dataIndex: "_id",
      render: (notificationType) => (
        <>
          <Space size="small">
            <Link to={`/notification/${notificationType}`}>
              <Button size="small" shape="circle" icon={<EditOutlined />} />
            </Link>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (notificationList.length == 0) {
      axios({
        url: config.api.base_url + "notification/list",
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          setNotificationList(response.data.data);
          setListAccordingToPagination([...response.data.data.slice(0, 26)]);
          console.log("response", response);
          setOnFirstLoad(false);
        })
        .catch((error) => {
          console.log("error ===> ", error);
        });
    }
  }, [notificationList]);

  useEffect(() => {
    if (!onFirstLoad) {
      const startIndex =
        (pagination.page == 1 ? 0 : pagination.page) * pagination.records;
      setListAccordingToPagination([
        ...notificationList.slice(
          startIndex,
          startIndex + pagination.records + 1
        ),
      ]);
      console.log(
        [
          ...notificationList.slice(
            startIndex,
            startIndex + pagination.records + 1
          ),
        ],
        pagination,
        startIndex + pagination.records + 1,
        "Pagination"
      );
    }
  }, [pagination]);
  return (
    <>
      <Header title="Notification List" />
      <PageContainer>
        <LayoutContainer>
          <Xtable
            dataSource={listAccordingToPagination}
            columns={columns}
            pagination={false}
            scroll={{ x: 768 }}
            // loading={getListTestMasterState.apiState === "loading"}
          />
          <Xpagination
            pageSizeOptions={["10", "15", "25", "50"]}
            showSizeChanger
            onChange={(page, pageSize) =>
              setPagination({ page: page, records: pageSize })
            }
            current={pagination.page}
            defaultPageSize={pagination?.records}
            // total={getListTestMasterState.pagination.total_records}
            total={notificationList.length}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </LayoutContainer>
      </PageContainer>
    </>
  );
};

export default NotificationList;
