import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_QUICK_SUPPORT_QUESTION_ANSWER,
    saveQuickSupportQuestionAnswerFail,
    saveQuickSupportQuestionAnswerSuccess
} from '../action/saveQuickSupportQuestionAnswerAction'

// api 
import saveQuickSupportQuestionAnswerApi from '../api/saveQuickSupportQuestionAnswerApi'

export function* saveQuickSupportQuestionAnswerSaga(action) {
    try {
        const response = yield call(() => saveQuickSupportQuestionAnswerApi(action.params))
        if (response.data.status === 1) {
            yield put(saveQuickSupportQuestionAnswerSuccess(response, action))
        } else {
            yield put(saveQuickSupportQuestionAnswerFail(response, action))
        }
    }
    catch (e) {
        yield put(saveQuickSupportQuestionAnswerFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_QUICK_SUPPORT_QUESTION_ANSWER, saveQuickSupportQuestionAnswerSaga)
}