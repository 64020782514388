import { Space, Layout } from 'antd'
import React from 'react'

/* custom component */
import { Xheader } from "./LoginHeaderStyle"

const LoginHeader = () => {
    return (
        <Xheader>
            <Space>
                <img src="./images/lilac-Insights-logo1.png" alt="Lilac-Logo" style={{ height: 50, width: "auto" }} />
                <span style={{ fontSize: 18 }}>Super Admin Panel</span>
            </Space>
        </Xheader>
    )
}

export default LoginHeader
