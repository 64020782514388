import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PATIENT_DETAIL,
    getPatientDetailFail,
    getPatientDetailSuccess
} from '../action/getPatientDetailAction'

// api 
import getPatientDetailApi from '../api/getPatientDetailApi'

export function* getPatientDetailSaga(action) {
    try {
        const response = yield call(() => getPatientDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getPatientDetailSuccess(response, action))
        } else {
            yield put(getPatientDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getPatientDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PATIENT_DETAIL, getPatientDetailSaga)
}