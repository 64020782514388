import axios from 'axios'
import config from '../config'
import { getUserToken } from "../utils"

const resetPasswordApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}user/reset-password`,
        method: "post",
        data: params,
        headers: {
            "Content-Type": "application/json",
        }
    })

    return response

}

export default resetPasswordApi