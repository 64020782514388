import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_COMMON_MASTER,
    saveCommonMasterFail,
    saveCommonMasterSuccess
} from '../action/saveCommonMasterAction'

// api 
import saveCommonMasterApi from '../api/saveCommonMasterApi'

export function* saveCommonMasterSaga(action) {
    try {
        const response = yield call(() => saveCommonMasterApi(action.params))
        if (response.data.status === 1) {
            yield put(saveCommonMasterSuccess(response, action))
        } else {
            yield put(saveCommonMasterFail(response, action))
        }
    }
    catch (e) {
        yield put(saveCommonMasterFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_COMMON_MASTER, saveCommonMasterSaga)
}