import React, { useEffect } from 'react';
import { Drawer, Skeleton, Row, Col, Button } from 'antd';
import { connect } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
// custom components
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"
import { viewTestMaster, viewTestMasterReset } from '../../../action/viewTestMasterAction'
import { dateFormat } from '../../../utils';


const TestMasterView = (props) => {
    // variables
    const {
        viewTestMaster, viewTestMasterReset, viewTestMasterState,
    } = props

    // Callback
    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            viewTestMasterReset()
        }
    }, [props.visible])

    useEffect(() => {
        if (props.id && props.visible) {
            viewTestMaster({
                id: props.id
            })
        }

    }, [props.id, props.visible])


    return (
        <Drawer
            title={`Test Master View ${viewTestMasterState.apiState === "success" ? "- " + viewTestMasterState.data.lilac_test_id : ""}`}
            placement="right"
            closeIcon={<><Link to={`/test-master/edit/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {viewTestMasterState.apiState === "loading" &&
                <Skeleton active />}

            {viewTestMasterState.apiState === "error" &&
                <p>{viewTestMasterState.message}</p>
            }

            {viewTestMasterState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Test Name</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.test_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Therapeutic Category</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.therapeutic_category}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Test Collection Charges</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.test_collection_charges}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Mandatory Attachments</ViewLabel>
                                <ViewValue>
                                    {viewTestMasterState.data.mandatory_attachments.join(", ")}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Non Mandatory Attachments</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.non_mandatory_attachments.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Patient Stage</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.patient_stage}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Type Of Patient</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.type_of_patient}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Test Group</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.test_group &&
                                    viewTestMasterState.data.test_group.value}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sub Group</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.sub_group}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Keywords Or Aliases</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.keywords_or_aliases}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Test Name</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.test_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Description</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.description}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Details</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.details}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sample Type</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.sample_type &&
                                    viewTestMasterState.data.sample_type.map(data => (
                                        <>
                                            <div>{data.sample_type}</div>
                                        </>
                                    ))}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sub Sample</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.sub_sample}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Technology</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.technology}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Speciality</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.speciality}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pcpndt</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.pcpndt === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Home Collection Possible</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.home_collection_possible === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Resampling required/ possible</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.resampling_required_or_possible === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sample Journey Statuses</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.sample_journey_statuses}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Tat</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.tat}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Can Be Outsourced</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.can_be_outsourced === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Outsource Partner</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.outsource_partner}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Outsource Partner Category</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.outsource_partner_category}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Cprt</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.cprt}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Stagewise Process</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.stagewise_process}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Stagewise Process Tat</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.stagewise_process_tat}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Processing Center</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.processing_center}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Direct Consumer Booking Scope</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.direct_consumer_booking_scope === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Display Test Name</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.display_test_name}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Twin Condition</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.twin_condition}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sex</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.sex.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Is Pre Eclampsia</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.is_pre_eclampsia === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Is Thalasseima</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.is_thalasseima === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Twin Allowed</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.twin_allowed === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Trimester Test</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.trimester_test}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Sample Collection Charges</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.sample_collection_charges}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Gestation Age Start Week</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.gestation_age_start_week}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Gestation Age End Week</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.gestation_age_end_week}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Gestation Age Start Day</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.gestation_age_start_day}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Gestation Age End Day</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.gestation_age_end_day}</ViewValue>
                            </ViewBox>
                        </Col>


                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Twin With Donor Egg</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.twin_with_donor_egg}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Max Age</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.max_age}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Outcomes</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.outcomes}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>MRP</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.mrp}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Transfer Rate</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.transfer_rate}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Tier</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.tier}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Category for Test Directory</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.category_for_test_directory}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Lims Clinical Data</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.lims_clinical_data}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>B2C</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.b2c === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Multiple Patient</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.multiple_patient === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Multiple Sample</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.multiple_sample === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Multiple Report</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.multiple_report === true ? "Yes" : "No"}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Flow</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.flow}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Display</ViewLabel>
                                <ViewValue>{viewTestMasterState.data.display}</ViewValue>
                            </ViewBox>
                        </Col>

                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(viewTestMasterState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(viewTestMasterState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                    </Row>

                </>
            }
        </Drawer>
    )
}
const mapStateToProps = (state) => ({
    viewTestMasterState: state.viewTestMaster,
})

const mapDispatchToProps = (dispatch) => ({
    viewTestMaster: (params) => dispatch(viewTestMaster(params)),
    viewTestMasterReset: () => dispatch(viewTestMasterReset()),
})




export default connect(mapStateToProps, mapDispatchToProps)(TestMasterView)
