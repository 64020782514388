import React, { useEffect, useState } from "react"
import { Link, useLocation, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Button, Input, Popconfirm, notification, Tag } from "antd"
import { EditOutlined, PlusOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons"
import _ from 'lodash'

/* CUSTOM COMPONENT */
import Header from "../../../component/Header/Header"
import { PageContainer, Xpagination, Xtable } from "../../../component/Xcomponent"

/* ACTION */
import { getRoleModuleMappingList, getRoleModuleMappingListReset } from '../../../action/getRoleModuleMappingListAction'
import { deleteRoleModuleMapping, deleteRoleModuleMappingReset } from '../../../action/deleteRoleModuleMappingAction'

/* OTHERS */
import { checkRights } from '../../../utils'


const RoleModuleMappingMasterList = (props) => {
    /* VARIABLES */
    const {
        getRoleModuleMappingList, getRoleModuleMappingListReset, getRoleModuleMappingListState,
        deleteRoleModuleMapping, deleteRoleModuleMappingReset, deleteRoleModuleMappingState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [tableData, setTableData] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [redirect, setRedirect] = useState(["", false])

    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 20,
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }


    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });



    const columns = [
        {
            title: 'Role Name',
            dataIndex: 'role_name',
            key: 'role_name',
            ...getColumnSearchProps('role_name', 'Role Name')
        },
        {
            title: 'Module Name',
            dataIndex: 'module_name',
            key: 'module_name',
            render: (module_name) => <Space wrap>
                {module_name.length > 0 && module_name.map(item => (
                    <Tag style={{ marginBottom: 8 }}>{item.name} </Tag>
                ))}
            </Space>,
            ...getColumnSearchProps('module_name', 'Module Name')
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (action) => (
                <Space size="small">
                    {checkRights("role_module_mapping", "edit") &&
                        <Link to={`/role-module-mapping/save/${action.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    }
                    {checkRights("role_module_mapping", "delete") &&
                        <Popconfirm
                            title="Are you sure to delete this item?"
                            onConfirm={() => deleteRoleModuleMapping({ id: action.id })}
                            okText="Delete"
                            cancelText="Cancel"
                            okType="danger"
                        >
                            <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                        </Popconfirm>
                    }
                </Space >
            )
        },
    ];
    /* CALLBACKS */

    useEffect(() => {
        getRoleModuleMappingList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/role-module-mapping/list?page=${pagination.page}`])

    }, [pagination, refresh, searchText])

    useEffect(() => {
        return (() => {
            getRoleModuleMappingListReset()
            deleteRoleModuleMappingReset()
        })
    }, [])

    useEffect(() => {
        if (deleteRoleModuleMappingState.apiState === "success") {
            notification["success"]({
                message: "Delete Records Successfully"
            })
            deleteRoleModuleMappingReset()
            getRoleModuleMappingList({ dropDownList: "" })
        }
        if (deleteRoleModuleMappingState.apiState === "error") {
            notification["error"]({
                message: "Something went wrong"
            })
            deleteRoleModuleMappingReset()
        }
    }, [deleteRoleModuleMappingState])

    useEffect(() => {
        if (getRoleModuleMappingListState.apiState === "success") {
            let tableData = []
            getRoleModuleMappingListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        role_name: row.role_master.name,
                        module_name: row.module_master,
                        action: { id: row._id }
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getRoleModuleMappingListState])

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                title="Role Module Mapping List"
                rightChildren={checkRights("role_module_mapping", "add") && <Link to="/role-module-mapping/save" ><Button type="primary" ><PlusOutlined />New</Button></Link>}
            />
            <PageContainer>
                {getRoleModuleMappingListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getRoleModuleMappingListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getRoleModuleMappingListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getRoleModuleMappingListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getRoleModuleMappingListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                    </>
                }
            </PageContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    getRoleModuleMappingListState: state.getRoleModuleMappingList,
    deleteRoleModuleMappingState: state.deleteRoleModuleMapping,

})
const mapDispatchToProps = (dispatch) => ({
    getRoleModuleMappingList: (params) => dispatch(getRoleModuleMappingList(params)),
    getRoleModuleMappingListReset: () => dispatch(getRoleModuleMappingListReset()),
    deleteRoleModuleMapping: (params) => dispatch(deleteRoleModuleMapping(params)),
    deleteRoleModuleMappingReset: () => dispatch(deleteRoleModuleMappingReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RoleModuleMappingMasterList)
