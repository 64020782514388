import { call, put, takeLatest, } from 'redux-saga/effects'

/* OTHERS */
import { handleHttpErr } from "../utils"

// action
import {
    GET_DTRFS_LIST,
    getDtrfsListFail,
    getDtrfsListSuccess
} from '../action/getDtrfsListAction'

// api
import getDtrfsListApi from '../api/getDtrfsListApi'


export function* getDtrfsListSaga(action) {
    try {
        // const response = yield call(() => getListTestMasterApi(action.params))
        const response = yield call(getDtrfsListApi, action.params)
        if (response.status === 1) {
            yield put(getDtrfsListSuccess(response, action))
        }
        else {
            yield put(getDtrfsListFail(response, action))
        }
    }
    catch (e) {
        handleHttpErr(e.response)
        yield put(getDtrfsListFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GET_DTRFS_LIST, getDtrfsListSaga)
}