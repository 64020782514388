import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Form, Row, Col, Button, notification, Select } from 'antd'
import { Redirect } from "react-router-dom";

/* CUSTOM COMPONENT */
import Header from '../../../component/Header/Header';
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveAdminType, saveAdminTypeReset } from '../../../action/saveAdminTypeAction'
import { getAdminTypeDetail, getAdminTypeDetailReset } from '../../../action/getAdminTypeDetailAction'
import { getModuleList, getModuleListReset } from '../../../action/getModuleListAction'

const AdminTypeMasterSave = (props) => {
    /* VARIABLES */
    const id = props.match.params.id
    const mode = id ? "edit" : "add"
    const {
        saveAdminType, saveAdminTypeReset, saveAdminTypeState,
        getAdminTypeDetail, getAdminTypeDetailReset, getAdminTypeDetailState,
        getModuleList, getModuleListReset, getModuleListState,
    } = props
    const [formData, setFormData] = useState({
        name: "",
        module_id: "",
    })
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    if (mode === "edit") {
        formData.id = id
        formData.mode = mode
    }

    /* CALLBACKS */
    useEffect(() => {
        return (() => {
            saveAdminTypeReset()
            getAdminTypeDetailReset()
            getModuleListReset()
        })
    }, [])
    useEffect(() => {
        getModuleList({
            dropDownList: "/get-dropdown-list"
        })
        if (mode === "edit") {
            getAdminTypeDetail({
                id: id
            })
        }
    }, [])
    useEffect(() => {
        if (saveAdminTypeState.apiState === "success") {
            notification["success"]({
                message: saveAdminTypeState.message,
                placement: "topRight"
            })
            setRedirect([true, "/admin-type/list"])
        }
        if (saveAdminTypeState.apiState === "error") {
            notification["error"]({
                message: saveAdminTypeState.data,
                placement: "topRight",
            })
        }
    }, [saveAdminTypeState])

    useEffect(() => {
        if (getAdminTypeDetailState.apiState === "success") {
            let data = getAdminTypeDetailState.data
            form.setFieldsValue({
                name: data.name,
                module_id: data.module_id,
            })
            setFormData({
                ...formData,
                ["name"]: data.name,
                ["module_id"]: data.module_id,
            })
        }
    }, [getAdminTypeDetailState])

    /* FUNCTIONS */

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }
    const handleSubmit = () => {
        saveAdminType(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                backButton={"/admin-type/list"}
                title={mode === "edit" ? "Edit Admin Type" : "Add Admin Type"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    name="name"
                                    label="Name"
                                    rules={[{
                                        required: true,
                                        message: "Required"
                                    }]}
                                    onChange={handleChange}
                                >
                                    <Input name="name" placeholder="Enter Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Module"
                                    name="module_id"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        name="module_id"
                                        placeholder="Select Module"
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(v) => handleSelect("module_id", v)}
                                        mode="multiple"
                                    >
                                        {getModuleListState.list.map((row, index) => {
                                            return (
                                                <Select.Option value={`${row._id}`} key={`${index}`}>{row.name}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            mode === "edit" ?
                                <Button loading={getAdminTypeDetailState.apiState === "loading"} type="primary" htmlType="submit">Update Admin Type</Button> :
                                <Button loading={saveAdminTypeState.apiState === "loading"} type="primary" htmlType="submit">Add Admin Type</Button>
                        }
                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveAdminTypeState: state.saveAdminType,
    getAdminTypeDetailState: state.getAdminTypeDetail,
    getModuleListState: state.getModuleList,
})
const mapDispatchToProps = (dispatch) => ({
    saveAdminType: (params) => dispatch(saveAdminType(params)),
    saveAdminTypeReset: () => dispatch(saveAdminTypeReset()),
    getAdminTypeDetail: (params) => dispatch(getAdminTypeDetail(params)),
    getAdminTypeDetailReset: () => dispatch(getAdminTypeDetailReset()),
    getModuleList: (params) => dispatch(getModuleList(params)),
    getModuleListReset: () => dispatch(getModuleListReset()),
})


export default connect(mapStateToProps, mapDispatchToProps)(AdminTypeMasterSave)

