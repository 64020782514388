import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_MODULE_LIST,
    getModuleListFail,
    getModuleListSuccess
} from '../action/getModuleListAction'

// api 
import getModuleListApi from '../api/getModuleListApi'

export function* getModuleListSaga(action) {
    try {
        const response = yield call(() => getModuleListApi(action.params))
        if (response.data.status === 1) {
            yield put(getModuleListSuccess(response, action))
        } else {
            yield put(getModuleListFail(response, action))
        }
    }
    catch (e) {
        yield put(getModuleListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_MODULE_LIST, getModuleListSaga)
}