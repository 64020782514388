import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Form, Row, Col, Button, notification, Select } from 'antd'
import { Redirect } from "react-router-dom";

/* CUSTOM COMPONENT */
import Header from '../../../component/Header/Header';
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveRole, saveRoleReset } from '../../../action/saveRoleAction'
import { getRoleDetail, getRoleDetailReset } from '../../../action/getRoleDetailAction'
import { getAdminTypeList, getAdminTypeListReset } from '../../../action/getAdminTypeListAction'

const RoleMasterSave = (props) => {
    /* VARIABLES */
    const id = props.match.params.id
    const mode = id ? "edit" : "add"
    const {
        saveRole, saveRoleReset, saveRoleState,
        getRoleDetail, getRoleDetailReset, getRoleDetailState,
        getAdminTypeList, getAdminTypeListReset, getAdminTypeListState,
    } = props
    const [formData, setFormData] = useState({
        name: "",
        admin_type_id: "",
    })
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    if (mode === "edit") {
        formData.id = id
        formData.mode = mode
    }

    /* CALLBACKS */
    useEffect(() => {
        return (() => {
            saveRoleReset()
            getRoleDetailReset()
            getAdminTypeListReset()
        })
    }, [])
    useEffect(() => {
        getAdminTypeList({
            dropDownList: "/get-dropdown-list"
        })

        if (mode === "edit") {
            getRoleDetail({
                id: id
            })
        }
    }, [])
    useEffect(() => {
        if (saveRoleState.apiState === "success") {
            notification["success"]({
                message: saveRoleState.message,
                placement: "topRight"
            })
            setRedirect([true, "/role/list"])
        }
        if (saveRoleState.apiState === "error") {
            notification["error"]({
                message: saveRoleState.data,
                placement: "topRight",
            })
        }
    }, [saveRoleState])

    useEffect(() => {
        if (getRoleDetailState.apiState === "success") {
            let data = getRoleDetailState.data
            form.setFieldsValue({
                name: data.name,
                admin_type_id: data.admin_type_id._id,
            })
            setFormData({
                ...formData,
                ["name"]: data.name,
                ["admin_type_id"]: data.admin_type_id._id,
            })
        }
    }, [getRoleDetailState])

    /* FUNCTIONS */

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }

    const handleSubmit = () => {
        saveRole(formData)
    }


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                backButton={"/role/list"}
                title={mode === "edit" ? "Edit Admin Type" : "Add Admin Type"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    name="name"
                                    label="Role Name"
                                    rules={[{
                                        required: true,
                                        message: "Required"
                                    }]}
                                    onChange={handleChange}
                                >
                                    <Input name="name" placeholder="Enter Role Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Admin Type"
                                    name="admin_type_id"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        name="admin_type_id"
                                        placeholder="Select Admin Type"
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(v) => handleSelect("admin_type_id", v)}
                                    >
                                        {getAdminTypeListState.list.map((row, index) => {
                                            return (
                                                <Select.Option value={`${row._id}`} key={`${index}`}>{row.name}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            mode === "edit" ?
                                <Button loading={getRoleDetailState.apiState === "loading"} type="primary" htmlType="submit">Update Role</Button> :
                                <Button loading={saveRoleState.apiState === "loading"} type="primary" htmlType="submit">Add Role</Button>
                        }
                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveRoleState: state.saveRole,
    getRoleDetailState: state.getRoleDetail,
    getAdminTypeListState: state.getAdminTypeList,
})
const mapDispatchToProps = (dispatch) => ({
    saveRole: (params) => dispatch(saveRole(params)),
    saveRoleReset: () => dispatch(saveRoleReset()),
    getRoleDetail: (params) => dispatch(getRoleDetail(params)),
    getRoleDetailReset: () => dispatch(getRoleDetailReset()),
    getAdminTypeList: (params) => dispatch(getAdminTypeList(params)),
    getAdminTypeListReset: () => dispatch(getAdminTypeListReset()),
})


export default connect(mapStateToProps, mapDispatchToProps)(RoleMasterSave)

