import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PAYMENT_HISTORY_LIST,
    getPaymentHistoryListFail,
    getPaymentHistoryListSuccess
} from '../action/getPaymentHistoryListAction'

// api 
import getPaymentHistoryListApi from '../api/getPaymentHistoryListApi'

export function* getPaymentHistoryListSaga(action) {
    try {
        const response = yield call(() => getPaymentHistoryListApi(action.params))
        if (response.data.status === 1) {
            yield put(getPaymentHistoryListSuccess(response, action))
        } else {
            yield put(getPaymentHistoryListFail(response, action))
        }
    }
    catch (e) {
        yield put(getPaymentHistoryListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PAYMENT_HISTORY_LIST, getPaymentHistoryListSaga)
}