import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_CONTAINER,
    saveContainerFail,
    saveContainerSuccess
} from '../action/saveContainerAction'

// api 
import saveContainerApi from '../api/saveContainerApi'

export function* saveContainerSaga(action) {
    try {
        const response = yield call(() => saveContainerApi(action.params))
        if (response) {
            yield put(saveContainerSuccess(response, action))
        }
        else {
            yield put(saveContainerFail(response, action))
        }
    }
    catch (e) {
        yield put(saveContainerFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_CONTAINER, saveContainerSaga)
}