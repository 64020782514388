import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PREGNANCY_HIGHLIGHTS_DETAIL,
    getPregnancyHighlightsDetailFail,
    getPregnancyHighlightsDetailSuccess
} from '../action/getPregnancyHighlightsDetailAction'

// api 
import getPregnancyHighlightsDetailApi from '../api/getPregnancyHighlightsDetailApi'

export function* getPregnancyHighlightsDetailSaga(action) {
    try {
        const response = yield call(() => getPregnancyHighlightsDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getPregnancyHighlightsDetailSuccess(response, action))
        } else {
            yield put(getPregnancyHighlightsDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getPregnancyHighlightsDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PREGNANCY_HIGHLIGHTS_DETAIL, getPregnancyHighlightsDetailSaga)
}