import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Checkbox, Form, Row, Col, Button, notification, Select } from 'antd'
import { Redirect } from "react-router-dom";
import _ from 'lodash'

/* CUSTOM COMPONENT */
import Header from '../../../component/Header/Header';
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveRoleModuleMapping, saveRoleModuleMappingReset } from '../../../action/saveRoleModuleMappingAction'
import { getRoleModuleMappingDetail, getRoleModuleMappingDetailReset } from '../../../action/getRoleModuleMappingDetailAction'
import { getModuleListByRole, getModuleListByRoleReset } from '../../../action/getModuleListByRoleAction'
import { getRoleList, getRoleListReset } from '../../../action/getRoleListAction'
import { UserRightTable } from '../../../component/UserRights/AddUserRightsStyle';

const RoleModuleMappingMasterSave = (props) => {
    /* VARIABLES */
    const id = props.match.params.id
    const mode = id ? "edit" : "add"
    const {
        saveRoleModuleMapping, saveRoleModuleMappingReset, saveRoleModuleMappingState,
        getRoleModuleMappingDetail, getRoleModuleMappingDetailReset, getRoleModuleMappingDetailState,
        getModuleListByRole, getModuleListByRoleReset, getModuleListByRoleState,
        getRoleList, getRoleListReset, getRoleListState,
    } = props
    const [roleModuleData, setRoleModuleData] = useState({
        role_id: "",
        module_id: "",
    })

    const [initialData, setInitialData] = useState({
        test_master: [],
        institute_master: [],
        sample: [],
        container: [],
        sample_container_test_map_master: [],
        doctor_master: [],
        institute_test_pricing_master: [],
        page_content: [],
        user_term_and_condition: [],
        content_repo: [],
        pregnancy_data: [],
        employee_master: [],
        bdm_master: [],
        patient_master: [],
        coupon_engine: [],
        payment_history: [],
        chat_history: [],
        testimonial: [],
        consulation_dashboard: [],
        user: [],
        // user_rights: [],
        module: [],
        admin_type: [],
        role: [],
        role_module_mapping: [],
        super_dtrf: [],
        super_dtrf_dashboard: [],
        start_dashboard: [],
        emergency_dtrf_dashboard: [],
        patient_test_book: [],
        dtrf: [],
        mou: [],
        contact_us_enquries:[],
        doctor_signup_request:[],
        shipments:[],
        shipment_containers:[],
    })
    const [formData, setFormData] = useState(initialData)

    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()
    const [roleList, setRoleList] = useState([])
    if (mode === "edit") {
        roleModuleData.id = id
        roleModuleData.mode = mode
    }

    /* CALLBACKS */
    useEffect(() => {
        return (() => {
            saveRoleModuleMappingReset()
            getRoleModuleMappingDetailReset()
            getModuleListByRoleReset()
            // getRoleListReset()
        })
    }, [])
    useEffect(() => {

        getRoleList({
            dropDownList: "/get-dropdown-list"
        })

        if (mode === "edit") {
            getRoleModuleMappingDetail({
                id: id
            })

        }
    }, [])

    useEffect(() => {
        if (getRoleListState.apiState === "success") {
            setRoleList(getRoleListState.list)
            getRoleListReset()
        }
    }, [getRoleListState])

    useEffect(() => {
        if (saveRoleModuleMappingState.apiState === "success") {
            notification["success"]({
                message: saveRoleModuleMappingState.message,
                placement: "topRight"
            })
            setRedirect([true, "/role-module-mapping/list"])
        }
        if (saveRoleModuleMappingState.apiState === "error") {
            notification["error"]({
                message: saveRoleModuleMappingState.data,
                placement: "topRight",
            })
        }
    }, [saveRoleModuleMappingState])

    useEffect(() => {
        if (getRoleModuleMappingDetailState.apiState === "success") {
            let data = getRoleModuleMappingDetailState.data
            getModuleListByRole({
                role_id: data.role_id._id
            })
            let module = []
            data.module_id.map(item => {
                module.push(item._id)
            })
            form.setFieldsValue({
                role_id: data.role_id._id,
                module_id: module,
            })
            setRoleModuleData({
                ...roleModuleData,
                ["role_id"]: data.role_id._id,
                ["module_id"]: module,
            })
            setFormData(data.rights)
        }
    }, [getRoleModuleMappingDetailState])

    /* FUNCTIONS */

    const handleSelect = (name, v) => {
        setRoleModuleData({
            ...roleModuleData,
            [name]: v
        })
    }
    const handleSelectRole = (name, v) => {
        setRoleModuleData({
            ...roleModuleData,
            [name]: v,
            ["module_id"]: ""
        })
        form.setFieldsValue({
            module_id: []
        })
        setFormData(initialData)
        getModuleListByRole({
            role_id: v
        })



    }

    const handleSubmit = () => {
        let user_rights = { rights: formData }
        saveRoleModuleMapping({ ...roleModuleData, ...user_rights })
    }

    const handleCheckboxChanged = (checked, module, sub_module) => {
        if (checked) {
            setFormData({
                ...formData, [module]: formData[module] ? formData[module].concat([sub_module]) : [sub_module]
            })
        } else {
            let arr = formData[module]
            arr = arr.length > 0 ? arr.filter(el => el !== sub_module) : []
            setFormData({ ...formData, [module]: arr })
        }
    }
    const handleAllCheckBox = (checked, module, sub_modules) => {
        if (checked) {
            setFormData({
                ...formData, [module]: sub_modules
            })
        } else {
            setFormData({
                ...formData, [module]: []
            })
        }
    }

    const onDeselect = (value) => {
        let key = _.find(getModuleListByRoleState.list, { _id: value })?.key
        setFormData({ ...formData, [key]: [] })
    }


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                backButton={"/role-module-mapping/list"}
                title={mode === "edit" ? "Edit Role Module Mapping" : "Add Role Module Mapping"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Role"
                                    name="role_id"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        name="role_id"
                                        placeholder="Select Role"
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(v) => handleSelectRole("role_id", v)}
                                    >
                                        {roleList.map((row, index) => {
                                            return (
                                                <Select.Option value={`${row._id}`} key={`${index}`}>{row.name}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Module"
                                    name="module_id"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        name="module_id"
                                        placeholder="Select Module"
                                        showSearch
                                        allowClear
                                        onDeselect={(v) => onDeselect(v)}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(v) => handleSelect("module_id", v)}
                                        mode="multiple"
                                    >
                                        {getModuleListByRoleState.list.map((row, index) => {
                                            return (
                                                <Select.Option value={`${row._id}`} key={`${index}`}>{row.name}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <UserRightTable>
                                <thead >
                                    <tr>
                                        <th>
                                            Module Name
                                        </th>
                                        <th>
                                            List
                                        </th>
                                        <th>
                                            Add
                                        </th>
                                        <th>
                                            Edit
                                        </th>
                                        <th>
                                            Delete
                                        </th>
                                        <th>
                                            View
                                        </th>
                                        <th>
                                            Check/Uncheck
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "test_master" })?._id) &&
                                        <>

                                            <tr>
                                                <td className="centerAlign">
                                                    Test Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['test_master'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'test_master', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['test_master'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'test_master', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['test_master'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'test_master', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['test_master'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'test_master', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['test_master'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'test_master', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.test_master && formData.test_master.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "test_master", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "institute_master" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Institute Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_master'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_master', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_master'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_master', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_master'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_master', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_master'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_master', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_master'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_master', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.institute_master && formData.institute_master.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "institute_master", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "sample" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Sample Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.sample && formData.sample.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "sample", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "container" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Container Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['container'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'container', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['container'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'container', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['container'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'container', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['container'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'container', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['container'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'container', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.container && formData.container.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "container", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "sample_container_test_map_master" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Sample Container Test Map Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample_container_test_map_master'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample_container_test_map_master', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample_container_test_map_master'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample_container_test_map_master', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample_container_test_map_master'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample_container_test_map_master', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample_container_test_map_master'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample_container_test_map_master', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['sample_container_test_map_master'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'sample_container_test_map_master', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.sample_container_test_map_master && formData.sample_container_test_map_master.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "sample_container_test_map_master", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "doctor_master" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Doctor Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['doctor_master'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'doctor_master', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['doctor_master'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'doctor_master', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['doctor_master'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'doctor_master', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['doctor_master'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'doctor_master', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['doctor_master'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'doctor_master', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.doctor_master && formData.doctor_master.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "doctor_master", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "institute_test_pricing_master" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Institute Test Pricing Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_test_pricing_master'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_test_pricing_master', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_test_pricing_master'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_test_pricing_master', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_test_pricing_master'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_test_pricing_master', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_test_pricing_master'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_test_pricing_master', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['institute_test_pricing_master'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'institute_test_pricing_master', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.institute_test_pricing_master && formData.institute_test_pricing_master.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "institute_test_pricing_master", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "page_content" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Page Content
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['page_content'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'page_content', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['page_content'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'page_content', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['page_content'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'page_content', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['page_content'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'page_content', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['page_content'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'page_content', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.page_content && formData.page_content.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "page_content", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "user_term_and_condition" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    User Term And Condition
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user_term_and_condition'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user_term_and_condition', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user_term_and_condition'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user_term_and_condition', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user_term_and_condition'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user_term_and_condition', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user_term_and_condition'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user_term_and_condition', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user_term_and_condition'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user_term_and_condition', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.user_term_and_condition && formData.user_term_and_condition.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "user_term_and_condition", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "content_repo" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Content Repo
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['content_repo'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'content_repo', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['content_repo'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'content_repo', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['content_repo'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'content_repo', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['content_repo'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'content_repo', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['content_repo'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'content_repo', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.content_repo && formData.content_repo.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "content_repo", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "pregnancy_data" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Pregnancy Data
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['pregnancy_data'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'pregnancy_data', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['pregnancy_data'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'pregnancy_data', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['pregnancy_data'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'pregnancy_data', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['pregnancy_data'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'pregnancy_data', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['pregnancy_data'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'pregnancy_data', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.pregnancy_data && formData.pregnancy_data.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "pregnancy_data", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "employee_master" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Employee Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['employee_master'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'employee_master', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['employee_master'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'employee_master', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['employee_master'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'employee_master', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['employee_master'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'employee_master', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['employee_master'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'employee_master', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.employee_master && formData.employee_master.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "employee_master", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }

                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "bdm_master" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    BDM Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['bdm_master'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'bdm_master', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['bdm_master'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'bdm_master', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['bdm_master'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'bdm_master', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['bdm_master'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'bdm_master', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['bdm_master'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'bdm_master', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.bdm_master && formData.bdm_master.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "bdm_master", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "patient_master" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Patient Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['patient_master'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'patient_master', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['patient_master'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'patient_master', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['patient_master'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'patient_master', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['patient_master'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'patient_master', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['patient_master'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'patient_master', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.patient_master && formData.patient_master.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "patient_master", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "coupon_engine" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Coupon Engine
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['coupon_engine'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'coupon_engine', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['coupon_engine'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'coupon_engine', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['coupon_engine'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'coupon_engine', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['coupon_engine'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'coupon_engine', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['coupon_engine'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'coupon_engine', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.coupon_engine && formData.coupon_engine.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "coupon_engine", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "testimonial" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Testimonial
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['testimonial'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'testimonial', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.testimonial && formData.testimonial.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "testimonial", ["list"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "consulation_dashboard" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Consulation Dashboard
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['consulation_dashboard'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'consulation_dashboard', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                </td>
                                                <td className="centerAlign">
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['consulation_dashboard'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'consulation_dashboard', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.consulation_dashboard && formData.consulation_dashboard.length === 2}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "consulation_dashboard", ["list", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "payment_history" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Payment History
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['payment_history'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'payment_history', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.payment_history && formData.payment_history.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "payment_history", ["list"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "chat_history" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Chat History
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['chat_history'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'chat_history', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.chat_history && formData.chat_history.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "chat_history", ["list"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "user" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    User
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.user && formData.user.length === 4}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "user", ["add", "list", "edit", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {/* {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "user_rights" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    User Rights
                                                </td>
                                                <td className="centerAlign">
                                                </td>
                                                <td className="centerAlign">
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['user_rights'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'user_rights', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                </td>
                                                <td className="centerAlign">
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.user_rights && formData.user_rights.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "user_rights", ["edit"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    } */}

                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "module" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Module Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['module'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'module', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['module'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'module', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['module'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'module', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['module'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'module', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['module'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'module', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.module && formData.module.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "module", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "admin_type" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Admin Type Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['admin_type'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'admin_type', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['admin_type'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'admin_type', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['admin_type'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'admin_type', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['admin_type'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'admin_type', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['admin_type'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'admin_type', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.admin_type && formData.admin_type.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "admin_type", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "role" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Role Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.role && formData.role.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "role", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "role_module_mapping" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Role Module Mapping Master
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role_module_mapping'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role_module_mapping', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role_module_mapping'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role_module_mapping', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role_module_mapping'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role_module_mapping', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role_module_mapping'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role_module_mapping', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['role_module_mapping'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'role_module_mapping', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.role_module_mapping && formData.role_module_mapping.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "role_module_mapping", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "super_dtrf" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Super DTRF
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['super_dtrf'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'super_dtrf', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.super_dtrf && formData.super_dtrf.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "super_dtrf", ["list"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "super_dtrf_dashboard" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Super DTRF Dashboard
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['super_dtrf_dashboard'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'super_dtrf_dashboard', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.super_dtrf_dashboard && formData.super_dtrf_dashboard.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "super_dtrf_dashboard", ["list"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "start_dashboard" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    STAR Dashboard
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['start_dashboard'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'start_dashboard', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.start_dashboard && formData.start_dashboard.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "start_dashboard", ["list"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "emergency_dtrf_dashboard" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Emergency DTRF Dashboard
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['emergency_dtrf_dashboard'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'emergency_dtrf_dashboard', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.emergency_dtrf_dashboard && formData.emergency_dtrf_dashboard.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "emergency_dtrf_dashboard", ["list"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "patient_test_book" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Patient Test Book
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['patient_test_book'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'patient_test_book', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">

                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.patient_test_book && formData.patient_test_book.length === 1}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "patient_test_book", ["list"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "dtrf" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    DTRF
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['dtrf'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'dtrf', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['dtrf'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'dtrf', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['dtrf'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'dtrf', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['dtrf'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'dtrf', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['dtrf'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'dtrf', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.dtrf && formData.dtrf.length === 5}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "dtrf", ["add", "list", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "mou" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                    Mou
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['mou'], el => el === 'list'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'mou', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['mou'], el => el === 'add'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'mou', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['mou'], el => el === 'edit'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'mou', 'edit')} ></Checkbox>
                                                </td>
                                            <td className="centerAlign">
                                                <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['mou'], el => el === 'delete'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'mou', 'delete')} ></Checkbox>
                                            </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['mou'], el => el === 'view'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'mou', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                <Checkbox checked={formData.mou && formData.mou.length === 5}
                                                    onChange={(e) => handleAllCheckBox(e.target.checked, "mou", ["add", "list", "delete","edit", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "contact_us_enquries" })?._id) &&
                                        <>
                                            <tr>
                                                <td className="centerAlign">
                                                Contact Us Enquries
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['contact_us_enquries'], el => el === 'list'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'contact_us_enquries', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['contact_us_enquries'], el => el === 'add'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'contact_us_enquries', 'add')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['contact_us_enquries'], el => el === 'edit'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'contact_us_enquries', 'edit')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['contact_us_enquries'], el => el === 'delete'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'contact_us_enquries', 'delete')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                    checked={(formData !== undefined && _.some(formData['contact_us_enquries'], el => el === 'view'))}
                                                    onChange={(e) => handleCheckboxChanged(e.target.checked, 'contact_us_enquries', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                <Checkbox checked={formData.contact_us_enquries && formData.contact_us_enquries.length === 5}
                                                    onChange={(e) => handleAllCheckBox(e.target.checked, "contact_us_enquries", ["add", "list", "delete", "edit", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "doctor_signup_request" })?._id) &&
                                        <>

                                            <tr>
                                                <td className="centerAlign">
                                                    Doctor Signup Request
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['doctor_signup_request'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'doctor_signup_request', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    
                                                </td>
                                                <td className="centerAlign">
                                                    
                                                </td>
                                                <td className="centerAlign">
                                                    
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['doctor_signup_request'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'doctor_signup_request', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.doctor_signup_request && formData.doctor_signup_request.length === 2}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "doctor_signup_request", ["list", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "shipments" })?._id) &&
                                        <>

                                            <tr>
                                                <td className="centerAlign">
                                                    Shipments
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipments'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipments', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipments'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipments', 'add')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipments'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipments', 'edit')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipments'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipments', 'delete')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipments'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipments', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.doctor_signup_request && formData.doctor_signup_request.length === 2}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "shipments", ["list", "add", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {_.includes(roleModuleData.module_id, _.find(getModuleListByRoleState.list, { key: "shipment_containers" })?._id) &&
                                        <>

                                            <tr>
                                                <td className="centerAlign">
                                                    Shipment Containers
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipment_containers'], el => el === 'list'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipment_containers', 'list')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipment_containers'], el => el === 'add'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipment_containers', 'add')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipment_containers'], el => el === 'edit'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipment_containers', 'edit')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipment_containers'], el => el === 'delete'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipment_containers', 'delete')}></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox
                                                        checked={(formData !== undefined && _.some(formData['shipment_containers'], el => el === 'view'))}
                                                        onChange={(e) => handleCheckboxChanged(e.target.checked, 'shipment_containers', 'view')} ></Checkbox>
                                                </td>
                                                <td className="centerAlign">
                                                    <Checkbox checked={formData.doctor_signup_request && formData.doctor_signup_request.length === 2}
                                                        onChange={(e) => handleAllCheckBox(e.target.checked, "shipment_containers", ["list", "add", "edit", "delete", "view"])}
                                                    ></Checkbox>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </UserRightTable>
                        </Row>
                        {
                            mode === "edit" ?
                                <Button loading={getRoleModuleMappingDetailState.apiState === "loading"} type="primary" htmlType="submit">Update Mapping</Button> :
                                <Button loading={saveRoleModuleMappingState.apiState === "loading"} type="primary" htmlType="submit">Add Mapping</Button>
                        }
                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveRoleModuleMappingState: state.saveRoleModuleMapping,
    getRoleModuleMappingDetailState: state.getRoleModuleMappingDetail,
    getModuleListByRoleState: state.getModuleListByRole,
    getRoleListState: state.getRoleList,
})
const mapDispatchToProps = (dispatch) => ({
    saveRoleModuleMapping: (params) => dispatch(saveRoleModuleMapping(params)),
    saveRoleModuleMappingReset: () => dispatch(saveRoleModuleMappingReset()),
    getRoleModuleMappingDetail: (params) => dispatch(getRoleModuleMappingDetail(params)),
    getRoleModuleMappingDetailReset: () => dispatch(getRoleModuleMappingDetailReset()),
    getModuleListByRole: (params) => dispatch(getModuleListByRole(params)),
    getModuleListByRoleReset: () => dispatch(getModuleListByRoleReset()),
    getRoleList: (params) => dispatch(getRoleList(params)),
    getRoleListReset: () => dispatch(getRoleListReset()),
})


export default connect(mapStateToProps, mapDispatchToProps)(RoleModuleMappingMasterSave)

