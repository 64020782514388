import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import { Space, Button, Avatar, Rate, Popconfirm, notification, Tag } from "antd"
import { CheckOutlined, CloseOutlined } from "@ant-design/icons"

/* custom component */
import Header from "../../../component/Header/Header"
import { Xtable, PageContainer } from '../../../component/Xcomponent'

/* action */
import { getTestimonialList, getTestimonialListReset } from '../../../action/getTestimonialListAction'
import { changeTestimonialStatus, changeTestimonialStatusReset } from '../../../action/changeTestimonialStatusAction'

/* other */
import { s3_cdn, capFirstLt } from "../../../utils"

const TestimonialList = (props) => {
    /* variables */
    const {
        getTestimonialList, getTestimonialListReset, getTestimonialListState,
        changeTestimonialStatus, changeTestimonialStatusReset, changeTestimonialStatusState,
    } = props
    const [tableData, setTableData] = useState([])
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const columns = [
        {
            title: 'Doctor',
            dataIndex: 'doctor',
            key: 'doctor',
            render: doctor => <div>
                <Avatar size="large" src={`${s3_cdn()}doctor/${doctor?.profile_picture}`} />
                <span style={{ marginLeft: 4 }} >{`${doctor.fname} ${doctor.lname}`}</span>
            </div>
        },
        {
            title: 'Rating',
            dataIndex: 'rating',
            key: 'rating',
            render: rating => <Rate defaultValue={rating} disabled />
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment'
        },
        {
            title: 'Patient',
            dataIndex: 'patient',
            key: 'patient',
            render: patient => <div>
                <Avatar size="large" src={`${s3_cdn()}patient/${patient?.profile_pic}`} />
                {/* new code start */}
                {
                    patient ? <span style={{ marginLeft: 4 }} >{`${patient?.name.firstName} ${patient?.name.lastName}`}</span> : <span></span>
                }
                {/* new code end */}
                {/* older one */}
             {/* <span style={{ marginLeft: 4 }} >{`${patient?.name.firstName} ${patient?.name.lastName}`}</span> */}
            </div>
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (obj) => {
                let jsx = null
                if (obj.status === 'pending') {
                    jsx = <Space size="small">
                        <Popconfirm
                            title="Approve the testimonial?"
                            onConfirm={() => handleChangeStatusClick(obj.testimonial_id, 'approved')}
                            okText="Approve"
                            cancelText="Cancel"
                        >
                            <Button size="small" icon={<CheckOutlined />} />
                        </Popconfirm>
                        <Popconfirm
                            title="Reject the testimonial?"
                            onConfirm={() => handleChangeStatusClick(obj.testimonial_id, 'rejected')}
                            okText="Reject"
                            cancelText="Cancel"
                            okType="danger"
                        >
                            <Button size="small" icon={<CloseOutlined />} />
                        </Popconfirm>
                    </Space>
                } else {
                    jsx = <Tag color={obj.status === 'approved' ? '#7ab75f' : '#f50'}>{capFirstLt(obj.status)}</Tag>
                }

                return jsx
            }
        },
    ];

    /* callbacks */
    useEffect(() => {
        getTestimonialList()
    }, [refresh])

    useEffect(() => {
        if (getTestimonialListState.apiState === "success") {
            let rows = []
            getTestimonialListState.list.map(row => {
                rows.push({
                    doctor: row.doctor_id,
                    rating: row.rating,
                    comment: row.comment,
                    patient: row.patient_id,
                    action: { testimonial_id: row._id, status: row.status }
                })
            })
            setTableData(rows)
        }
    }, [getTestimonialListState])

    useEffect(() => {
        if (changeTestimonialStatusState.apiState === "error") {
            notification["error"]({
                message: changeTestimonialStatusState.message,
                placement: "topRight"
            })
            changeTestimonialStatusReset()
        }
        if (changeTestimonialStatusState.apiState === "success") {
            notification["success"]({
                message: changeTestimonialStatusState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
            changeTestimonialStatusReset()
        }
    }, [changeTestimonialStatusState])

    /* function */
    const handleChangeStatusClick = (testimonial_id, status) => {
        changeTestimonialStatus({
            testimonial_id: testimonial_id,
            status: status
        })
    }



    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                title="Testimonial List"
            />
            <PageContainer>
                {getTestimonialListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getTestimonialListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {
                    getTestimonialListState.apiState !== "error" &&
                    <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                        loading={getTestimonialListState.apiState === "loading"}
                    />
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getTestimonialListState: state.getTestimonialList,
    changeTestimonialStatusState: state.changeTestimonialStatus
});

const mapDispatchToProps = (dispatch) => ({
    getTestimonialList: (params) => dispatch(getTestimonialList(params)),
    getTestimonialListReset: () => dispatch(getTestimonialListReset()),
    changeTestimonialStatus: (params) => dispatch(changeTestimonialStatus(params)),
    changeTestimonialStatusReset: () => dispatch(changeTestimonialStatusReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialList);