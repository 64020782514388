import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PAGE_CONTENT_DETAIL,
    getPageContentDetailFail,
    getPageContentDetailSuccess
} from '../action/getPageContentDetailAction'

// api 
import getPageContentDetailApi from '../api/getPageContentDetailApi'

export function* getPageContentDetailSaga(action) {
    try {
        const response = yield call(() => getPageContentDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getPageContentDetailSuccess(response, action))
        } else {
            yield put(getPageContentDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getPageContentDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PAGE_CONTENT_DETAIL, getPageContentDetailSaga)
}