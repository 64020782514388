import React from "react"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"
import BulkUpload from "../../../component/BulkUpload/BulkUpload"

/* actions */

/* otehrs */

export const EmployeeMasterBulkUpload = props => {
    /* variables */
    return (
        <>
            <Header
                backButton={"/employee-master/list"}
                title={"Employee Master Bulk Upload"}
            />
            <PageContainer>
                <LayoutContainer>
                    <BulkUpload master="employeeMaster" backUrl="/employee-master/list" apiUrl="employee/bulk-upload" />
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

export default EmployeeMasterBulkUpload;