import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_COMMON_MASTER_LIST,
    getCommonMasterListFail,
    getCommonMasterListSuccess
} from '../action/getCommonMasterListAction'

// api 
import getCommonMasterListApi from '../api/getCommonMasterListApi'

export function* getCommonMasterListSaga(action) {
    try {
        const response = yield call(() => getCommonMasterListApi(action.params))
        if (response.data.status === 1) {
            yield put(getCommonMasterListSuccess(response, action))
        } else {
            yield put(getCommonMasterListFail(response, action))
        }
    }
    catch (e) {
        yield put(getCommonMasterListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_COMMON_MASTER_LIST, getCommonMasterListSaga)
}