import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_CONTACT_US_ENQURIES_LIST,
    getContactUsEnquriesListFail,
    getContactUsEnquriesListSuccess
} from '../action/getContactUsEnquriesListAction'

// api 
import getContactUsEnquriesListApi from '../api/getContactUsEnquriesListApi'

export function* getContactUsEnquriesListSaga(action) {
    try {
        const response = yield call(() => getContactUsEnquriesListApi(action.params))
        if (response.data.status === 1) {
            yield put(getContactUsEnquriesListSuccess(response, action))
        } else {
            yield put(getContactUsEnquriesListFail(response, action))
        }
    }
    catch (e) {
        yield put(getContactUsEnquriesListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_CONTACT_US_ENQURIES_LIST, getContactUsEnquriesListSaga)
}