import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_USER_TERM_AND_CONDITION_DETAIL,
    getUserTermAndConditionDetailFail,
    getUserTermAndConditionDetailSuccess
} from '../action/getUserTermAndConditionDetailAction'

// api 
import getUserTermAndConditionDetailApi from '../api/getUserTermAndConditionDetailApi'

export function* getUserTermAndConditionDetailSaga(action) {
    try {
        const response = yield call(() => getUserTermAndConditionDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getUserTermAndConditionDetailSuccess(response, action))
        } else {
            yield put(getUserTermAndConditionDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getUserTermAndConditionDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_USER_TERM_AND_CONDITION_DETAIL, getUserTermAndConditionDetailSaga)
}