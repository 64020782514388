import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SYSTEM_USER_DETAIL,
    systemUserDetailFail,
    systemUserDetailSuccess
} from '../action/systemUserDetailAction'

// api 
import systemUserDetailApi from '../api/systemUserDetailApi'

export function* systemUserListSaga(action) {
    try {
        const response = yield call(() => systemUserDetailApi(action.params))
        if (response) {
            yield put(systemUserDetailSuccess(response, action))
        }
    }
    catch (e) {
        yield put(systemUserDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SYSTEM_USER_DETAIL, systemUserListSaga)
}