import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, DatePicker, InputNumber, Tag, Divider } from "antd"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import moment from 'moment'
import _ from "lodash"
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { getPatientDetail, getPatientDetailReset } from "../../../action/getPatientDetailAction"
import { savePatient, savePatientReset } from "../../../action/savePatientAction"

/* otehrs */
import { s3_cdn, validateImageFile, getS3SingedUrl } from '../../../utils'



const { Option } = Select

let index = 0

const PatientMasterSave = props => {
    /* variables */
    const patient_id = props.match.params.patient_id
    const mode = patient_id ? "edit" : "add"
    const {
        getPatientDetail, getPatientDetailReset, getPatientDetailState,
        savePatient, savePatientReset, savePatientState,
    } = props
    const [imageFile, setImageFile] = useState({
        fileName: null,
        isChanged: false,
        edit: true
    })
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        gender: "",
        dob: "",
        lmp_as_of: "",
        lmp: "",
        interest: "",
        tribe: "",
        conceive_status: "",
        patient_id: "",
    })
    if (mode === "edit") {
        formData.id = patient_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    const [interest, setInterest] = useState({
        items: [],
        name: ""
    })
    const [tribe, setTribe] = useState({
        items: [],
        name: ""
    })

    /* callbacks */
    useEffect(() => {
        return (() => {
            savePatientReset()
            getPatientDetailReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getPatientDetail({
                id: patient_id
            })
        }
    }, [])




    useEffect(() => {
        if (getPatientDetailState.apiState === "success") {
            setInterest({ items: getPatientDetailState.data.interest })
            setTribe({ items: getPatientDetailState.data.tribe })
            form.setFieldsValue({
                firstName: getPatientDetailState.data.name.firstName,
                lastName: getPatientDetailState.data.name.lastName,
                phone: getPatientDetailState.data.phone,
                email: getPatientDetailState.data.email,
                gender: getPatientDetailState.data.gender,
                dob: getPatientDetailState.data.dob ? moment(getPatientDetailState.data.dob) : "",
                lmp_as_of: getPatientDetailState.data.lmp_as_of ? moment(getPatientDetailState.data.lmp_as_of) : "",
                lmp: getPatientDetailState.data.lmp ? moment(getPatientDetailState.data.lmp) : "",
                interest: getPatientDetailState.data.interest,
                tribe: getPatientDetailState.data.tribe,
                conceive_status: getPatientDetailState.data.conceive_status,

            })
            setFormData({
                ...formData,
                ['firstName']: getPatientDetailState.data.name.firstName,
                ['lastName']: getPatientDetailState.data.name.lastName,
                ['phone']: getPatientDetailState.data.phone,
                ['email']: getPatientDetailState.data.email,
                ['gender']: getPatientDetailState.data.gender,
                ['dob']: moment(getPatientDetailState.data.dob),
                ['lmp_as_of']: moment(getPatientDetailState.data.lmp_as_of),
                ['lmp']: moment(getPatientDetailState.data.lmp),
                ['interest']: getPatientDetailState.data.interest,
                ['tribe']: getPatientDetailState.data.tribe,
                ['conceive_status']: getPatientDetailState.data.conceive_status,
                ['patient_id']: getPatientDetailState.data.patient_id,
            })
        }
    }, [getPatientDetailState])


    useEffect(() => {
        if (savePatientState.apiState === "success") {
            savePatientReset()
            setRedirect([true, "/patient-master/list"])
            notification["success"]({
                message: savePatientState.message,
                placement: "topRight"
            })
        }
        if (savePatientState.apiState === "error") {
            savePatientReset()
            notification["error"]({
                message: savePatientState.message,
                placement: "topRight"
            })
        }
    }, [savePatientState])



    /* functions */
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleDateChange = (name, date, dateString) => {
        setFormData({ ...formData, [name]: moment(date) })
    }



    const handleSelectChange = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }


    const handleSubmit = async () => {
        if (imageFile.isChanged) {
            let imagefilename = uuidv4()
            let imageValidationRes = validateImageFile(imageFile.fileName)
            if (imageValidationRes.status) {
                let s3UrlResImage = await getS3SingedUrl(imagefilename, imageValidationRes.ext, imageFile.fileName, 'patient')
                await axios.put(s3UrlResImage.url, imageFile.fileName)
                formData.profile_picture = `${imagefilename}.${imageValidationRes.ext}`
                formData.profile_picture_changed = true
            }
            else {
                alert("Select image type JPG or PNG.")
                return
            }
        }
        else {
            formData.profile_picture_changed = false
        }
        savePatient(formData)
    }

    const onNameChangeInterest = event => {
        setInterest({
            items: interest.items,
            name: event.target.value,
        })
    }

    const addItemInterest = () => {
        if (interest.name !== "") {
            const { items, name } = interest
            setInterest({
                items: [...items, name],
                name: '',
            })
        }
    }

    const onNameChangeTribe = event => {
        setTribe({
            items: tribe.items,
            name: event.target.value,
        })
    }

    const addItemTribe = () => {
        if (tribe.name !== "") {
            const { items, name } = tribe
            setTribe({
                items: [...items, name],
                name: '',
            })
        }
    }
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/patient-master/list"}
                title={mode === "edit" ? "Edit Patient Master" : "Add Patient Master"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >

                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input name="firstName" placeholder="Enter First Name"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                >
                                    <Input name="lastName" placeholder="Enter Last Name"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Phone Number"
                                    name="phone"
                                    rules={[
                                        { required: true, message: 'Required' },
                                        {
                                            pattern: new RegExp('^[6-9]\\d{9}$'),
                                            message: 'Phone number is not valid',
                                        }
                                    ]}
                                >
                                    <Input name="phone" placeholder="Enter Phone Number"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Required' },
                                        { type: 'email', message: 'Email is not valid' },
                                    ]}
                                >
                                    <Input name="email" placeholder="Enter Email"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Gender"
                                    name="gender"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        name="gender"
                                        placeholder="Select Gender"
                                        allowClear
                                        onChange={(v) => handleSelectChange("gender", v)}
                                    >
                                        <Select.Option value={"Male"} >Male</Select.Option>
                                        <Select.Option value={"Female"} >Female</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="DOB"
                                    name="dob"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <DatePicker name="dob" placeholder="Enter DOB"
                                        onChange={(date, dateString) => handleDateChange("dob", date, dateString)}
                                        style={{ width: "100%" }}
                                        format={'DD/MM/YYYY'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="LMP"
                                    name="lmp"
                                >
                                    <DatePicker name="lmp" placeholder="Enter LMP"
                                        onChange={(date, dateString) => handleDateChange("lmp", date, dateString)}
                                        style={{ width: "100%" }}
                                        format={'DD/MM/YYYY'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="LMP As Of"
                                    name="lmp_as_of"
                                >
                                    <DatePicker name="lmp_as_of" placeholder="Enter LMP As Of"
                                        onChange={(date, dateString) => handleDateChange("lmp_as_of", date, dateString)}
                                        style={{ width: "100%" }}
                                        format={'DD/MM/YYYY'}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Conceive Status"
                                    name="conceive_status"
                                >
                                    <Input name="conceive_status" placeholder="Enter Conceive Status"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Interest"
                                    name="interest"
                                >
                                    <Select
                                        name="interest"
                                        style={{ width: '100%' }}
                                        mode="multiple"
                                        placeholder="Interest"
                                        onChange={(v) => handleSelectChange("interest", v)}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={interest.name} onChange={onNameChangeInterest} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={addItemInterest}
                                                    >
                                                        Add item
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {interest.items.map(item => (
                                            <Option key={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Tribe"
                                    name="tribe"
                                >
                                    <Select
                                        name="tribe"
                                        style={{ width: '100%' }}
                                        mode="multiple"
                                        placeholder="Tribe"
                                        onChange={(v) => handleSelectChange("tribe", v)}
                                        dropdownRender={menu => (
                                            <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <Input style={{ flex: 'auto' }} value={tribe.name} onChange={onNameChangeTribe} />
                                                    <a
                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                        onClick={addItemTribe}
                                                    >
                                                        Add item
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        {tribe.items.map(item => (
                                            <Option key={item}>{item}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>



                        <Button type="primary" htmlType="submit" loading={savePatientState.apiState === "loading"} >SUBMIT</Button>

                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getPatientDetailState: state.getPatientDetail,
    savePatientState: state.savePatient,
})

const mapDispatchToProps = (dispatch) => ({
    getPatientDetail: (params) => dispatch(getPatientDetail(params)),
    getPatientDetailReset: () => dispatch(getPatientDetailReset()),
    savePatient: (params) => dispatch(savePatient(params)),
    savePatientReset: () => dispatch(savePatientReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientMasterSave)