import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_ROLE_MODULE_MAPPING_DETAIL,
    getRoleModuleMappingDetailFail,
    getRoleModuleMappingDetailSuccess
} from '../action/getRoleModuleMappingDetailAction'

// api 
import getRoleModuleMappingDetailApi from '../api/getRoleModuleMappingDetailApi'

export function* getRoleModuleMappingDetailSaga(action) {
    try {
        const response = yield call(() => getRoleModuleMappingDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getRoleModuleMappingDetailSuccess(response, action))
        } else {
            yield put(getRoleModuleMappingDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getRoleModuleMappingDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_ROLE_MODULE_MAPPING_DETAIL, getRoleModuleMappingDetailSaga)
}