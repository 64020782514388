import React, { useState, useEffect } from "react"
import { Form, Row, Col, Select, Button, notification, Spin } from "antd"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveSampleContainerTestMap, saveSampleContainerTestMapReset } from "../../../action/saveSampleContainerTestMapAction"
import { getSampleContainerTestMapDetail, getSampleContainerTestMapDetailReset } from "../../../action/getSampleContainerTestMapDetailAction"
import { getCommonMasterList, getCommonMasterListReset } from "../../../action/getCommonMasterListAction";
import { getSampleList, getSampleListReset } from "../../../action/getSampleListAction"
import { getContainerList, getContainerListReset } from "../../../action/getContainerListAction"

/* otehrs */

export const SampleContainerTestMapAdd = props => {
    /* variables */
    const sample_container_test_map_id = props.match.params.sample_container_test_map_id
    const mode = sample_container_test_map_id ? "edit" : "add"
    const {
        saveSampleContainerTestMap, saveSampleContainerTestMapReset, saveSampleContainerTestMapState,
        getSampleContainerTestMapDetail, getSampleContainerTestMapDetailReset, getSampleContainerTestMapDetailState,
        getCommonMasterList, getCommonMasterListReset, getCommonMasterListState,
        getSampleList, getSampleListReset, getSampleListState,
        getContainerList, getContainerListReset, getContainerListState,

    } = props
    const [formData, setFormData] = useState({
        sample: "",
        test_group: [],
        container: "",
    })
    if (mode === "edit") {
        formData.id = sample_container_test_map_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    /* callbacks */
    useEffect(() => {
        getCommonMasterList({ slug: 'test-group' })
        return (() => {
            saveSampleContainerTestMapReset()
            getSampleContainerTestMapDetailReset()
            getCommonMasterListReset()
            getSampleListReset()
            getContainerListReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getSampleContainerTestMapDetail({
                id: sample_container_test_map_id
            })
        }
    }, [])

    useEffect(() => {
        if (getSampleContainerTestMapDetailState.apiState === "success") {
            if (getSampleContainerTestMapDetailState.data.sample) {
                getSampleList({ searchTerm: getSampleContainerTestMapDetailState.data.sample.sample_type, dropDownList: "/get-dropdown-list" })
            }
            if (getSampleContainerTestMapDetailState.data.container) {
                getContainerList({ searchTerm: getSampleContainerTestMapDetailState.data.container.display_container_name, dropDownList: "/get-dropdown-list" })
            }
            form.setFieldsValue({
                sample: getSampleContainerTestMapDetailState.data.sample?._id,
                test_group: getSampleContainerTestMapDetailState.data.test_group.map(obj => obj._id),
                container: getSampleContainerTestMapDetailState.data.container?._id,
            })
            setFormData({
                ...formData,
                ['sample']: getSampleContainerTestMapDetailState.data.sample?._id,
                ['test_group']: getSampleContainerTestMapDetailState.data.test_group.map(obj => obj._id),
                ['container']: getSampleContainerTestMapDetailState.data.container?._id,
            })
        }
    }, [getSampleContainerTestMapDetailState])

    useEffect(() => {
        if (saveSampleContainerTestMapState.apiState === "success") {
            saveSampleContainerTestMapReset()
            setRedirect([true, "/sample-container-test-map-master/list"])
            notification["success"]({
                message: saveSampleContainerTestMapState.message,
                placement: "topRight"
            })
        }
        if (saveSampleContainerTestMapState.apiState === "error") {
            saveSampleContainerTestMapReset()
            notification["error"]({
                message: saveSampleContainerTestMapState.message,
                placement: "topRight"
            })
        }
    }, [saveSampleContainerTestMapState])

    /* functions */
    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }

    const handleSubmit = () => {
        saveSampleContainerTestMap(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/sample-container-test-map-master/list"}
                title={mode === "edit" ? "Edit Sample Container Master" : "Add Sample Container Master"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Sample"
                                    name="sample"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Required"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        name="sample"
                                        onChange={(v) => handleSelect("sample", v)}
                                        placeholder="Select Sample"
                                        allowClear
                                        filterOption={false}
                                        onSearch={_.debounce(value => {
                                            getSampleList({ searchTerm: value, dropDownList: "/get-dropdown-list" })
                                        }, 500)}
                                        notFoundContent={getSampleListState.apiState === "loading" && <Spin size="small" />}
                                    >
                                        {getSampleListState.list.map((item, index) => {
                                            return (
                                                < Select.Option value={`${item._id}`
                                                } key={`${index}`}>{item.sample_type}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item
                                    label="Test Group"
                                    name="test_group"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        name="test_group"
                                        placeholder="Select Container Type"
                                        showSearch
                                        allowClear
                                        mode="multiple"
                                        onChange={(v) => handleSelect("test_group", v)}
                                    >
                                        {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "test-group" })) &&
                                            <>
                                                {_.find(getCommonMasterListState.list, { slug: "test-group" }).common_master_detail.map((item) => (
                                                    <Select.Option value={item._id} >{item.value}</Select.Option>
                                                ))}

                                            </>
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Container"
                                    name="container"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Required"
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        name="container"
                                        onChange={(v) => handleSelect("container", v)}
                                        placeholder="Select Container"
                                        allowClear
                                        filterOption={false}
                                        onSearch={_.debounce(value => {
                                            getContainerList({ searchTerm: value, dropDownList: "/get-dropdown-list" })
                                        }, 500)}
                                        notFoundContent={getContainerListState.apiState === "loading" && <Spin size="small" />}
                                    >
                                        {getContainerListState.list.map((item, index) => {
                                            return (
                                                < Select.Option value={`${item._id}`
                                                } key={`${index}`}>{item.display_container_name}</Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        {
                            saveSampleContainerTestMapState.apiState=="loading" ? <Button type="primary" loading>Adding</Button> 
                            : <Button type="primary" htmlType="submit">SUBMIT</Button>
                        }

                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveSampleContainerTestMapState: state.saveSampleContainerTestMap,
    getSampleContainerTestMapDetailState: state.getSampleContainerTestMapDetail,
    getCommonMasterListState: state.getCommonMasterList,
    getSampleListState: state.getSampleList,
    getContainerListState: state.getContainerList,

});

const mapDispatchToProps = (dispatch) => ({
    saveSampleContainerTestMap: (params) => dispatch(saveSampleContainerTestMap(params)),
    saveSampleContainerTestMapReset: () => dispatch(saveSampleContainerTestMapReset()),
    getSampleContainerTestMapDetail: (params) => dispatch(getSampleContainerTestMapDetail(params)),
    getSampleContainerTestMapDetailReset: () => dispatch(getSampleContainerTestMapDetailReset()),
    getCommonMasterList: (params) => dispatch(getCommonMasterList(params)),
    getCommonMasterListReset: () => dispatch(getCommonMasterListReset()),
    getSampleList: (params) => dispatch(getSampleList(params)),
    getSampleListReset: () => dispatch(getSampleListReset()),
    getContainerList: (params) => dispatch(getContainerList(params)),
    getContainerListReset: () => dispatch(getContainerListReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SampleContainerTestMapAdd);