
import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_NEW_INSTITUTE_LIST,
    getNewInstituteListFail,
    getNewInstituteListSuccess
} from '../action/getNewInstituteListAction'

// api 
import getNewInstituteListApi from '../api/getNewInstituteListApi'

export function* getNewInstituteListSaga(action) {
    try {
        const response = yield call(() => getNewInstituteListApi(action.params))
        if (response.data.status === 1) {
            yield put(getNewInstituteListSuccess(response, action))
        } else {
            yield put(getNewInstituteListFail(response, action))
        }
    }
    catch (e) {
        yield put(getNewInstituteListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_NEW_INSTITUTE_LIST, getNewInstituteListSaga)
}
