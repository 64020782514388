import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "antd/dist/antd.less";
import { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";

import store from "./store";

import { Layout } from "antd";

import Sidebar from "./component/Sidebar/Sidebar";

import Home from "./page/Home/Home"
import NotFound from "./page/NotFound/NotFound"
import Login from "./page/Login/Login"
import ResetPassword from "./page/ResetPassword/ResetPassword"

// others  
import theme from "./theme";

//Pages
import TestMasterList from "./page/TestMaster/TestMasterList/TestMasterList";
import TestMasterAdd from "./page/TestMaster/TestMasterAdd/TestMasterAdd";
import InstituteMasterAdd from "./page/InstituteMaster/InstituteMasterAdd/InstituteMasterAdd";
import InstituteMasterList from "./page/InstituteMaster/InstituteMasterList/InstituteMasterList";
import DoctorMasterList from "./page/DoctorMaster/DoctorMasterList/DoctorMasterList";
import DoctorMasterSave from "./page/DoctorMaster/DoctorMasterSave/DoctorMasterSave";
import SampleList from "./page/SampleMaster/SampleList/SampleList";
import SampleAdd from "./page/SampleMaster/SampleAdd/SampleAdd";
import SampleMasterBulkUpload from "./page/SampleMaster/SampleBulkUpload/SampleMasterBulkUpload";
import SampleContainerTestMapList from "./page/SampleContainerTestMapMaster/SampleContainerTestMapList/SampleContainerTestMapList";
import SampleContainerTestMapAdd from "./page/SampleContainerTestMapMaster/SampleContainerTestMapAdd/SampleContainerTestMapAdd";
import SampleContainerTestMapMasterBulkUpload from "./page/SampleContainerTestMapMaster/SampleContainerTestMapBulkUpload/SampleContainerTestMapMasterBulkUpload";
import ContainerList from "./page/ContainerMaster/ContainerList/ContainerList";
import ContainerAdd from "./page/ContainerMaster/ContainerAdd/ContainerAdd";
import ContainerMasterBulkUpload from "./page/ContainerMaster/ContainerBulkUpload/ContainerMasterBulkUpload";
import CommonMaster from "./page/CommonMaster/CommonMaster";
import InstituteTestPricingMasterInstituteList from "./page/InstituteTestPricingMaster/InstituteTestPricingMasterInstituteList/InstituteTestPricingMasterInstituteList";
import InstituteTestPricingMasterList from "./page/InstituteTestPricingMaster/InstituteTestPricingMasterList/InstituteTestPricingMasterList";
import PregnancyHighlightsList from "./page/PregnancyHighlights/PregnancyHighlightsList/PregnancyHighlightsList";
import PregnancyHighlightsAdd from "./page/PregnancyHighlights/PregnancyHighlightsAdd/PregnancyHighlightsAdd";
import PrivateRoute from "./component/PrivateRoute";
import PageContentList from "./page/PageContentMaster/PageContentList/PageContentList";
import PageContentAdd from "./page/PageContentMaster/PageContentAdd/PageContentAdd";
import UserTermAndConditionList from "./page/UserTermAndCondition/UserTermAndConditionList/UserTermAndConditionList";
import ContentRepoPregnancyAdd from "./page/ContentRepo/ContentRepoPregnancy/ContentRepoPregnancyAdd/ContentRepoPregnancyAdd";
import ContentRepoPregnancyList from "./page/ContentRepo/ContentRepoPregnancy/ContentRepoPregnancyList/ContentRepoPregnancyList";
import ContentRepoTribeList from "./page/ContentRepo/ContentRepoTribe/ContentRepoTribeList/ContentRepoTribeList";
import ContentRepoTribeAdd from "./page/ContentRepo/ContentRepoTribe/ContentRepoTribeAdd/ContentRepoTribeAdd";
import ContentRepoGeneralList from "./page/ContentRepo/ContentRepoGeneral/ContentRepoGeneralList/ContentRepoGeneralList";
import ContentRepoGeneralAdd from "./page/ContentRepo/ContentRepoGeneral/ContentRepoGeneralAdd/ContentRepoGeneralAdd";
import ContentRepoDoctorList from "./page/ContentRepo/ContentRepoDoctor/ContentRepoDoctorList/ContentRepoDoctorList";
import ContentRepoDoctorAdd from "./page/ContentRepo/ContentRepoDoctor/ContentRepoDoctorAdd/ContentRepoDoctorAdd";
import InstituteMasterBulkUpload from "./page/InstituteMaster/InstituteMasterBulkUpload/InstituteMasterBulkUpload";
import InstituteTestPricingMasterBulkUpload from "./page/InstituteTestPricingMaster/InstituteTestPricingMasterBulkUpload/InstituteTestPricingMasterBulkUpload";
import EmployeeList from "./page/EmployeeMaster/EmployeeList/EmployeeList";
import EmployeeSave from "./page/EmployeeMaster/EmployeeSave/EmployeeSave";
import EmployeeMasterBulkUpload from "./page/EmployeeMaster/EmployeeBulkUpload/EmployeeMasterBulkUpload";
import AddSystemUser from "./page/SystemUser/saveSystemUser/AddSystemUser";
import UserList from "./page/SystemUser/SystemUserList/UserList";
import UserRights from "./page/SystemUser/UserRights/UserRights";
import DoctorMasterBulkUpload from "./page/DoctorMaster/DoctorMasterBulkUpload/DoctorMasterBulkUpload";
import TestMasterBulkUpload from "./page/TestMaster/TestMasterBulkUpload/TestMasterBulkUpload";
import CouponList from "./page/CouponEngine/CouponList/CouponList";
import CouponSave from "./page/CouponEngine/CouponSave/CouponSave";
import TestimonialList from "./page/Testimonial/TestimonialList/TestimonialList";
import PatientMasterList from "./page/PatientMaster/PatientMasterList/PatientMasterList";
import ConsulationDashboardList from "./page/ConsulationDashboard/ConsulationDashboardList/ConsulationDashboardList";
import PatientMasterSave from "./page/PatientMaster/PatientMasterSave/PatientMasterSave";
import PaymentHistory from "./page/PaymentHistory/PaymentHistory";
import PaymentHistoryCoupon from "./page/PaymentHistoryCoupon/PaymentHistoryCoupon";
import ChatHistory from "./page/ChatHistory/ChatHistory";
import QuickSupportCategorySave from "./page/QuickSupport/QuickSupportCategorySave/QuickSupportCategorySave";
import QuickSupportCategoryList from "./page/QuickSupport/QuickSupportCategoryList/QuickSupportCategoryList";
import AdminTypeMasterList from "./page/AdminTypeMaster/AdminTypeMasterList/AdminTypeMasterList";
import AdminTypeMasterSave from "./page/AdminTypeMaster/AdminTypeMasterSave/AdminTypeMasterSave";
import RoleMasterList from "./page/RoleMaster/RoleMasterList/RoleMasterList";
import RoleMasterSave from "./page/RoleMaster/RoleMasterSave/RoleMasterSave";
import ModuleMasterList from "./page/ModuleMaster/ModuleMasterList/ModuleMasterList";
import ModuleMasterSave from "./page/ModuleMaster/ModuleMasterSave/ModuleMasterSave";
import RoleModuleMappingMasterSave from "./page/RoleModuleMappingMaster/RoleModuleMappingMasterSave/RoleModuleMappingMasterSave";
import RoleModuleMappingMasterList from "./page/RoleModuleMappingMaster/RoleModuleMappingMasterList/RoleModuleMappingMasterList";
import QuickSupportQuestionAnswerList from "./page/QuickSupport/QuickSupportQuestionAnswerList/QuickSupportQuestionAnswerList";
import QuickSupportQuestionAnswerSave from "./page/QuickSupport/QuickSupportQuestionAnswerSave/QuickSupportQuestionAnswerSave";
import TestBookList from "./page/TestBook/TestMasterList/TestBookList";
import NotificationList from "./page/Notification/NotificationList/NotificationList";
import NotificationDetailView from "./page/Notification/NotificationDetailView/NotificationDetailView";
import Mou from "./page/Mou/Mou"
import MouSave from "./page/Mou/MouSave/MouSave"
import ContactUslist from "./page/ContactUs/ContactUsList/ContactUsList"
import DoctorSignupRequestList from "./page/DoctorSignupRequest/DoctorSignupRequestList/DoctorSignupRequestList";
import DtrfsList from "./page/Dtrfs/DtrfsList/DtrfsList";
const { Header, Footer, Content } = Layout;

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route
              path={[
                "/",
                "/test-master/list",
                "/test-master/add",
                "/test-master-bulk-upload",
                "/institute-master/add",
                "/institute-master/list",
                "/institute-master/edit/:institute_id",
                "/institute-master-bulk-upload",
                "/test-master/edit/:test_master_id",
                "/doctor-master/list",
                "/doctor-master/save",
                "/doctor-master/save/:doctor_id",
                "/doctor-master-bulk-upload",
                "/sample-master/list",
                "/sample-master/add",
                "/sample-master/edit/:sample_id",
                "/sample-master-bulk-upload",
                "/sample-container-test-map-master/list",
                "/sample-container-test-map-master/add",
                "/sample-container-test-map-master/edit/:sample_container_test_map_id",
                "/sample-container-test-map-master-bulk-upload",
                "/container-master/list",
                "/container-master/add",
                "/container-master/edit/:container_id",
                "/container-master-bulk-upload",
                "/common-master",
                "/common-master/:tab",
                "/institute-test-pricing-master-institute/list",
                "/institute-test-pricing-master/add",
                "/institute-test-pricing-master/edit/:institute_test_pricing_id",
                "/institute-test-pricing-master/list/:institute_id",
                "/institute-test-pricing-master/list",
                "/institute-test-pricing-master-bulk-upload",
                "/content-repo-pregnancy/list",
                "/content-repo-pregnancy/add",
                "/content-repo-pregnancy/edit/:article_id",
                "/content-repo-tribe/list",
                "/content-repo-tribe/add",
                "/content-repo-tribe/edit/:article_id",
                "/content-repo-general/list",
                "/content-repo-general/add",
                "/content-repo-general/edit/:article_id",
                "/content-repo-doctor/list",
                "/content-repo-doctor/add",
                "/content-repo-doctor/edit/:article_id",
                "/pregnancy-highlights/list",
                "/pregnancy-highlights/add",
                "/pregnancy-highlights/edit/:pregnancy_highlights_id",
                "/page-content/list",
                "/page-content/add",
                "/page-content/edit/:page_content_id",
                "/user-term-and-condition/list",
                "/employee-master/list",
                "/employee-master/save",
                "/employee-master/save/:e",
                "/employee-master-bulk-upload",
                "/user/add",
                "/user/list",
                "/user/:id",
                "/user/:id/rights",
                "/coupon/list",
                "/coupon/save",
                "/coupon/save/:id",
                "/testimonial/list",
                "/patient-master/list",
                "/patient-master/save/:patient_id",
                "/consulation-dashboard/list",
                "/payment-history",
                "/payment-history-coupon",
                "/chat-history",
                "/quick-support-category/list",
                "/quick-support-category/save",
                "/quick-support-category/save/:id",
                "/admin-type/list",
                "/admin-type/save",
                "/admin-type/save/:id",
                "/role/list",
                "/role/save",
                "/role/save/:id",
                "/module/list",
                "/module/save",
                "/module/save/:id",
                "/role-module-mapping/list",
                "/role-module-mapping/save",
                "/role-module-mapping/save/:id",
                "/quick-support-question-answer",
                "/quick-support-question-answer/list/:category_id",
                "/quick-support-question-answer/save/:category_id",
                "/quick-support-question-answer/save/:category_id/:id",
                "/patient-test-book/list",
                "/notification/list",
                            "/mou",
                            "/mou/save",
                            "/contact-us/list",
                          
                "/notification/:notification_type",
                              "/doctor-signup-request/list",
                              "/dtrfs/list",
                              "/dtrfs/:id",
              ]}
              exact
            >
              <Layout>
                <Sidebar />
                <Layout className="site-layout" style={{ marginLeft: 200 }}>
                  <Content style={{ overflow: "initial", background: "#fff" }}>
                    <Switch>
                      <PrivateRoute exact path="/" component={Home} />
                      <PrivateRoute
                        exact
                        path="/notification/list"
                        component={NotificationList}
                      />
                      <PrivateRoute
                        // exact
                        path="/notification/:notification_type"
                        component={NotificationDetailView}
                      />
                      <PrivateRoute
                        exact
                        path="/test-master/list"
                        component={TestMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/test-master/add"
                        component={TestMasterAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/test-master/edit/:test_master_id"
                        component={TestMasterAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/test-master-bulk-upload"
                        component={TestMasterBulkUpload}
                      />
                      <PrivateRoute
                        exact
                        path="/institute-master/add"
                        component={InstituteMasterAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/institute-master/list"
                        component={InstituteMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/institute-master/edit/:institute_id"
                        component={InstituteMasterAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/institute-master-bulk-upload"
                        component={InstituteMasterBulkUpload}
                      />
                      <PrivateRoute
                        exact
                        path="/doctor-master/list"
                        component={DoctorMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/doctor-master/save"
                        component={DoctorMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/doctor-master/save/:doctor_id"
                        component={DoctorMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/doctor-master-bulk-upload"
                        component={DoctorMasterBulkUpload}
                      />
                      <PrivateRoute
                        exact
                        path="/sample-master/list"
                        component={SampleList}
                      />
                      <PrivateRoute
                        exact
                        path="/sample-master/add"
                        component={SampleAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/sample-master/edit/:sample_id"
                        component={SampleAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/sample-master-bulk-upload"
                        component={SampleMasterBulkUpload}
                      />
                      <PrivateRoute
                        exact
                        path="/sample-container-test-map-master/list"
                        component={SampleContainerTestMapList}
                      />
                      <PrivateRoute
                        exact
                        path="/sample-container-test-map-master/add"
                        component={SampleContainerTestMapAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/sample-container-test-map-master/edit/:sample_container_test_map_id"
                        component={SampleContainerTestMapAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/sample-container-test-map-master-bulk-upload"
                        component={SampleContainerTestMapMasterBulkUpload}
                      />
                      <PrivateRoute
                        exact
                        path="/container-master/list"
                        component={ContainerList}
                      />
                      <PrivateRoute
                        exact
                        path="/container-master/add"
                        component={ContainerAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/container-master/edit/:container_id"
                        component={ContainerAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/container-master-bulk-upload"
                        component={ContainerMasterBulkUpload}
                      />
                      <PrivateRoute
                        exact
                        path="/common-master"
                        component={CommonMaster}
                      />
                      <PrivateRoute
                        exact
                        path="/common-master/:tab"
                        component={CommonMaster}
                      />
                      
                      <PrivateRoute
                        exact
                        path="/institute-test-pricing-master-institute/list"
                        component={InstituteTestPricingMasterInstituteList}
                      />
                      <PrivateRoute
                        exact
                        path="/institute-test-pricing-master/list"
                        component={InstituteTestPricingMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/institute-test-pricing-master-bulk-upload"
                        component={InstituteTestPricingMasterBulkUpload}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-pregnancy/list"
                        component={ContentRepoPregnancyList}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-pregnancy/add"
                        component={ContentRepoPregnancyAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-pregnancy/edit/:article_id"
                        component={ContentRepoPregnancyAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-tribe/list"
                        component={ContentRepoTribeList}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-tribe/add"
                        component={ContentRepoTribeAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-tribe/edit/:article_id"
                        component={ContentRepoTribeAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-general/list"
                        component={ContentRepoGeneralList}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-general/add"
                        component={ContentRepoGeneralAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-general/edit/:article_id"
                        component={ContentRepoGeneralAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-doctor/list"
                        component={ContentRepoDoctorList}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-doctor/add"
                        component={ContentRepoDoctorAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/content-repo-doctor/edit/:article_id"
                        component={ContentRepoDoctorAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/pregnancy-highlights/list"
                        component={PregnancyHighlightsList}
                      />
                      <PrivateRoute
                        exact
                        path="/pregnancy-highlights/add"
                        component={PregnancyHighlightsAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/pregnancy-highlights/edit/:pregnancy_highlights_id"
                        component={PregnancyHighlightsAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/page-content/list"
                        component={PageContentList}
                      />
                      <PrivateRoute
                        exact
                        path="/page-content/add"
                        component={PageContentAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/page-content/edit/:page_content_id"
                        component={PageContentAdd}
                      />
                      <PrivateRoute
                        exact
                        path="/user-term-and-condition/list"
                        component={UserTermAndConditionList}
                      />
                      <PrivateRoute
                        exact
                        path="/employee-master/list"
                        component={EmployeeList}
                      />
                      <PrivateRoute
                        exact
                        path="/employee-master/save"
                        component={EmployeeSave}
                      />
                      <PrivateRoute
                        exact
                        path="/employee-master/save/:employee_id"
                        component={EmployeeSave}
                      />
                      <PrivateRoute
                        exact
                        path="/employee-master-bulk-upload"
                        component={EmployeeMasterBulkUpload}
                      />
                      <PrivateRoute
                        exact
                        path="/user/list"
                        component={UserList}
                      />
                      <PrivateRoute
                        exact
                        path="/user/add"
                        component={AddSystemUser}
                      />
                      <PrivateRoute
                        exact
                        path="/user/:id"
                        component={AddSystemUser}
                      />
                      <PrivateRoute
                        exact
                        path="/user/:id/rights"
                        component={UserRights}
                      />
                      <PrivateRoute
                        exact
                        path="/coupon/list"
                        component={CouponList}
                      />
                      <PrivateRoute
                        exact
                        path="/coupon/save"
                        component={CouponSave}
                      />
                      <PrivateRoute
                        exact
                        path="/coupon/save/:id"
                        component={CouponSave}
                      />
                      <PrivateRoute
                        exact
                        path="/testimonial/list"
                        component={TestimonialList}
                      />
                      <PrivateRoute
                        exact
                        path="/patient-master/list"
                        component={PatientMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/patient-master/save/:patient_id"
                        component={PatientMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/consulation-dashboard/list"
                        component={ConsulationDashboardList}
                      />
                      <PrivateRoute
                        exact
                        path="/payment-history"
                        component={PaymentHistory}
                      />
                      <PrivateRoute
                        exact
                        path="/payment-history-coupon"
                        component={PaymentHistoryCoupon}
                      />
                      <PrivateRoute
                        exact
                        path="/chat-history"
                        component={ChatHistory}
                      />
                      <PrivateRoute
                        exact
                        path="/quick-support-category/list"
                        component={QuickSupportCategoryList}
                      />
                      <PrivateRoute
                        exact
                        path="/quick-support-category/save"
                        component={QuickSupportCategorySave}
                      />
                      <PrivateRoute
                        exact
                        path="/quick-support-category/save/:id"
                        component={QuickSupportCategorySave}
                      />
                      <PrivateRoute
                        exact
                        path="/admin-type/list"
                        component={AdminTypeMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/admin-type/save"
                        component={AdminTypeMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/admin-type/save/:id"
                        component={AdminTypeMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/role/list"
                        component={RoleMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/role/save"
                        component={RoleMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/role/save/:id"
                        component={RoleMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/module/list"
                        component={ModuleMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/module/save"
                        component={ModuleMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/module/save/:id"
                        component={ModuleMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/role-module-mapping/list"
                        component={RoleModuleMappingMasterList}
                      />
                      <PrivateRoute
                        exact
                        path="/role-module-mapping/save"
                        component={RoleModuleMappingMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/role-module-mapping/save/:id"
                        component={RoleModuleMappingMasterSave}
                      />
                      <PrivateRoute
                        exact
                        path="/quick-support-question-answer"
                        component={QuickSupportCategoryList}
                      />
                      <PrivateRoute
                        exact
                        path="/quick-support-question-answer/list/:category_id"
                        component={QuickSupportQuestionAnswerList}
                      />
                      <PrivateRoute
                        exact
                        path="/quick-support-question-answer/save/:category_id"
                        component={QuickSupportQuestionAnswerSave}
                      />
                      <PrivateRoute
                        exact
                        path="/quick-support-question-answer/save/:category_id/:id"
                        component={QuickSupportQuestionAnswerSave}
                      />
                      <PrivateRoute
                        exact
                        path="/patient-test-book/list"
                        component={TestBookList}
                      />
                                            <PrivateRoute exact path="/mou" component={Mou} />
                                            <PrivateRoute exact path="/mou/save" component={MouSave} />
                                            <PrivateRoute exact path="/contact-us/list" component={ContactUslist} />

                      <PrivateRoute
                        exact
                        path="/doctor-signup-request/list"
                        component={DoctorSignupRequestList}
                                          />
                                          <PrivateRoute
                                              exact
                                              path="/dtrfs/list"
                                              component={DtrfsList}
                                          />
                                          <PrivateRoute
                                              exact
                                              path="/dtrfs/:id"
                                              component={DtrfsList}
                                          />
                    </Switch>
                  </Content>
                </Layout>
              </Layout>
            </Route>

                        <Route path={["/login"]} exact >
                            <Switch>
                                <Route exact path="/login" component={Login} />
                            </Switch>
                        </Route>
                        <Route path={["/reset-password"]} exact >
                            <Switch>
                                <Route exact path="/reset-password" component={ResetPassword} />
                            </Switch>
                        </Route>

            <Route path="*" component={NotFound} status={404} />
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
