import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Menu, Dropdown } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"

/* actions */
import { getUserTermAndConditionList, getUserTermAndConditionListReset } from "../../../action/getUserTermAndConditionListAction"
import UserTermAndConditionView from "../UserTermAndConditionView/UserTermAndConditionView";
import { dateFormat } from "../../../utils";

const UserTermAndConditionList = props => {
    /* variables */
    const {
        getUserTermAndConditionList, getUserTermAndConditionListReset, getUserTermAndConditionListState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'username',
            key: 'username',
            ...getColumnSearchProps('username', 'User Name')
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            ...getColumnSearchProps('phone', 'Phone')
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email', 'Email')
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                </Space>
            )
        },
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    /* callbacks */
    useEffect(() => {
        return (() => {
            getUserTermAndConditionListReset()
        })
    }, [])
    useEffect(() => {
        getUserTermAndConditionList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getUserTermAndConditionListState.apiState === "success") {
            let tableData = []
            getUserTermAndConditionListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        username: row.username,
                        phone: row.phone,
                        email: row.email,
                        created_at: dateFormat(row.created_at),
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getUserTermAndConditionListState])

    useEffect(() => {
        setRedirect([true, `/user-term-and-condition/list?page=${pagination.page}`])
    }, [pagination])

    // Functions

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="User Term And Condition List"
            />
            <PageContainer>
                {getUserTermAndConditionListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getUserTermAndConditionListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getUserTermAndConditionListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getUserTermAndConditionListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getUserTermAndConditionListState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <UserTermAndConditionView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getUserTermAndConditionListState: state.getUserTermAndConditionList,
});

const mapDispatchToProps = (dispatch) => ({
    getUserTermAndConditionList: (params) => dispatch(getUserTermAndConditionList(params)),
    getUserTermAndConditionListReset: () => dispatch(getUserTermAndConditionListReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(UserTermAndConditionList);