import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import { Link } from 'react-router-dom';
/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getCouponDetail, getCouponDetailReset } from "../../../action/getCouponDetailAction"
import { dateFormat, dateTimeFormat } from '../../../utils';

const EmployeeView = props => {
    /* variable */
    const { getCouponDetail, getCouponDetailReset, getCouponDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getCouponDetailReset()
        }
    }, [props.visible])

    useEffect(() => {
        if (props.id && props.visible) {
            getCouponDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`Coupon View ${getCouponDetailState.apiState === "success" ? "- " + getCouponDetailState.data.code : ""}`}
            placement="right"
            closeIcon={<><Link to={`/coupon/save/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getCouponDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getCouponDetailState.apiState === "error" &&
                <p>{getCouponDetailState.message}</p>
            }

            {getCouponDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Code</ViewLabel>
                                <ViewValue><span>{getCouponDetailState.data.code}</span></ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Valid Till</ViewLabel>
                                <ViewValue>{dateTimeFormat(getCouponDetailState.data.valid_till)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Minimum Order</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.minimum_order}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Maximum Discount</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.maximum_discount}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Discount</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.discount}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Is Percentage</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.is_percentage ? 'Yes' : 'No'}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Max Usage Allowed</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.max_usage_allowed}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Once Per Usage</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.once_per_usage ? 'Yes' : 'No'}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Active</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.active ? 'Yes' : 'No'}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Times Used</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.times_used}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Consolation Type</ViewLabel>
                                <ViewValue>{getCouponDetailState.data.consolation_type}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getCouponDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getCouponDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>

                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getCouponDetailState: state.getCouponDetail
});

const mapDispatchToProps = (dispatch) => ({
    getCouponDetail: (params) => dispatch(getCouponDetail(params)),
    getCouponDetailReset: () => dispatch(getCouponDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeView);