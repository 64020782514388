import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Button, Input, Tooltip } from "antd"
import { EyeOutlined, EditOutlined, SettingOutlined, SearchOutlined, PlusOutlined, } from "@ant-design/icons"
import _ from 'lodash'

/* CUSTOM COMPONENT */
import Header from "../../../component/Header/Header"
import { PageContainer, Xpagination, Xtable } from "../../../component/Xcomponent"
import DoctorSignupRequestView from "../DoctorSignupRequestView/DoctorSignupRequestView";

/* ACTION */
import { doctorSignupRequestList, doctorSignupRequestListReset } from '../../../action/doctorSignupRequestListAction'

/* OTHERS */
import { checkRights } from '../../../utils' 


const DoctorSignupRequestList = (props) => {
    /* VARIABLES */
    const { doctorSignupRequestList, doctorSignupRequestListReset, doctorSignupRequestListState } = props
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [isSuperAdmin, setIsSuperAdmin] = useState(null)
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [redirect, setRedirect] = useState(["", false])
    const [columns, setColumns] = useState([])
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 20
    })


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
 
    /* CALLBACKS */
    useEffect(() => {
        return (() => {
            doctorSignupRequestListReset()
        })
    }, [])


    useEffect(() => {
        doctorSignupRequestList({
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/doctor-signup-request/list?page=${pagination.page}`])
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (doctorSignupRequestListState.apiState === "success") {
            setColumns([
                {
                    title: 'Doctor',
                    dataIndex: 'doctor_name',
                    key: 'doctor_name',
                    render: doctor_name => (
                        <span>{doctor_name}</span>
                    ),
                    ...getColumnSearchProps('doctor_name', 'doctor_name')
                },
                {
                    title: 'Institute',
                    dataIndex: 'institute_name',
                    key: 'institute_name',
                    render: institute_name => (
                        <span>{institute_name}</span>
                    ),
                    ...getColumnSearchProps('institute_name', 'Institute Name')
                },
                {
                    title: 'Mobile',
                    dataIndex: 'mobile_number',
                    key: 'mobile_number',
                    render: mobile_number => (
                        <span>{mobile_number}</span>
                    ),
                    ...getColumnSearchProps('mobile_number', 'Mobile')
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    render: (action) => (
                        <Space size="small">
                            <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: action.id, visible: true })} />
                            {/* {
                                (checkRights("user", "edit")) &&
                                <Link to={`/user/${action.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                            }
                            {checkRights("user_rights", "edit") &&
                                <>
                                    {isSuperAdmin !== action.role &&
                                        < Tooltip title="User Right" placement="bottom" >
                                            <Link to={`/user/${action.id}/rights`}><Button size="small" shape="circle" icon={<SettingOutlined />} /></Link>
                                        </Tooltip>
                                    }
                                </>
                            } */}
                        </Space >
                    )
                },
            ])
            let data = doctorSignupRequestListState.data
            let findAdminData = _.find(data, { 'role': "admin" })
            let findAdminRole = findAdminData?.role
            setIsSuperAdmin(findAdminRole)
            let tableData = []
            data.map((row, index) => {
                tableData.push(
                    {
                        doctor_name: row.fname+" "+row.lname,
                        institute_name: row.institute_name,
                        mobile_number: row.mobile_number,
                        action: { id: row._id },
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [doctorSignupRequestListState])

    return (
        <>
            <Header
                title="Doctor Signup Requests"
                
            />
            <PageContainer>
                {doctorSignupRequestListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{doctorSignupRequestListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {doctorSignupRequestListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={doctorSignupRequestListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={doctorSignupRequestListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                    </>
                }
                <DoctorSignupRequestView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />

            </PageContainer>
        </>
    )
}
const mapStateToProps = (state) => ({
    doctorSignupRequestListState: state.doctorSignupRequestList
})
const mapDispatchToProps = (dispatch) => ({
    doctorSignupRequestList: (params) => dispatch(doctorSignupRequestList(params)),
    doctorSignupRequestListReset: () => dispatch(doctorSignupRequestListReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSignupRequestList)
