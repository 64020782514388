import axios from 'axios'
import config from '../config'
import { getUserToken } from "../utils"

const getModuleListApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}module${params.dropDownList}`,
        method: "get",
        params: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getUserToken()}`
        }
    })

    return response

}

export default getModuleListApi