import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom"
import { connect } from "react-redux"
import { Space, Popconfirm, Button, DatePicker, Input, Select } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import _ from "lodash"
import moment from "moment"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination } from "../../../component/Xcomponent"
import ConsulationDashboardView from "../ConsulationDashboardView/ConsulationDashboardView"

/* actions */
import { getConsulationDashboardList, getConsulationDashboardListReset } from "../../../action/getConsulationDashboardListAction"
import { dateFormat, dateFormatMonthName, dateTimeFormat, dateTimeFormatMonthName, timeSlotFormat } from "../../../utils"
const { RangePicker } = DatePicker

const ConsulationDashboardList = props => {
    /* variables */
    const {
        getConsulationDashboardList, getConsulationDashboardListReset, getConsulationDashboardListState,
    } = props
    const [tableData, setTableData] = useState([])
    const search = useLocation().search
    const page = new URLSearchParams(search).get("page")
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 10,
    })
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})

    const [view, setView] = useState({
        visible: false,
        id: null,
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }


    const getColumnSearchProps = (dataIndex, name, dataType) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                {dataType === 'Text' &&
                    <Input
                        placeholder={`Search ${name}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                }
                {dataType === 'DateRange' &&
                    <RangePicker
                        // value={selectedKeys[0] ? "" : ""}
                        onChange={(value, string) => setSelectedKeys(string ? [[moment(string[0]).format("YYYY-MM-DD"), moment(string[1]).format("YYYY-MM-DD")]] : [])}
                        format={"DD-MMM-YYYY"}
                        style={{ marginBottom: 8, display: 'block', width: '100%' }}
                    />
                }
                {dataType === 'Dropdown' &&
                    <Select value={selectedKeys[0]} onChange={v => setSelectedKeys(v ? [v] : [])} style={{ marginBottom: 8, display: 'block', width: "100%" }} placeholder="Select Status" >
                        <Select.Option value="pending">Pending</Select.Option>
                        <Select.Option value="success">Success</Select.Option>
                    </Select>
                }
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    })

    const columns = [
        {
            title: "Appointment No",
            dataIndex: "appointment_no",
            key: "appointment_no",
        },
        {
            title: "Appointment On",
            dataIndex: "appointment_date",
            key: "appointment_date",
            ...getColumnSearchProps('appointment_date', 'Appointment Date', 'DateRange')
        },
        {
            title: "Patient Name",
            dataIndex: "patient_name",
            key: "patient_name",
            ...getColumnSearchProps('patient_name', 'Patient Name', 'Text')
        },
        {
            title: "Doctor Name",
            dataIndex: "doctor_name",
            key: "doctor_name",
            ...getColumnSearchProps('doctor_name', 'Doctor Name', 'Text'),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            ...getColumnSearchProps('status', 'Status', 'Dropdown'),
            render: (status) => (
                <span style={{ textTransform: "capitalize" }}>{status}</span>
            ),
        },
        {
            title: "Booked Date",
            dataIndex: "booked_date",
            key: "booked_date",
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "action",
            width: 100,
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                </Space>
            ),
        },
    ]


    /* callbacks */
    useEffect(() => {
        return (() => {
            getConsulationDashboardListReset()
        })
    }, [])

    useEffect(() => {
        if (getConsulationDashboardListState.apiState === "success") {
            let tableData = []
            getConsulationDashboardListState.list.map((data) => {
                tableData.push(
                    {
                        appointment_no: data.appointment_no,
                        appointment_date: `${dateFormatMonthName(data.date)} ${timeSlotFormat(data.time_slot_start)} - ${timeSlotFormat(data.time_slot_end)}`,
                        patient_name: data.some_one_else ? `${data.full_name}` : `${data.patient.name.firstName} ${data.patient.name.lastName}`,
                        doctor_name: `${data.doctor.fname} ${data.doctor.lname}`,
                        booked_date: dateTimeFormatMonthName(data.createdAt),
                        status: data.status,
                        id: data._id
                    }
                )
            })

            setTableData(tableData)
        }

    }, [getConsulationDashboardListState])

    useEffect(() => {
        setPagination({ ...pagination, ['page']: 1 })
    }, [searchText, refresh])

    useEffect(() => {
        getConsulationDashboardList({
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/consulation-dashboard/list?page=${pagination.page}`])
    }, [pagination])


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                title="Consulation Dashboard List"
            />
            <PageContainer>
                {getConsulationDashboardListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getConsulationDashboardListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getConsulationDashboardListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getConsulationDashboardListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getConsulationDashboardListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <ConsulationDashboardView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getConsulationDashboardListState: state.getConsulationDashboardList,
})

const mapDispatchToProps = (dispatch) => ({
    getConsulationDashboardList: (params) => dispatch(getConsulationDashboardList(params)),
    getConsulationDashboardListReset: () => dispatch(getConsulationDashboardListReset()),

})

export default connect(mapStateToProps, mapDispatchToProps)(ConsulationDashboardList)