import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_PREGNANCY_HIGHLIGHTS,
    deletePregnancyHighlightsFail,
    deletePregnancyHighlightsSuccess
} from '../action/deletePregnancyHighlightsAction'

// api 
import deletePregnancyHighlightsApi from '../api/deletePregnancyHighlightsApi'

export function* deletePregnancyHighlightsSaga(action) {
    try {
        const response = yield call(() => deletePregnancyHighlightsApi(action.params))
        if (response.data.status === 1) {
            yield put(deletePregnancyHighlightsSuccess(response, action))
        } else {
            yield put(deletePregnancyHighlightsFail(response, action))
        }
    }
    catch (e) {
        yield put(deletePregnancyHighlightsFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_PREGNANCY_HIGHLIGHTS, deletePregnancyHighlightsSaga)
}