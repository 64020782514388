import React, { useState, useEffect, useRef } from "react"
import { Form, Input, Row, Col, Select, Button, notification, InputNumber } from "antd"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash"
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react'
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { savePageContent, savePageContentReset } from "../../../action/savePageContentAction"
import { getPageContentDetail, getPageContentDetailReset } from "../../../action/getPageContentDetailAction"
import { tinyEditor, getS3SingedUrl, s3_cdn } from "../../../utils";
import { v4 as uuidv4 } from 'uuid'

/* otehrs */

export const PageContentAdd = props => {
    /* variables */
    const page_content_id = props.match.params.page_content_id
    const mode = page_content_id ? "edit" : "add"
    const {
        savePageContent, savePageContentReset, savePageContentState,
        getPageContentDetail, getPageContentDetailReset, getPageContentDetailState,
    } = props
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        apiState: "",
    })
    if (mode === "edit") {
        formData.id = page_content_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()
    const editorUseRef = useRef(null);

    /* callbacks */
    useEffect(() => {
        return (() => {
            savePageContentReset()
            getPageContentDetailReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getPageContentDetail({
                id: page_content_id
            })
        }
    }, [])

    useEffect(() => {
        if (getPageContentDetailState.apiState === "success") {
            form.setFieldsValue({
                title: getPageContentDetailState.data.title,
                slug: getPageContentDetailState.data.slug,
                description: decodeURIComponent(getPageContentDetailState.data.description),
            })
            setFormData({
                ...formData,
                ['title']: getPageContentDetailState.data.title,
                ['slug']: getPageContentDetailState.data.slug,
                ['description']: getPageContentDetailState.data.description,
                ['apiState']: "success",
            })
            editorUseRef.current = decodeURIComponent(getPageContentDetailState.data.description);
        }
    }, [getPageContentDetailState])

    useEffect(() => {
        if (savePageContentState.apiState === "success") {
            savePageContentReset()
            setRedirect([true, "/page-content/list"])
            notification["success"]({
                message: savePageContentState.message,
                placement: "topRight"
            })
        }
        if (savePageContentState.apiState === "error") {
            savePageContentReset()
            notification["error"]({
                message: savePageContentState.message,
                placement: "topRight"
            })
        }
    }, [savePageContentState])

    /* functions */
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleEditorChange = (content, editor) => {
        setFormData({
            ...formData,
            ["description"]: encodeURI(content)
        })
    }

    const handleSubmit = () => {
        savePageContent(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={"/page-content/list"}
                title={mode === "edit" ? "Edit Page Content" : "Add Page Content"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Title"
                                    name="title"
                                >
                                    <Input name="title" placeholder="Enter Title"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item
                                    label="Slug"
                                    name="slug"
                                >
                                    <Input name="slug" placeholder="Enter Slug"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                {((formData.apiState === "success" && mode === 'edit') || mode === 'add') &&
                                    <Form.Item
                                        label="Description"
                                        // name="description"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <Editor
                                            placeholder="Enter description"
                                            onInit={(evt, editor) => editorUseRef.current = editor}
                                            initialValue={editorUseRef.current}
                                            apiKey={tinyEditor()}
                                            name="description"
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount'
                                                ],
                                                toolbar: "styles bold italic underline strikethrough alignleft aligncenter alignright alignjustify bullist numlist outdent indent image link openlink unlink removeformat fontsize undo redo",
                                                // toolbar:
                                                //     'formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | link image | fontsizeselect',
                                                fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                                images_upload_handler: function (blobInfo) {
                                                    return new Promise(async (resolve, reject) => {
                                                      try {
                                                        const imageFilename = uuidv4();
                                                        const fileName = blobInfo.filename();
                                                        const fileExtension = fileName.split('.').pop().toLowerCase();
                                                
                                                        const s3UrlResImage = await getS3SingedUrl(imageFilename, fileExtension, fileName, 'pageContent');
                                                        await axios.put(s3UrlResImage.url, blobInfo.blob());
                                                
                                                        // Provide the URL of the uploaded image in the resolved Promise
                                                        const imageUrl = s3_cdn() + 'pageContent/' + imageFilename + '.' + fileExtension;
                                                        resolve(imageUrl);
                                                      } catch (error) {
                                                        console.error('File upload failed:', error.message);
                                                        reject('Unable to upload the file. Please try again.');
                                                      }
                                                    });
                                                },
                                                link_default_target: '_blank',
                                                paste_data_images:true,
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </Form.Item>
                                }
                            </Col>

                        </Row>
                        {
                            savePageContentState.apiState=="loading" ? <Button type="primary" loading>Adding</Button> 
                            : <Button type="primary" htmlType="submit">SUBMIT</Button>
                        }

                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    savePageContentState: state.savePageContent,
    getPageContentDetailState: state.getPageContentDetail,
});

const mapDispatchToProps = (dispatch) => ({
    savePageContent: (params) => dispatch(savePageContent(params)),
    savePageContentReset: () => dispatch(savePageContentReset()),
    getPageContentDetail: (params) => dispatch(getPageContentDetail(params)),
    getPageContentDetailReset: () => dispatch(getPageContentDetailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageContentAdd);