import styled from 'styled-components'
import { Tabs } from 'antd'
const { TabPane } = Tabs;

export const CustomTabs = styled(Tabs)`
.ant-tabs-extra-content{
    margin: 5px;
}
`
export const CustomTabPane = styled(TabPane)`
margin-top: 20px;
`