import React, { useState } from "react";
import {
  Select,
  Row,
  Col,
  Checkbox,
  Tabs,
  Button,
  Space,
  Form,
  Switch,
  Input,
  notification,
  Divider,
} from "antd";
import axios from "axios";
import config from "../../config";
import { getUserToken } from "../../utils";
import { createRef ,useEffect } from "react";
const { TextArea } = Input;
const recipientFields = {
  "doctor":{
    emails:[{value:"email",label:"Official Email",type:'to'}],
    sms:[{value:"mobile_number",label:"Mobile Number"}]
  },
  "institute":{
    emails:[
      {value:"email",label:"Email",type:"to"},
      {value:"report_email_cc",label:"Report Email CC",type:"cc"},
      {value:"report_email_bcc",label:"Report Email BCC",type:"bcc"},
      {value:"follow_up_email",label:"Follow Up Email",type:"to"},
      {value:"sample_knowledge_email",label:"Sample Acknowledgement Email",type:"to"},
      {value:"finance_person_email",label:"Finance Person Email",type:"to"},
      {value:"finance_person_email_cc",label:"Finance Person Email CC",type:"cc"},
      {value:"finance_person_email_bcc",label:"Finance Person Email BCC",type:"bcc"},
      {value:"key_decision_maker_email_id",label:"Key Decision Maker Email"},

    ],
    sms:[
      {value:"mobile",label:"Official Mobile"},
      {value:"report_delivery_alert_mobile_number",label:"Report Delivery Mobile Number"},
      {value:"financial_sms_details_to",label:"Financial SMS Mobile Number"},
      {value:"key_decision_maker_mobile_num",label:"Key Decision Maker Mobile"},
    ]
  },
  "employee":{
    emails:[{value:"official_email_id",label:"Official Email",type:'to'}],
    sms:[{value:"official_mobile",label:"Official Mobile"},]
  },
  "patient":{
    emails:[{value:"email",label:"Email",type:"to"}],
    sms:[{value:"phone",label:"Phone number"}]
  }
}
const NotificationSendTo = (props) => {
  console.log("Sendto props", props);
  const [touchPoints, setTouchPoints] = useState([]);
  const [touchPointsValidationErrors, setTouchPointsValidationError] = useState(
    props.touch_points.map(() => ({}))
  );
  const [formRef, setFormRef] = useState([]);
  console.log(touchPointsValidationErrors);
  const [initialValues, setInitialValues] = useState(props.touch_points);
  const [firstLoad, setFirstLostLoad] = useState(true);

  useEffect(() => {
    if (firstLoad) {
      setFirstLostLoad(false);
      const tempData = JSON.parse(JSON.stringify(props.touch_points));
      tempData.map((touchPoint) => {
        touchPoint.checked = false;
        return touchPoint;
      });
      setTouchPoints(tempData);
    }
  }, []);
  const handleSubmit = () => {
    console.log("handleSubmit");
  };

  const handleChange = (fieldName, value, formId) => {
    const temp = JSON.parse(JSON.stringify(touchPoints));
    temp[formId][fieldName] = value;
    setTouchPoints(temp);
  };

  const handleUpdate = () => {
    console.log(props);
    const validationPromises = [];
    formRef.map((ref, id) => {
      if (ref) {
        validationPromises.push(ref.current.validateFields());
      }
    });
    const deepClone = JSON.parse(JSON.stringify(touchPoints));
    Promise.all(validationPromises)
      .then((resp) => {
        console.log("validated successfully", resp);
        let hasError = false;
        const data = {
          app_name: props.app_name,
          id: props.id,
          touch_points: deepClone.map((touchPoint, id) => {
            // delete touchPoint.checked;
            if (touchPoint.priority) {
              touchPoint.priority = parseInt(touchPoint.priority);
            }

            return touchPoint;
          }),
          send_to: props.send_to,
          sendToListSelected:props.sendToTypes
        };
        console.log(data);
        const response = axios({
          url: `${config.api.base_url}notification/notification-update`,
          method: "post",
          data: data,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getUserToken()}`,
          },
        });
        response
          .then((resp) => {
            notification["success"]({
              message: "Updated Successfully",
              placement: "topRight",
            });
            setInitialValues(touchPoints);
          })
          .catch((err) => {
            notification["error"]({
              message: "Error in updating",
              placement: "topRight",
            });
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("Validation Failed", err);
      });
  };

  const getTouchPointValidationError = (fieldName, id) => {
    const error = touchPointsValidationErrors[id][fieldName];
    if (error) {
      return error;
    } else {
      return "";
    }
  };

  const getFields = (fieldsType, id, eachTouchPoint) => {
    const FieldsToReturn = [];
    fieldsType.map((fieldType) => {
      switch (fieldType) {
        case "content":
          FieldsToReturn.push(
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={20}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label="Content"
                    name="content"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <TextArea
                      rows={8}
                      placeholder="Enter content"
                      onChange={(e) => {
                        return handleChange("content", e.target.value, id);
                      }}
                    />
                  </Form.Item>
                  {getTouchPointValidationError("content", id)}
                </Col>
              </Row>
            </Col>
          );
          break;

        case "is_enabled":
          FieldsToReturn.push(
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item label="Enabled" name="is_enabled">
                <Switch
                  checked={eachTouchPoint.is_enabled}
                  onChange={(v) => handleChange("is_enabled", v, id)}
                  name="is_enabled"
                />
              </Form.Item>
            </Col>
          );
          break;

        case "is_critical":
          FieldsToReturn.push(
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <Form.Item label="Critical" name="is_critical">
                <Switch
                  checked={eachTouchPoint.is_critical}
                  onChange={(v) => handleChange("is_critical", v, id)}
                  name="is_critical"
                />
              </Form.Item>
            </Col>
          );
          break;

        case "head":
          FieldsToReturn.push(
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label="Notification Head"
                name="notification_head"
                rules={[]}
              >
                <TextArea
                  rows={6}
                  placeholder=""
                  onChange={(e) => {
                    return handleChange(
                      "notification_head",
                      e.target.value,
                      id
                    );
                  }}
                />
              </Form.Item>
              {getTouchPointValidationError("notification_head", id)}
            </Col>
          );
          break;

        case "body":
          FieldsToReturn.push(
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Body" name="body" rules={[]}>
                <TextArea
                  rows={6}
                  placeholder=""
                  onChange={(e) => {
                    return handleChange("body", e.target.value, id);
                  }}
                />
              </Form.Item>
              {getTouchPointValidationError("body", id)}
            </Col>
          );
          break;
        case "subject":
          FieldsToReturn.push(
            <Col xs={24} sm={24} md={8} lg={12} xl={12}>
              <Form.Item label="Subject" name="subject" rules={[]}>
                <TextArea
                  rows={3}
                  placeholder="Enter subject"
                  onChange={(e) => {
                    return handleChange("subject", e.target.value, id);
                  }}
                />
              </Form.Item>
              {getTouchPointValidationError("subject", id)}
            </Col>
          );
          break;
        default:
          break;
      }
    });
    return FieldsToReturn;
  };

  const getEmailList = (listOf) => {
    console.log("listOf",listOf)
    if(listOf == "Doctor")
      return recipientFields.doctor.emails
    else if(listOf == "Institute")
      return recipientFields.institute.emails
    else if(listOf == "Patient")
      return recipientFields.patient.emails
    else
      return recipientFields.employee.emails
  }
  const getSmsList = (listOf) => {
    if(listOf == "Doctor")
      return recipientFields.doctor.sms
    else if(listOf == "Institute")
      return recipientFields.institute.sms
    else if(listOf == "Patient")
      return recipientFields.patient.sms
    else
      return recipientFields.employee.sms
  }

  return (
    <div>
      {/* <div>{props.send_to}</div> */}
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2>

          </h2>
        </Col>
      </Row>
      <Divider orientation="left">{props.send_to}</Divider>
      {touchPoints.map((eachTouchPoint, id) => (
        <Form
          layout="vertical"
          ref={formRef[id]}
          initialValues={initialValues[id]}
        >
          <>
            <div style={{ margin: "5px", marginTop: "10px" }}>
              <Checkbox
                checked={eachTouchPoint.checked}
                onChange={(e) => {
                  const temp = JSON.parse(JSON.stringify(touchPoints));
                  if (e.target.checked) {
                    const tempRef = [...formRef];
                    tempRef[id] = createRef();
                    setFormRef(tempRef);
                  } else {
                    formRef[id].current.resetFields();
                    const values = formRef[id].current.getFieldsValue();
                    temp[id] = formRef[id].current.getFieldsValue();
                    console.log(values);
                    temp[id].touch_point_name = eachTouchPoint.touch_point_name;
                    setFormRef([...formRef.slice(0, id), ...formRef.slice(id)]);
                  }
                  temp[id].checked = e.target.checked;
                  setTouchPoints(temp);
                }}
              >
                {eachTouchPoint.touch_point_name}
              </Checkbox>
            </div>
            <Row gutter={20} key={id} style={{ padding: "20px" }}>
              {eachTouchPoint.checked && (
                <>
                  {eachTouchPoint.touch_point_name == "Email" ? (
                    <>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Email to"
                          name="email_to"
                          rules={[]}
                        >
                          <Select
                            mode="multiple"
                            labelInValue
                            allowClear
                            style={{ width: '100%' }}
                            value={(eachTouchPoint.email_to || []).map((email)=>email.label)}
                            placeholder="Please select"
                            onChange={(value)=>{
                              console.log(value)
                              if(value.length == 0){
                                handleChange(
                                  "email_to",
                                  [],
                                  id
                                )
                                return 
                              }
                              const optionsToMap =getEmailList(props.send_to) 
                              const data = []
                               optionsToMap.map((list)=>{
                                value.map((selected)=>{
                                  if(list.value == selected.value){
                                    data.push(list)
                                  }
                                })
                              })
                              console.log(optionsToMap,value,data)
                              handleChange(
                              "email_to",
                              data,
                              id
                            )}}
                            options={props.send_to == "Doctor" ? recipientFields.doctor.emails : props.send_to == "Institute" ? recipientFields.institute.emails : props.send_to == "Patient" ?  recipientFields.patient.emails : recipientFields.employee.emails}
                          />
                        </Form.Item>
                        {getTouchPointValidationError("email_to", id)}
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Additional email to"
                          name="additional_email"
                          rules={[]}
                        >
                          <Input
                            placeholder=""
                            onChange={(e) => {
                              return handleChange(
                                "additional_email",
                                e.target.value,
                                id
                              );
                            }}
                          />
                        </Form.Item>
                        {getTouchPointValidationError("additional_email", id)}
                      </Col>

                      {getFields(
                        ["subject", "body", "is_enabled"],
                        id,
                        eachTouchPoint
                      )}
                    </>
                  ) : eachTouchPoint.touch_point_name == "SMS" ? (
                    <>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="SMS to"
                          name="sms_to"
                          rules={[]}
                        >
                          <Select
                            mode="multiple"
                            labelInValue
                            allowClear
                            style={{ width: '100%' }}
                            value={(eachTouchPoint.sms_to || []).map((sms)=>sms.label)}
                            placeholder="Please select"
                            onChange={(value)=>{
                              const optionsToMap = getSmsList(props.send_to) 
                              const data = [] 
                              optionsToMap.map((list)=>{
                                value.map((selected)=>{
                                  if(list.value == selected.value){
                                    data.push(list)
                                  }
                                })
                              })
                              console.log(optionsToMap,value,data)
                              handleChange(
                              "sms_to",
                              data,
                              id
                            )}}
                            options={props.send_to == "Doctor" ? recipientFields.doctor.sms : props.send_to == "Institute" ? recipientFields.institute.sms :props.send_to == "Patient" ? recipientFields.patient.sms: recipientFields.employee.sms}
                          />
                        </Form.Item>
                        {getTouchPointValidationError("sms_to", id)}
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item
                          label="Additional SMS to"
                          name="additional_sms"
                          rules={[]}
                        >
                          <Input
                            placeholder=""
                            onChange={(e) => {
                              return handleChange(
                                "additional_sms",
                                e.target.value,
                                id
                              );
                            }}
                          />
                        </Form.Item>
                        {getTouchPointValidationError("additional_sms", id)}
                      </Col>

                      {getFields(["content", "is_enabled"], id, eachTouchPoint)}
                    </>
                  ) : eachTouchPoint.touch_point_name == "App" ? (
                    <>
                      {getFields(
                        ["head", "body", "is_enabled", "is_critical"],
                        id,
                        eachTouchPoint
                      )}
                    </>
                  ) : (
                    <h1>Invalid TouchPoint Found</h1>
                  )}
                </>
              )}
            </Row>
          </>
        </Form>
      ))}
      <div style={{ padding: 8, float: "right" }}>
        <Space>
          <Button
            type={"primary"}
            size="small"
            style={{ width: 90 }}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Space>
      </div>
      <Divider orientation="left"></Divider>
    </div>
  );
};

export default NotificationSendTo;
