import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { CloseOutlined, EditOutlined } from "@ant-design/icons"


/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { getPregnancyHighlightsDetail, getPregnancyHighlightsDetailReset } from "../../../action/getPregnancyHighlightsDetailAction"
import { dateFormat, s3_cdn } from '../../../utils';

const PregnancyHighlightsView = props => {
    /* variable */
    const { getPregnancyHighlightsDetail, getPregnancyHighlightsDetailReset, getPregnancyHighlightsDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getPregnancyHighlightsDetailReset()
        }
    }, [props.visible])
    useEffect(() => {
        if (props.id && props.visible) {
            getPregnancyHighlightsDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])
    return (
        <Drawer
            title={`Pregnancy Data View ${getPregnancyHighlightsDetailState.apiState === "success" ? "- " + getPregnancyHighlightsDetailState.data.week : ""}`}
            placement="right"
            closeIcon={<><Link to={`/pregnancy-highlights/edit/${props.id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link> <Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getPregnancyHighlightsDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getPregnancyHighlightsDetailState.apiState === "error" &&
                <p>{getPregnancyHighlightsDetailState.message}</p>
            }

            {getPregnancyHighlightsDetailState.apiState === "success" &&
                <>
                    <Row gutter={20} >
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Week</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.week}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Weight</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.weight}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Height</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.height}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Days Left for Delivery</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.days_left_for_delivery}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Size</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.size}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Size Image</ViewLabel>
                                <ViewValue>
                                    <img src={`${s3_cdn()}pregnancy-data/${getPregnancyHighlightsDetailState.data.size_image_path}`} style={{ width: 60 }} />
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Highlights of The Week for Baby</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.highlights_of_the_week.baby.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Highlights of The Week for Mom</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.highlights_of_the_week.mom.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Precautions for Baby</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.precautions.baby.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Precautions for Mom</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.precautions.mom.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Recommendation for Baby</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.recommendation_for_you.baby.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Recommendation for Mom</ViewLabel>
                                <ViewValue>{getPregnancyHighlightsDetailState.data.recommendation_for_you.mom.join(", ")}</ViewValue>
                            </ViewBox>
                        </Col>

                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getPregnancyHighlightsDetailState.data.createdAt)}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>{dateFormat(getPregnancyHighlightsDetailState.data.updatedAt)}</ViewValue>
                            </ViewBox>
                        </Col>

                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getPregnancyHighlightsDetailState: state.getPregnancyHighlightsDetail
});

const mapDispatchToProps = (dispatch) => ({
    getPregnancyHighlightsDetail: (params) => dispatch(getPregnancyHighlightsDetail(params)),
    getPregnancyHighlightsDetailReset: () => dispatch(getPregnancyHighlightsDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(PregnancyHighlightsView);