import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_QUICK_SUPPORT_CATEGORY_DETAIL,
    getQuickSupportCategoryDetailFail,
    getQuickSupportCategoryDetailSuccess
} from '../action/getQuickSupportCategoryDetailAction'

// api 
import getQuickSupportCategoryDetailApi from '../api/getQuickSupportCategoryDetailApi'

export function* getQuickSupportCategoryDetailSaga(action) {
    try {
        const response = yield call(() => getQuickSupportCategoryDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getQuickSupportCategoryDetailSuccess(response, action))
        } else {
            yield put(getQuickSupportCategoryDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getQuickSupportCategoryDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_QUICK_SUPPORT_CATEGORY_DETAIL, getQuickSupportCategoryDetailSaga)
}