import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER,
    getInstituteTestPricingUploadMasterFail,
    getInstituteTestPricingUploadMasterSuccess
} from '../action/getInstituteTestPricingUploadListAction'

// api 
import getInstituteTestPricingUploadMasterApi from '../api/getInstituteTestPricingUploadMasterApi'

export function* getInstituteTestPricingUploadMasterSaga(action) {
    try {
        const response = yield call(() => getInstituteTestPricingUploadMasterApi(action.params))
        if (response.data.status === 1) {
            yield put(getInstituteTestPricingUploadMasterSuccess(response, action))
        } else {
            yield put(getInstituteTestPricingUploadMasterFail(response, action))
        }
    }
    catch (e) {
        yield put(getInstituteTestPricingUploadMasterFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_INSTITUTE_TEST_PRICING_UPLOAD_MASTER, getInstituteTestPricingUploadMasterSaga)
}