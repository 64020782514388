import React, { useState, useEffect } from "react"
import { Button, Checkbox, Form, notification, Row, Card, Table } from 'antd';
import { SaveOutlined } from "@ant-design/icons"
import { connect } from "react-redux";
import _ from 'lodash'

/* CUSTOM COMPONENTS */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"
import AddUserRights from "../../../component/UserRights/AddUserRights";

/* ACTIONS */

const UserRights = props => {
    /* VARIABLES */
    const user_id = props.match.params.id

    /* CAllBACKS */


    return (
        <>
            <Header
                backButton={"/user/list"}
                title={"User Rights"}
            />
            <PageContainer>
                <LayoutContainer>
                    <AddUserRights user_id={user_id} />
                </LayoutContainer>
            </PageContainer >
        </>
    )
}
const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(UserRights);