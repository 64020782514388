import React, { useEffect, useState } from "react"
import { Layout, Menu, Alert, Button, Form, Input, Space, notification } from "antd"
import { connect } from "react-redux"
import { Redirect, Link, useHistory } from "react-router-dom"

/* custom component */
import { Container, Box, ContainerHeader, Left, Right, LoginBox, XContent, SiteLayoutBackground } from './ResetPasswordStyle'
import LoginHeader from '../../component/LoginHeader/LoginHeader';

/* Actions */
import { otp } from '../../action/otpAction'
import { resetPassword } from '../../action/resetPasswordAction'
import styled from "styled-components"
const { Footer } = Layout;

const ResetPassword = props => {

    // variables
    const {
        otp, otpState,
        resetPassword, resetPasswordState, location,
    } = props

    const [formData, setFormData] = useState({
        email: location.state?.email,
        password: location.state?.password,
        otp: "",
        new_password: "",
        re_entered_password: "",
        hasOtp: location.state?.reset
    })
    const [redirect, setRedirect] = useState(false)
    //const [reset, setReset] = useState(dat)
    /* Callback */
    useEffect(() => {
        if (resetPasswordState.apiState === "success") {
            notification.success({
                message: resetPasswordState.message,
                placement: "bottomRight"
            });
            setRedirect(true)
        }
        else if (resetPasswordState.apiState === "error") {
            notification.success({
                message: resetPasswordState.message,
                placement: "bottomRight"
            });
        }
        else {
            otpState.apiState = ""
        }
    }, [resetPasswordState.apiState])
    useEffect(() => {
        if (otpState.apiState === "success") {
            notification.success({
                message: otpState.message,
                placement: "bottomRight"
            });   
        }
   
        else if (otpState.apiState === "error")  {
            notification.success({ 
                message: otpState.message,
                placement: "bottomRight"
            });
        }
        else {
            otpState.apiState = ""
        }
    }, [otpState.apiState])

    // functions
    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onFormSubmit = () => {
        resetPassword(formData)
    }
    const onOtpSubmit = () => {
        otp(formData)
    }

    return (
        <>
            {redirect &&
                <Redirect to="/login" />
            }
            <Layout style={{ backgroundColor: "#fff" }}>
                <LoginHeader />
                <XContent className="site-layout">
                    <SiteLayoutBackground className="site-layout-background">
                        <Left>
                            <img height='100%' width='100%' src={process.env.PUBLIC_URL + "/images/svg/AdminPanle.svg"} alt="" />
                        </Left>
                        <Right>
                            <LoginBox>
                                <h2>Reset Password?</h2>
                            
                            {location.state?.reset == true &&
                                    <>
                                    <p>OTP will be sent to your Official Email ID</p>
                                <Form layout="vertical" onFinish={onOtpSubmit}>
                                    <Form.Item
                                            label="User Login Email"
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Required' },
                                            { type: 'email', message: 'Email is not valid' },
                                        ]}
                                    >
                                        <Input
                                            name="email"
                                            placeholder="Email"
                                            onChange={handleOnChange}
                                            size="large"
                                        />
                                    </Form.Item>
                                <Button htmlType="submit" type="primary"
                                        size="middle"
                                        loading={otpState.apiState === "loading" ? true : false}
                                        block >SEND OTP</Button>
                                </Form>
                     
                            
                           
                             
                                { otpState.apiState === "success" &&
                                 
                                    <Form layout="vertical" onFinish={onFormSubmit}>
                                  
                                        <Form.Item
                                            label="OTP"
                                            name="otp"
                                            rules={[{ required: true, message: 'Required' }]}
                                        >
                                            <Input.Password name="otp" placeholder="Otp" onChange={handleOnChange}
                                                size="large"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="New Password"
                                            name="new_password"
                                            rules={[{ required: true, message: 'Required' }]}
                                        >
                                            <Input.Password name="new_password" placeholder="New Password" onChange={handleOnChange}
                                                size="large"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Confirm Password"
                                            name="re_entered_password"
                                            rules={[{ required: true, message: 'Required' }]}
                                        >
                                            <Input.Password name="re_entered_password" placeholder="Confirm Password" onChange={handleOnChange}
                                                size="large"
                                            />
                                        </Form.Item>
                                        <Button htmlType="submit" type="primary"
                                            size="middle"
                                            loading={resetPasswordState.apiState === "loading" ? true : false}
                                            block >RESET PASSWORD</Button>
                                    </Form>
                                }
                                    
                                
                                   </>
                            }
                            {location.state?.reset == false &&
                          
                            
                                <Form layout="vertical" onFinish={onFormSubmit}>
                        
                                    <Form.Item
                                        label="New Password"
                                        name="new_password"
                                        rules={[{ required: true, message: 'Required' }]}
                                    >
                                        <Input.Password name="new_password" placeholder="New Password" onChange={handleOnChange}
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Confirm Password"
                                        name="re_entered_password"
                                        rules={[{ required: true, message: 'Required' }]}
                                    >
                                        <Input.Password name="re_entered_password" placeholder="Confirm Password" onChange={handleOnChange}
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Button htmlType="submit" type="primary"
                                        size="middle"
                                        loading={resetPasswordState.apiState === "loading" ? true : false}
                                        block >RESET PASSWORD</Button>
                                </Form>
                              
                           
                                }
                             
                                <Button htmlType="submit" type="primary" style={{marginTop:"10px"}}
                                    onClick={() => { setRedirect(true)}}
                                    size="middle"
                                        block >CANCEL</Button>
                                  
                                </LoginBox>
                        </Right>
                    </SiteLayoutBackground>
                </XContent>
                <Footer style={{ textAlign: 'center', position: "fixed", bottom: 0, width: "100%", backgroundColor: "#15558d", color: "#fff" }}>Lilac Insights �2021</Footer>
            </Layout>
        </>
    )
}

const mapStateToProps = (state) => ({
    resetPasswordState: state.resetPassword,
    otpState: state.otp
})

const mapDispatchToProps = (dispatch) => ({
    otp: (params) => dispatch(otp(params)),
    resetPassword: (params) => dispatch(resetPassword(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)