import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, DatePicker, TimePicker, Tabs, Switch, InputNumber, Space, Dropdown, Menu } from "antd"
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import moment from 'moment'
import _ from "lodash"
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveMou, saveMouReset } from "../../../action/saveMouAction"

/* otehrs */
import { s3_cdn, validateImageFile, getS3SingedUrl,capitalizeFirst } from '../../../utils'
import { DownOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;
const { TabPane } = Tabs;



const MouSave = props => {
    /* variables */
    const { saveMou, saveMouState, saveMouReset} = props

    const [cancelButton, setCancelButton] = useState(false)
    
    const [formData, setFormData] = useState({
        start_date: "",
        end_date: "",
        files: "",
        type_of_mou: "",
    })

    const [imageFile, setImageFile] = useState({
        fileName: null,
        isChanged: false,
        edit: true
    })
    
    const [testGroupForm] = Form.useForm()
    const [redirect, setRedirect] = useState([false, ""])
    

    /* callbacks */
    useEffect(() => {
        return (() => {
            saveMouReset()
        })
    }, [])

    useEffect(() => {
        if (saveMouState.apiState === "success") {
            setRedirect([true, "/mou"])
            saveMouReset()
            //resetAllData()
            notification["success"]({
                message: saveMouState.message,
                placement: "topRight"
            })
        }
        if (saveMouState.apiState === "error") {
            saveMouReset()
            notification["error"]({
                message: saveMouState.message,
                placement: "topRight"
            })
        }
    }, [saveMouState])


    

    /* functions */
    
    const handleSubmit = async () => {
        
        console.log("check", formData)
        saveMou(formData)

        // if (imageFile.isChanged) {
        //     let imagefilename = uuidv4()
        //     let imageValidationRes = validateImageFile(imageFile.fileName)
        //     if (imageValidationRes.status) {
        //         let s3UrlResImage = await getS3SingedUrl(imagefilename, imageValidationRes.ext, imageFile.fileName, 'mou')
        //         await axios.put(s3UrlResImage.url, imageFile.fileName)
        //         // formData.profile_picture = `${imagefilename}.${imageValidationRes.ext}`
        //         // formData.profile_picture_changed = true
        //     }
        //     else {
        //         alert("Select image type JPG or PNG.")
        //         return
        //     }
        // }
        // else {
        //     formData.profile_picture_changed = false
        // }

        console.log("checks")
    }

    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const resetAllData = () => {
        setFormData()
        testGroupForm.resetFields()
        setCancelButton(false)
    }

    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }

    const handlefileOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.files[0].name })
    }

    // const handleDateChange = (name, date, dateString) => {
    //     setFormData({ ...formData, [name]: moment(date) })
    // }

    // const handleDateRangeChange = (from_name, to_name, dateArr) => {
    //     setFormData({ ...formData, [from_name]: moment(dateArr[0]), [to_name]: moment(dateArr[1]) })
    // }

    // const handleTimeRangeChange = (from_name, to_name, dateArr) => {
    //     setFormData({ ...formData, [from_name]: moment(dateArr[0]).format("HH:mm"), [to_name]: moment(dateArr[1]).format("HH:mm") })
    // }

    
    

    // const handleSubmit = async () => {

    //     if (imageFile.isChanged) {
    //         let imagefilename = uuidv4()
    //         let imageValidationRes = validateImageFile(imageFile.fileName)
    //         if (imageValidationRes.status) {
    //             let s3UrlResImage = await getS3SingedUrl(imagefilename, imageValidationRes.ext, imageFile.fileName, 'doctor')
    //             await axios.put(s3UrlResImage.url, imageFile.fileName)
    //             formData.profile_picture = `${imagefilename}.${imageValidationRes.ext}`
    //             formData.profile_picture_changed = true
    //         }
    //         else {
    //             alert("Select image type JPG or PNG.")
    //             return
    //         }
    //     }
    //     else {
    //         formData.profile_picture_changed = false
    //     }

    //     console.log("-->form",formData);
        
    //     // saveDoctor(formData)
    // }

    // const handleValidation = () => {
    //     form.validateFields()
    //         .then(handleSubmit)
    //         .catch(e => {
    //             requiredFiledsTabWise.map(obj => {
    //                 if (obj.fields.includes(e.errorFields[0].name[0])) {
    //                     setActiveTab(obj.tab)
    //                 }
    //             })
    //         })
    // }

    // const handleOnChangePincode = (e) => {
    //     if (e.target.value.length === 6) {
    //         getPincodeList({ pincode: e.target.value })
    //     }
    // }
    // const validatePincode = (rule, value, callback) => {
    //     if (value.length === 6) {
    //         if (getPincodeListState.apiState === "success" && getPincodeListState.data !== null) {
    //             callback();
    //         } else {
    //             callback(`Please enter correct Pincode.`);
    //         }
    //     }
    //     else {
    //         callback();
    //     }
    // }

    // const menu = (
    //     <Menu>
    //         {(getDoctorDetailState.data.activate_status === "pending" || getDoctorDetailState.data.activate_status === "deactive") &&
    //             <Menu.Item>
    //                 <Link onClick={() => activateDoctor({ id: getDoctorDetailState.data._id, status: "active" })}>
    //                     Active
    //                 </Link>
    //             </Menu.Item>
    //         }
    //         {(getDoctorDetailState.data.activate_status === "pending" || getDoctorDetailState.data.activate_status === "active") &&

    //             <Menu.Item>
    //                 <Link onClick={() => activateDoctor({ id: getDoctorDetailState.data._id, status: "deactive" })}>
    //                     Deactive
    //                 </Link>
    //             </Menu.Item>
    //         }
    //     </Menu>
    // )

    return (
        <>
            {redirect[0] && <Redirect to={redirect[1]} />}

            <Header
                backButton={"/mou"}
                title={"Add MOU"}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={testGroupForm} layout="vertical" onFinish={handleSubmit}>
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Start Date"
                                    name="start_date"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                <Input name="start_date" type="date" onChange={handleOnChange} /></Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="End Date"
                                    name="end_date"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input name="end_date" type="date"
                                        onChange={handleOnChange}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="Upload File"
                                    name="files"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input name="files" type="file" placeholder="Upload File"
                                        onChange={handlefileOnChange}
                                        // onChange={e => setImageFile({
                                        //     fileName: e.target.files[0],
                                        //     isChanged: e.target.files[0] ? true : false,
                                        //     edit: false
                                        // })}
                                        accept="image/png, image/jpeg"
                                    />
                                    {/* <Input name="profile_picture" placeholder="Enter Profile Picture"
                                        type="file"
                                        onChange={e => setImageFile({
                                            fileName: e.target.files[0],
                                            isChanged: e.target.files[0] ? true : false,
                                            edit: false
                                        })}
                                        accept="image/png, image/jpeg"
                                        suffix={(formData.profile_picture && mode === 'edit') ? <img src={`${s3_cdn()}doctor/${formData.profile_picture}`} style={{ width: 26 }} /> : null}
                                        disabled={formData.is_dcp_activated === "No"}
                                    /> */}
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Form.Item
                                    label="MOU type"
                                    name="type_of_mou"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Select
                                        name="type_of_mou"

                                        placeholder="Select type_of_mou"
                                        onChange={(v) => handleSelect("type_of_mou", v)}>
                                        <Select.Option value="price_mou">Price Mou</Select.Option>
                                        <Select.Option value="legal_mou">Legal Mou</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                <Space>
                                    <Button type="primary" htmlType="submit" loading={saveMouState.apiState === "loading"} >SUBMIT</Button>
                                    {cancelButton &&
                                        <Button type="default" onClick={resetAllData} >Cancel</Button>
                                    }
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </LayoutContainer >
            </PageContainer >
        </>
    )
}

const mapStateToProps = (state) => ({
    saveMouState: state.saveMou,
});

const mapDispatchToProps = (dispatch) => ({
    saveMou: (params) => dispatch(saveMou(params)),
    saveMouReset: () => dispatch(saveMouReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MouSave);