import React, { useEffect } from 'react'
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { CloseOutlined, EditOutlined } from "@ant-design/icons"

/* custom component */
import { ViewBox, ViewLabel, ViewValue } from "../../../component/Xcomponent"

/* action */
import { viewDoctorSignupRequest, viewDoctorSignupRequestReset } from '../../../action/viewDoctorSignupRequestAction'

const DoctorSignupRequestView = (props) => {
    /* varibles */
    const { viewDoctorSignupRequest, viewDoctorSignupRequestReset, viewDoctorSignupRequestState } = props

    /* callbacks */
    useEffect(() => {
        return (() => {
            viewDoctorSignupRequestReset()
        })
    }, [])

    useEffect(() => {
        if (props.id && props.visible) {
            viewDoctorSignupRequest({
                id: props.id
            })
        }
    }, [props.id, props.visible])

    return (
        <Drawer
            title={`User View`}
            placement="right"
            closeIcon={<Button size="small" shape="circle" icon={<CloseOutlined />} />}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {viewDoctorSignupRequestState.apiState === "loading" &&
                <Skeleton active />}

            {viewDoctorSignupRequestState.apiState === "error" &&
                <p>{viewDoctorSignupRequestState.message}</p>
            }
            {viewDoctorSignupRequestState.apiState === "success" &&
                <Row gutter={20} >
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>First Name</ViewLabel>
                            <ViewValue>{viewDoctorSignupRequestState.data.data.result.fname ? viewDoctorSignupRequestState.data.data.result.fname : "-"}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>Last Name</ViewLabel>
                            <ViewValue>{viewDoctorSignupRequestState.data.data.result.lname ? viewDoctorSignupRequestState.data.data.result.lname : "-"}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>Mobile Number</ViewLabel>
                            <ViewValue>{viewDoctorSignupRequestState.data.data.result.mobile_number ? viewDoctorSignupRequestState.data.data.result.mobile_number : "-"}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>Email</ViewLabel>
                            <ViewValue>{viewDoctorSignupRequestState.data.data.result.email ? viewDoctorSignupRequestState.data.data.result.email : "-"}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>Institute Name</ViewLabel>
                            <ViewValue>{viewDoctorSignupRequestState.data.data.result.institute_name ? viewDoctorSignupRequestState.data.data.result.institute_name : "-"}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>Institute Email</ViewLabel>
                            <ViewValue>{viewDoctorSignupRequestState.data.data.result.institute_email ? viewDoctorSignupRequestState.data.data.result.institute_email : "-"}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="12">
                        <ViewBox>
                            <ViewLabel>FMF Code</ViewLabel>
                            <ViewValue>{viewDoctorSignupRequestState.data.data.result.fmf_code ? viewDoctorSignupRequestState.data.data.result.fmf_code : "-"}</ViewValue>
                        </ViewBox>
                    </Col>
                    <Col span="24">
                        <ViewBox>
                            <ViewLabel>Institute Address</ViewLabel>
                            <ViewValue>{viewDoctorSignupRequestState.data.data.result.institute_address ? viewDoctorSignupRequestState.data.data.result.institute_address : "-"}</ViewValue>
                        </ViewBox>
                    </Col>
                </Row>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    viewDoctorSignupRequestState: state.viewDoctorSignupRequest
});

const mapDispatchToProps = (dispatch) => ({
    viewDoctorSignupRequest: (params) => dispatch(viewDoctorSignupRequest(params)),
    viewDoctorSignupRequestReset: () => dispatch(viewDoctorSignupRequestReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSignupRequestView);
