import React, { useState, useEffect } from "react"
import { Form, Input, Row, Col, Select, Button, notification, InputNumber, Card, DatePicker, Switch } from "antd"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import _ from "lodash"
import moment from 'moment'
/* custom component */
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveCoupon, saveCouponReset } from "../../../action/saveCouponAction"
import { getCouponDetail, getCouponDetailReset } from "../../../action/getCouponDetailAction"
import Header from "../../../component/Header/Header";

/* otehrs */
export const CouponSave = props => {
    /* variables */
    const id = props.match.params.id
    const mode = id ? "edit" : "add"
    const {
        saveCoupon, saveCouponReset, saveCouponState,
        getCouponDetail, getCouponDetailReset, getCouponDetailState,
    } = props
    const [formData, setFormData] = useState({
        code: "",
        valid_till: "",
        minimum_order: "",
        maximum_discount: "",
        discount: "",
        is_percentage: false,
        max_usage_allowed: "",
        once_per_usage: false,
        active: false,
        consolation_type: "",
        times_used: "",
    })
    if (mode === "edit") {
        formData.id = id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()

    /* callbacks */
    useEffect(() => {
        return (() => {
            saveCouponReset()
            getCouponDetailReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getCouponDetail({
                id: id
            })
        }
    }, [])

    useEffect(() => {
        if (getCouponDetailState.apiState === "success") {
            form.setFieldsValue({
                code: getCouponDetailState.data.code,
                // valid_till: getCouponDetailState.data.valid_till,
                valid_till: getCouponDetailState.data.valid_till ? moment(getCouponDetailState.data.valid_till) : null,
                minimum_order: getCouponDetailState.data.minimum_order,
                maximum_discount: getCouponDetailState.data.maximum_discount,
                discount: getCouponDetailState.data.discount,
                is_percentage: getCouponDetailState.data.is_percentage,
                max_usage_allowed: getCouponDetailState.data.max_usage_allowed,
                once_per_usage: getCouponDetailState.data.once_per_usage,
                active: getCouponDetailState.data.active,
                consolation_type: getCouponDetailState.data.consolation_type,
                times_used: getCouponDetailState.data.times_used,
            })
            setFormData({
                ...formData,
                ['code']: getCouponDetailState.data.code,
                ['valid_till']: getCouponDetailState.data.valid_till,
                ['minimum_order']: getCouponDetailState.data.minimum_order,
                ['maximum_discount']: getCouponDetailState.data.maximum_discount,
                ['discount']: getCouponDetailState.data.discount,
                ['is_percentage']: getCouponDetailState.data.is_percentage,
                ['max_usage_allowed']: getCouponDetailState.data.max_usage_allowed,
                ['once_per_usage']: getCouponDetailState.data.once_per_usage,
                ['active']: getCouponDetailState.data.active,
                ['consolation_type']: getCouponDetailState.data.consolation_type,
                ['times_used']: getCouponDetailState.data.times_used,
            })
        }
    }, [getCouponDetailState])

    useEffect(() => {
        if (saveCouponState.apiState === "success") {
            saveCouponReset()
            setRedirect([true, "/coupon/list"])
            notification["success"]({
                message: saveCouponState.message,
                placement: "topRight"
            })
        }
        if (saveCouponState.apiState === "error") {
            saveCouponReset()
            notification["error"]({
                message: saveCouponState.message,
                placement: "topRight"
            })
        }
    }, [saveCouponState])

    /* functions */
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const onChange = (value, dateString) => {
        setFormData({ ...formData, ['valid_till']: value })
    }

    const handleInputChange = (name, value) => {
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = () => {
        saveCoupon(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                backButton={"/coupon/list"}
                title={mode === "edit" ? "Edit Coupon" : "Add Coupon"}
            />
            <PageContainer>
                <Card title={mode === "edit" ? "Edit Coupon" : "Add Coupon"} bordered={false} >
                    <LayoutContainer>
                        <Form form={form} layout="vertical" onFinish={handleSubmit} >
                            <Row gutter={20}>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Code"
                                        name="code"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <Input name="code" placeholder="Enter Code"
                                            onChange={handleOnChange}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Valid Till"
                                        name="valid_till"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <DatePicker name="valid_till" showTime onChange={onChange} style={{ width: '100%' }} />
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Information"
                                    >

                                    </Form.Item>
                                </Col> */}
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Minimum Order"
                                        name="minimum_order"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <InputNumber min={1} name="minimum_order" placeholder="Enter Minimum Order"
                                            onChange={v => handleInputChange("minimum_order", v)} style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Maximum Discount"
                                        name="maximum_discount"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <InputNumber min={0} name="maximum_discount" placeholder="Enter Maximum Discount"
                                            onChange={v => handleInputChange("maximum_discount", v)} style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Discount"
                                        name="discount"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <InputNumber min={0} name="discount" placeholder="Enter Discount"
                                            onChange={v => handleInputChange("discount", v)} style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Is Percentage"
                                        name="is_percentage"
                                    >
                                        <Switch name="is_percentage"
                                            onChange={checked => handleInputChange("is_percentage", checked)}
                                            checked={formData.is_percentage}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Max Usage Allowed"
                                        name="max_usage_allowed"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <InputNumber min={0} name="max_usage_allowed" placeholder="Enter Max Usage Allowed"
                                            onChange={v => handleInputChange("max_usage_allowed", v)} style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Once Per Usage"
                                        name="once_per_usage"
                                    >
                                        <Switch name="once_per_usage"
                                            onChange={checked => handleInputChange("once_per_usage", checked)}
                                            checked={formData.once_per_usage}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Active"
                                        name="active"
                                    >
                                        <Switch name="active"
                                            onChange={checked => handleInputChange("active", checked)}
                                            checked={formData.active}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Times Used"
                                        name="times_used"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <InputNumber min={0} name="times_used" placeholder="Enter Times Used"
                                            onChange={v => handleInputChange("times_used", v)} style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Consolation Type"
                                        name="consolation_type"
                                        rules={[{ required: true, message: "Required" }]}
                                    >
                                        <Select
                                            showSearch
                                            name="consolation_type"
                                            placeholder="Select Consolation Type"
                                            onChange={(v) => handleInputChange("consolation_type", v)}
                                        >
                                            <Select.Option value="MHC" key="MHC" >MHC</Select.Option>
                                            <Select.Option value="GC" key="GC" >GC</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                saveCouponState.apiState=="loading" ? <Button type="primary" loading>Adding</Button> 
                                : <Button type="primary" htmlType="submit">SUBMIT</Button>
                            }

                        </Form>
                    </LayoutContainer>
                </Card>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveCouponState: state.saveCoupon,
    getCouponDetailState: state.getCouponDetail,
});

const mapDispatchToProps = (dispatch) => ({
    saveCoupon: (params) => dispatch(saveCoupon(params)),
    saveCouponReset: () => dispatch(saveCouponReset()),
    getCouponDetail: (params) => dispatch(getCouponDetail(params)),
    getCouponDetailReset: () => dispatch(getCouponDetailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponSave);