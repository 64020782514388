import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_USER_RIGHTS,
    saveUserRightsFail,
    saveUserRightsSuccess
} from '../action/saveUserRightsAction'

// api 
import saveUserRightsApi from '../api/saveUserRightsApi'

export function* saveUserRightsSaga(action) {
    try {
        const response = yield call(() => saveUserRightsApi(action.params))
        if (response) {
            yield put(saveUserRightsSuccess(response, action))
        } else {
            yield put(saveUserRightsFail(response, action))
        }
    }
    catch (e) {
        yield put(saveUserRightsFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_USER_RIGHTS, saveUserRightsSaga)
}