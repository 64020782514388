import React, { useEffect, useState } from "react";
import { Menu, Layout, Row, Col, Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  AppstoreOutlined,
  BookOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UserOutlined,
  CalendarOutlined,
  WechatOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import _ from "lodash";

/* custom components */
import { MenuIcon } from "../CustomIcons";

/* OTHERS */
import { checkRights, checkRightsForMainModule } from "../../utils";

const { Header, Footer, Sider, Content } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {
  /* variables */
  const [collapse, setCollapse] = useState(false);
  const [logoSpan, setLogoSpan] = useState({ first: 18, second: 6 });
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState("");
  const location = useLocation().pathname;
  /* callbacks */
  useEffect(() => {
    if (location !== "/") {
      let arrayPath = location.split("/");
      setDefaultSelectedKeys(arrayPath[1]);
    }
  }, []);
  useEffect(() => {
    let elms = document.getElementsByClassName("site-layout");
    if (collapse) {
      elms[0].style.margin = "0 0 0 80px";
    } else {
      elms[0].style.margin = "0 0 0 200px";
    }
  }, [collapse]);

  /* functions */
  const toggle = () => {
    setCollapse(!collapse);
    setLogoSpan(collapse ? { first: 18, second: 6 } : { first: 0, second: 24 });
  };

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        overflowX: "hidden",
      }}
      // collapsible
      collapsed={collapse}
    >
      <div style={{ padding: "7px 8px", textAlign: "center" }}>
        <div className="sidebar-header">
          {!collapse && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span style={{ paddingLeft: "35px" }}>
                <img
                  style={{ width: 35 }}
                  src="/lilac-insights-logo.png"
                  alt=""
                />
              </span>
              <Button type="link" className="sider-toggle" onClick={toggle}>
                <MenuFoldOutlined style={{ color: "#fff", fontSize: 20 }} />
              </Button>
            </div>
          )}
          {collapse && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span style={{ paddingLeft: "5px" }}>
                <img
                  style={{ width: 35 }}
                  src="/lilac-insights-logo.png"
                  alt=""
                />
              </span>
              <Button type="link" className="sider-toggle" onClick={toggle}>
                <MenuUnfoldOutlined style={{ color: "#fff", fontSize: 20 }} />
              </Button>
            </div>
          )}
        </div>
      </div>
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={[defaultSelectedKeys]}
        defaultOpenKeys={[]}
      >
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to={"/"}>
            <span className="menuName">Home</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="notification" icon={<HomeOutlined />}>
          <Link to={"/notification/list"}>
            <span className="menuName">Notification Master</span>
          </Link>
        </Menu.Item>
        {checkRightsForMainModule([
          "test_master",
          "institute_master",
          "sample",
          "container",
          "sample_container_test_map_master",
          "bdm_master",
          "doctor_master",
          "patient_master",
          "institute_test_pricing_master",
          "employee_master",
          "coupon_engine",
          "page_content",
          "user_term_and_condition",
        ]) && (
          <SubMenu
            key="master-management"
            title="Master Management"
            icon={<SettingOutlined />}
          >
            {checkRights("test_master", "list") && (
              <Menu.Item key="test-master">
                <Link to={"/test-master/list"}>
                  <span className="menuName">Test Master</span>
                </Link>
              </Menu.Item>
            )}
            {checkRights("institute_master", "list") && (
              <Menu.Item key="institute-master">
                <Link to={"/institute-master/list"}>
                  <span className="menuName">Institute Master</span>
                </Link>
              </Menu.Item>
            )}
            {checkRights("sample", "list") && (
              <Menu.Item key="sample-master">
                <Link to={"/sample-master/list"}>
                  <span className="menuName">Sample Master</span>
                </Link>
              </Menu.Item>
            )}
            {checkRights("container", "list") && (
              <Menu.Item key="container-master">
                <Link to={"/container-master/list"}>
                  <span className="menuName">Container Master</span>
                </Link>
              </Menu.Item>
            )}
            {checkRights("sample_container_test_map_master", "list") && (
              <Menu.Item key="sample-container-test-map-master">
                <Link to={"/sample-container-test-map-master/list"}>
                  <span className="menuName">
                    Sample Container Test Map Master
                  </span>
                </Link>
              </Menu.Item>
            )}
            {/* {checkRights("bdm_master", "list") && (
              <Menu.Item key="4">BDM Master</Menu.Item>
            )} */}
            {checkRights("doctor_master", "list") && (
              <Menu.Item key="doctor-master">
                <Link to={"/doctor-master/list"}>
                  <span className="menuName">Dr. Master</span>
                </Link>
              </Menu.Item>
            )}
            {checkRights("patient_master", "list") && (
              <Menu.Item key="patient-master">
                <Link to={"/patient-master/list"}>
                  <span className="menuName">Patient Master</span>
                </Link>
              </Menu.Item>
            )}
            {/* {checkRights("institute_test_pricing_master", "list") && (
              <Menu.Item key="institute-test-pricing-master">
                <Link to={"/institute-test-pricing-master/list"}>
                  Institute Pricing Master
                </Link>
              </Menu.Item>
            )} */}
            {checkRights("institute_test_pricing_master", "list") && (
              <Menu.Item key="institute-test-pricing-master-institute">
                <Link to={"/institute-test-pricing-master-institute/list"}>
                  Institute Pricing Master
                </Link>
              </Menu.Item>
            )}
            {checkRights("employee_master", "list") && (
              <Menu.Item key="employee-master">
                <Link to={"/employee-master/list"}>
                  <span className="menuName">Employee Master</span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="common-master">
              <Link to={"/common-master"}>
                <span className="menuName">Common Master</span>
              </Link>
            </Menu.Item>
            {checkRights("coupon_engine", "list") && (
              <Menu.Item key="coupon">
                <Link to={"/coupon/list"}>
                  <span className="menuName">Coupon Engine</span>
                </Link>
              </Menu.Item>
            )}
            {checkRights("page_content", "list") && (
              <Menu.Item key="page-content-list">
                <Link to={"/page-content/list"}>
                  <span className="menuName">Page Content Master</span>
                </Link>
              </Menu.Item>
            )}
            {checkRights("user_term_and_condition", "list") && (
              <Menu.Item key="user-term-and-condition">
                <Link to={"/user-term-and-condition/list"}>
                  <span className="menuName">User Term And Condition</span>
                </Link>
              </Menu.Item>
        )}

          </SubMenu>
        )}

                {checkRightsForMainModule(['content_repo', 'pregnancy_data']) &&
                    <SubMenu key="cms" title="CMS" icon={<AppstoreOutlined />}>
                        {checkRights("content_repo", "list") &&
                            <SubMenu key="content-repo" title="Content Repo" icon={<BookOutlined />}>
                                <Menu.Item key="content-repo-pregnancy">
                                    <Link to={"/content-repo-pregnancy/list"}>
                                        <span className='menuName'>Pregnancy</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="content-repo-tribe">
                                    <Link to={"/content-repo-tribe/list"}>
                                        <span className='menuName'>Tribe</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="content-repo-general">
                                    <Link to={"/content-repo-general/list"}>
                                        <span className='menuName'>General</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="content-repo-doctor">
                                    <Link to={"/content-repo-doctor/list"}>
                                        <span className='menuName'>Doctor</span>
                                    </Link>
                                </Menu.Item>
                            </SubMenu>
                        }
                        {checkRights("pregnancy_data", "list") &&
                            <Menu.Item key="pregnancy-highlights">
                                <Link to={"/pregnancy-highlights/list"}>
                                    <span className='menuName'>Pregnancy Data</span>
                                </Link>
                            </Menu.Item>
                        }

                    </SubMenu>
                }
                {checkRights("user", "list") &&
                    <Menu.Item key="user" icon={<UserOutlined />} >
                        <Link to={"/user/list"}>
                            <span className='menuName'>User</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("doctor_signup_request", "list") &&
                    <Menu.Item key="doctor_signup_request" icon={<UserOutlined />} >
                        <Link to={"/doctor-signup-request/list"}>
                            <span className='menuName'>Doctor Signup Requests</span>
                        </Link>
                    </Menu.Item>
              }
              {checkRights("dtrf", "list") &&
                  <Menu.Item key="dtrf" icon={<UserOutlined />} >
                      <Link to={"/dtrfs/list"}>
                          <span className='menuName'>Custom Doctor List</span>
                      </Link>
                  </Menu.Item>
              }
                {checkRights("testimonial", "list") &&
                    <Menu.Item key="testimonial" icon={<UserOutlined />} >
                        <Link to={"/testimonial/list"}>
                            <span className='menuName'>Testimonial</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("consulation_dashboard", "list") &&
                    <Menu.Item key="consulation_dashboard" icon={<UserOutlined />} >
                        <Link to={"/consulation-dashboard/list"}>
                            <span className='menuName'>Consulation Dashboard</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("payment_history", "list") &&
                    <Menu.Item key="payment-history" icon={<UserOutlined />} >
                        <Link to={"/payment-history"}>
                        {/* new code */}
                        <span className='menuName'>Consulation appointments</span>
                        {/* older code */}
                            {/* <span className='menuName'>Payment History</span> */}
                        </Link>
                    </Menu.Item>
                }
                {checkRights("chat_history", "list") &&
                    <Menu.Item key="chat-history" icon={<WechatOutlined />} >
                        <Link to={"/chat-history"}>
                            <span className='menuName'>Chat History</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("quick_support", "list") &&
                    <SubMenu key="quick-support" title="Quick Support" icon={<BookOutlined />}>
                        <Menu.Item key="quick-support-category">
                            <Link to={"/quick-support-category/list"}>
                                <span className='menuName'>Category</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="quick-support-question-answer">
                            <Link to={"/quick-support-question-answer"}>
                                <span className='menuName'>Question Answer</span>
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                }
                {checkRights("module", "list") &&
                    <Menu.Item key="module" icon={<SettingOutlined />} >
                        <Link to={"/module/list"}>
                            <span className='menuName'>Module Master</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("admin_type", "list") &&
                    <Menu.Item key="admin-type" icon={<SettingOutlined />} >
                        <Link to={"/admin-type/list"}>
                            <span className='menuName'>Admin Type Master</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("role", "list") &&
                    <Menu.Item key="role" icon={<SettingOutlined />} >
                        <Link to={"/role/list"}>
                            <span className='menuName'>Role Master</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("role_module_mapping", "list") &&
                    <Menu.Item key="role-module-mapping" icon={<SettingOutlined />} >
                        <Link to={"/role-module-mapping/list"}>
                            <span className='menuName'>Role Module Mapping Master</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("patient_test_book", "list") &&
                    <Menu.Item key="patient-test-book" icon={<SettingOutlined />} >
                        <Link to={"/patient-test-book/list"}>
                            <span className='menuName'>Patient Test Book</span>
                        </Link>
                    </Menu.Item>
                }
                {checkRights("contact_us_enquries", "list") &&
                    <Menu.Item key="contact_us_enquries" icon={<SettingOutlined />} >
                    <Link to={"/contact-us/list"}>
                        <span className='menuName'>Contact Us Dashboard</span>
                    </Link>
                    </Menu.Item>
                }
            </Menu>
        </Sider >
    )
}

export default Sidebar