import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Modal,Form, Row, Col, Select, InputNumber, DatePicker, Spin, Drawer } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, DownloadOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import _ from "lodash"
import moment from 'moment'

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination } from "../../../component/Xcomponent"
import InstituteTestPricingMasterView from '../InstituteTestPricingMasterView/InstituteTestPricingMasterView'

/* actions */
import { getInstituteTestPricingMaster, getInstituteTestPricingMasterReset } from "../../../action/getInstituteTestPricingListAction"
import { deleteInstituteTestPricingMaster, deleteInstituteTestPricingMasterReset } from "../../../action/deleteInstituteTestPricingMasterAction"
import { saveInstituteTestPricingMaster, saveInstituteTestPricingMasterReset } from "../../../action/saveInstituteTestPricingMasterAction"
import { getInstituteTestPricingDetail, getInstituteTestPricingDetailReset } from '../../../action/getInstituteTestPricingDetailAction'
import { getInstituteList, getInstituteListReset } from '../../../action/getInstituteListAction'
import { getListTestMaster, getListTestMasterReset } from '../../../action/getListTestMasterActions'
import { dateFormat } from '../../../utils';
import { getInstituteTestPricingUploadMaster, getInstituteTestPricingUploadMasterReset } from "../../../action/getInstituteTestPricingUploadListAction"
var Excel = require("exceljs");

const InstituteTestPricingMasterList = props => {
    /* variables */
    
    const {
        getInstituteTestPricingMaster, getInstituteTestPricingMasterReset, getInstituteTestPricingMasterState,
        deleteInstituteTestPricingMaster, deleteInstituteTestPricingMasterReset, deleteInstituteTestPricingMasterState,saveInstituteTestPricingMaster, saveInstituteTestPricingMasterReset, saveInstituteTestPricingMasterState,
        getInstituteTestPricingDetail, getInstituteTestPricingDetailReset, getInstituteTestPricingDetailState,
        getInstituteList, getInstituteListReset, getInstituteListState,
        getListTestMaster, getListTestMasterReset, getListTestMasterState,getInstituteTestPricingUploadMasterState,
        getInstituteTestPricingUploadMaster,getInstituteTestPricingUploadMasterReset
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const institute_id = new URLSearchParams(search).get('id');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [open, setOpen] = useState(false);
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
const [instituteId, setInstituteId] = useState("");
const [intituteName, setInstituteName] = useState("");
    
    const [formData, setFormData] = useState({
        mode:"edit",
        institute_lilac_id: "",
        institute_id: "",
        test_id: "",
        lilac_test_id: "",
        lap: 0,
        mrp: 0,
        name_of_the_institute: "",
        test_name: "",
        transfer_rate: 0,
        standard_transfer_rate: 0,
        discount_amount: 0,
        discount_percentage: 0,
        billing_category_id: "",
        billing_category_name: "",
        billing_category_valid_till: "",
        price_updated_on: new Date(),
    })
    const [isModalVisible, setIsModalVisible] = useState(false);	
    const [exportedData, setExportedData] = useState([]);

    const [form] = Form.useForm()

    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    const handleSubmit = () => {
        saveInstituteTestPricingMaster(formData);
        setIsModalVisible(false);
        form.resetFields()

    }
    const onChange = (value, dateString) => {
        setFormData({ ...formData, ['billing_category_valid_till']: value })
    }

    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSelect = (name, v) => {
        if(name == "transfer_rate"){
            let transfer_rate = v;
            if(formData["mrp"] > 0 && formData["mrp"] > transfer_rate){
                let discount_amt = formData["mrp"] - transfer_rate;
                let percentage_discount = (discount_amt / formData["mrp"]) * 100;
                setFormData({
                    ...formData,
                    ["transfer_rate"]:transfer_rate,
                    ["discount_percentage"]: percentage_discount.toFixed(2),
                    ["discount_amount"]: discount_amt.toFixed(2),
                })
                form.setFieldsValue({
                    discount_percentage: percentage_discount.toFixed(2),
                    discount_amount: discount_amt.toFixed(2),
                })
            }
            

        }
        else{
        setFormData({
            ...formData, [name]: v
        })
        }
       
    }
    const handleChange = e => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
        
    }
    const handleSelectTest = (display_test_name, value) => {
        let lilac_test_id = _.find(getListTestMasterState.list, { _id: value }).lilac_test_id
        let test_mrp = _.find(getListTestMasterState.list, { _id: value }).mrp
        let standard_transfer_rate = _.find(getListTestMasterState.list, { _id: value }).transfer_rate
        setFormData({
            ...formData,
            [display_test_name]: value,
            ["lilac_test_id"]: lilac_test_id,
            ["mrp"]: test_mrp,
            ["standard_transfer_rate"]: standard_transfer_rate,
        })
        form.setFieldsValue({
            lilac_test_id: lilac_test_id,
            mrp: test_mrp,
            standard_transfer_rate: standard_transfer_rate,
        })
    }

    const handleExportFeedbacks = () => {
		// Extract field names from the first feedback object
		const fieldNames = Object.keys(exportedData[0]);

        console.log(fieldNames, "=============================");

		var wb = new Excel.Workbook();
		const workbook = wb.addWorksheet('Sheet1');

		workbook.getRow(1).values = fieldNames;

		exportedData.forEach((element, index) => {
			const rowIndex = index + 2;
			const rows = [];

            console.log(element['LAP']);
			rows[0] = element['ID'];
			rows[1] = element["LAP"];
			rows[2] = element["MRP"];
			rows[3] = element['Transfer Rate'];
			rows[4] = element["Standard Transfer Rate"];
			rows[5] = element['Discount Amount'];
			rows[6] = element['Discount Percentage'];
			rows[7] = element['Institute ID'];
			rows[8] = element['Institute Name'];
			rows[9] = element['Test ID'];
			rows[10] = element['Test Name'];
			rows[11] = element["Billing Category ID"];
			rows[12] = element["Billing Category Name"];
			workbook.getRow(rowIndex).values = rows;
		});
		wb.xlsx.writeBuffer().then((buffer) => {
			const fileData = new Blob([buffer], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			const fileUrl = URL.createObjectURL(fileData);
			const link = document.createElement('a');
			link.href = fileUrl;
			link.download = 'Institute Test Pricing.xlsx';
			link.click();
		});
	};

    useEffect(() => {
		if (getInstituteTestPricingUploadMasterState.apiState === 'success') {
			if (getInstituteTestPricingUploadMasterState.list.length > 0) {
				setExportedData(getInstituteTestPricingUploadMasterState.list);
			} else {
                setExportedData([]);
			}
		}
	}, [getInstituteTestPricingUploadMasterState]);
    
    const columns = [
        {
            title: 'TEST ID',
            dataIndex: 'test_id',
            key: 'test_id',
            ...getColumnSearchProps('test_id', 'Test ID')
        },
        {
            title: 'TEST NAME',
            dataIndex: 'test_name',
            key: 'test_name',
            width:"300px",
            render: test_name => (<span>{test_name}</span>),
            ...getColumnSearchProps('test_name', 'Test Name')
        },
        {
            title: 'LAP',
            dataIndex: 'lap',
            key: 'lap',
            ...getColumnSearchProps('lap', 'LAP')
        },
        {
            title: 'MRP',
            dataIndex: 'mrp',
            key: 'mrp',
            ...getColumnSearchProps('mrp', 'MRP')
        },
        {
            title: 'TRANSFER RATE',
            dataIndex: 'transfer_rate',
            key: 'transfer_rate',
            render: transfer_rate => (<span>{transfer_rate}</span>),
            ...getColumnSearchProps('transfer_rate', 'Transfer Rate')
        },
        {
            title: 'STR',
            dataIndex: 'standard_transfer_rate',
            key: 'standard_transfer_rate',
            render: standard_transfer_rate => (<span>{standard_transfer_rate}</span>),
            ...getColumnSearchProps('standard_transfer_rate', 'Standard Transfer Rate')
        },
        {
            title: 'DISCOUNT AMT',
            dataIndex: 'discount_amount',
            key: 'discount_amount',
            render: discount_amount => (<span>{discount_amount}</span>),
            ...getColumnSearchProps('discount_amount', 'Discount Amount')
        },
        {
            title: 'DISCOUNT %',
            dataIndex: 'discount_percentage',
            key: 'discount_percentage',
            render: discount_percentage => (<span>{discount_percentage}</span>),
            ...getColumnSearchProps('discount_percentage', 'Discount Percentage')
        },
        {
            title: 'PRICE UPDATED ON',
            dataIndex: 'price_updated_on',
            key: 'price_updated_on',
            render: price_updated_on => (<span>{price_updated_on?dateFormat(price_updated_on):"-"}</span>),
        },
        {
            title: 'ACTION',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                        <Button size="small" shape="circle" icon={<EditOutlined />} onClick={()=> { 
                            getInstituteTestPricingDetail({
                                id: id
                            });
                            setIsModalVisible(true);
                            setFormData({
                                ...formData,
                            id:id
                            })}
                        }/>
                    {/* <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteInstituteTestPricingMaster({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm> */}
                </Space>
            )
        },
    ];

    /* callbacks */
    useEffect(() => {
        return (() => {
            getInstituteTestPricingMasterReset()
        })
    }, [])

    useEffect(() => {
        getInstituteTestPricingMaster({
            page: pagination.page,
            records: pagination.records,
            id: institute_id,
            ...searchText
        })

        getInstituteTestPricingUploadMaster({
            id: institute_id,
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getInstituteTestPricingMasterState.apiState === "success") {
            let tableData = []
            getInstituteTestPricingMasterState.list.map((row, index) => {
                tableData.push(
                    {
                        id: row._id,
                        key: index,
                        test_id: row.test_id?.lilac_test_id || "-",
                        test_name: row.test_id?.test_name || "-",
                        institute_name: row.instituteId?.name || "-",
                        institute: row.instituteId?.lilac_id || "-",
                        lap: row.lap || "-",
                        mrp: row.mrp || "-",
                        transfer_rate:row.transfer_rate || "-",
                        standard_transfer_rate:row.standard_transfer_rate || "-",
                        discount_amount:row.discount_amount || "-",
                        discount_percentage:row.discount_percentage || "-",
                        price_updated_on:row.price_updated_on,
                    }
                )
            })
            setTableData(tableData)
            setInstituteId(getInstituteTestPricingMasterState.list[0]?.instituteId?.lilac_id)
            setInstituteName(getInstituteTestPricingMasterState.list[0]?.instituteId?.name)

        }
    }, [getInstituteTestPricingMasterState])

    useEffect(() => {
        setRedirect([true, `/institute-test-pricing-master/list?id=${institute_id}&page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteInstituteTestPricingMasterState.apiState === "success") {
            deleteInstituteTestPricingMasterReset()
            notification["success"]({
                message: deleteInstituteTestPricingMasterState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteInstituteTestPricingMasterState.apiState === "error") {
            deleteInstituteTestPricingMasterReset()
            notification["error"]({
                message: deleteInstituteTestPricingMasterState.message,
                placement: "topRight"
            })
        }
    }, [deleteInstituteTestPricingMasterState])
    
    /* callbacks */
    useEffect(() => {
        return (() => {
            saveInstituteTestPricingMasterReset()
            getInstituteTestPricingDetailReset()
            getInstituteListReset()
            getListTestMasterReset()
        })
    }, [])


    useEffect(() => {
        if (getInstituteTestPricingDetailState.apiState === "success") {
            form.setFieldsValue({
                instituteId: getInstituteTestPricingDetailState.data.instituteId?.name,
                institute_lilac_id: getInstituteTestPricingDetailState.data.instituteId?.lilac_id,
                test_id: getInstituteTestPricingDetailState.data.test_id?.test_name,
                lilac_test_id: getInstituteTestPricingDetailState.data.test_id?.lilac_test_id,
                lap: getInstituteTestPricingDetailState.data.lap,
                mrp: getInstituteTestPricingDetailState.data.mrp,
                transfer_rate: getInstituteTestPricingDetailState.data.transfer_rate,
                standard_transfer_rate: getInstituteTestPricingDetailState.data.standard_transfer_rate,
                discount_amount: getInstituteTestPricingDetailState.data.discount_amount,
                discount_percentage: getInstituteTestPricingDetailState.data.discount_percentage,
                billing_category_id: getInstituteTestPricingDetailState.data.billing_category_id,
                billing_category_name: getInstituteTestPricingDetailState.data.billing_category_name,
                billing_category_valid_till: getInstituteTestPricingDetailState.data.billing_category_valid_till ? moment(getInstituteTestPricingDetailState.data.billing_category_valid_till) : null,
            })
            setFormData({
                ...formData,
                ['instituteId']: getInstituteTestPricingDetailState.data.instituteId?._id,
                ['institute_lilac_id']: getInstituteTestPricingDetailState.data.instituteId?.lilac_id,
                ['test_id']: getInstituteTestPricingDetailState.data.test_id?._id,
                ['lilac_test_id']: getInstituteTestPricingDetailState.data.test_id?.lilac_test_id,
                ['lap']: getInstituteTestPricingDetailState.data.lap,
                ['mrp']: getInstituteTestPricingDetailState.data.mrp,
                ['transfer_rate']: getInstituteTestPricingDetailState.data.transfer_rate,
                ['standard_transfer_rate']: getInstituteTestPricingDetailState.data.standard_transfer_rate,
                ['discount_amount']: getInstituteTestPricingDetailState.data.discount_amount,
                ['discount_percentage']: getInstituteTestPricingDetailState.data.discount_percentage,
                ['billing_category_id']: getInstituteTestPricingDetailState.data.billing_category_id,
                ['billing_category_name']: getInstituteTestPricingDetailState.data.billing_category_name,
                ['billing_category_valid_till']: getInstituteTestPricingDetailState.data.billing_category_valid_till,
            })
        }
        // getInstituteTestPricingDetailReset()
        // setFormData({})
    }, [getInstituteTestPricingDetailState])

    useEffect(() => {
        if (saveInstituteTestPricingMasterState.apiState === "success") {
            saveInstituteTestPricingMasterReset()
            notification["success"]({
                message: saveInstituteTestPricingMasterState.message,
                placement: "topRight"
            })
        }
        if (saveInstituteTestPricingMasterState.apiState === "error") {
            saveInstituteTestPricingMasterReset()
            notification["error"]({
                message: saveInstituteTestPricingMasterState.message,
                placement: "topRight"
            })
        }
        getInstituteTestPricingMaster({
            page: pagination.page,
            records: pagination.records,
            id: institute_id,
        })
    }, [saveInstituteTestPricingMasterState])

     /* functions */
 
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Institute Test Pricing Master List"
                rightChildren={
                    <>
                        <Link to="/institute-test-pricing-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Update</Button></Link>
                        <Button type="primary" onClick={handleExportFeedbacks}><DownloadOutlined />Download</Button>
                    </>
                }
            />
            <PageContainer>
                {getInstituteTestPricingMasterState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getInstituteTestPricingMasterState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getInstituteTestPricingMasterState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            title={()=><div style={{display:"flex", justifyContent:"space-between"}}><h1><span style={{fontWeight:"normal"}}>Institue Name: </span>{intituteName}</h1><h1><span style={{fontWeight:"normal"}}>Institue ID: </span>{instituteId}</h1></div>}

                            

                            loading={getInstituteTestPricingMasterState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getInstituteTestPricingMasterState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <InstituteTestPricingMasterView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
            <Drawer title="Edit Institute Test Pricing Master" 
                visible={isModalVisible}
                width={800}
                onClose={()=>{
                    console.log("hello from close");
                    form.resetFields();
                    setIsModalVisible(false);
                }}
                onCancel={_ =>{
                    console.log("hello from cancel");
                    form.resetFields();
                    setIsModalVisible(false);
                }}
                footer={
                    getInstituteTestPricingDetailState.apiState=="loading" ? <Button type="primary" loading>Adding</Button> 
                    : <Button type="primary" onClick={()=>{
                        form.submit();
                    }}>SUBMIT</Button>
                }
            >
                <Form form={form} layout="vertical" onFinish={()=>{
                    handleSubmit();
                    setIsModalVisible(false)
                }}>
                    <Row gutter={20}>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Test Name"
                                name="test_id"
                                rules={[{ required: true, message: "Required" }]}
                            >
                                <Select
                                    showSearch
                                    name="test_id"
                                    placeholder="Select Test Name"
                                    disabled
                                    filterOption={false}
                                    onChange={(v) => handleSelectTest("test_id", v)}
                                    onSearch={_.debounce(value => {
                                        getListTestMaster({ searchTerm: value, dropDownList: "/get-dropdown-list" })
                                    }, 500)}
                                    notFoundContent={getListTestMasterState.apiState === "loading" && <Spin size="small" />}
                                >
                                    {getListTestMasterState.list.map((item, index) => {
                                        return (
                                            < Select.Option value={`${item._id}`
                                            } key={`${index}`}>{item.display_test_name}</Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Test ID"
                                name="lilac_test_id"
                                rules={[{ required: true, message: "Required" }]}
                            >
                                <Input disabled name="lilac_test_id" placeholder="Test Id"
                                    onChange={handleOnChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="LAP"
                                name="lap"
                                rules={[
                                    { required: true, message: "Required" },

                                ]}
                            >
                                <InputNumber name="lap"   placeholder="Enter LAP" style={{ width: '100%' }}
                                    onChange={(v) => handleSelect("lap", v)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="MRP"
                                name="mrp"
                                rules={[
                                    { required: true, message: "Required" },
                                    () => ({
                                        validator(_, value) {
                                            if (!value) {
                                                return Promise.reject();
                                            }
                                            
                                            if (value <= formData.lap) {
                                                return Promise.reject("lap will never exceed mrp.");
                                            }
                                            
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber name="mrp" disabled placeholder="Enter Transfer MRP" style={{ width: '100%' }}
                                    onChange={(v) => handleSelect("mrp", v)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Transfer Rate"
                                name="transfer_rate"
                                rules={[

                                ]}
                            >
                                <InputNumber name="transfer_rate" placeholder="Enter Transfer Rate" style={{ width: '100%' }}
                                    onChange={(v) => handleSelect("transfer_rate", v)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Standard Transfer Rate"
                                name="standard_transfer_rate"
                                rules={[

                                ]}
                            >
                                <InputNumber name="standard_transfer_rate" placeholder="Enter Standard Transfer Rate" style={{ width: '100%' }}
                                    onChange={(v) => handleSelect("standard_transfer_rate", v)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Discount Amount"
                                name="discount_amount"
                                rules={[

                                ]}
                            >
                                <InputNumber name="discount_amount" placeholder="Enter Discount Amount" style={{ width: '100%' }}
                                    onChange={(v) => handleSelect("discount_amount", v)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Discount Percentage"
                                name="discount_percentage" 
                                rules={[

                                ]}
                            >
                                <InputNumber name="discount_percentage" placeholder="Enter Discount Percentage" style={{ width: '100%' }}
                                    onChange={(v) => handleSelect("discount_percentage", v)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Billing Category Id"
                                name="billing_category_id"
                                rules={[

                                ]}
                            >
                                <InputNumber name="billing_category_id" placeholder="Enter Billing Category Id" style={{ width: '100%' }}
                                    onChange={(v) => handleSelect("billing_category_id", v)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Billing Category Name"
                                name="billing_category_name"
                                rules={[

                                ]}
                            >
                                <Input name="billing_category_name" placeholder="Enter Billing Category Name" style={{ width: '100%' }}
                                    onChange={handleChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Billing Category Valid Till"
                                name="billing_category_valid_till"
                                rules={[

                                ]}
                            >
                                <DatePicker name="billing_category_valid_till" onChange={onChange} style={{ width: '100%' }} />
                                {/* <Input name="billing_category_valid_till" placeholder="Enter Billing Category Valid Till" style={{ width: '100%' }}
                                    onChange={(v) => handleSelect("billing_category_valid_till", v)}
                                /> */}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Last Date of Price Update"
                                name="price_updated_on"
                            >

                                {/* <span>{update_date}</span> */}
                                {getInstituteTestPricingDetailState.data.price_updated_on ? dateFormat(getInstituteTestPricingDetailState.data.price_updated_on) : ""}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getInstituteTestPricingMasterState: state.getInstituteTestPricingMaster,
    deleteInstituteTestPricingMasterState: state.deleteInstituteTestPricingMaster,
    saveInstituteTestPricingMasterState: state.saveInstituteTestPricingMaster,
    getInstituteTestPricingDetailState: state.getInstituteTestPricingDetail,
    getInstituteListState: state.getInstituteList,
    getListTestMasterState: state.getListTestMaster,
    getInstituteTestPricingUploadMasterState: state.getInstituteTestPricingUploadMaster,

});

const mapDispatchToProps = (dispatch) => ({
    getInstituteTestPricingMaster: (params) => dispatch(getInstituteTestPricingMaster(params)),
    getInstituteTestPricingMasterReset: () => dispatch(getInstituteTestPricingMasterReset()),
    deleteInstituteTestPricingMaster: (params) => dispatch(deleteInstituteTestPricingMaster(params)),
    deleteInstituteTestPricingMasterReset: () => dispatch(deleteInstituteTestPricingMasterReset()),
    saveInstituteTestPricingMaster: (params) => dispatch(saveInstituteTestPricingMaster(params)),
    saveInstituteTestPricingMasterReset: () => dispatch(saveInstituteTestPricingMasterReset()),
    getInstituteTestPricingDetail: (params) => dispatch(getInstituteTestPricingDetail(params)),
    getInstituteTestPricingDetailReset: () => dispatch(getInstituteTestPricingDetailReset()),
    getInstituteList: (params) => dispatch(getInstituteList(params)),
    getInstituteListReset: () => dispatch(getInstituteListReset()),
    getListTestMaster: (params) => dispatch(getListTestMaster(params)),
    getListTestMasterReset: () => dispatch(getListTestMasterReset()),
    getInstituteTestPricingUploadMaster: (params) => dispatch(getInstituteTestPricingUploadMaster(params)),


});

export default connect(mapStateToProps, mapDispatchToProps)(InstituteTestPricingMasterList);