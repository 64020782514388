import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_COUPON,
    deleteCouponFail,
    deleteCouponSuccess
} from '../action/deleteCouponAction'

// api 
import deleteCouponApi from '../api/deleteCouponApi'

export function* deleteCouponSaga(action) {
    try {
        const response = yield call(() => deleteCouponApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteCouponSuccess(response, action))
        } else {
            yield put(deleteCouponFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteCouponFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_COUPON, deleteCouponSaga)
}