import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_QUICK_SUPPORT_QUESTION_ANSWER,
    deleteQuickSupportQuestionAnswerFail,
    deleteQuickSupportQuestionAnswerSuccess
} from '../action/deleteQuickSupportQuestionAnswerAction'

// api 
import deleteQuickSupportQuestionAnswerApi from '../api/deleteQuickSupportQuestionAnswerApi'

export function* deleteQuickSupportQuestionAnswerSaga(action) {
    try {
        const response = yield call(() => deleteQuickSupportQuestionAnswerApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteQuickSupportQuestionAnswerSuccess(response, action))
        } else {
            yield put(deleteQuickSupportQuestionAnswerFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteQuickSupportQuestionAnswerFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_QUICK_SUPPORT_QUESTION_ANSWER, deleteQuickSupportQuestionAnswerSaga)
}