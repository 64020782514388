import config from '../config'
import axios from 'axios'
import { getUserToken } from "../utils"

export const logoutApi = async(params) => {

    const response = await axios({
        method: 'post',
        url: `${config.api.base_url}user/logout`,

        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getUserToken()}`
        }
    })

    return response
}

export default logoutApi