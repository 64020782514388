import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PATIENT_LIST,
    getPatientListFail,
    getPatientListSuccess
} from '../action/getPatientListAction'

// api 
import getPatientListApi from '../api/getPatientListApi'

export function* getPatientListSaga(action) {
    try {
        const response = yield call(() => getPatientListApi(action.params))
        if (response.data.status === 1) {
            yield put(getPatientListSuccess(response, action))
        } else {
            yield put(getPatientListFail(response, action))
        }
    }
    catch (e) {
        yield put(getPatientListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PATIENT_LIST, getPatientListSaga)
}