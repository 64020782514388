import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    VIEW_DOCTOR_SIGNUP_REQUEST,
    viewDoctorSignupRequestFail,
    viewDoctorSignupRequestSuccess
} from '../action/viewDoctorSignupRequestAction'

// api 
import viewDoctorSignupRequestApi from '../api/viewDoctorSignupRequestApi'

export function* viewDoctorSignupRequestSaga(action) {
    try {
        const response = yield call(() => viewDoctorSignupRequestApi(action.params))
        if (response) {
            yield put(viewDoctorSignupRequestSuccess(response, action))
        }
    }
    catch (e) {
        yield put(viewDoctorSignupRequestFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(VIEW_DOCTOR_SIGNUP_REQUEST, viewDoctorSignupRequestSaga)
}