import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import reducer from './reducer'



// sagas
import saveTestMasterSaga from './saga/saveTestMasterSaga'
import getListTestMasterSaga from './saga/getListTestMasterSaga'
import deleteTestMasterSaga from './saga/deleteTestMasterSaga'
import viewTestMasterSaga from './saga/viewTestMasterSaga'
import saveInstituteSaga from './saga/saveInstituteSaga'
import getInstituteListSaga from './saga/getInstituteListSaga'
import getInstituteDetailSaga from './saga/getInstituteDetailSaga'
import deleteInstituteSaga from './saga/deleteInstituteSaga'
import getDoctorListSaga from './saga/getDoctorListSaga'
import deleteDoctorSaga from './saga/deleteDoctorSaga'
import saveDoctorSaga from './saga/saveDoctorSaga'
import getDoctorDetailSaga from './saga/getDoctorDetailSaga'
import saveSampleSaga from './saga/saveSampleSaga'
import getSampleListSaga from './saga/getSampleListSaga'
import getSampleDetailSaga from './saga/getSampleDetailSaga'
import deleteSampleSaga from './saga/deleteSampleSaga'
import saveInstituteTestPricingMasterSaga from './saga/saveInstituteTestPricingMaster'
import getInstituteTestPricingMasterSaga from './saga/getInstituteTestPricingMasterSaga'
import deleteInstituteTestPricingMasterSaga from './saga/deleteInstituteTestPricingMasterSaga'
import getInstituteTestPricingDetailSaga from './saga/getInstituteTestPricingDetailSaga'
import saveArticleSaga from './saga/saveArticleSaga'
import getArticleListSaga from './saga/getArticleListSaga'
import deleteArticleSaga from './saga/deleteArticleSaga'
import getArticleDetailSaga from './saga/getArticleDetailSaga'
import savePregnancyHighlightsSaga from './saga/savePregnancyHighlightsSaga'
import getPregnancyHighlightsListSaga from './saga/getPregnancyHighlightsListSaga'
import deletePregnancyHighlightsSaga from './saga/deletePregnancyHighlightsSaga'
import getPregnancyHighlightsDetailSaga from './saga/getPregnancyHighlightsDetailSaga'
import loginSaga from './saga/loginSaga'
import logoutSaga from './saga/logoutSaga'
import getCommonMasterListSaga from './saga/getCommonMasterListSaga'
import saveCommonMasterSaga from './saga/saveCommonMasterSaga'
import deleteCommonMasterSaga from './saga/deleteCommonMasterSaga'
import getPageContentListSaga from './saga/getPageContentListSaga'
import savePageContentSaga from './saga/savePageContentSaga'
import getPageContentDetailSaga from './saga/getPageContentDetailSaga'
import deletePageContentSaga from './saga/deletePageContentSaga'
import getUserTermAndConditionListSaga from './saga/getUserTermAndConditionListSaga'
import getUserTermAndConditionDetailSaga from './saga/getUserTermAndConditionDetailSaga'
import saveSystemUserSaga from './saga/saveSystemUserSaga'
import systemUserListSaga from './saga/systemUserListSaga'
import systemUserDetailSaga from './saga/systemUserDetailSaga'
import saveUserRightsSaga from './saga/saveUserRightsSaga'
import saveBulkUploadSaga from './saga/saveBulkUploadSaga'
import getEmployeeListSaga from './saga/getEmployeeListSaga'
import getEmployeeDropdownSaga from './saga/getEmployeeDropdownSaga'

import deleteEmployeeSaga from './saga/deleteEmployeeSaga'
import saveEmployeeSaga from './saga/saveEmployeeSaga'
import getEmployeeDetailSaga from './saga/getEmployeeDetailSaga'
import getCouponListSaga from './saga/getCouponListSaga'
import deleteCouponSaga from './saga/deleteCouponSaga'
import saveCouponSaga from './saga/saveCouponSaga'
import getCouponDetailSaga from './saga/getCouponDetailSaga'
import getTestmonialListSaga from './saga/getTestmonialListSaga'
import changeTestimonialStatusSaga from './saga/changeTestimonialStatusSaga'
import getPincodeDetailSaga from './saga/getPincodeDetailSaga'
import getPincodeListSaga from './saga/getPincodeListSaga'
import getPatientListSaga from './saga/getPatientListSaga'
import getPatientDetailSaga from './saga/getPatientDetailSaga'
import savePatientSaga from './saga/savePatientSaga'
import getConsulationDashboardListSaga from './saga/getConsulationDashboardListSaga'
import getConsulationDashboardDetailSaga from './saga/getConsulationDashboardDetailSaga'
import getPaymentHistorySaga from './saga/getPaymentHistorySaga'
import getPaymentHistoryCouponSaga from './saga/getPaymentHistoryCouponSaga'
import getChatLogSaga from './saga/getChatLogSaga'
import saveContainerSaga from './saga/saveContainerSaga'
import getContainerListSaga from './saga/getContainerListSaga'
import getContainerDetailSaga from './saga/getContainerDetailSaga'
import deleteContainerSaga from './saga/deleteContainerSaga'
import saveSampleContainerTestMapSaga from './saga/saveSampleContainerTestMapSaga'
import getSampleContainerTestMapListSaga from './saga/getSampleContainerTestMapListSaga'
import getSampleContainerTestMapDetailSaga from './saga/getSampleContainerTestMapDetailSaga'
import deleteSampleContainerTestMapSaga from './saga/deleteSampleContainerTestMapSaga'
import getQuickSupportCategoryListSaga from './saga/getQuickSupportCategoryListSaga'
import saveQuickSupportCategorySaga from './saga/saveQuickSupportCategorySaga'
import saveAdminTypeSaga from './saga/saveAdminTypeSaga'
import getAdminTypeDetailSaga from './saga/getAdminTypeDetailSaga'
import getAdminTypeListSaga from './saga/getAdminTypeListSaga'
import deleteAdminTypeSaga from './saga/deleteAdminTypeSaga'
import saveRoleSaga from './saga/saveRoleSaga'
import getRoleListSaga from './saga/getRoleListSaga'
import getRoleDetailSaga from './saga/getRoleDetailSaga'
import deleteRoleSaga from './saga/deleteRoleSaga'
import deleteModuleSaga from './saga/deleteModuleSaga'
import saveModuleSaga from './saga/saveModuleSaga'
import getModuleListSaga from './saga/getModuleListSaga'
import getModuleDetailSaga from './saga/getModuleDetailSaga'
import saveRoleModuleMappingSaga from './saga/saveRoleModuleMappingSaga'
import getRoleModuleMappingListSaga from './saga/getRoleModuleMappingListSaga'
import getRoleModuleMappingDetailSaga from './saga/getRoleModuleMappingDetailSaga'
import deleteRoleModuleMappingSaga from './saga/deleteRoleModuleMappingSaga'
import getRoleModuleMappingDetailByRoleSaga from './saga/getRoleModuleMappingDetailByRoleSaga'
import getQuickSupportCategoryDetailSaga from './saga/getQuickSupportCategoryDetailSaga'
import getQuickSupportQuestionAnswerListSaga from './saga/getQuickSupportQuestionAnswerListSaga'
import saveQuickSupportQuestionAnswerSaga from './saga/saveQuickSupportQuestionAnswerSaga'
import getQuickSupportQuestionAnswerDetailSaga from './saga/getQuickSupportQuestionAnswerDetailSaga'
import deleteQuickSupportQuestionAnswerSaga from './saga/deleteQuickSupportQuestionAnswerSaga'
import getModuleListByRoleSaga from './saga/getModuleListByRoleSaga'
import getTestBookListSaga from './saga/getTestBookListSaga'
import deleteQuickSupportCategorySaga from './saga/deleteQuickSupportCategorySaga'
import activateDoctorSaga from './saga/activateDoctorSaga'
import changeEmployeeStatusSaga from './saga/changeEmployeeStatusSaga'
import changeInstituteStatusSaga from './saga/changeInstituteStatusSaga'


import getMouListSaga from './saga/getMouListSaga'
import saveMouSaga from './saga/saveMouSaga'
import deleteMouSaga from './saga/deleteMouSaga'
import getContactUsEnquriesListSaga from './saga/getContactUsEnquriesListSaga'
import getContactUsEnquriesDetailSaga from './saga/getContactUsEnquriesDetailSaga'
import otpSaga from './saga/otpSaga'
import resetPasswordSaga from './saga/resetPasswordSaga'
import getLedgerAccountListSaga from './saga/getLedgerAccountListSaga'
import doctorSignupRequestListSaga from './saga/doctorSignupRequestListSaga'
import viewDoctorSignupRequestSaga from './saga/viewDoctorSignupRequestSaga'
import getDtrfsListSaga from './saga/getDtrfsListSaga'
import getSubGroupListSaga from './saga/getSubGroupListSaga'
import getEmployeeMasterSaga from './saga/getEmployeeMasterSaga'
import getPaymentHistoryListSaga from './saga/getPaymentHistoryListSaga'
import getInstituteTestPricingMasterInstituteSaga from './saga/getInstituteTestPricingMasterInstituteSaga'
import getNewDoctorListSaga from './saga/getNewDoctorListSaga';
import getNewInstituteListSaga from './saga/getNewInstituteListSaga';
import getCentreLocationsSaga from './saga/getCentreLocationsSaga';
import getInstituteTestPricingUploadMasterSaga from './saga/getInstituteTestPricingUploadMasterSaga';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
    // mount it on the Store
const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(sagaMiddleware)
    )
)

// then run the saga
sagaMiddleware.run(saveTestMasterSaga)
sagaMiddleware.run(getListTestMasterSaga)
sagaMiddleware.run(deleteTestMasterSaga)
sagaMiddleware.run(viewTestMasterSaga)
sagaMiddleware.run(saveInstituteSaga)
sagaMiddleware.run(getInstituteListSaga)
sagaMiddleware.run(getInstituteDetailSaga)
sagaMiddleware.run(deleteInstituteSaga)
sagaMiddleware.run(getDoctorListSaga)
sagaMiddleware.run(deleteDoctorSaga)
sagaMiddleware.run(getDoctorDetailSaga)
sagaMiddleware.run(saveSampleSaga)
sagaMiddleware.run(getSampleListSaga)
sagaMiddleware.run(getSampleDetailSaga)
sagaMiddleware.run(deleteSampleSaga)
sagaMiddleware.run(saveDoctorSaga)
sagaMiddleware.run(saveInstituteTestPricingMasterSaga)
sagaMiddleware.run(getInstituteTestPricingMasterSaga)
sagaMiddleware.run(deleteInstituteTestPricingMasterSaga)
sagaMiddleware.run(getInstituteTestPricingDetailSaga)
sagaMiddleware.run(saveArticleSaga)
sagaMiddleware.run(getArticleListSaga)
sagaMiddleware.run(deleteArticleSaga)
sagaMiddleware.run(getArticleDetailSaga)
sagaMiddleware.run(savePregnancyHighlightsSaga)
sagaMiddleware.run(getPregnancyHighlightsListSaga)
sagaMiddleware.run(deletePregnancyHighlightsSaga)
sagaMiddleware.run(getPregnancyHighlightsDetailSaga)
sagaMiddleware.run(loginSaga)
sagaMiddleware.run(logoutSaga)
sagaMiddleware.run(getCommonMasterListSaga)
sagaMiddleware.run(saveCommonMasterSaga)
sagaMiddleware.run(deleteCommonMasterSaga)
sagaMiddleware.run(getPageContentListSaga)
sagaMiddleware.run(savePageContentSaga)
sagaMiddleware.run(getPageContentDetailSaga)
sagaMiddleware.run(deletePageContentSaga)
sagaMiddleware.run(getUserTermAndConditionListSaga)
sagaMiddleware.run(getUserTermAndConditionDetailSaga)
sagaMiddleware.run(saveSystemUserSaga)
sagaMiddleware.run(systemUserListSaga)
sagaMiddleware.run(systemUserDetailSaga)
sagaMiddleware.run(saveUserRightsSaga)
sagaMiddleware.run(saveBulkUploadSaga)
sagaMiddleware.run(getEmployeeListSaga)
sagaMiddleware.run(deleteEmployeeSaga)
sagaMiddleware.run(saveEmployeeSaga)
sagaMiddleware.run(getEmployeeDetailSaga)
sagaMiddleware.run(getCouponListSaga)
sagaMiddleware.run(deleteCouponSaga)
sagaMiddleware.run(saveCouponSaga)
sagaMiddleware.run(getCouponDetailSaga)
sagaMiddleware.run(getTestmonialListSaga)
sagaMiddleware.run(changeTestimonialStatusSaga)
sagaMiddleware.run(getPincodeDetailSaga)
sagaMiddleware.run(getPincodeListSaga)
sagaMiddleware.run(getPatientListSaga)
sagaMiddleware.run(getPatientDetailSaga)
sagaMiddleware.run(savePatientSaga)
sagaMiddleware.run(getConsulationDashboardListSaga)
sagaMiddleware.run(getConsulationDashboardDetailSaga)
sagaMiddleware.run(getPaymentHistorySaga)
sagaMiddleware.run(getPaymentHistoryCouponSaga)
sagaMiddleware.run(getChatLogSaga)
sagaMiddleware.run(saveContainerSaga)
sagaMiddleware.run(getContainerListSaga)
sagaMiddleware.run(getContainerDetailSaga)
sagaMiddleware.run(deleteContainerSaga)
sagaMiddleware.run(saveSampleContainerTestMapSaga)
sagaMiddleware.run(getSampleContainerTestMapListSaga)
sagaMiddleware.run(getSampleContainerTestMapDetailSaga)
sagaMiddleware.run(deleteSampleContainerTestMapSaga)
sagaMiddleware.run(getQuickSupportCategoryListSaga)
sagaMiddleware.run(saveQuickSupportCategorySaga)
sagaMiddleware.run(saveAdminTypeSaga)
sagaMiddleware.run(getAdminTypeDetailSaga)
sagaMiddleware.run(getAdminTypeListSaga)
sagaMiddleware.run(deleteAdminTypeSaga)
sagaMiddleware.run(saveRoleSaga)
sagaMiddleware.run(getRoleListSaga)
sagaMiddleware.run(getRoleDetailSaga)
sagaMiddleware.run(deleteRoleSaga)
sagaMiddleware.run(deleteModuleSaga)
sagaMiddleware.run(saveModuleSaga)
sagaMiddleware.run(getModuleListSaga)
sagaMiddleware.run(getModuleDetailSaga)
sagaMiddleware.run(saveRoleModuleMappingSaga)
sagaMiddleware.run(getRoleModuleMappingListSaga)
sagaMiddleware.run(getRoleModuleMappingDetailSaga)
sagaMiddleware.run(deleteRoleModuleMappingSaga)
sagaMiddleware.run(getRoleModuleMappingDetailByRoleSaga)
sagaMiddleware.run(getQuickSupportCategoryDetailSaga)
sagaMiddleware.run(getQuickSupportQuestionAnswerListSaga)
sagaMiddleware.run(saveQuickSupportQuestionAnswerSaga)
sagaMiddleware.run(getQuickSupportQuestionAnswerDetailSaga)
sagaMiddleware.run(deleteQuickSupportQuestionAnswerSaga)
sagaMiddleware.run(getModuleListByRoleSaga)
sagaMiddleware.run(getTestBookListSaga)
sagaMiddleware.run(deleteQuickSupportCategorySaga)
sagaMiddleware.run(activateDoctorSaga)
sagaMiddleware.run(getEmployeeDropdownSaga)
sagaMiddleware.run(changeEmployeeStatusSaga)
sagaMiddleware.run(changeInstituteStatusSaga)
sagaMiddleware.run(getMouListSaga)
sagaMiddleware.run(saveMouSaga)
sagaMiddleware.run(deleteMouSaga)
sagaMiddleware.run(getContactUsEnquriesListSaga)
sagaMiddleware.run(getContactUsEnquriesDetailSaga)
sagaMiddleware.run(otpSaga)
sagaMiddleware.run(resetPasswordSaga)
sagaMiddleware.run(getLedgerAccountListSaga)
sagaMiddleware.run(doctorSignupRequestListSaga)
sagaMiddleware.run(viewDoctorSignupRequestSaga)
sagaMiddleware.run(getDtrfsListSaga)
sagaMiddleware.run(getSubGroupListSaga)
sagaMiddleware.run(getEmployeeMasterSaga)
sagaMiddleware.run(getPaymentHistoryListSaga)
sagaMiddleware.run(getInstituteTestPricingMasterInstituteSaga)
sagaMiddleware.run(getNewDoctorListSaga)
sagaMiddleware.run(getNewInstituteListSaga)
sagaMiddleware.run(getCentreLocationsSaga)
sagaMiddleware.run(getInstituteTestPricingUploadMasterSaga)

    // render the application
export default store

