import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_ROLE,
    saveRoleFail,
    saveRoleSuccess
} from '../action/saveRoleAction'

// api 
import saveRoleApi from '../api/saveRoleApi'

export function* saveRoleSaga(action) {
    try {
        const response = yield call(() => saveRoleApi(action.params))
        if (response.data.status === 1) {
            yield put(saveRoleSuccess(response, action))
        } else {
            yield put(saveRoleFail(response, action))
        }
    }
    catch (e) {
        yield put(saveRoleFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_ROLE, saveRoleSaga)
}