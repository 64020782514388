import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST,
    getQuickSupportQuestionAnswerListFail,
    getQuickSupportQuestionAnswerListSuccess
} from '../action/getQuickSupportQuestionAnswerListAction'

// api 
import getQuickSupportQuestionAnswerListApi from '../api/getQuickSupportQuestionAnswerListApi'

export function* getQuickSupportQuestionAnswerListSaga(action) {
    try {
        const response = yield call(() => getQuickSupportQuestionAnswerListApi(action.params))
        if (response.data.status === 1) {
            yield put(getQuickSupportQuestionAnswerListSuccess(response, action))
        } else {
            yield put(getQuickSupportQuestionAnswerListFail(response, action))
        }
    }
    catch (e) {
        yield put(getQuickSupportQuestionAnswerListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_QUICK_SUPPORT_QUESTION_ANSWER_LIST, getQuickSupportQuestionAnswerListSaga)
}