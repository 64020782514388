import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_BULK_UPLOAD,
    saveBulkUploadFail,
    saveBulkUploadSuccess
} from '../action/saveBulkUploadAction'

// api 
import saveBulkUploadApi from '../api/saveBulkUploadApi'

export function* saveBulkUploadSaga(action) {
    try {
        const response = yield call(() => saveBulkUploadApi(action.params, action.apiUrl.apiUrl))
        if (response.data.status === 1) {
            yield put(saveBulkUploadSuccess(response, action))
        } else {
            yield put(saveBulkUploadFail(response, action))
        }
    }
    catch (e) {
        yield put(saveBulkUploadFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_BULK_UPLOAD, saveBulkUploadSaga)
}