import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import moment from 'moment'
import _ from 'lodash'

/* CUSTOM COMPONENT */
import Header from "../../component/Header/Header"
import { PageContainer, } from "../../component/Xcomponent"
import { PatientCard, PatientName, Status, MessageRigth, MessageLeft, RightMessageBody, LeftMessageBody } from './ChatHistoryStyle'

/* Actions */
import { getChatLog, getChatLogReset } from '../../action/getChatLogAction';

const ChatHistory = props => {
    // Variable
    const { getChatLog, getChatLogState } = props
    const [sessionList, setSessionList] = useState([])
    const [chat, setChat] = useState([])
    const [activePatient, setActivePatient] = useState('')
    const [activeSession, setActiveSession] = useState('')

    // callbacks
    useEffect(() => {
        getChatLog()
    }, [])




    return (
        <>
            <Header
                title="Chat History"
            />
            <PageContainer>
                <div style={{ display: 'flex', height: 700, backgroundColor: "#fff" }} >
                    <div style={{ flex: 0.25, height: '100%', overflowY: 'scroll' }} >
                        <div style={{ padding: '10px 8px', backgroundColor: "#302f2f", borderRadius: 4, color: "#fff", marginBottom: 8 }} >Select a patient to see sessions</div>
                        {getChatLogState.apiState === 'success' &&
                            <>
                                {getChatLogState.list.map(row => (
                                    <PatientCard onClick={_ => {
                                        setSessionList(row.sessions)
                                        setActivePatient(row._id.patient_id)
                                        setChat([])
                                    }}
                                        active={activePatient === row._id.patient_id}
                                    >
                                        <PatientName>{row._id.name.firstName + " " + row._id.name.lastName}</PatientName>
                                    </PatientCard>
                                ))}
                            </>
                        }
                    </div>
                    {sessionList.length > 0 &&
                        <div style={{ flex: 0.25, height: '100%', overflowY: 'scroll' }} >
                            <div style={{ padding: '10px 8px', backgroundColor: "#302f2f", borderRadius: 4, color: "#fff", marginBottom: 8 }} >Select a session to see chat history</div>
                            {sessionList.map(session => (
                                <PatientCard onClick={_ => {
                                    setChat(session.history)
                                    setActiveSession(session.session_id)
                                }}
                                    active={activeSession === session.session_id}
                                >
                                    <PatientName>{moment(session.updatedAt).format('DD MMM YYYY hh:MM A')}</PatientName>
                                    <Status>{session.status}</Status>
                                </PatientCard>
                            ))}
                            {sessionList.length === 0 && <div style={{ padding: '12px 8px' }} >Select a patient to see sessions</div>}
                        </div>
                    }
                    {chat.length > 0 &&
                        <div style={{ flex: 0.5, height: '100%', overflowY: 'scroll', padding: 8 }} >
                            {chat.length === 0 && <div style={{ padding: '12px 8px' }} >Select a session to see chat history</div>}
                            {chat.map(row => (
                                <>
                                    {row.welcome_message &&
                                        <MessageRigth>
                                            <RightMessageBody>{row.welcome_message}</RightMessageBody>
                                        </MessageRigth>}
                                    {row.question &&
                                        <MessageRigth>
                                            <RightMessageBody>{row.question}</RightMessageBody>
                                        </MessageRigth>}
                                    {row.response_for_new_question_to_be_sent ?
                                        <MessageLeft>
                                            <LeftMessageBody>{row.response_for_new_question_to_be_sent}</LeftMessageBody>
                                        </MessageLeft>
                                        :
                                        (row.answer &&
                                            <MessageLeft>
                                                <LeftMessageBody>{_.find(row.options, { option_code: row.answer }).option_value}</LeftMessageBody>
                                            </MessageLeft>
                                        )
                                    }
                                    {row.response_from_sehej &&
                                        <MessageRigth>
                                            <RightMessageBody>{row.response_from_sehej}</RightMessageBody>
                                        </MessageRigth>}
                                </>
                            ))}
                        </div>
                    }
                </div>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getChatLogState: state.getChatLog,
});

const mapDispatchToProps = (dispatch) => ({
    getChatLog: (params) => dispatch(getChatLog(params)),
    getChatLogReset: (params) => dispatch(getChatLogReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatHistory);
