import React from "react"
import { Button } from "antd"

/* custom component */
import Header from "../../component/Header/Header"
import { PageContainer } from "../../component/Xcomponent"
import { getUser } from '../../utils'

const Home = props => {
    let fname = getUser() && getUser().fname || null
    let lname = getUser() && getUser().lname || null
    let role_name = getUser() && getUser().role?.name || null
    let admin_type_name = getUser() && getUser().role?.admin_type_id?.name || null

    return (
        <>
            <Header />
            <PageContainer>
                <h1>Welcome, {fname} {lname}</h1>
                <h4>Admin Type :- {admin_type_name}</h4>
                <h4>Role :- {role_name}</h4>
            </PageContainer>
        </>
    )
}

export default Home