import React, { useState, useEffect, useRef } from "react"
import { Form, Input, Row, Col, Select, Button, notification, Radio, DatePicker } from "antd"
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { Editor } from '@tinymce/tinymce-react'
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"

/* actions */
import { saveArticle, saveArticleReset } from "../../../action/saveArticleAction"
import { getArticleDetail, getArticleDetailReset } from "../../../action/getArticleDetailAction"
import { getCommonMasterList, getCommonMasterListReset } from "../../../action/getCommonMasterListAction";

/* otehrs */
import { articleTypes } from "../../../strings.json"
import { validateImageFile, getS3SingedUrl, s3_cdn, validateAudioFile, validateVideoFile, tinyEditor } from '../../../utils'


const { TextArea } = Input

export const ContentRepoAdd = props => {

    /* variables */
    const editorUseRef = useRef(null);

    const {
        article_id,
        pageUrl,
        pageType,
        saveArticle, saveArticleReset, saveArticleState,
        getArticleDetail, getArticleDetailReset, getArticleDetailState,
        getCommonMasterList, getCommonMasterListReset, getCommonMasterListState,
    } = props
    const mode = article_id ? "edit" : "add"
    const [formData, setFormData] = useState({
        title: "",
        short_description: "",
        type: "",
        slug: "",
        image_path: "",
        image_path_changed: "",
        published_at: "",
        icon_image_path: "",
        icon_image_path_changed: "",
        audio_path_changed: "",
        audio_path: "",
        audio_link: "",
        video_type: "YouTube",
        video_link: "",
        video_path: "",
        video_path_changed: "",
        support_tribe: [],
        tags: [],
        week: [],
        category: [],
        gender: "",
        apiState: "",
        content_repo_type: pageType
    })
    const [imageFile, setImageFile] = useState({
        fileName: null,
        isChanged: false,
        edit: true,
        width: 0,
        height: 0
    })
    const [iconImageFile, setIconImageFile] = useState({
        fileName: null,
        isChanged: false,
        edit: true,
        width: 0,
        height: 0
    })

    const [audioFile, setAudioFile] = useState({
        fileName: null,
        isChanged: false,
        edit: true
    })

    const [videoFile, setVideoFile] = useState({
        fileName: null,
        isChanged: false,
        edit: true
    })

    if (mode === "edit") {
        formData.id = article_id
        formData.mode = mode
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [form] = Form.useForm()
    const [weekDisabled, setWeekDisabled] = useState(true)
    const [removeData, setRemoveData] = useState({
        tribe: false,
        category: false,
        week: false,
        gender: false
    })
    /* callbacks */
    useEffect(() => {
        getCommonMasterList({ slug: 'tribe-tags,tags,category' })
        if (pageType === 'Pregnancy') {
            setRemoveData({
                tribe: true,
                category: true,
                week: false,
                gender: true
            })
        }
        else if (pageType === 'Tribe') {
            setRemoveData({
                tribe: false,
                category: true,
                week: true,
                gender: false
            })
        }
        else if (pageType === 'General') {
            setRemoveData({
                tribe: true,
                category: false,
                week: true,
                gender: false
            })
        }
        else if (pageType === 'Doctor') {
            setRemoveData({
                tribe: true,
                category: true,
                week: true,
                gender: false
            })
        }
        return (() => {
            saveArticleReset()
            getArticleDetailReset()
            getCommonMasterListReset()
        })
    }, [])

    useEffect(() => {
        if (mode === "edit") {
            getArticleDetail({
                id: article_id
            })
        } else {
            form.setFieldsValue({
                published_at: moment(moment(new Date()), "DD/MM/YYYY")
            })
            setFormData({ ...formData, ['published_at']: moment(new Date()) })
        }
    }, [])

    useEffect(() => {
        if (getArticleDetailState.apiState === "success" && getCommonMasterListState.apiState === "success") {

            if (mode === "edit") {
                let exist = true
                if (_.some(getArticleDetailState.data.category, { value: "Pregnancy & Reproductive Wellness" })) {
                    exist = false
                }
                setWeekDisabled(exist)
                let weekData = []
                if (!exist) {
                    weekData = getArticleDetailState.data.week
                }
                form.setFieldsValue({
                    title: getArticleDetailState.data.title,
                    short_description: getArticleDetailState.data.short_description,
                    description: decodeURIComponent(getArticleDetailState.data.description),
                    type: getArticleDetailState.data.type,
                    slug: getArticleDetailState.data.slug,
                    published_at: getArticleDetailState.data.published_at ? moment(moment(getArticleDetailState.data.published_at), "DD/MM/YYYY") : "",
                    support_tribe: getArticleDetailState.data.support_tribe.map(obj => obj._id),
                    tags: getArticleDetailState.data.tags.map(obj => obj._id),
                    week: weekData,
                    category: getArticleDetailState.data.category.map(obj => obj._id),
                    video_type: getArticleDetailState.data.video_type,
                    video_link: getArticleDetailState.data.video_link,
                    gender: getArticleDetailState.data.gender,
                })

                setFormData({
                    ...formData,
                    ['title']: getArticleDetailState.data.title,
                    ['short_description']: getArticleDetailState.data.short_description,
                    ['description']: getArticleDetailState.data.description,
                    ['type']: getArticleDetailState.data.type,
                    ['slug']: getArticleDetailState.data.slug,
                    ['published_at']: getArticleDetailState.data.published_at,
                    ['image_path']: getArticleDetailState.data.image_path,
                    ['audio_path']: getArticleDetailState.data.audio_path,
                    ['audio_link']: getArticleDetailState.data.audio_path,
                    ['icon_image_path']: getArticleDetailState.data.icon_image_path,
                    ['support_tribe']: getArticleDetailState.data.support_tribe.map(obj => obj._id),
                    ['tags']: getArticleDetailState.data.tags.map(obj => obj._id),
                    ['week']: weekData,
                    ['video_type']: getArticleDetailState.data.video_type,
                    ['video_link']: getArticleDetailState.data.video_link,
                    ['video_path']: getArticleDetailState.data.video_path,
                    ['video_path_changed']: false,
                    ['category']: getArticleDetailState.data.category.map(obj => obj._id),
                    ['image_path_changed']: false,
                    ['icon_image_path_changed']: false,
                    ['audio_path_changed']: false,
                    ['gender']: getArticleDetailState.data.gender,
                    ['apiState']: "success"
                })
                editorUseRef.current = decodeURIComponent(getArticleDetailState.data.description);
            }

        }
    }, [getArticleDetailState, getCommonMasterListState])

    useEffect(() => {
        if (pageType === 'Pregnancy' && getCommonMasterListState.apiState === "success" && mode !== "edit") {
            let categories = _.find(getCommonMasterListState.list, { slug: "category" })
            let category = _.find(categories.common_master_detail, { value: "Pregnancy & Reproductive Wellness" })
            if (category) {
                setFormData({
                    ...formData,
                    ['category']: [category._id]
                })
                setWeekDisabled(false)
            }
        }
    }, [getCommonMasterListState, pageType])

    useEffect(() => {
        if (saveArticleState.apiState === "success") {
            saveArticleReset()
            setRedirect([true, `/${pageUrl}/list`])
            notification["success"]({
                message: saveArticleState.message,
                placement: "topRight"
            })
        }
        if (saveArticleState.apiState === "error") {
            saveArticleReset()
            notification["error"]({
                message: saveArticleState.message,
                placement: "topRight"
            })
        }
    }, [saveArticleState])

    /* functions */
    const handleOnChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSelect = (name, v) => {
        if (name === "category") {
            let weekDisabledValue = disableWeek(v)
            if (weekDisabledValue) {
                setFormData({ ...formData, ['week']: [], [name]: v })
                form.setFieldsValue({
                    week: []
                })
            }
            else {
                setFormData({
                    ...formData,
                    [name]: v
                })
            }
            setWeekDisabled(weekDisabledValue)
        } else {
            setFormData({
                ...formData,
                [name]: v
            })
        }
    }

    const handleRadioChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
            ['video_link']: "",
            ['video_path']: ""
        })
    }

    const disableWeek = (v) => {
        let categories = _.find(getCommonMasterListState.list, { slug: "category" })
        let exist = true
        v.map((id) => {
            if (_.some(categories.common_master_detail, { _id: id })) {
                if (_.find(categories.common_master_detail, { _id: id }).value === "Pregnancy & Reproductive Wellness") {
                    exist = false
                }
            }
        })
        return exist
    }

    const handleDateChange = (name, date, dateString) => {
        setFormData({ ...formData, [name]: moment(date) })
    }

    useEffect(()=>{
        console.log("number=>", imageFile);
    },[imageFile]);

    const handleSubmit = async () => {
        if (imageFile.isChanged) {
            let imagefilename = uuidv4()
            if(imageFile.width == 1500 && imageFile.height == 850)
            {
                let imageValidationRes = validateImageFile(imageFile.fileName)
                if (imageValidationRes.status) {
                    if(imageFile.fileName.size <= 1024 * 1024)
                    {
                        let s3UrlResImage = await getS3SingedUrl(imagefilename, imageValidationRes.ext, imageFile.fileName, 'article')
                        await axios.put(s3UrlResImage.url, imageFile.fileName)
                        formData.image_path = `${imagefilename}.${imageValidationRes.ext}`
                        formData.image_path_changed = true
                    }
                    else
                    {
                        alert("Select image size should be less than 1 MB")
                        return
                    }
                    
                }
                else {
                    alert("Select image type JPG or PNG.")
                    return
                }
            }
            else 
            {
                alert("Select image resolution should be 1500px by 850px.")
                return
            }
            
        }
        else {
            formData.image_path_changed = false
        }

        if (audioFile.isChanged) {
            let audiofilename = uuidv4()
            let audioValidationRes = validateAudioFile(audioFile.fileName)
            if (audioValidationRes.status) {
                let s3UrlResImage = await getS3SingedUrl(audiofilename, audioValidationRes.ext, audioFile.fileName, 'article')
                await axios.put(s3UrlResImage.url, audioFile.fileName)
                formData.audio_path = `${audiofilename}.${audioValidationRes.ext}`
                formData.audio_path_changed = true
            }
            else {
                alert("Select audio file.")
                return
            }
        }
        else {
            formData.audio_path_changed = false
        }
        if (videoFile.isChanged) {
            let videofilename = uuidv4()
            let videoValidationRes = validateVideoFile(videoFile.fileName)
            if (videoValidationRes.status) {
                let s3UrlResImage = await getS3SingedUrl(videofilename, videoValidationRes.ext, videoFile.fileName, 'article')
                await axios.put(s3UrlResImage.url, videoFile.fileName)
                formData.video_path = `${videofilename}.${videoValidationRes.ext}`
                formData.video_path_changed = true
            }
            else {
                alert("Select video file.")
                return
            }
        }
        else {
            formData.audio_path_changed = false
        }
        if (iconImageFile.isChanged) {
            let iconfilename = uuidv4()

            if(iconImageFile.width == 300 && iconImageFile.height == 170)
            {
                let iconValidationRes = validateImageFile(iconImageFile.fileName)
                if (iconValidationRes.status) {
                    if(iconImageFile.fileName.size <= 200 * 1024)
                    {
                        let s3UrlResIcon = await getS3SingedUrl(iconfilename, iconValidationRes.ext, iconImageFile, 'article')
                        await axios.put(s3UrlResIcon.url, iconImageFile.fileName)
                        formData.icon_image_path = `${iconfilename}.${iconValidationRes.ext}`
                        formData.icon_image_path_changed = true
                    }
                    else
                    {
                        alert("Select Icon image size should be less than 200 KB")
                        return    
                    }
                }
                else {
                    alert("Select Icon image type JPG or PNG.")
                    return
                }
            }
            else
            {
                alert("Select Icon image resolution should be 300px by 170px.")
                return
            }
        }
        else {
            formData.icon_image_path_changed = false
        }
        saveArticle(formData)

    }

    const handleEditorChange = (content, editor) => {
        setFormData({
            ...formData,
            ["description"]: encodeURI(content)
        })
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Form form={form} layout="vertical" onFinish={handleSubmit} >
                <Row gutter={20}>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input name="title" placeholder="Enter Title"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                    </Col>


                    {/* <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            label="Slug"
                            name="slug"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input name="slug" placeholder="Enter Slug"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                    </Col> */}
                    {!removeData.category &&

                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[
                                    { required: true, message: 'Required' },
                                ]}
                            >
                                <Select
                                    name="category"
                                    placeholder="Select Category"
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    onChange={(v) => handleSelect("category", v)}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "category" })) &&
                                        <>
                                            {_.find(getCommonMasterListState.list, { slug: "category" }).common_master_detail.map((item) => (
                                                <Select.Option style={{ display: (pageType === 'General' && item.value === "Pregnancy & Reproductive Wellness") ? 'none' : 'block' }} value={item._id} >{item.value}</Select.Option>
                                            ))}

                                        </>
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    {!removeData.week &&
                        <>
                            {!weekDisabled &&
                                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                    <Form.Item
                                        label="Week"
                                        name="week"
                                        rules={[
                                            { required: !weekDisabled, message: 'Required' },
                                        ]}
                                    >
                                        <Select
                                            name="week"
                                            placeholder="Select Week"
                                            showSearch
                                            allowClear
                                            mode="multiple"
                                            onChange={(v) => handleSelect("week", v)}
                                            disabled={weekDisabled}
                                        >
                                            {[...Array(40)].map((week, index) => {
                                                return (
                                                    <Select.Option value={`${index + 1}`} key={`${index}`}>{index + 1}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }
                        </>
                    }
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Select
                                name="type"
                                placeholder="Select Article Type"
                                showSearch
                                allowClear
                                onChange={(v) => handleSelect("type", v)}
                            >
                                {articleTypes.map((type, index) => {
                                    return (
                                        <Select.Option value={`${type}`} key={`${index}`}>{type}</Select.Option>
                                    )
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            label="Video Type"
                            name="video_type"
                        >
                            <Radio.Group
                                options={[
                                    { label: 'YouTube', value: 'YouTube' },
                                    { label: 'Upload', value: 'LocalFile' }
                                ]}
                                onChange={e => handleRadioChange("video_type", e.target.value)}
                                value={formData.video_type}
                                defaultValue={formData.video_type}
                                optionType="button"
                                buttonStyle="solid"
                                disabled={formData.type !== 'Video Article'}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        {formData.video_type === "YouTube"
                            ? <Form.Item
                                label="Video Link"
                                name="video_link"
                                rules={[
                                    { required: formData.type === 'Video Article', message: 'Required' },
                                ]}
                            >
                                <Input name="video_link" placeholder="Enter Video Link"
                                    onChange={handleOnChange} disabled={formData.type !== 'Video Article'}
                                />
                            </Form.Item>
                            : <Form.Item
                                label="Video File"
                                name="video_path"
                                rules={[
                                    { required: (mode === 'edit' && videoFile.edit && formData.video_path && formData.type === 'Video Article') ? videoFile.isChanged : formData.type === 'Video Article' && !videoFile.isChanged, message: 'Required' },
                                ]}
                                extra={(formData.video_path && mode === 'edit') ? <Link to={{
                                    pathname: `${s3_cdn()}article/${formData.video_path}`
                                }} target="_blank">{formData.video_path ? "Video" : ""}</Link> : null}
                            >
                                <Input
                                    name="video_path"
                                    placeholder="Select video file"
                                    type="file"
                                    onChange={e => setVideoFile({
                                        fileName: e.target.files[0],
                                        isChanged: e.target.files[0] ? true : false,
                                        edit: false
                                    })}
                                // accept="audio/*"
                                // disabled={formData.type !== 'Audio Article'}
                                // suffix={(formData.video_path && mode === 'edit') ? <Link to={{
                                //     pathname: `${s3_cdn()}article/${formData.video_path}`
                                // }} target="_blank">{formData.video_path ? "Video" : ""}</Link> : null}

                                />
                            </Form.Item>
                        }
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        {audioFile.edit}
                        <Form.Item
                            label="Audio Path"
                            name="audio_path"
                            rules={[
                                { required: (mode === 'edit' && audioFile.edit && formData.audio_path && formData.type === 'Audio Article') ? audioFile.isChanged : formData.type === 'Audio Article' && !audioFile.isChanged, message: 'Required' },
                            ]}
                        >
                            <Input
                                name="audio_path"
                                placeholder="Enter Audio Path"
                                type="file"
                                onChange={e => setAudioFile({
                                    fileName: e.target.files[0],
                                    isChanged: e.target.files[0] ? true : false,
                                    edit: false
                                })}
                                accept="audio/*"
                                disabled={formData.type !== 'Audio Article'}
                                suffix={(formData.audio_link && mode === 'edit') ? <Link to={{
                                    pathname: `${s3_cdn()}article/${formData.audio_link}`
                                }} target="_blank">{formData.audio_link ? "Audio" : ""}</Link> : null}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            label="Image Path (Dimensions: 1500px x 850px) (File Size less than 1 MB)"
                            name="image_path"
                            rules={[
                                { required: (mode === 'edit' && imageFile.edit) ? imageFile.isChanged : !imageFile.isChanged, message: 'Required' },
                            ]}
                        >
                            <Input name="image_path" placeholder="Enter Image Path"
                                type="file"
                                onChange={e=>{
                                    let imge = new Image();
                                    imge.src = URL.createObjectURL(e.target.files[0]);
                                    let img = {
                                        fileName: e.target.files[0],
                                        isChanged: e.target.files[0] ? true : false,
                                        edit: false,
                                        width: 0,
                                        height: 0,
                                    };
                                    
                                    imge.onload = () => {
                                        img = {
                                            ...img,
                                            width: imge.width,
                                            height: imge.height,
                                        }
                                        setImageFile(img);
                                    }   
                                    
                                }}
                                // onChange={e => setImageFile({
                                // fileName: e.target.files[0],
                                // isChanged: e.target.files[0] ? true : false,
                                // edit: false
                                // })}
                                accept="image/png, image/jpeg"
                                // suffix={(formData.image_path && mode === 'edit') ? <img src={`${s3_cdn()}article/${formData.image_path}`} style={{ width: 26 }} /> : null}
                            />
                            {formData.image_path && mode === 'edit' && (
                                <img
                                    src={`${s3_cdn()}article/${formData.image_path}`}
                                    style={{ 
                                        marginTop: '20px',
                                        width: 40 
                                    }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            label="Icon Image Path (Dimensions: 300px x 170px) (File Size less than 200 KB)"
                            name="icon_image_path"
                            rules={[
                                { required: (mode === 'edit' && iconImageFile.edit) ? iconImageFile.isChanged : !iconImageFile.isChanged, message: 'Required' },
                            ]}
                        >
                            <Input name="icon_image_path" placeholder="Enter Icon Image Path" type="file"
                                onChange={e=>{
                                    let imge = new Image();
                                    imge.src = URL.createObjectURL(e.target.files[0]);
                                    let img = {
                                        fileName: e.target.files[0],
                                        isChanged: e.target.files[0] ? true : false,
                                        edit: false,
                                        width: 0,
                                        height: 0,
                                    };
                                    
                                    imge.onload = () => {
                                        img = {
                                            ...img,
                                            width: imge.width,
                                            height: imge.height,
                                        }
                                        setIconImageFile(img);
                                    }
                                }}
                                // onChange={e => setIconImageFile({
                                //     fileName: e.target.files[0],
                                //     isChanged: e.target.files[0] ? true : false,
                                //     edit: false
                                // })}
                                accept="image/png, image/jpeg"
                                // suffix={(formData.icon_image_path && mode === 'edit') ? <img src={`${s3_cdn()}article/${formData.icon_image_path}`} style={{ width: 26 }} /> : null}
                            />
                            {formData.icon_image_path && mode === 'edit' && (
                                
                                <img
                                    src={`${s3_cdn()}article/${formData.icon_image_path}`}
                                    style={{ 
                                        marginTop: '20px',
                                        width: 40 
                                    }}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    {!removeData.tribe &&
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Tribe Tags"
                                name="support_tribe"
                            >
                                <Select
                                    name="support_tribe"
                                    placeholder="Select Tribe Tags"
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    onChange={(v) => handleSelect("support_tribe", v)}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "tribe-tags" })) &&
                                        <>
                                            {_.find(getCommonMasterListState.list, { slug: "tribe-tags" }).common_master_detail.map((item) => (
                                                <Select.Option value={item._id} >{item.value}</Select.Option>
                                            ))}

                                        </>
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            label="Tags"
                            name="tags"
                        // rules={[
                        //     { required: true, message: 'Required' },
                        // ]}
                        >
                            <Select
                                name="tags"
                                placeholder="Enter Tags"
                                showSearch
                                allowClear
                                mode="multiple"
                                onChange={(v) => handleSelect("tags", v)}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "tags" })) &&
                                    <>
                                        {_.find(getCommonMasterListState.list, { slug: "tags" }).common_master_detail.map((item) => (
                                            <Select.Option value={item._id} >{item.value}</Select.Option>
                                        ))}

                                    </>
                                }
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                        <Form.Item
                            label="Published At"
                            name="published_at"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <DatePicker name="published_at" placeholder="Enter Published At"
                                onChange={(date, dateString) => handleDateChange("published_at", date, dateString)}
                                style={{ width: "100%" }}
                                format={'DD/MM/YYYY'}
                            />
                        </Form.Item>
                    </Col>
                    {!removeData.gender &&
                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={[
                                    { required: true, message: 'Required' },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    name="gender"
                                    placeholder="Select Gender"
                                    showSearch
                                    allowClear
                                    onChange={(v) => handleSelect("gender", v)}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="Male" >Male</Select.Option>
                                    <Select.Option value="Female" >Female</Select.Option>
                                    <Select.Option value="Transgender" >Transgender</Select.Option>
                                    <Select.Option value="Non-Binary" >Non-Binary</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item
                            label="Short Description"
                            name="short_description"
                            rules={[{ required: true, message: "Required" }]}
                        >
                            <TextArea name="short_description" placeholder="Enter Short Description"
                                onChange={handleOnChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {((formData.apiState === "success" && mode === 'edit') || mode === 'add') &&
                            <Form.Item
                                label="Description"
                                rules={[{ required: true, message: "Required" }]}
                            >
                                <Editor
                                    placeholder="Enter description"
                                    apiKey={tinyEditor()}
                                    onInit={(evt, editor) => editorUseRef.current = editor}
                                    initialValue={editorUseRef.current}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist', 'autolink', 'lists', 'image', 'charmap', 'print', 'preview', 'anchor',
                                            'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount', 'image', 'link','accordion'
                                        ],   
                                        toolbar: "styles bold italic underline strikethrough alignleft aligncenter alignright alignjustify bullist numlist outdent indent image link openlink unlink removeformat fontsize undo redo accordion",
                                        images_upload_handler: function (blobInfo) {
                                            return new Promise(async (resolve, reject) => {
                                              try {
                                                const imageFilename = uuidv4();
                                                const fileName = blobInfo.filename();
                                                const fileExtension = fileName.split('.').pop().toLowerCase();
                                        
                                                const s3UrlResImage = await getS3SingedUrl(imageFilename, fileExtension, fileName, 'article');
                                                await axios.put(s3UrlResImage.url, blobInfo.blob());
                                        
                                                // Provide the URL of the uploaded image in the resolved Promise
                                                const imageUrl = s3_cdn() + 'article/' + imageFilename + '.' + fileExtension;
                                                resolve(imageUrl);
                                              } catch (error) {
                                                console.error('File upload failed:', error.message);
                                                reject('Unable to upload the file. Please try again.');
                                              }
                                            });
                                        },                                 
                                        fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                        paste_data_images:true,
                                        link_default_target: '_blank',
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </Form.Item>
                        }
                    </Col>

                </Row>
                <Button type="primary" htmlType="submit">SUBMIT</Button>

            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveArticleState: state.saveArticle,
    getArticleDetailState: state.getArticleDetail,
    getCommonMasterListState: state.getCommonMasterList,
});

const mapDispatchToProps = (dispatch) => ({
    saveArticle: (params) => dispatch(saveArticle(params)),
    saveArticleReset: () => dispatch(saveArticleReset()),
    getArticleDetail: (params) => dispatch(getArticleDetail(params)),
    getArticleDetailReset: () => dispatch(getArticleDetailReset()),
    getCommonMasterList: (params) => dispatch(getCommonMasterList(params)),
    getCommonMasterListReset: () => dispatch(getCommonMasterListReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(ContentRepoAdd);