import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_ROLE_MODULE_MAPPING_LIST,
    getRoleModuleMappingListFail,
    getRoleModuleMappingListSuccess
} from '../action/getRoleModuleMappingListAction'

// api 
import getRoleModuleMappingListApi from '../api/getRoleModuleMappingListApi'

export function* getRoleModuleMappingListSaga(action) {
    try {
        const response = yield call(() => getRoleModuleMappingListApi(action.params))
        if (response.data.status === 1) {
            yield put(getRoleModuleMappingListSuccess(response, action))
        } else {
            yield put(getRoleModuleMappingListFail(response, action))
        }
    }
    catch (e) {
        yield put(getRoleModuleMappingListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_ROLE_MODULE_MAPPING_LIST, getRoleModuleMappingListSaga)
}