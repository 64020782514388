import React, { useState,useEffect } from "react";
import { Avatar, Popover, Button, Space, notification } from "antd";
import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { PropTypes } from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// components
import {
    Container,
    LeftSection,
    RightSection,
    Title,
    TitleContainer,
} from "./HeaderStyle";
import { getUser } from '../../utils'

import { logoutApi  } from "../../api/logoutApi"

const Header = props => {
    let name = getUser() && getUser().fname || null
    const [redirect, setRedirect] = useState(false)
    const profilePopover = () => {
        return (
            <>
                <p>Welcome {name}</p>
                <Space>
                    <Link onClick={logoutUser}><Button type="dashed" >Logout</Button></Link>
                </Space>
            </>
        )
    }

    const logoutUser = async () => {
       try{
        let result =await logoutApi();
        localStorage.removeItem('LilacUserToken')
         localStorage.removeItem('LilacUser')
         localStorage.removeItem('LilacRoleRights')
         notification.success({
             message: "You are logged out successfully.",
             placement: "bottomRight"
         });
         setRedirect(true)
       }catch{
        setRedirect(true)
       }
       
       
    }
  
    return (
        <>
            {redirect &&
                <Redirect to="/login" />
            }
            <Container id="adminHeader" subHeaderExists={props.subHeaderExists}>
                <div>
                    {props.backButton &&
                        <Link to={props.backButton} style={{ marginRight: 12 }} ><ArrowLeftOutlined style={{ color: '#000' }} /></Link>
                    }
                    <Title>{props.title}</Title>
                </div>
                <Space size={"small"} style={{ alignItems: "center" }} >
                    {props.rightChildren}
                    <Popover
                        placement="bottomRight"
                        content={profilePopover}
                        trigger="click"
                    >
                        <a><Avatar icon={<UserOutlined />} /></a>
                    </Popover>
                </Space>
            </Container>
        </>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    status: PropTypes.element,
    rightChildren: PropTypes.element,
    icon: PropTypes.element,
    subHeaderExists: PropTypes.bool,
    button: PropTypes.element,
};

Header.defaultProps = {
    title: "",
    subTitle: "",
    status: null,
    rightChildren: null,
    icon: null,
    subHeaderExists: false,
    button: null,
};


export default Header;
