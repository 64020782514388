import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_EMPLOYEE_LIST,
    getEmployeeListFail,
    getEmployeeListSuccess
} from '../action/getEmployeeListAction'

// api 
import getEmployeeListApi from '../api/getEmployeeListApi'

export function* getEmployeeListSaga(action) {
    try {
        const response = yield call(() => getEmployeeListApi(action.params))
        if (response.data.status === 1) {
            yield put(getEmployeeListSuccess(response, action))
        } else {
            yield put(getEmployeeListFail(response, action))
        }
    }
    catch (e) {
        yield put(getEmployeeListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_EMPLOYEE_LIST, getEmployeeListSaga)
}