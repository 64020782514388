import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DELETE_COMMON_MASTER,
    deleteCommonMasterFail,
    deleteCommonMasterSuccess
} from '../action/deleteCommonMasterAction'

// api 
import deleteCommonMasterApi from '../api/deleteCommonMasterApi'

export function* deleteCommonMasterSaga(action) {
    try {
        const response = yield call(() => deleteCommonMasterApi(action.params))
        if (response.data.status === 1) {
            yield put(deleteCommonMasterSuccess(response, action))
        } else {
            yield put(deleteCommonMasterFail(response, action))
        }
    }
    catch (e) {
        yield put(deleteCommonMasterFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DELETE_COMMON_MASTER, deleteCommonMasterSaga)
}