import React, { useState, useEffect } from 'react';
import { Drawer, Button, Skeleton, Row, Col } from 'antd';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { CloseOutlined, EditOutlined } from "@ant-design/icons"
import moment from 'moment'


/* custom component */
import { ViewBox, ViewLabel, ViewValue, Heading } from "../../../component/Xcomponent"

/* action */
import { getConsulationDashboardDetail, getConsulationDashboardDetailReset } from "../../../action/getConsulationDashboardDetailAction"

/* others */
import { dateFormat, dateTimeFormat, dateTimeUTCFormat, s3_cdn } from '../../../utils';


const ConsulationDashboardView = props => {
    /* variable */
    const { getConsulationDashboardDetail, getConsulationDashboardDetailReset, getConsulationDashboardDetailState } = props

    /* callbacks */
    useEffect(() => {
        if (props.visible === false) {
            getConsulationDashboardDetailReset()
        }
    }, [props.visible])
    useEffect(() => {
        if (props.id && props.visible) {
            getConsulationDashboardDetail({
                id: props.id
            })
        }
    }, [props.id, props.visible])
    return (
        <Drawer
            title={`Consulation Dashboard View`}
            placement="right"
            closeIcon={<><Button size="small" shape="circle" icon={<CloseOutlined />} /></>}
            onClose={props.onClose}
            visible={props.visible}
            width={800}
        >
            {getConsulationDashboardDetailState.apiState === "loading" &&
                <Skeleton active />}

            {getConsulationDashboardDetailState.apiState === "error" &&
                <p>{getConsulationDashboardDetailState.message}</p>
            }

            {getConsulationDashboardDetailState.apiState === "success" &&
                <>
                    <Row gutter={20}>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Appointment No.</ViewLabel>
                                <ViewValue>
                                    <span>{getConsulationDashboardDetailState.data.appointment_no}</span>
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Payment ID</ViewLabel>
                                <ViewValue>
                                    {getConsulationDashboardDetailState.data.payment_id}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Date & Time</ViewLabel>
                                <ViewValue>
                                    {dateTimeUTCFormat(getConsulationDashboardDetailState.data.date)}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Doctor Name</ViewLabel>
                                <ViewValue>{getConsulationDashboardDetailState.data.doctor_id.fname} {getConsulationDashboardDetailState.data.doctor_id.lname}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Patient Name</ViewLabel>
                                <ViewValue>{getConsulationDashboardDetailState.data.some_one_else ? getConsulationDashboardDetailState.data.full_name : `${getConsulationDashboardDetailState.data.patient_id?.name.firstName} ${getConsulationDashboardDetailState.data.patient_id?.name.lastName}`}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Mobile No.</ViewLabel>
                                <ViewValue>
                                    {getConsulationDashboardDetailState.data.some_one_else ? getConsulationDashboardDetailState.data.phone : getConsulationDashboardDetailState.data.patient_id?.phone}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Address</ViewLabel>
                                <ViewValue>
                                    {getConsulationDashboardDetailState.data.patient_id?.address}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>City</ViewLabel>
                                <ViewValue>
                                    {getConsulationDashboardDetailState.data.patient_id?.city}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Pincode</ViewLabel>
                                <ViewValue>
                                    {getConsulationDashboardDetailState.data.patient_id?.pincode}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Service Type</ViewLabel>
                                <ViewValue>
                                    {getConsulationDashboardDetailState.data.service_type}
                                </ViewValue>
                            </ViewBox>
                        </Col>

                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Symptoms</ViewLabel>
                                <ViewValue>{getConsulationDashboardDetailState.data.symptoms}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>Status</ViewLabel>
                                <ViewValue style={{ textTransform: "capitalize" }}>{getConsulationDashboardDetailState.data.status}</ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>CreatedAt</ViewLabel>
                                <ViewValue>
                                    {dateFormat(getConsulationDashboardDetailState.data.createdAt)}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                        <Col span="12">
                            <ViewBox>
                                <ViewLabel>UpdatedAt</ViewLabel>
                                <ViewValue>
                                    {dateFormat(getConsulationDashboardDetailState.data.updatedAt)}
                                </ViewValue>
                            </ViewBox>
                        </Col>
                    </Row>
                </>
            }
        </Drawer >
    )
}

const mapStateToProps = (state) => ({
    getConsulationDashboardDetailState: state.getConsulationDashboardDetail
});

const mapDispatchToProps = (dispatch) => ({
    getConsulationDashboardDetail: (params) => dispatch(getConsulationDashboardDetail(params)),
    getConsulationDashboardDetailReset: () => dispatch(getConsulationDashboardDetailReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(ConsulationDashboardView);