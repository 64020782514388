import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_ADMIN_TYPE_DETAIL,
    getAdminTypeDetailFail,
    getAdminTypeDetailSuccess
} from '../action/getAdminTypeDetailAction'

// api 
import getAdminTypeDetailApi from '../api/getAdminTypeDetailApi'

export function* getAdminTypeDetailSaga(action) {
    try {
        const response = yield call(() => getAdminTypeDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getAdminTypeDetailSuccess(response, action))
        } else {
            yield put(getAdminTypeDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getAdminTypeDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_ADMIN_TYPE_DETAIL, getAdminTypeDetailSaga)
}