import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    DOCTOR_SIGNUP_REQUEST_LIST,
    doctorSignupRequestListFail,
    doctorSignupRequestListSuccess
} from '../action/doctorSignupRequestListAction'

// api 
import doctorSignupRequestListApi from '../api/doctorSignupRequestListApi'

export function* doctorSignupRequestListSaga(action) {
    try {
        const response = yield call(() => doctorSignupRequestListApi(action.params))
        if (response) {
            yield put(doctorSignupRequestListSuccess(response, action))
        }
    }
    catch (e) {
        yield put(doctorSignupRequestListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(DOCTOR_SIGNUP_REQUEST_LIST, doctorSignupRequestListSaga)
}