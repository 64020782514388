import React, { useEffect, useState, useRef } from "react"
import { Button, Form, Row, Col, Input, notification, Select } from "antd"
import { Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import _ from "lodash"
// component
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"
import { Editor } from '@tinymce/tinymce-react'

// actions
import { saveQuickSupportQuestionAnswer, saveQuickSupportQuestionAnswerReset } from '../../../action/saveQuickSupportQuestionAnswerAction'
import { getQuickSupportQuestionAnswerDetail, getQuickSupportQuestionAnswerDetailReset } from '../../../action/getQuickSupportQuestionAnswerDetailAction'
import { getQuickSupportCategoryDetail, getQuickSupportCategoryDetailReset } from '../../../action/getQuickSupportCategoryDetailAction'

/* otehrs */
import { tinyEditor } from '../../../utils'

const QuickSupportQuestionAnswerSave = props => {

    // Variables
    const editorUseRef = useRef(null);
    const {
        saveQuickSupportQuestionAnswer, saveQuickSupportQuestionAnswerState, saveQuickSupportQuestionAnswerReset,
        getQuickSupportQuestionAnswerDetail, getQuickSupportQuestionAnswerDetailState, getQuickSupportQuestionAnswerDetailReset,
        getQuickSupportCategoryDetail, getQuickSupportCategoryDetailState, getQuickSupportCategoryDetailReset

    } = props
    const category_id = props.match.params.category_id
    const id = props.match.params.id
    const mode = id ? "edit" : "add"
    const [form] = Form.useForm()
    const [formData, setFormData] = useState({
        question: "",
        answer: "",
        quick_support_id: category_id,
        application_type: "",
        text_editor: false
    })
    const [redirect, setRedirect] = useState([false, ""])
    if (mode === "edit") {
        formData.id = id
        formData.mode = mode
    }
    else {
        formData.text_editor = true
    }
    // callbacks
    /* To fetch categories */
    useEffect(() => {
        getQuickSupportCategoryDetail({
            id: category_id
        })
        if (mode === "edit") {
            getQuickSupportQuestionAnswerDetail({
                id: id
            })
        }
        return (() => {
            getQuickSupportQuestionAnswerDetailReset()
            saveQuickSupportQuestionAnswerReset()
            getQuickSupportCategoryDetailReset()
        })
    }, [])



    /* Watching add api to display notifications */
    useEffect(() => {
        if (saveQuickSupportQuestionAnswerState.apiState === "success") {
            notification["success"]({
                message: saveQuickSupportQuestionAnswerState.message,
                placement: "bottomRight"
            })
            saveQuickSupportQuestionAnswerReset()
            setRedirect([true, `/quick-support-question-answer/list/${category_id}`])
        }

        if (saveQuickSupportQuestionAnswerState.apiState === "error") {
            notification["error"]({
                message: saveQuickSupportQuestionAnswerState.message,
                placement: "bottomRight"
            })
        }
    }, [saveQuickSupportQuestionAnswerState])

    useEffect(() => {
        if (getQuickSupportQuestionAnswerDetailState.apiState === "success") {
            let item = getQuickSupportQuestionAnswerDetailState.data
            form.setFieldsValue({
                application_type: item.application_type,
                question: item.question,
                answer: decodeURIComponent(item.answer),
            })
            setFormData({ ...formData, ['application_type']: item.application_type, ['answer']: decodeURIComponent(item.answer), ['question']: item.question, ['text_editor']: true })
            editorUseRef.current = decodeURIComponent(item.answer);
        }
    }, [getQuickSupportQuestionAnswerDetailState])
    // functions

    const handleOnChange = (e) => { 
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    const handleSelect = (name, v) => {
        setFormData({
            ...formData,
            [name]: v
        })
    }

    const handleEditorChange = (content, editor) => {
        setFormData({
            ...formData,
            ["answer"]: encodeURI(content)
        })
    }

    const onFinish = () => {
        console.log("formadata",formData)
        saveQuickSupportQuestionAnswer(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                backButton={`/quick-support-question-answer/list/${category_id}`}
                title={(mode === "edit" ? "Edit Quick Support Question Answer" : "Add Quick Support Question Answer") + " of " + (getQuickSupportCategoryDetailState.apiState === "success" ? getQuickSupportCategoryDetailState.data.name : "")}
            />
            <PageContainer>
                <LayoutContainer>
                    <Form form={form} layout="vertical" onFinish={onFinish} >
                        <Row gutter={20}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="Application Type"
                                    name="application_type"
                                    rules={[{ required: true, message: "Required" }]}
                                >
                                    <Select
                                        name="application_type"
                                        placeholder="Select Application Type"
                                        showSearch
                                        allowClear
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={(v) => handleSelect("application_type", v)}
                                        mode="multiple"
                                    >
                                        <Select.Option value="Patient">Patient</Select.Option>
                                        <Select.Option value="Doctor">Doctor</Select.Option>
                                        <Select.Option value="Institute">Institute</Select.Option>
                                        <Select.Option value="Super Admin">Super Admin</Select.Option>
                                        <Select.Option value="Admin">Admin</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span="24">
                                <Form.Item
                                    label="Question"
                                    name="question"
                                    rules={[
                                        { required: true, message: 'Required' },
                                    ]}
                                >
                                    <Input name="question" placeholder="Enter question" onChange={handleOnChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {formData.text_editor &&
                            <Row gutter={20}>
                                <Col span="24">
                                    <Form.Item
                                        label="Answer"
                                    >
                                        <Editor
                                            placeholder="Enter answer"
                                            apiKey={tinyEditor()}
                                            onInit={(evt, editor) => editorUseRef.current = editor}
                                            initialValue={editorUseRef.current}
                                            init={{
                                                height: 500,
                                                menubar: false,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'paste', 'code', 'help', 'wordcount',
                                                    'link'
                                                ],
                                                toolbar: "styles bold italic underline strikethrough alignleft aligncenter alignright alignjustify bullist numlist outdent indent link openlink unlink removeformat fontsize undo redo",
                                                // toolbar:
                                                //     'formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | link image | fontsizeselect',
                                                fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                                link_default_target: '_blank',
                                            }}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }

                        <Button type="primary" htmlType="submit" loading={saveQuickSupportQuestionAnswerState.apiState === "loading" ? true : false} >SAVE</Button>
                    </Form>
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveQuickSupportQuestionAnswerState: state.saveQuickSupportQuestionAnswer,
    getQuickSupportQuestionAnswerDetailState: state.getQuickSupportQuestionAnswerDetail,
    getQuickSupportCategoryDetailState: state.getQuickSupportCategoryDetail,
})

const mapDispatchToProps = (dispatch) => ({
    saveQuickSupportQuestionAnswer: (params) => dispatch(saveQuickSupportQuestionAnswer(params)),
    saveQuickSupportQuestionAnswerReset: () => dispatch(saveQuickSupportQuestionAnswerReset()),
    getQuickSupportQuestionAnswerDetail: (params) => dispatch(getQuickSupportQuestionAnswerDetail(params)),
    getQuickSupportQuestionAnswerDetailReset: () => dispatch(getQuickSupportQuestionAnswerDetailReset()),
    getQuickSupportCategoryDetail: (params) => dispatch(getQuickSupportCategoryDetail(params)),
    getQuickSupportCategoryDetailReset: () => dispatch(getQuickSupportCategoryDetailReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickSupportQuestionAnswerSave)