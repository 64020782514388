import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_CONSULATION_DASHBOARD_DETAIL,
    getConsulationDashboardDetailFail,
    getConsulationDashboardDetailSuccess
} from '../action/getConsulationDashboardDetailAction'

// api 
import getConsulationDashboardDetailApi from '../api/getConsulationDashboardDetailApi'

export function* getConsulationDashboardDetailSaga(action) {
    try {
        const response = yield call(() => getConsulationDashboardDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getConsulationDashboardDetailSuccess(response, action))
        } else {
            yield put(getConsulationDashboardDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getConsulationDashboardDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_CONSULATION_DASHBOARD_DETAIL, getConsulationDashboardDetailSaga)
}