import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons"
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"
/* actions */
import { getArticleList, getArticleListReset } from "../../../action/getArticleListAction"
import { deleteArticle, deleteArticleReset } from "../../../action/deleteArticleAction"
import { dateFormat } from "../../../utils";
import ContentRepoView from "../ContentRepoView/ContentRepoView";

const ContentRepoList = props => {
    /* variables */
    const {
        getArticleList, getArticleListReset, getArticleListState,
        deleteArticle, deleteArticleReset, deleteArticleState,
        pageUrl,
        pageType,
        refreshPageUrl,
        heighlightsWeek,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [searchText, setSearchText] = useState({
        'content_repo_type': pageType,
        'week': heighlightsWeek
    })
    const [tableData, setTableData] = useState([])
    const [columns, setColumns] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const [removeData, setRemoveData] = useState({
        tribe: false,
        category: false,
        week: false
    })

    //Callback
    useEffect(() => {
        if (pageType === 'Pregnancy') {
            setRemoveData({
                tribe: true,
                category: false,
                week: false
            })
        }
        else if (pageType === 'Tribe') {
            setRemoveData({
                tribe: false,
                category: true,
                week: true
            })
        }
        else if (pageType === 'General') {
            setRemoveData({
                tribe: true,
                category: false,
                week: true
            })
        }
        else if (pageType === 'Doctor') {
            setRemoveData({
                tribe: true,
                category: true,
                week: true
            })
        }
    }, [])

    //Functions

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, display: heighlightsWeek > 0 && dataIndex === 'week' ? 'none' : 'block' }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined, display: heighlightsWeek > 0 && dataIndex === 'week' ? 'none' : 'block' }} />,
    });

    useEffect(() => {
        let conditionalColumn = []
        conditionalColumn.push({
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 300,
            render: title => (
                title.length > 30 ? title.substring(0, 30)+"..." : title 
            ),
            ...getColumnSearchProps('title', 'Title')
        })
        conditionalColumn.push({
            title: 'Article Type',
            dataIndex: 'articleType',
            key: 'articleType',
            width: 200,
            ...getColumnSearchProps('type', 'type')
        })
        if (!removeData.week) {
            conditionalColumn.push({
                title: 'Week',
                dataIndex: 'week',
                key: 'week',
                width: 300,
                render: week => (
                    <>
                        <Space>
                            <div>
                                {week.map(item => (
                                    <XtagTable>{item}</XtagTable>
                                ))}
                            </div>
                        </Space>
                    </>
                ),
                ...getColumnSearchProps('week', 'Week')
            })
        }
        if (!removeData.tribe) {
            conditionalColumn.push({
                title: 'Tribe Tags',
                dataIndex: 'support_tribe',
                key: 'tribe',
                width: 300,
                render: support_tribe => (
                    <>
                        <Space>
                            <div>
                                {support_tribe.map(item => (
                                    <XtagTable>{item.value}</XtagTable>
                                ))}
                            </div>
                        </Space>
                    </>
                ),
                ...getColumnSearchProps('support_tribe', 'Tribe Tags')
            })
        }
        conditionalColumn.push({
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            width: 300,
            render: tags => (
                <>
                    <Space>
                        <div>
                            {tags.map(item => (
                                <XtagTable>{item.value}</XtagTable>
                            ))}
                        </div>
                    </Space>
                </>
            ),
            ...getColumnSearchProps('tags', 'Tags')
        })
        if (!removeData.category) {
            conditionalColumn.push({
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
                width: 300,
                render: category => (
                    <>
                        <Space>
                            <div>
                                {category.map(item => (
                                    <XtagTable>{item.value}</XtagTable>
                                ))}
                            </div>
                        </Space>
                    </>
                ),
                ...getColumnSearchProps('category', 'Category')
            })
        }
        conditionalColumn.push({
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            fixed: 'right',
            width: 120,
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/${pageUrl}/edit/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteArticle({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        })
        setColumns(conditionalColumn)
    }, [removeData])


    /* callbacks */
    useEffect(() => {
        getArticleList({
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/${refreshPageUrl}?page=${pagination.page}`])
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getArticleListState.apiState === "success") {
            let tableData = []
            getArticleListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        title: row.title,
                        week: row.week,
                        support_tribe: row.support_tribe,
                        tags: row.tags,
                        category: row.category,
                        published_at: row.published_at,
                        articleType: row.type,
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getArticleListState])

    useEffect(() => {
        setRedirect([true, `/${refreshPageUrl}?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteArticleState.apiState === "success") {
            deleteArticleReset()
            notification["success"]({
                message: deleteArticleState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteArticleState.apiState === "error") {
            deleteArticleReset()
            notification["error"]({
                message: deleteArticleState.message,
                placement: "topRight"
            })
        }
    }, [deleteArticleState])


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}


            {getArticleListState.apiState === "error" &&
                <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                    <p>{getArticleListState.message}</p>
                    <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                </div>
            }

            {getArticleListState.apiState !== "error" &&
                <>
                    <Xtable columns={columns} id="actionFreeze" dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                        loading={getArticleListState.apiState === "loading"}
                    />
                    <Xpagination
                        pageSizeOptions={['25', '50', '100', '500']}
                        showSizeChanger
                        total={getArticleListState.pagination.total_records}
                        onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                        current={pagination.page}
                        defaultPageSize={pagination.records}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                    <ContentRepoView pageUrl={pageUrl} pageType={pageType} id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                </>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    getArticleListState: state.getArticleList,
    deleteArticleState: state.deleteArticle
});

const mapDispatchToProps = (dispatch) => ({
    getArticleList: (params) => dispatch(getArticleList(params)),
    getArticleListReset: () => dispatch(getArticleListReset()),
    deleteArticle: (params) => dispatch(deleteArticle(params)),
    deleteArticleReset: () => dispatch(deleteArticleReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(ContentRepoList);