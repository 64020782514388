import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_TESTIMONIAL_LIST,
    getTestimonialListFail,
    getTestimonialListSuccess
} from '../action/getTestimonialListAction'

// api 
import getTestimonialListApi from '../api/getTestimonialListApi'

export function* getTestmonialListSaga(action) {
    try {
        const response = yield call(() => getTestimonialListApi(action.params))
        if (response.data.status === 1) {
            yield put(getTestimonialListSuccess(response, action))
        } else {
            yield put(getTestimonialListFail(response, action))
        }
    }
    catch (e) {
        yield put(getTestimonialListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_TESTIMONIAL_LIST, getTestmonialListSaga)
}