import React, { useEffect, useState } from "react"
import { UploadOutlined, PlusOutlined } from '@ant-design/icons'
import { Link, useLocation, Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { Input, Button, Popconfirm, notification, Space, Menu, Dropdown } from 'antd'
import { EditOutlined, DeleteOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import _ from "lodash"
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xpagination, Xtable, LayoutContainer } from "../../../component/Xcomponent"


// actions
import { getDtrfsList, getDtrfsListReset } from '../../../action/getDtrfsListAction'
 
/* OTHERS */
import { checkRights } from '../../../utils'

const DtrfsList = props => {
    // variables
    const {
        getDtrfsList, getDtrfsListReset, getDtrfsListState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [columns, setColumns] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [refresh, setRefresh] = useState(0)
    const [redirect, setRedirect] = useState(["", false])
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 20,
    })
    const [view, setView] = useState({
        visible: false,
        id: null,
    })

    // Functions
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }

    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }


    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });

    // callbacks
    useEffect(() => {
        return (() => {
            getDtrfsListReset()
        })
    }, [])

    useEffect(() => {
        getDtrfsList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
        setRedirect([true, `/dtrfs/list?page=${pagination.page}`])

    }, [pagination, refresh, searchText])

    useEffect(() => {
        if (getDtrfsListState.apiState === "success") {
            setColumns([
                {
                    title: "InstituteName",
                    dataIndex: "institute_name",
                    render: institute_name => (
                        <span>{institute_name}</span>
                    ),
                    ...getColumnSearchProps('institute_name', 'InstituteName')

                },
                {
                    title: "TestName",
                    dataIndex: "test_name",
                    render: test_name => (
                        <span>{test_name}</span>
                    ),
                    ...getColumnSearchProps('test_name', 'TestName')

                },
              
                {
                    title: "CustomReferringDoctor",
                    dataIndex: "customReferringDoctor",
                    render: customReferringDoctor => (
                        <span>{customReferringDoctor}</span>
                    ),
                    ...getColumnSearchProps('customReferringDoctor', 'CustomReferringDoctor')

                },
                {
                    title: "PatientName",
                    dataIndex: "patientname",
                    render: patientname => (
                        <span>{patientname}</span>
                    ),
                    ...getColumnSearchProps('patientname', 'PatientName')

                },
            ])
            let rows = []
            getDtrfsListState.list.map((result) => {
                rows.push({
                    test_name: result.test_info.test_name,
                    institute_name: result.institute_name,
                    patientname: result.patient[0].name.firstName + " " + result.patient[0].name.lastName,
                    customReferringDoctor:  result.medical_info.customReferringDoctor,
                    doctor_name: result.doctor_info.doctorName.fname + " " + result.doctor_info.doctorName.lname,
                    id: result._id,
                })
            })
            setDataSource(rows)
        }
    }, [getDtrfsListState])



    // Functions
    const menu = (
        <Menu>
            <Menu.Item>
                <Link to="/common-master/test-group">
                    Test Group
                </Link>
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            {
                getDtrfsListState.apiState !== "error" &&
                <>
                    {redirect[0] &&
                        <Redirect to={redirect[1]} />}
                    <Header
                        title="Custom Doctor"
                       
                    />
                    <PageContainer>
                        <LayoutContainer>

                            <Xtable dataSource={dataSource} columns={columns} pagination={false} scroll={{ x: 768, y: 600 }}
                                loading={getDtrfsListState.apiState === "loading"} />
                            <Xpagination
                                pageSizeOptions={['10', '15', '25', '50']}
                                showSizeChanger
                                onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                                current={pagination.page}
                                defaultPageSize={pagination?.records}
                                total={getDtrfsListState.pagination.total_records}
                                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            />
                        </LayoutContainer>
                    </PageContainer>
                </>
            }
            <>
                {
                    getDtrfsListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getDtrfsListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

            </>
        </>
    )
}
const mapStateToProps = (state) => ({
    getDtrfsListState: state.getDtrfsList,
})

const mapDispatchToProps = (dispatch) => ({
    getDtrfsList: (params) => dispatch(getDtrfsList(params)),
    getDtrfsListReset: () => dispatch(getDtrfsListReset()),
})




export default connect(mapStateToProps, mapDispatchToProps)(DtrfsList)