import React, { useState, useEffect } from "react";
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    notification,
    Select,
    Switch,
    Spin,

} from "antd";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../component/Xcomponent"
import ScrollBox from "../../../component/ScrollBox"

// actions
import { saveTestMaster, saveTestMasterReset } from '../../../action/saveTestMasterAction'
import { viewTestMaster, viewTestMasterReset } from '../../../action/viewTestMasterAction'
import { getCommonMasterList, getCommonMasterListReset } from "../../../action/getCommonMasterListAction";
import { getSubGroupList, getSubGroupListReset } from "../../../action/getSubGroupListAction";
import { getSampleList, getSampleListReset } from "../../../action/getSampleListAction"

/* OTHERS */
import { checkRights } from '../../../utils'

const TestMasterAdd = (props) => {
    // variables
    const test_master_id = props.match.params.test_master_id
    const mode = test_master_id ? "edit" : "add"
    const {
        saveTestMaster, saveTestMasterReset, saveTestMasterState,
        viewTestMaster, viewTestMasterReset, viewTestMasterState,
        getCommonMasterList, getCommonMasterListReset, getCommonMasterListState,
        getSampleList, getSampleListReset, getSampleListState,
        getSubGroupList, getSubGroupListReset, getSubGroupListState,
    } = props
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [subGroupList,setSubGroupList] = useState([]);
    const [formData, setFormData] = useState({
        therapeutic_category: "",
        patient_stage: "",
        type_of_patient: "",
        test_group: "",
        sub_group: "",
        keywords_or_aliases: "",
        description: "",
        details: "",
        lilac_test_id: "",
        sample_type: [],
        technology: "",
        speciality: "",
        pcpndt: false,
        home_collection_possible: false,
        test_cancellation_rules: "",
        resampling_required_or_possible: false,
        sample_journey_statuses: "",
        tat: "",
        can_be_outsourced: false,
        outsource_partner: "",
        outsource_partner_category: "",
        cprt: "",
        stagewise_process: "",
        stagewise_process_tat: "",
        processing_center: "",
        direct_consumer_booking_scope: false,
        display_test_name: "",
        twin_condition: "",
        mandatory_attachments: [],
        non_mandatory_attachments: [],
        sex: [],
        is_pre_eclampsia: false,
        is_thalasseima: false,
        twin_allowed: false,
        trimester_test: "",
        t1_10:"",
        sample_collection_charges: "1500.00",
        test_collection_charges: "",
        // gestation_age_start: "",
        // gestation_age_end: "14 weeks",
        twin_with_donor_egg: "",
        max_age: 60,
        test_name: "",
        sub_sample: "",
        outcomes: "",
        mrp: "",
        transfer_rate: "",
        gestation_age_start_week: "",
        gestation_age_end_week: "",
        gestation_age_start_day: "",
        gestation_age_end_day: "",
        tier: "",
        category_for_test_directory: "",
        can_book_by_patient: false,
        can_book_by_patient_test_display_name: "",
        b2b_test_display_name: "",
        multiple_report_count: "",
        combo_test_codes:"",
        lims_clinical_data: "",
        multiple_patient: false,
        multiple_sample: false,
        multiple_report: false,
        multiple_sample_type:"",
        sample_validity_storage:"",
        dna_validity_storage:"",
        flow:"",
        display:""
    })
    const [attachments, setAttachments] = useState({mandatory_attachments:[],non_mandatory_attachments:[]})
    if (mode === "edit") {
        formData.id = test_master_id
        formData.mode = mode
    }
    const [form] = Form.useForm()

    const { Option } = Select

    // callbacks
    useEffect(() => {
        if (mode === "edit") {
            viewTestMaster({
                id: test_master_id
            })
        }
        getCommonMasterList({ slug: 'test-group,outsource-partner,category-for-test-directory' })
        getSubGroupList()
        return (() => {
            getCommonMasterListReset()
            getSampleListReset()
        })
    }, [])

    useEffect(() => {
        if (viewTestMasterState.apiState === "success") {
            if (viewTestMasterState.data.sample_type) {
                getSampleList({ searchTerm: "", dropDownList: "/get-dropdown-list" })
                var sample_type = []
                var sample_type_name = []
                viewTestMasterState.data.sample_type.map(data => {
                    sample_type.push(data._id)
                    sample_type_name.push(data.sample_type)
                })
                // getSampleList()
            }
            if(viewTestMasterState.data.test_group&&getCommonMasterListState.apiState==="success")
            {
                const list = _.find(_.find(getCommonMasterListState.list,{slug:"test-group"})?.common_master_detail,{_id:viewTestMasterState.data.test_group._id})?.test_sub_group
                console.log("List",list)
                setSubGroupList(list ? list:[])                    
            }
            form.setFieldsValue({
                lilac_test_id: viewTestMasterState.data?.lilac_test_id,
                therapeutic_category: viewTestMasterState.data.therapeutic_category,
                patient_stage: viewTestMasterState.data.patient_stage,
                type_of_patient: viewTestMasterState.data.type_of_patient,
                test_group: viewTestMasterState.data.test_group ? viewTestMasterState.data.test_group._id : null,
                sub_group: viewTestMasterState.data.sub_group,
                test_name: viewTestMasterState.data.test_name,
                keywords_or_aliases: viewTestMasterState.data.keywords_or_aliases,
                description: viewTestMasterState.data.description,
                details: viewTestMasterState.data.details,
                sample_type: viewTestMasterState.data.sample_type ? viewTestMasterState.data.sample_type : null,
                technology: viewTestMasterState.data.technology,
                outcomes: viewTestMasterState.data.outcomes,
                resampling_required_or_possible: viewTestMasterState.data.resampling_required_or_possible,
                tat: viewTestMasterState.data.tat,
                can_be_outsourced: viewTestMasterState.data.can_be_outsourced,
                outsource_partner: viewTestMasterState.data.outsource_partner,
                outsource_partner_category: viewTestMasterState.data.outsource_partner_category,
                cprt: viewTestMasterState.data?.cprt?.toString(),
                can_book_by_patient: viewTestMasterState.data.can_book_by_patient,
                display_test_name: viewTestMasterState.data.display_test_name,
                mandatory_attachments: viewTestMasterState.data.mandatory_attachments.map((attachment)=>attachment.variable),
                non_mandatory_attachments: viewTestMasterState.data.non_mandatory_attachments.map((attachment)=>attachment.variable),
                sex: viewTestMasterState.data.sex,
                trimester_test: viewTestMasterState.data.trimester_test,
                tier: viewTestMasterState.data.tier,
                sample_collection_charges: viewTestMasterState.data?.sample_collection_charges?.toString(),
                gestation_age_start_week: viewTestMasterState.data?.gestation_age_start_week?.toString(),
                gestation_age_end_week: viewTestMasterState.data?.gestation_age_end_week?.toString(),
                gestation_age_start_day: viewTestMasterState.data?.gestation_age_start_day?.toString(),
                gestation_age_end_day: viewTestMasterState.data?.gestation_age_end_day?.toString(),
                mrp: viewTestMasterState.data.mrp?.toString(),
                transfer_rate: viewTestMasterState.data.transfer_rate?.toString(),
                category_for_test_directory: viewTestMasterState.data.category_for_test_directory ? viewTestMasterState.data.category_for_test_directory._id : null,
                // category_for_test_directory: viewTestMasterState.data.category_for_test_directory,
                multiple_sample: viewTestMasterState.data.multiple_sample,
                multiple_patient: viewTestMasterState.data.multiple_patient,
                multiple_report: viewTestMasterState.data.multiple_report,
                multiple_report_count: viewTestMasterState.data.multiple_report_count?.toString(),
                combo_test_codes: viewTestMasterState.data.combo_test_codes,
                lims_clinical_data: viewTestMasterState.data.lims_clinical_data,
                max_age: viewTestMasterState.data.max_age,
                sample_validity_storage: viewTestMasterState.data.sample_validity_storage,
                dna_validity_storage: viewTestMasterState.data.dna_validity_storage,
                flow: viewTestMasterState.data.flow,
                display: viewTestMasterState.data.display,
            })
            setFormData({
                ...formData,
                ["lilac_test_id"]: viewTestMasterState.data.lilac_test_id,
                ["therapeutic_category"]: viewTestMasterState.data.therapeutic_category,
                ["patient_stage"]: viewTestMasterState.data.patient_stage,
                ["type_of_patient"]: viewTestMasterState.data.type_of_patient,
                ["test_group"]: viewTestMasterState.data.test_group ? viewTestMasterState.data.test_group._id : null,
                ["sub_group"]: viewTestMasterState.data.sub_group,
                ["test_name"]: viewTestMasterState.data.test_name,
                ["keywords_or_aliases"]: viewTestMasterState.data.keywords_or_aliases,
                ["description"]: viewTestMasterState.data.description,
                ["details"]: viewTestMasterState.data.details,
                ["sample_type"]: viewTestMasterState.data.sample_type ? viewTestMasterState.data.sample_type : null,
                ["technology"]: viewTestMasterState.data.technology,
                ["outcomes"]: viewTestMasterState.data.outcomes,
                ["resampling_required_or_possible"]: viewTestMasterState.data.resampling_required_or_possible,
                ["tat"]: viewTestMasterState.data.tat,
                ["can_be_outsourced"]: viewTestMasterState.data.can_be_outsourced,
                ["outsource_partner"]: viewTestMasterState.data.outsource_partner,
                ["outsource_partner_category"]: viewTestMasterState.data.outsource_partner_category,
                ["cprt"]: viewTestMasterState.data.cprt?.toString(),
                ["can_book_by_patient"]: viewTestMasterState.data.can_book_by_patient,
                ["display_test_name"]: viewTestMasterState.data.display_test_name,
                ["mandatory_attachments"]: viewTestMasterState.data.mandatory_attachments.map((attachment) => attachment.variable),
                ["non_mandatory_attachments"]: viewTestMasterState.data.non_mandatory_attachments.map((attachment) => attachment.variable),
                ["sex"]: viewTestMasterState.data.sex,
                ["trimester_test"]: viewTestMasterState.data.trimester_test,
                ["tier"]: viewTestMasterState.data.tier,
                ["sample_collection_charges"]: viewTestMasterState.data.sample_collection_charges?.toString(),
                ["gestation_age_start_week"]: viewTestMasterState.data.gestation_age_start_week?.toString(),
                ["gestation_age_end_week"]: viewTestMasterState.data.gestation_age_end_week?.toString(),
                ["gestation_age_start_day"]: viewTestMasterState.data.gestation_age_start_day?.toString(),
                ["gestation_age_end_day"]: viewTestMasterState.data.gestation_age_end_day?.toString(),
                ["mrp"]: viewTestMasterState.data.mrp?.toString(),
                ["transfer_rate"]: viewTestMasterState.data.transfer_rate?.toString(),
                ["category_for_test_directory"]: viewTestMasterState.data.category_for_test_directory,
                ["multiple_sample"]: viewTestMasterState.data.multiple_sample,
                ["multiple_patient"]: viewTestMasterState.data.multiple_patient,
                ["multiple_report"]: viewTestMasterState.data.multiple_report,
                ["multiple_report_count"]: viewTestMasterState.data.multiple_report_count?.toString(),
                ["combo_test_codes"]: viewTestMasterState.data.combo_test_codes,
                ["lims_clinical_data"]: viewTestMasterState.data.lims_clinical_data,
                ["max_age"]: viewTestMasterState.data.max_age,
                ["sample_validity_storage"]: viewTestMasterState.data.sample_validity_storage,
                ["dna_validity_storage"]: viewTestMasterState.data.dna_validity_storage,
                ["flow"]: viewTestMasterState.data.flow,
                ["display"]: viewTestMasterState.data.display,
                // ["speciality"]: viewTestMasterState.data.speciality,
                // ["pcpndt"]: viewTestMasterState.data.pcpndt,
                // ["home_collection_possible"]: viewTestMasterState.data.home_collection_possible,
                // ["test_cancellation_rules"]: viewTestMasterState.data.test_cancellation_rules,
                // ["sample_journey_statuses"]: viewTestMasterState.data.sample_journey_statuses,
                // ["stagewise_process"]: viewTestMasterState.data.stagewise_process,
                // ["stagewise_process_tat"]: viewTestMasterState.data.stagewise_process_tat,
                // ["processing_center"]: viewTestMasterState.data.processing_center,
                // ["direct_consumer_booking_scope"]: viewTestMasterState.data.direct_consumer_booking_scope,
                // ["twin_condition"]: viewTestMasterState.data.twin_condition,
                // ["is_pre_eclampsia"]: viewTestMasterState.data.is_pre_eclampsia,
                // ["is_thalasseima"]: viewTestMasterState.data.is_thalasseima,
                // ["twin_allowed"]: viewTestMasterState.data.twin_allowed,
                // ["test_collection_charges"]: viewTestMasterState.data.test_collection_charges,
                // ["twin_with_donor_egg"]: viewTestMasterState.data.twin_with_donor_egg,
                // ["sub_sample"]: viewTestMasterState.data.sub_sample,
                // ["can_book_by_patient_test_display_name"]: viewTestMasterState.data.can_book_by_patient_test_display_name,
                // ["b2b_test_display_name"]: viewTestMasterState.data.b2b_test_display_name,
                // ["t1_10"]: viewTestMasterState.data.t1_10,
                // ["multiple_sample_type"]: viewTestMasterState.data.multiple_sample_type,
            })
            setAttachments({mandatory_attachments:viewTestMasterState.data.mandatory_attachments,
                non_mandatory_attachments:viewTestMasterState.data.non_mandatory_attachments})            
        }
    }, [viewTestMasterState,getCommonMasterListState])

    useEffect(() => {
        if (saveTestMasterState.apiState === "success") {
            notification["success"]({
                message: saveTestMasterState.message,
                placement: "topRight"
            })
            setRedirect([true, "/test-master/list"])
            saveTestMasterReset()

        }
        if (saveTestMasterState.apiState === "error") {
            notification["error"]({
                message: saveTestMasterState.message,
                placement: "topRight"
            })
            saveTestMasterReset()
        }

    }, [saveTestMasterState])

    // functions
    const handleChange = e => {
        setFormData({
            ...formData, [e.target.name]: e.target.value
        })
    }

    const handleSelect = (v, name, l) => {
        if (name === "mandatory_attachments" || name === "non_mandatory_attachments" ) {
            attachments[name] = l.map((attachment)=>{return {variable:attachment.value, display:attachment.label}})
            // console.log(attachments);
            setAttachments(attachments)
        }

        if(name=="test_group")
        {
            if(getCommonMasterListState.apiState === "success")
            {
                setSubGroupList(_.find(_.find(getCommonMasterListState.list,{slug:"test-group"})?.common_master_detail,{_id:v})?.test_sub_group)
            }
        }

        if(name=="sub_group"&&getSubGroupListState.apiState=="success"&&getSubGroupListState.data.length>0)
        {
            let flow = _.find(getSubGroupListState.data,{sub_group:v}).flow;
            setFormData({
                ...formData,
                [name]: v,
                ["flow"]: flow
            })
            form.setFieldsValue({flow:flow})
        }
        else
        {
            setFormData({
                ...formData,
                [name]: v
            })
        }
        
    }

    const handleSubmit = () => {
        formData.mandatory_attachments = attachments.mandatory_attachments
        formData.non_mandatory_attachments = attachments.non_mandatory_attachments
        // console.log(formData);
        saveTestMaster(formData)
    }


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <Header
                backButton={"/test-master/list"}
                title={mode === "edit" ? "Edit Test Master" : "Add Test Master"}
                subHeaderExists={false}
            />
            <PageContainer>

                <ScrollBox>
                    <LayoutContainer>
                        {
                            checkRights("test_master", "add") ?
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={handleSubmit}
                                >
                                    <Row gutter={20}>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="LIMS ID"
                                                name="lilac_test_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                    () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please input alphabet or number!')); }, })]}
                                            >
                                                <Input placeholder="Enter Test Id" onChange={handleChange} name="lilac_test_id" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Therapeutic category/ Disorder Category"
                                                name="therapeutic_category"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="therapeutic_category"
                                                    onSelect={(v) => handleSelect(v, "therapeutic_category")}
                                                    placeholder="Select Therapeutic category/ Disorder Category"
                                                >
                                                    <Select.Option value={"Reproductive"} >Reproductive</Select.Option>
                                                    <Select.Option value={"Metabolic"} >Metabolic</Select.Option>Metabolic
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Patient Stage"
                                                name="patient_stage"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    name="patient_stage"
                                                    onSelect={(v) => handleSelect(v, "patient_stage")}
                                                    placeholder="Select Therapeutic category/ Disorder Category"
                                                >
                                                    <Select.Option value={"adult"} >Adult</Select.Option>
                                                    <Select.Option value={"all"} >All</Select.Option>
                                                    <Select.Option value={"neonatal"} >Neonatal</Select.Option>
                                                    <Select.Option value={"pediatric"} >Pediatric</Select.Option>
                                                    <Select.Option value={"poc"} >POC</Select.Option>
                                                    <Select.Option value={"postnatal"} >Postnatal</Select.Option>
                                                    <Select.Option value={"preconception"} >Preconception</Select.Option>
                                                    <Select.Option value={"prenatal"} >Prenatal</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Type of patient"
                                                name="type_of_patient"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    mode="multiple"
                                                    showSearch
                                                    name="type_of_patient"
                                                    onSelect={(v) => handleSelect(v, "type_of_patient")}
                                                    placeholder="Select Type of patient"
                                                >
                                                    <Select.Option value={"children"} >Children</Select.Option>
                                                    <Select.Option value={"male"} >Male</Select.Option>
                                                    <Select.Option value={"female"} >Female</Select.Option>
                                                    <Select.Option value={"pregnant_females"} >Pregnant Females</Select.Option>
                                                    <Select.Option value={"others"} >Others</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Test Group"
                                                name="test_group"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="test_group"
                                                    onSelect={(v) => handleSelect(v, "test_group")}
                                                    placeholder="Select Test Group"
                                                >
                                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "test-group" })) &&
                                                        <>
                                                            {_.find(getCommonMasterListState.list, { slug: "test-group" }).common_master_detail.map((item) => (
                                                                <Select.Option value={item._id} >{item.value}</Select.Option>
                                                            ))}

                                                        </>
                                                    }

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Sub Group"
                                                name="sub_group"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="sub_group"
                                                    onSelect={(v) => handleSelect(v, "sub_group")}
                                                    placeholder="Select Sub Group"
                                                >
                                                    {subGroupList.length > 0 && subGroupList.map((item)=>(<Select.Option value={item}>{item}</Select.Option>))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Test Name"
                                                name="test_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter Test Name" onChange={handleChange} name="test_name" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Keyword/ Aliases"
                                                name="keywords_or_aliases"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                    () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z\s\,]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please input alphabet!')); }, })
                                                ]}
                                            >
                                                <Input placeholder="Enter Keyword/ Aliases" onChange={handleChange} name="keywords_or_aliases" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Test Description"
                                                name="description"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter Test Description" onChange={handleChange} name="description" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Details of the Test"
                                                name="details"
                                            >
                                                <Input placeholder="Enter Details of the Test" onChange={handleChange} name="details" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Sample type"
                                                name="sample_type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },

                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="sample_type"
                                                    onChange={(v) => handleSelect(v, "sample_type")}
                                                    placeholder="Select Sample type"
                                                    allowClear
                                                    filterOption={false}
                                                    mode="multiple"
                                                    onSearch={_.debounce(value => {
                                                        getSampleList({ searchTerm: value, dropDownList: "/get-dropdown-list" })
                                                    }, 500)}
                                                    notFoundContent={getSampleListState.apiState === "loading" && <Spin size="small" />}
                                                >
                                                    {getSampleListState.list.map((item, index) => {
                                                        return (
                                                            < Select.Option value={`${item._id}`
                                                            } key={`${index}`}>{item.sample_type}</Select.Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Technology"
                                                name="technology"
                                            >
                                                <Input placeholder="Enter Technology" onChange={handleChange} name="technology" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Outcomes"
                                                name="outcomes"
                                            >
                                                <Select
                                                    showSearch
                                                    name="outcomes"
                                                    onSelect={(v) => handleSelect(v, "outcomes")}
                                                    placeholder="Select Outcomes"
                                                >
                                                    <Select.Option value={"Low Risk"} >Low Risk</Select.Option>
                                                    <Select.Option value={"Intermediate Risk"} >Intermediate Risk</Select.Option>
                                                    <Select.Option value={"High Risk"} >High Risk</Select.Option>
                                                    <Select.Option value={"Normal"} >Normal</Select.Option>
                                                    <Select.Option value={"Abnormal"} >Abnormal</Select.Option>
                                                    <Select.Option value={"Failed"} >Failed</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Resampling required/ possible"
                                                name="resampling_required_or_possible"
                                                rules={[
                                                    // {
                                                    //     required: true,
                                                    //     message: "Required"
                                                    // },
                                                ]}
                                            >
                                                <Switch checked={formData.resampling_required_or_possible} onChange={(v) => handleSelect(v, "resampling_required_or_possible")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="TAT (in hours)"
                                                name="tat"
                                                rules={[

                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input number!')); }, })
                                                ]}
                                            >
                                                <Input placeholder="Enter TAT (in hours)" onChange={handleChange} name="tat" />
                                            </Form.Item>
                                        </Col>
                                        {/* tested */}
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Can be Outsourced"
                                                name="can_be_outsourced"
                                                
                                            >
                                                <Switch checked={formData.can_be_outsourced} onChange={(v) => handleSelect(v, "can_be_outsourced")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                               label="Outsource Partner"
                                                name="outsource_partner"
                                               /* rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                    () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9\s]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please input alphabet or number!')); }, })
                                                ]}*/
                                            >
                                                <Select
                                                    showSearch
                                                    name="outsource_partner"
                                                    onChange={(v) => handleSelect(v, "outsource_partner")}
                                                    placeholder="Select Outsource Partner"
                                                >
                                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "outsource-partner" })) &&
                                                        <>
                                                        {_.find(getCommonMasterListState.list, { slug: "outsource-partner" }).common_master_detail.map((item) => (
                                                                <Select.Option value={item.value} >{item.value}</Select.Option>
                                                            ))}

                                                        </>
                                                    }

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Outsource Partner Category"
                                                name="outsource_partner_category"
                                              /*  rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                    () => ({ validator(_, value) { if (!value || value.match(/^[A-Za-z0-9\s\,]+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please input alphabet or number!')); }, })
                                                ]}*/
                                            >
                                                <Input placeholder="Enter Outsource Partner Category" onChange={handleChange} name="outsource_partner_category" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="CPRT"
                                                name="cprt"
                                                rules={[
                                                    // { type: "number" },
                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, })
]}

                                            >
                                                <Input placeholder="Enter CPRT" onChange={handleChange} name="cprt" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="B2C"
                                                name="can_book_by_patient"
                                                /*rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}*/
                                            >
                                                <Switch checked={formData.can_book_by_patient} onChange={(v) => handleSelect(v, "can_book_by_patient")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Display Test Name"
                                                name="display_test_name"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter Display Test Name" onChange={handleChange} name="display_test_name" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Mandatory attachments"
                                                name="mandatory_attachments"
                                                rules={[
                                                    // {
                                                    //     required: true,
                                                    //     message: "Required"
                                                    // },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="mandatory_attachments"
                                                    onChange={(v,l) => handleSelect(v, 'mandatory_attachments',l)}
                                                    optionLabelProp="label"
                                                    mode="multiple"
                                                    placeholder="Select Mandatory attachments"
                                                >
                                                    <Option value="nt_image" label="NT image"></Option>
                                                    <Option value="ultrasound_report" label="Ultrasound report"></Option>
                                                    <Option value="reports_related_to_previous_history_if_any" label="Reports related to previous history if any"></Option>
                                                    <Option value="nt_images" label="NT images"></Option>
                                                    <Option value="cbc_report" label="CBC report"></Option>
                                                    <Option value="any_previous_history" label="Any Previous History"></Option>
                                                    <Option value="patient_informed_consent_form" label="Patient informed consent form"></Option>
                                                    <Option value="usg_report" label="USG Report"></Option>
                                                    <Option value="doctors_prescription_or_referral_note" label="Doctor's prescription or referral note"></Option>
                                                    <Option value="nationalized_id_proof_of_the_patient" label="Nationalized ID proof of the patient."></Option>
                                                    <Option value="clincal_reports" label="Clincal Reports"></Option>
                                                    <Option value="doctors_attestation_on_the_trf" label="Doctor's attestation on the TRF"></Option>
                                                    <Option value="patient_informed_consent" label="Patient informed consent"></Option>
                                                    <Option value="clinical_reports" label="Clinical reports"></Option>
                                                    <Option value="gc_summary" label="GC summary"></Option>
                                                    <Option value="prior_report_with_variant_details" label="Prior report with variant details"></Option>
                                                    <Option value="usg_report_previous_pregnancy" label="USG report (previous pregnancy)"></Option>
                                                    <Option value="other_clinical_reports" label="Other Clinical reports"></Option>
                                                    <Option value="form_of_consent_formg_duly_signed_by_patient_along_with_doctors_seal_and_signature" label="Form of consent (FormG) -Duly signed by patient along with Doctor's seal and signature"></Option>
                                                    <Option value="doctor_acknowledgement_form" label="Doctor Acknowledgement Form"></Option>
                                                    <Option value="pre_pgt_work_up_test_confirmation_or_reports" label="Pre PGT Work up test confirmation or reports"></Option>
                                                    <Option value="raw_data_retrival_form" label="Raw data retrival form"></Option>
                                                    <Option value="clinical_reports_for_which_raw_data_is_requested" label="Clinical reports for which Raw data is requested"></Option>
                                                    <Option value="clinical_reports_for_which_data_reanalysis_is_requested" label="Clinical reports for which Data re-analysis is requested"></Option>
                                                    <Option value="any_clinical_reports" label="any clinical reports"></Option>
                                                    <Option value="hplc_reports" label="HPLC reports"></Option>
                                                    <Option value="husbands_hplc_reports" label="Husband's HPLC reports"></Option>
                                                    <Option value="wifes_hplc_reports" label="Wife's HPLC reports"></Option>
                                                    <Option value="husbands_mutation_reports" label="Husband's Mutation reports"></Option>
                                                    <Option value="wifes_mutation_reports" label="Wife's Mutation reports"></Option>
                                                    <Option value="reports_pertaining_to_male_infertility" label="Reports pertaining to Male Infertility"></Option>
                                                    <Option value="maternal_antibody_screening_reports" label="Maternal antibody screening reports"></Option>
                                                    <Option value="clinical_reports_or_gc_summary" label="Clinical reports or GC summary"></Option>
                                                    <Option value="parental_mutation_report" label="Parental mutation report"></Option>
                                                    <Option value="parental_or__previous_childs_hla_typing_report" label="Parental or  previous child's HLA typing report"></Option>
                                                    <Option value="any_previous_clinical_reports" label="Any previous clinical reports"></Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Non Mandatory attachments"
                                                name="non_mandatory_attachments"
                                                rules={[
                                                    // {
                                                    //     required: true,
                                                    //     message: "Required"
                                                    // },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="non_mandatory_attachments"
                                                    onChange={(v,l) => handleSelect(v, 'non_mandatory_attachments',l)}
                                                    optionLabelProp="label"
                                                    mode="multiple"
                                                    placeholder="Select Non Mandatory attachments"
                                                >
                                                    <Option value="nt_image" label="NT image"></Option>
                                                    <Option value="ultrasound_report" label="Ultrasound report"></Option>
                                                    <Option value="reports_related_to_previous_history_if_any" label="Reports related to previous history if any"></Option>
                                                    <Option value="nt_images" label="NT images"></Option>
                                                    <Option value="cbc_report" label="CBC report"></Option>
                                                    <Option value="any_previous_history" label="Any Previous History"></Option>
                                                    <Option value="patient_informed_consent_form" label="Patient informed consent form"></Option>
                                                    <Option value="usg_report" label="USG Report"></Option>
                                                    <Option value="doctors_prescription_or_referral_note" label="Doctor's prescription or referral note"></Option>
                                                    <Option value="nationalized_id_proof_of_the_patient" label="Nationalized ID proof of the patient."></Option>
                                                    <Option value="clincal_reports" label="Clincal Reports"></Option>
                                                    <Option value="doctors_attestation_on_the_trf" label="Doctor's attestation on the TRF"></Option>
                                                    <Option value="patient_informed_consent" label="Patient informed consent"></Option>
                                                    <Option value="clinical_reports" label="Clinical reports"></Option>
                                                    <Option value="gc_summary" label="GC summary"></Option>
                                                    <Option value="prior_report_with_variant_details" label="Prior report with variant details"></Option>
                                                    <Option value="usg_report_previous_pregnancy" label="USG report (previous pregnancy)"></Option>
                                                    <Option value="other_clinical_reports" label="Other Clinical reports"></Option>
                                                    <Option value="form_of_consent_formg_duly_signed_by_patient_along_with_doctors_seal_and_signature" label="Form of consent (FormG) -Duly signed by patient along with Doctor's seal and signature"></Option>
                                                    <Option value="doctor_acknowledgement_form" label="Doctor Acknowledgement Form"></Option>
                                                    <Option value="pre_pgt_work_up_test_confirmation_or_reports" label="Pre PGT Work up test confirmation or reports"></Option>
                                                    <Option value="raw_data_retrival_form" label="Raw data retrival form"></Option>
                                                    <Option value="clinical_reports_for_which_raw_data_is_requested" label="Clinical reports for which Raw data is requested"></Option>
                                                    <Option value="clinical_reports_for_which_data_reanalysis_is_requested" label="Clinical reports for which Data re-analysis is requested"></Option>
                                                    <Option value="any_clinical_reports" label="any clinical reports"></Option>
                                                    <Option value="hplc_reports" label="HPLC reports"></Option>
                                                    <Option value="husbands_hplc_reports" label="Husband's HPLC reports"></Option>
                                                    <Option value="wifes_hplc_reports" label="Wife's HPLC reports"></Option>
                                                    <Option value="husbands_mutation_reports" label="Husband's Mutation reports"></Option>
                                                    <Option value="wifes_mutation_reports" label="Wife's Mutation reports"></Option>
                                                    <Option value="reports_pertaining_to_male_infertility" label="Reports pertaining to Male Infertility"></Option>
                                                    <Option value="maternal_antibody_screening_reports" label="Maternal antibody screening reports"></Option>
                                                    <Option value="clinical_reports_or_gc_summary" label="Clinical reports or GC summary"></Option>
                                                    <Option value="parental_mutation_report" label="Parental mutation report"></Option>
                                                    <Option value="parental_or__previous_childs_hla_typing_report" label="Parental or  previous child's HLA typing report"></Option>
                                                    <Option value="any_previous_clinical_reports" label="Any previous clinical reports"></Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        {/* tested */}
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Sex"
                                                name="sex"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="sex"
                                                    onChange={(v) => handleSelect(v, "sex")}
                                                    placeholder="Select Sex"
                                                    optionLabelProp="label"
                                                    mode="multiple"
                                                >
                                                    <Select.Option value={"Male"}  >Male</Select.Option>
                                                    <Select.Option value={"Female"}  >Female</Select.Option>
                                                    <Select.Option value={"Others"}  >Others</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Trimester"
                                                name="trimester_test"
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: "Required"
                                                //     },
                                                // ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="trimester_test"
                                                    onSelect={(v) => handleSelect(v, "trimester_test")}
                                                    placeholder="Select Trimester"
                                                >
                                                    <Select.Option value={"First"} >First</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Tier"
                                                name="tier"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="tier"
                                                    onSelect={(v) => handleSelect(v, "tier")}
                                                    placeholder="Select Tier"
                                                >
                                                    <Select.Option value={"T1"} >T1</Select.Option>
                                                    <Select.Option value={"T2"} >T2</Select.Option>
                                                    <Select.Option value={"T3"} >T3</Select.Option>
                                                    <Select.Option value={"T4"} >T4</Select.Option>
                                                    <Select.Option value={"T5"} >T5</Select.Option>
                                                    <Select.Option value={"T6"} >T6</Select.Option>
                                                    <Select.Option value={"T7"} >T7</Select.Option>
                                                    <Select.Option value={"T8"} >T8</Select.Option>
                                                    <Select.Option value={"T9"} >T9</Select.Option>
                                                    <Select.Option value={"T10"} >T10</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Sample Collection Charges"
                                                name="sample_collection_charges"
                                                rules={[
                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, })
                                                ]}
                                            >
                                                <Input placeholder="Enter Sample Collection Charges" onChange={handleChange} name="sample_collection_charges" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Gestation Age Start Week"
                                                name="gestation_age_start_week"
                                                rules={[
                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, })

                                                ]}
                                            >
                                                <Input placeholder="Enter Gestation Age Start Week" onChange={handleChange} name="gestation_age_start_week" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Gestation Age End Week"
                                                name="gestation_age_end_week"
                                                rules={[
                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, })

                                                ]}
                                            >
                                                <Input placeholder="Enter Gestation Age End Week" onChange={handleChange} name="gestation_age_end_week" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Gestation Age Start Day"
                                                name="gestation_age_start_day"
                                                rules={[                  
                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, })
                                                ]}
                                            >
                                                <Input placeholder="Enter Gestation Age Start Day" onChange={handleChange} name="gestation_age_start_day" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Gestation Age End Day"
                                                name="gestation_age_end_day"
                                                rules={[
                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, })
                                                ]}
                                            >
                                                <Input placeholder="Enter Gestation Age End Day" onChange={handleChange} name="gestation_age_end_day" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="MRP"
                                                name="mrp"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required",
                                                    },
                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, })

                                                ]}
                                            >
                                                <Input placeholder="Enter MRP" onChange={handleChange} name="mrp" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Transfer Rate"
                                                name="transfer_rate"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required",
                                                    },
                                                    () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter Numbers only!')); }, })

                                                ]}
                                            >
                                                <Input placeholder="Enter Transfer Rate" onChange={handleChange} name="transfer_rate" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Category for Test Directory"
                                                name="category_for_test_directory"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                {/* <Input placeholder="Enter Category For Test Directory" onChange={handleChange} name="category_for_test_directory" /> */}
                                                <Select
                                                    showSearch
                                                    name="category_for_test_directory"
                                                    onSelect={(v) => handleSelect(v, "category_for_test_directory")}
                                                    placeholder="Select Category for Test Directory"
                                                >
                                                    {(getCommonMasterListState.apiState === "success" && _.find(getCommonMasterListState.list, { slug: "category-for-test-directory" })) &&
                                                        <>
                                                            {_.find(getCommonMasterListState.list, { slug: "category-for-test-directory" }).common_master_detail.map((item) => (
                                                                <Select.Option value={item._id} >{item.value}</Select.Option>
                                                            ))}

                                                        </>
                                                    }

                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Multiple Sample"
                                                name="multiple_sample"
                                              /*  rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}*/
                                            >
                                                <Switch checked={formData.multiple_sample} onChange={(v) => handleSelect(v, "multiple_sample")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Multiple Patient"
                                                name="multiple_patient"
                                              /*    rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}*/
                                            >
                                                <Switch checked={formData.multiple_patient} onChange={(v) => handleSelect(v, "multiple_patient")} />
                                            </Form.Item>
                                        </Col>  
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Multiple Report"
                                                name="multiple_report"
                                               /* rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}*/
                                            >
                                                <Switch checked={formData.multiple_report} onChange={(v) => handleSelect(v, "multiple_report")} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Number of Reports"
                                                name="multiple_report_count"
                                                rules={[
                                                    {
                                                        required: formData.multiple_report ? true : false,
                                                        message: "Required"
                                                    },
                                                    ({ getFieldValue }) => ({ validator(_, value) { 
                                                        if (!value || value.match(/^\d+$/)) { 
                                                            return Promise.resolve();
                                                        } return Promise.reject(new Error('* Please enter Numbers!')); }, })
                                                ]}
                                            >
                                                <Input placeholder="Enter number of reports" onChange={handleChange} name="multiple_report_count" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Combo Test Codes"
                                                name="combo_test_codes"
                                            >
                                                <Input placeholder="Combo Test Codes" onChange={handleChange} name="ombo_test_codes" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Lims Clinical Data"
                                                name="lims_clinical_data"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="lims_clinical_data"
                                                    onSelect={(v) => handleSelect(v, "lims_clinical_data")}
                                                    placeholder="Select Lims Clinical Data"
                                                >
                                                    <Select.Option value={"Y"} >Y</Select.Option>
                                                    <Select.Option value={"N"} >N</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Max age"
                                                name="max_age"
                                                rules={[
                                                    // () => ({ validator(_, value) { if (!value || value.match(/^\d+$/)) { return Promise.resolve(); } return Promise.reject(new Error('* Please enter input Number!')); }, })
                                                ]}
                                            >
                                                <Input placeholder="Enter Max age" onChange={handleChange} name="max_age" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Sample Validity/Storage"
                                                name="sample_validity_storage"
                                            >
                                                <Input placeholder="Sample Validity/Storage" onChange={handleChange} name="sample_validity_storage" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="DNA Validity/Storage"
                                                name="dna_validity_storage"
                                            >
                                                <Input placeholder="DNA Validity/Storage" onChange={handleChange} name="dna_validity_storage" />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Flow"
                                                name="flow"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="flow"
                                                    onSelect={(v) => handleSelect(v, "flow")}
                                                    placeholder="Select Flow"
                                                    disabled={true}
                                                >
                                                    <Select.Option value={"1"} >1</Select.Option>
                                                    <Select.Option value={"2"} >2</Select.Option>
                                                    <Select.Option value={"3"} >3</Select.Option>
                                                    <Select.Option value={"4"} >4</Select.Option>
                                                    <Select.Option value={"5"} >5</Select.Option>
                                                    <Select.Option value={"6"} >6</Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                                            <Form.Item
                                                label="Display"
                                                name="display"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Required"
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    name="display"
                                                    onSelect={(v) => handleSelect(v, "display")}
                                                    placeholder="Select Display"
                                                >
                                                    <Select.Option value={"0"} >0</Select.Option>
                                                    <Select.Option value={"1"} >1</Select.Option>
                                                    
                                                   
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item                            >
                                        <Button type="primary" loading={saveTestMasterState.apiState === "loading" ? true : false} htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                                :
                                <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                                    <p>UnAuthorized User</p>
                                    <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                                </div>

                        }
                    </LayoutContainer>
                </ScrollBox>

            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    saveTestMasterState: state.saveTestMaster,
    viewTestMasterState: state.viewTestMaster,
    getCommonMasterListState: state.getCommonMasterList,
    getSampleListState: state.getSampleList,
    getSubGroupListState: state.getSubGroupList,
});

const mapDispatchToProps = (dispatch) => ({
    saveTestMaster: (params) => dispatch(saveTestMaster(params)),
    saveTestMasterReset: () => dispatch(saveTestMasterReset()),
    viewTestMaster: (params) => dispatch(viewTestMaster(params)),
    viewTestMasterReset: () => dispatch(viewTestMasterReset()),
    getCommonMasterList: (params) => dispatch(getCommonMasterList(params)),
    getCommonMasterListReset: () => dispatch(getCommonMasterListReset()),
    getSampleList: (params) => dispatch(getSampleList(params)),
    getSampleListReset: () => dispatch(getSampleListReset()),
    getSubGroupList: (params) => dispatch(getSubGroupList(params)),
    getSubGroupListReset: () => dispatch(getSubGroupListReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestMasterAdd);
