import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_PINCODE_DETAIL,
    getPincodeDetailFail,
    getPincodeDetailSuccess
} from '../action/getPincodeDetailAction'

// api 
import getPincodeDetailApi from '../api/getPincodeDetailApi'

export function* getPincodeDetailSaga(action) {
    try {
        const response = yield call(() => getPincodeDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getPincodeDetailSuccess(response, action))
        } else {
            yield put(getPincodeDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getPincodeDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_PINCODE_DETAIL, getPincodeDetailSaga)
}