import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_QUICK_SUPPORT_CATEGORY,
    saveQuickSupportCategoryFail,
    saveQuickSupportCategorySuccess
} from '../action/saveQuickSupportCategoryAction'

// api 
import saveQuickSupportCategoryApi from '../api/saveQuickSupportCategoryApi'

export function* saveQuickSupportCategorySaga(action) {
    try {
        const response = yield call(() => saveQuickSupportCategoryApi(action.params))
        if (response.data.status === 1) {
            yield put(saveQuickSupportCategorySuccess(response, action))
        } else {
            yield put(saveQuickSupportCategoryFail(response, action))
        }
    }
    catch (e) {
        yield put(saveQuickSupportCategoryFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_QUICK_SUPPORT_CATEGORY, saveQuickSupportCategorySaga)
}