import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_ARTICLE_DETAIL,
    getArticleDetailFail,
    getArticleDetailSuccess
} from '../action/getArticleDetailAction'

// api 
import getArticleDetailApi from '../api/getArticleDetailApi'

export function* getArticleDetailSaga(action) {
    try {
        const response = yield call(() => getArticleDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getArticleDetailSuccess(response, action))
        } else {
            yield put(getArticleDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getArticleDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_ARTICLE_DETAIL, getArticleDetailSaga)
}