import React from "react"
/* custom component */
import Header from "../../../../component/Header/Header"
import { PageContainer, LayoutContainer } from "../../../../component/Xcomponent"
import ContentRepoAdd from "../../../../component/ContentRepo/ContentRepoAdd/ContentRepoAdd";

export const ContentRepoTribeAdd = props => {
    /* variables */
    const article_id = props.match.params.article_id
    const mode = article_id ? "edit" : "add"

    return (
        <>

            <Header
                backButton={"/content-repo-tribe/list"}
                title={mode === "edit" ? "Edit Content Repo Tribe" : "Add Content Repo Tribe"}
            />
            <PageContainer>
                <LayoutContainer>
                    <ContentRepoAdd pageUrl={`content-repo-tribe`} pageType="Tribe" article_id={article_id} />
                </LayoutContainer>
            </PageContainer>
        </>
    )
}

export default ContentRepoTribeAdd;