import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination } from "../../../component/Xcomponent"
import PatientMasterView from "../PatientMasterView/PatientMasterView"

/* actions */
import { getPatientList, getPatientListReset } from "../../../action/getPatientListAction"
import { dateFormat } from "../../../utils";

const PatientMasterList = props => {
    /* variables */
    const {
        getPatientList, getPatientListReset, getPatientListState,
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    const columns = [
        {
            title: 'Patient ID',
            dataIndex: 'patient_id',
            key: 'patient_id',
            ...getColumnSearchProps('patient_id', 'Patient ID')
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name', 'Name')
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            ...getColumnSearchProps('gender', 'Gender')
        },
        {
            title: 'DOB',
            dataIndex: 'dob',
            key: 'dob',
            ...getColumnSearchProps('dob', 'DOB')
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/patient-master/save/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                </Space>
            )
        },
    ];

    /* callbacks */
    useEffect(() => {
        return (() => {
            getPatientListReset()
        })
    }, [])
    useEffect(() => {
        getPatientList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getPatientListState.apiState === "success") {
            let tableData = []
            getPatientListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        patient_id: row.patient_id,
                        name: row.name?.firstName + " " + row.name?.lastName,
                        gender: row.gender,
                        dob: dateFormat(row.dob),
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getPatientListState])

    useEffect(() => {
        setRedirect([true, `/patient-master/list?page=${pagination.page}`])
    }, [pagination])



    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            <PageContainer>
                {getPatientListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getPatientListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getPatientListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768,y:600 }}
                            loading={getPatientListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getPatientListState.pagination?.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <PatientMasterView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getPatientListState: state.getPatientList,
});

const mapDispatchToProps = (dispatch) => ({
    getPatientList: (params) => dispatch(getPatientList(params)),
    getPatientListReset: () => dispatch(getPatientListReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(PatientMasterList);