import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_CONTAINER_LIST,
    getContainerListFail,
    getContainerListSuccess
} from '../action/getContainerListAction'

// api 
import getContainerListApi from '../api/getContainerListApi'

export function* getContainerListSaga(action) {
    try {
        const response = yield call(() => getContainerListApi(action.params))
        if (response.data.status === 1) {
            yield put(getContainerListSuccess(response, action))
        } else {
            yield put(getContainerListFail(response, action))
        }
    }
    catch (e) {
        yield put(getContainerListFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_CONTAINER_LIST, getContainerListSaga)
}