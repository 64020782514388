import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    CHANGE_TESTIMONIAL_STATUS,
    changeTestimonialStatusFail,
    changeTestimonialStatusSuccess
} from '../action/changeTestimonialStatusAction'

// api 
import changeTestimonialStatusApi from '../api/changeTestimonialStatusApi'

export function* changeTestimonialStatusSaga(action) {
    try {
        const response = yield call(() => changeTestimonialStatusApi(action.params))
        if (response.data.status === 1) {
            yield put(changeTestimonialStatusSuccess(response, action))
        } else {
            yield put(changeTestimonialStatusFail(response, action))
        }
    }
    catch (e) {
        yield put(changeTestimonialStatusFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(CHANGE_TESTIMONIAL_STATUS, changeTestimonialStatusSaga)
}