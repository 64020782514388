import React, { useState, useEffect } from "react"
import { Redirect, useLocation, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Space, Popconfirm, Button, notification, Input, Menu, Dropdown } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons"
import _ from "lodash"

/* custom component */
import Header from "../../../component/Header/Header"
import { PageContainer, Xtable, Xpagination, XtagTable } from "../../../component/Xcomponent"

/* actions */
import { getContainerList, getContainerListReset } from "../../../action/getContainerListAction"
import { deleteContainer, deleteContainerReset } from "../../../action/deleteContainerAction"
import ContainerView from "../ContainerView/ContainerView";

const ContainerList = props => {
    /* variables */
    const {
        getContainerList, getContainerListReset, getContainerListState,
        deleteContainer, deleteContainerReset, deleteContainerState
    } = props
    const search = useLocation().search;
    const page = new URLSearchParams(search).get('page');
    const [redirect, setRedirect] = useState([false, ""])
    const [refresh, setRefresh] = useState(0)
    const [searchText, setSearchText] = useState({})
    const [pagination, setPagination] = useState({
        page: page ? page : 1,
        records: 25
    })
    const [tableData, setTableData] = useState([])
    const [view, setView] = useState({
        visible: false,
        id: null,
    })
    const getColumnSearchProps = (dataIndex, name) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${name}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => [handleSearch(selectedKeys, confirm, dataIndex), setPagination({ ...pagination, ['page']: 1 })]}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    });
    const columns = [
        {
            title: 'Container Type',
            dataIndex: 'container_type',
            key: 'container_type',
            ...getColumnSearchProps('container_type', 'Container Type')
        },
        {
            title: 'Display Container Name',
            dataIndex: 'display_container_name',
            key: 'display_container_name',
            ...getColumnSearchProps('display_container_name', 'Display Container Name')
        },
        {
            title: 'Container Cap Color',
            dataIndex: 'container_cap_color',
            key: 'container_cap_color',
            ...getColumnSearchProps('container_cap_color', 'Container Cap Color')
        },
        {
            title: 'MRP',
            dataIndex: 'mrp',
            key: 'mrp',
            ...getColumnSearchProps('mrp', 'MRP')
        },
        {
            title: 'SP',
            dataIndex: 'sp',
            key: 'sp',
            ...getColumnSearchProps('sp', 'SP')
        },
        {
            title: 'CP',
            dataIndex: 'cp',
            key: 'cp',
            ...getColumnSearchProps('cp', 'CP')
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id) => (
                <Space size="small">
                    <Button size="small" shape="circle" icon={<EyeOutlined />} onClick={() => setView({ id: id, visible: true })} />
                    <Link to={`/container-master/edit/${id}`}><Button size="small" shape="circle" icon={<EditOutlined />} /></Link>
                    <Popconfirm
                        title="Are you sure to delete this item?"
                        onConfirm={() => deleteContainer({ id: id })}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <Button size="small" shape="circle" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText({
            ...searchText,
            [dataIndex]: selectedKeys[0]
        })
    }
    const handleReset = (clearFilters, dataIndex) => {
        let remainSearch = _.omit(searchText, [dataIndex])
        clearFilters()
        setSearchText(remainSearch)
    }

    /* callbacks */
    useEffect(() => {
        return (() => {
            getContainerListReset()
        })
    }, [])
    useEffect(() => {
        getContainerList({
            dropDownList: "",
            page: pagination.page,
            records: pagination.records,
            ...searchText
        })
    }, [refresh, pagination, searchText])

    useEffect(() => {
        if (getContainerListState.apiState === "success") {
            let tableData = []
            getContainerListState.list.map((row, index) => {
                tableData.push(
                    {
                        key: index,
                        container_type: row.container_type,
                        display_container_name: row.display_container_name,
                        container_cap_color: row.container_cap_color,
                        mrp: row.mrp,
                        sp: row.sp,
                        cp: row.cp,
                        id: row._id,
                    }
                )
            })
            setTableData(tableData)
        }
    }, [getContainerListState])

    useEffect(() => {
        setRedirect([true, `/container-master/list?page=${pagination.page}`])
    }, [pagination])

    useEffect(() => {
        if (deleteContainerState.apiState === "success") {
            deleteContainerReset()
            notification["success"]({
                message: deleteContainerState.message,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
        }
        if (deleteContainerState.apiState === "error") {
            deleteContainerReset()
            notification["error"]({
                message: deleteContainerState.message,
                placement: "topRight"
            })
        }
    }, [deleteContainerState])
    // Functions

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}

            <Header
                title="Container List"
                rightChildren={
                    <>
                        <Link to="/container-master-bulk-upload" ><Button type="primary" ><UploadOutlined />Bulk Upload</Button></Link>
                        <Link to="/container-master/add" ><Button type="primary" ><PlusOutlined />New</Button></Link>
                    </>
                }
            />
            <PageContainer>
                {getContainerListState.apiState === "error" &&
                    <div style={{ height: 500, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                        <p>{getContainerListState.message}</p>
                        <Button type="primary" onClick={() => setRefresh(refresh + 1)} >TRY AGAIN</Button>
                    </div>
                }

                {getContainerListState.apiState !== "error" &&
                    <>
                        <Xtable columns={columns} dataSource={tableData} pagination={false} scroll={{ x: 768 }}
                            loading={getContainerListState.apiState === "loading"}
                        />
                        <Xpagination
                            pageSizeOptions={['25', '50', '100', '500']}
                            showSizeChanger
                            total={getContainerListState.pagination.total_records}
                            onChange={(page, pageSize) => setPagination({ page: page, records: pageSize })}
                            current={pagination.page}
                            defaultPageSize={pagination.records}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                        />
                        <ContainerView id={view.id} visible={view.visible} onClose={() => setView({ visible: false, id: null })} />
                    </>
                }
            </PageContainer>
        </>
    )
}

const mapStateToProps = (state) => ({
    getContainerListState: state.getContainerList,
    deleteContainerState: state.deleteContainer
});

const mapDispatchToProps = (dispatch) => ({
    getContainerList: (params) => dispatch(getContainerList(params)),
    getContainerListReset: () => dispatch(getContainerListReset()),
    deleteContainer: (params) => dispatch(deleteContainer(params)),
    deleteContainerReset: () => dispatch(deleteContainerReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(ContainerList);