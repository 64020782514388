import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    GET_CONTACT_US_ENQURIES_DETAIL,
    getContactUsEnquriesDetailFail,
    getContactUsEnquriesDetailSuccess
} from '../action/getContactUsEnquriesDetailAction'

// api 
import getContactUsEnquriesDetailApi from '../api/getContactUsEnquriesDetailApi'

export function* getContactUsEnquriesDetailSaga(action) {
    try {
        const response = yield call(() => getContactUsEnquriesDetailApi(action.params))
        if (response.data.status === 1) {
            yield put(getContactUsEnquriesDetailSuccess(response, action))
        } else {
            yield put(getContactUsEnquriesDetailFail(response, action))
        }
    }
    catch (e) {
        yield put(getContactUsEnquriesDetailFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(GET_CONTACT_US_ENQURIES_DETAIL, getContactUsEnquriesDetailSaga)
}