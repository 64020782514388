import axios from 'axios'
import config from '../config'
import { getUserToken } from "../utils"

const deleteCommonMasterApi = async (params) => {
    const response = await axios({
        url: `${config.api.base_url}common-master/value`,
        method: "delete",
        data: params,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getUserToken()}`
        }
    })

    return response

}

export default deleteCommonMasterApi