import { call, put, takeLatest } from "redux-saga/effects"

// action
import {
    SAVE_SYSTEM_USER,
    saveSystemUserFail,
    saveSystemUserSuccess
} from '../action/saveSystemUsersAction'

// api 
import saveSystemUserApi from '../api/saveSystemUserApi'

export function* saveSystemUserSaga(action) {
    try {
        const response = yield call(() => saveSystemUserApi(action.params))
        if (response) {
            yield put(saveSystemUserSuccess(response, action))
        }
    }
    catch (e) {
        yield put(saveSystemUserFail(e.response, action))
    }

}

export default function* mySaga() {
    yield takeLatest(SAVE_SYSTEM_USER, saveSystemUserSaga)
}